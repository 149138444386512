import { TableBody, TableCell, TableRow } from '@material-ui/core';
import _ from 'lodash';
import SVGInline from 'react-inlinesvg';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import icons from '../../assets/images/icons';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { ActiveItemState } from '../../redux/slices/activeItemSlice';
import {
    fetchAllLanguageTranslations,
    fetchLanguage,
    fetchLanguageTranslations,
    LanguagesState,
    updateLanguage
} from '../../redux/slices/languagesSlice';
import {
    ApplicationWrapper,
    MainContentWrapper,
    PageActionButton,
    PageActionsWrapper,
    TruncatedText
} from '../../style/styled-components/reusable.css';
import { Language } from '../../types/Language';
import { DIALOG_NAMES, dialogAlert, dialogConfirm, ToastAlert } from '../../utils/fnDialogs';
import { validateTranslations, validator, validTranslationKeyRegex } from '../../utils/fnValidator';
import ScreenTitle from '../common/DashboardTitle/ScreenTitle';
import { DialogDropdownSingle, DialogTextField } from '../common/Dialog/GenericDialog';
import { Loader } from '../common/Loader/Loader';
import PageActions from '../common/PageActions/PageActions';
import { SearchBar, SearchBarHandle } from '../common/SearchBar/SearchBar';
import Sidebar from '../common/Sidebar/Sidebar';
import GenericTable, { ActionsTableCell, SortableHeaderTableCell, tableActions } from '../common/Table/Table';
import { WidthTableCell } from '../common/Table/Table.css';
import {
    AddTranslationLabelWrapper,
    FilterTranslationsContainer,
    FilterTranslationsWrapper,
    TranslationfilterIcon,
    TranslationsInputWrapper,
    TranslationsKeyAndDescriptionContainer,
    TranslationsPageActions,
    TranslationsPaginationBottomContainer,
    TranslationsPaginationTopContainer,
    TranslationsStatisticsContainer
} from './Languages.css';
import { PageNameContainer } from '../common/DashboardTitle/DashboardTitle.css';
import BackendErrorDialog from '../common/Dialog/BackendErrorDialog';
import useBlockNavigation from '../../hooks/useBlockNavigation';
import { translationsParser } from '../../utils/parsers';
import Pagination from '../common/Pagination/Pagination';
import useScreenSize from '../../hooks/useScreenSize';
import useLockSystem, { LockableObjectTypes } from '../../hooks/useLockSystem';
import { renderLockedError, renderLockedWarningAlert, renderLockIcon } from '../../utils/fnLockingSystem';
import { API_ERROR_CODES } from '../../utils/Globals';
import { buildPathWithProjectId, PageRoutes } from '../../types/RouteTypes';
import { calculateOrderByFromSortConfig, DEFAULT_SORT_CONFIG_FOR_TRANSLATIONS, ISortConfig } from '../../utils/fnSort';
import { NewTranslation } from '../Translations/NewTranslationKeyDialog';
import { ButtonIcon } from '../Buttons/Button/Button.css';
import { renderTooltip, tooltipTypes } from '../common/Tooltips/Tooltips';
import { ObjectFilter } from '../../utils/fnFilter';

enum TranslationsFilters {
    ALL = 'all',
    TRANSLATED = 'translated',
    UNTRANSLATED = 'untranslated'
}

const Translations: React.FC = () => {
    const { language_id } = useParams();
    const [language, setLanguage] = useState<Language | undefined>(undefined);
    const [allTranslations, setAllTranslations] = useState<any>(undefined);
    const [translations, setTranslations] = useState<any>([]);
    const [originalTranslationsForCurrentPage, setOriginalTranslationsForCurrentPage] = useState<any>([]);
    const [translationStatistics, setTranslationStatistics] = useState<any>({});

    const [openNewTranslationKey, setOpeNewTranslationKey] = useState(false);
    const [creatingTranslation, setCreatingTranslation] = useState(false);
    const [newKey, setNewKey] = useState('');
    const [newValue, setNewValue] = useState('');
    const [newDescription, setNewDescription] = useState('');
    const [errors, setErrors] = useState<any>({});

    // PAGINATION, SEARCH AND FILTERING/SORTING RELATED FIELDS
    const [pages, setPages] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [filter, setFilter] = useState(TranslationsFilters.ALL);
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const [activeSortingKey, setActiveSortingKey] = useState<string | null>('key');
    const [showSortArrows, setShowSortArrows] = useState<Record<string, boolean> | null>(null);
    const [sortConfig, setSortConfig] = useState<ISortConfig>(DEFAULT_SORT_CONFIG_FOR_TRANSLATIONS);

    const [editedKey, setEditedKey] = useState(false);

    const {
        loading,
        error,
        translationsLoading,
        loadingLanguage,
        allTranslations: translationsObject
    } = useAppSelector<LanguagesState>((state) => state.languages);
    const { activeTenantId, activeProjectId } = useAppSelector<ActiveItemState>((state) => state.activeItem);
    const { isMobile, isTablet, isDesktop } = useScreenSize();

    const searchInputRef = useRef<SearchBarHandle>(null);

    //Locking
    const { isObjectLocked, objectIsLockedBy, lock, unlockOnClose } = useLockSystem(LockableObjectTypes.LANGUAGES);
    const [languageLockedBy, setLanguageLockedBy] = useState<string>('');

    const [showUnsaved, setShowUnsaved] = useState(false);
    const [isSaveDisabled, setIsSaveDisabled] = useState(false);

    const columnKeys = ['key', 'value'];
    const unsavedDependencyArray = [translations, allTranslations, newKey, newValue, newDescription, currentPage];

    const newKeyInputRef = useRef<any>(undefined);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useBlockNavigation(showUnsaved, () => renderAlertShowUnsaved(), unsavedDependencyArray);

    const loadLanguage = async () => {
        const response = await dispatch(fetchLanguage({ id: language_id || '', addTranslations: true })).unwrap();
        if (response.language) {
            const language = translationsParser([response.language])[0];
            setLanguage(language);
            setAllTranslations(language.translations);
        }
        activeProjectId && dispatch(fetchAllLanguageTranslations({ projectId: activeProjectId }));
    };

    const loadTranslations = async (page: number, pageSize: number, sortConfig: ISortConfig, filter: string, searchTerm?: string) => {
        const response = await dispatch(
            fetchLanguageTranslations({
                languageId: `${language_id}`,
                page: page || 1,
                size: pageSize,
                orderBy: calculateOrderByFromSortConfig(sortConfig),
                filter: { translated: [filter] } as ObjectFilter,
                searchTerm: searchTerm,
                withDescriptions: true
            })
        ).unwrap();
        if (response.translations) {
            const _translations = _.isEmpty(response.translations) ? [] : [...response.translations];
            setTranslations(_translations);
            setOriginalTranslationsForCurrentPage(_.cloneDeep(_translations));
            setPages(response.totalCount ? Math.ceil(response.totalCount / pageSize) : 1);
        }
    };

    const modifyLanguage = async (newLanguage: Language) => {
        const response = await dispatch(updateLanguage({ language: newLanguage })).unwrap();
        if (response.ok) {
            loadTranslations(currentPage, pageSize, sortConfig, filter, searchTerm);
            loadLanguage();
            clearState();
        }
    };

    const hasDuplicates = (arr: string[]) => !!arr.filter((item, index: number) => item && arr.indexOf(item) !== index).length;

    useEffect(() => {
        if (!language || !activeProjectId) return;

        // in case a user switches between projects, the selected translation should not be displayed
        if (language?.projectId !== activeProjectId) {
            navigate(buildPathWithProjectId(activeProjectId, PageRoutes.LANGUAGES));
        }
        const locked = isObjectLocked(language);
        const lockedBy = objectIsLockedBy(language);

        setLanguageLockedBy(locked ? lockedBy : '');

        if (locked) {
            return renderLockedWarningAlert(lockedBy);
        }
        lock(language._id);
        unlockOnClose(language._id);
    }, [language, activeProjectId]);

    useEffect(() => {
        loadLanguage();
        loadTranslations(1, pageSize, DEFAULT_SORT_CONFIG_FOR_TRANSLATIONS, TranslationsFilters.ALL);
        const arrows = { ...showSortArrows };
        columnKeys.forEach((key) => {
            arrows[key] = key === sortConfig?.field;
        });
        setShowSortArrows(arrows);
    }, [language_id]);

    useEffect(() => {
        const newErrors = { ...errors };
        if (translations.map((item: { key: string; value: string }) => item.key).includes(newKey)) {
            newErrors.newKey = 'Key already exists';
            setIsSaveDisabled(true);
        } else {
            delete newErrors.newKey;
            setIsSaveDisabled(hasDuplicates(translations.map((item: any) => item.key)));
        }
        setErrors(newErrors);
    }, [newKey]);

    useEffect(() => {
        const calculateUnsaved = () => {
            if (!language || loading || translationsLoading || loadingLanguage) return false;
            if (
                originalTranslationsForCurrentPage
                    .map((t: any) => t.key)
                    .sort()
                    .join() ===
                translations
                    .map((t: any) => t.key)
                    .sort()
                    .join()
            ) {
                setEditedKey(false);
            }
            if (!_.isEqual(originalTranslationsForCurrentPage, translations)) {
                return true;
            }
            return !!(newKey || newValue || newDescription);
        };
        setShowUnsaved(calculateUnsaved());
    }, [...unsavedDependencyArray]);

    useEffect(() => {
        if (creatingTranslation && newKeyInputRef.current) {
            newKeyInputRef.current.focus?.();
            newKeyInputRef.current.scrollIntoView?.({ behavior: 'smooth' });
        }
    }, [creatingTranslation]);

    useEffect(() => {
        if (!language || !translationsObject) return;

        const values = Object.values(translationsObject).map((val) => val?.values?.[language.code]);
        const statistics = {
            translations: Object.keys(translationsObject).length,
            translated: values.filter((val) => !!val).length,
            untranslated: values.filter((val) => !val).length
        };
        setTranslationStatistics(statistics);
    }, [translationsObject, language]);

    const clearState = () => {
        setNewKey('');
        setNewValue('');
        setNewDescription('');
        setCreatingTranslation(false);
        setEditedKey(false);
        setErrors({});
    };

    const handleSaveClick = () => {
        const validatedList = translations.map((item: any) => {
            item.error = item.key === '' || item.key === undefined ? 'Invalid key' : undefined;
            return item;
        });
        const hasEmptyKey = validatedList.some((item: any) => item.error);
        if (hasEmptyKey) return setTranslations(validatedList);

        const newTranslations = [...translations];
        const newErrors = { ...errors };
        if (creatingTranslation) {
            newTranslations.push({ key: newKey, value: newValue, description: newDescription });
            newErrors.newKey = validator({ required: true }, newKey);
        }

        if (Object.values(newErrors).some((err) => err)) return setErrors(newErrors);
        if (!!newTranslations.length && validateTranslations(newTranslations).error) {
            dialogAlert(DIALOG_NAMES.INVALID_TRANSLATIONS);
            return;
        }

        //  onSave only the modified + newlyAdded values will be sent, +  the deleted ones with NULL
        originalTranslationsForCurrentPage.forEach(({ key, value, description }: any) => {
            let index = newTranslations.findIndex((item) => item.key === key);

            if (index !== -1) {
                if (newTranslations[index].value === value && newTranslations[index].description === description) {
                    newTranslations.splice(index, 1);
                }
            } else {
                newTranslations.push({ key: key, value: null });
            }
        });

        const newLanguage: any = {
            _id: `${language_id}`,
            tenantId: language?.tenantId || activeTenantId,
            projectId: language?.projectId || activeProjectId,
            translations: newTranslations,
            lastModified: language?.lastModified
        };

        if (editedKey) {
            renderKeyEditWarning();
        }

        modifyLanguage(newLanguage);
    };

    const handleTableChange = useCallback((value: string, index: number, type: string) => {
        setTranslations((translations: any) => {
            const newTranslations = [...translations];

            switch (type) {
                case 'key':
                    const keys = newTranslations.map((item: { key: string }) => item.key);

                    if (value && keys.includes(value)) {
                        newTranslations[index].error = 'Key already exists';
                    } else {
                        delete newTranslations[index].error;
                    }
                    newTranslations[index].key = value;

                    setIsSaveDisabled(hasDuplicates(newTranslations.map((item: any) => item.key)));
                    setEditedKey(true);
                    break;
                case 'value':
                    newTranslations[index].value = value;
                    break;
                case 'description':
                    newTranslations[index].description = value;
                    break;
                default:
                    break;
            }
            return newTranslations;
        });
    }, []);

    const toggleArrows = (open: boolean) => {
        let newArrows = { ...showSortArrows };
        Object.keys(newArrows).forEach((key) => {
            if (key === activeSortingKey) {
                newArrows[key] = true;
            } else {
                newArrows[key] = open;
            }
        });

        setShowSortArrows(newArrows);
    };

    const handleSortIconClick = (field: string) => {
        setActiveSortingKey(field);
        let direction: 'asc' | 'desc' = 'asc';
        if (sortConfig?.field === field && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        const newSortConfig = { field, direction };
        setSortConfig(newSortConfig);
        loadTranslations(currentPage, pageSize, newSortConfig, filter, searchTerm);
    };

    const buildTableColumns = () =>
        translations.length || creatingTranslation
            ? columnKeys.map((key, index) => {
                  return (
                      <SortableHeaderTableCell
                          key={`${index}_cell`}
                          text={key === 'value' ? 'Translation' : key === 'key' ? 'Key' : ''}
                          direction={(sortConfig?.field === key && sortConfig?.direction) || 'asc'}
                          onClick={() => {
                              if (showUnsaved) return renderUnsavedAlertBeforeAction();
                              handleSortIconClick(key);
                          }}
                          hideArrow={!showSortArrows?.[key]}
                          onMouseEnter={() => toggleArrows(true)}
                          onMouseLeave={() => toggleArrows(false)}
                      />
                  );
              })
            : [];

    const buildTableBody = () => {
        const shouldShowDescriptionTooltip = (description: string) => {
            return isTablet ? description.length > 25 : isDesktop ? description.length > 45 : description.length > 10;
        };
        const rows = translations.map(
            (
                { key, value, description, error }: { key: string; value: string; description?: string; error: string | undefined },
                index: number
            ) => {
                const descriptionField = renderTooltip(
                    <DialogTextField
                        placeholder={'Add Description'}
                        value={description || ''}
                        error={error}
                        onChange={(e: any) => {
                            const newDescription = e.target.value;
                            handleTableChange(newDescription, index, 'description');
                        }}
                        noLabel
                    />,
                    tooltipTypes.HTML,
                    shouldShowDescriptionTooltip(description || '') ? <span>{description}</span> : ''
                );

                return (
                    <TableRow key={`language-translations-row-${index}`} data-cy={`language-translations-row-${index}`}>
                        <TableCell>
                            <TranslationsKeyAndDescriptionContainer>
                                <TranslationsInputWrapper>
                                    <DialogTextField
                                        placeholder={'Add Key'}
                                        value={key}
                                        error={error}
                                        onChange={(e: any) => {
                                            const newKey = e.target.value;
                                            if (!validTranslationKeyRegex.test(newKey)) return;
                                            handleTableChange(newKey, index, 'key');
                                        }}
                                        noLabel
                                    />
                                </TranslationsInputWrapper>
                                <TranslationsInputWrapper>{descriptionField}</TranslationsInputWrapper>
                            </TranslationsKeyAndDescriptionContainer>
                        </TableCell>
                        <TableCell>
                            <TranslationsInputWrapper>
                                <DialogTextField
                                    placeholder={'Add Translation'}
                                    value={value}
                                    onChange={(e: any) => {
                                        handleTableChange(e.target.value, index, 'value');
                                    }}
                                    noLabel
                                />
                            </TranslationsInputWrapper>
                        </TableCell>
                        {/* ACTIONS TABLE CELL */}
                        <WidthTableCell $wTab={92} $um={'px'}>
                            <ActionsTableCell
                                actions={[tableActions.REMOVE]}
                                onRemove={() => {
                                    if (languageLockedBy) {
                                        renderLockedWarningAlert(languageLockedBy);
                                    } else {
                                        setTranslations((prevState: { key: string }[]) => prevState.filter((item) => item.key !== key));
                                    }
                                }}
                                tooltipTexts={{ delete: 'language_translations_icon_delete' }}
                            />
                        </WidthTableCell>
                    </TableRow>
                );
            }
        );
        if (creatingTranslation) {
            const descriptionField = renderTooltip(
                <DialogTextField
                    placeholder={'Add Description'}
                    value={newDescription}
                    onChange={(e: any) => {
                        const newDescription = e.target.value;
                        setNewDescription(newDescription);
                    }}
                    noLabel
                />,
                tooltipTypes.HTML,
                shouldShowDescriptionTooltip(newKey) ? <span>{newDescription}</span> : ''
            );

            rows.push(<TableRow key={'language-translations-row-divider'} style={{ height: 24 }} />);
            !rows.some((r: { key: string }) => r.key === 'language-translations-row-new') &&
                rows.push(
                    <TableRow key={`language-translations-row-new`} data-cy={`language-translations-row-new`}>
                        <TableCell>
                            <TranslationsKeyAndDescriptionContainer>
                                <TranslationsInputWrapper>
                                    <DialogTextField
                                        placeholder={'Add Key'}
                                        value={newKey}
                                        error={errors.newKey}
                                        onChange={(e: any) => {
                                            const newKey = e.target.value;
                                            if (!validTranslationKeyRegex.test(newKey)) return;
                                            setNewKey(newKey);
                                        }}
                                        inputRef={newKeyInputRef}
                                        noLabel
                                    />
                                </TranslationsInputWrapper>
                                <TranslationsInputWrapper>{descriptionField}</TranslationsInputWrapper>
                            </TranslationsKeyAndDescriptionContainer>
                        </TableCell>
                        <TableCell>
                            <TranslationsInputWrapper>
                                <DialogTextField
                                    placeholder={'Add Translation'}
                                    value={newValue}
                                    onChange={(e: any) => {
                                        const newValue = e.target.value;
                                        setNewValue(newValue);
                                    }}
                                    noLabel
                                />
                            </TranslationsInputWrapper>
                        </TableCell>
                        {/* ACTIONS TABLE CELL */}
                        <WidthTableCell $wTab={56} $um={'px'}>
                            <ActionsTableCell
                                actions={[tableActions.REMOVE]}
                                onRemove={() => {
                                    setCreatingTranslation(false);
                                    setNewKey('');
                                    setNewValue('');
                                    setNewDescription('');
                                }}
                                tooltipTexts={{ delete: 'languages_icon_delete' }}
                            />
                        </WidthTableCell>
                    </TableRow>
                );
        }
        return <TableBody>{rows}</TableBody>;
    };

    const renderAlertShowUnsaved = (callback?: any) => {
        dialogConfirm(
            DIALOG_NAMES.UNSAVED_CHANGES,
            () => {
                handleSaveClick();
                callback?.();
            },
            null,
            null,
            {
                noButton: { label: 'Discard Changes' },
                confirmButton: { label: 'Save' }
            },
            { warningIcon: true },
            () => {
                clearState();
                setTranslations(originalTranslationsForCurrentPage);
                callback?.();
            },
            true
        );
    };

    const renderUnsavedAlertBeforeAction = () => {
        const values = {
            title: 'Action is blocked',
            text: 'You have unsaved changes, please save before proceeding with this action!'
        };
        ToastAlert('warning', values.title, values.text, undefined, undefined, () => {
            dialogAlert('', false, values, null, false, icons.warningYellowIcon);
        });
    };

    const editKeyWarningValues = {
        title: 'We noticed you edited a key',
        subtitle: `If you edit a key (not it's values) in a language, it's values will be lost in the other languages!`,
        text: `Make sure you re-add them globally via the translation keys dialog by clicking the 'Add Translation Key (All)' button in the top right corner.`
    };

    const renderKeyEditWarning = () => {
        ToastAlert('critical_warning', editKeyWarningValues.title, editKeyWarningValues.subtitle, undefined, undefined, () => {
            dialogAlert('', false, editKeyWarningValues, null, false, icons.warningIcon);
        });
    };

    const title = (
        <>
            {language && (
                <PageNameContainer>
                    {isDesktop && (
                        <div>
                            <span>Languages</span>
                            <SVGInline src={icons.arrowRightIcon} />
                        </div>
                    )}
                    <TruncatedText>
                        {language.name} ({language.code})
                    </TruncatedText>
                    <div>{!!languageLockedBy && renderLockIcon(languageLockedBy)}</div>
                </PageNameContainer>
            )}
        </>
    );

    const pageSizeOptions = [10, 20, 30, 40, 50].map((el) => {
        return {
            label: `${el}`,
            value: el
        };
    });

    const filterPageOptions = Object.keys(translationStatistics).length
        ? Object.values(TranslationsFilters).map((el) => {
              return {
                  label: `${_.capitalize(el)} (${translationStatistics[el] ?? translationStatistics.translations})`,
                  value: el,
                  translationsNumber: translationStatistics[el] ?? translationStatistics.translations
              };
          })
        : [];

    const generalLoading = loading || translationsLoading || loadingLanguage;

    const AddGlobalTranslationKeyLabel = (
        <AddTranslationLabelWrapper>
            <ButtonIcon>
                <SVGInline src={icons.globalTranslationsIcon} />
            </ButtonIcon>
            <span>Add Translation Key (All)</span>
        </AddTranslationLabelWrapper>
    );
    const AddTranslationKeyLabel = (
        <AddTranslationLabelWrapper>
            <ButtonIcon>
                <SVGInline src={icons.translationsIcon} />
            </ButtonIcon>
            <span>Add Translation Key</span>
        </AddTranslationLabelWrapper>
    );

    return (
        <>
            {error && (error.code === API_ERROR_CODES.LOCKED_ERROR ? renderLockedError(error) : <BackendErrorDialog error={error} />)}
            <ApplicationWrapper>
                <Sidebar />
                <MainContentWrapper>
                    <ScreenTitle
                        title={title}
                        withProfile={true}
                        withAddButton
                        backIcon
                        onBack={() => navigate(-1)}
                        addLabel={isMobile ? '+' : AddTranslationKeyLabel}
                        loading={generalLoading}
                        onAdd={() => {
                            !!languageLockedBy ? renderLockedWarningAlert(languageLockedBy) : setCreatingTranslation(true);
                        }}
                        withSecondaryButton
                        secondaryButtonLabel={AddGlobalTranslationKeyLabel}
                        onSecondarybuttonClick={() => {
                            if (showUnsaved) return renderUnsavedAlertBeforeAction();
                            setOpeNewTranslationKey(true);
                        }}
                    />
                    <TranslationsPaginationTopContainer>
                        <FilterTranslationsContainer>
                            <SearchBar
                                title={'Search translations'}
                                ref={searchInputRef}
                                searchTerm={searchTerm}
                                onSearch={(value) => {
                                    if (showUnsaved) return;
                                    loadTranslations(1, pageSize, sortConfig, filter, value);
                                }}
                                setSearchTerm={(value: string) => {
                                    if (showUnsaved) {
                                        searchInputRef.current?.blurInput();
                                        return renderUnsavedAlertBeforeAction();
                                    }
                                    setSearchTerm(value);
                                }}
                                tooltipText={'language_translations_icon_search'}
                            />

                            {filterPageOptions.length ? (
                                <FilterTranslationsWrapper>
                                    <TranslationfilterIcon>
                                        <SVGInline src={icons.filterIcon} />
                                    </TranslationfilterIcon>
                                    <DialogDropdownSingle
                                        value={filterPageOptions.find((el) => el.value === filter)}
                                        onChange={(value: any) => {
                                            if (showUnsaved) {
                                                return renderUnsavedAlertBeforeAction();
                                            }
                                            if (value.translationsNumber < 1) return;
                                            setCurrentPage(1);
                                            setFilter(value.value);
                                            loadTranslations(1, pageSize, sortConfig, value.value, searchTerm);
                                        }}
                                        options={filterPageOptions}
                                        placeholder={''}
                                        adornmentStart={'Show'}
                                        noLabel
                                        noError
                                    />
                                </FilterTranslationsWrapper>
                            ) : null}
                        </FilterTranslationsContainer>

                        <TranslationsStatisticsContainer>
                            {!!translationStatistics.translations ? (
                                <div>
                                    <b>{language?.translationProgress}%</b>
                                    {!isMobile ? ' Translated | ' : ' | '}
                                    <b>{translationStatistics.translations}</b> Keys, <b>{translationStatistics.translated}</b> Translations
                                </div>
                            ) : null}
                            <DialogDropdownSingle
                                value={pageSizeOptions.find((el) => el.value === pageSize)}
                                options={pageSizeOptions}
                                placeholder=""
                                onChange={(val: any) => {
                                    if (showUnsaved) return renderUnsavedAlertBeforeAction();
                                    setCurrentPage(1);
                                    setPageSize(val.value);
                                    loadTranslations(1, val.value, sortConfig, filter, searchTerm);
                                }}
                                adornmentStart={'Show'}
                                noLabel
                                noError
                            />
                        </TranslationsStatisticsContainer>
                    </TranslationsPaginationTopContainer>

                    {generalLoading ? (
                        <Loader title={'Language Translations'} />
                    ) : (
                        <>
                            <GenericTable body={buildTableBody()} columns={buildTableColumns()} dataCy={'language-translations-table'} />
                            {!creatingTranslation && (
                                <TranslationsPageActions>
                                    <PageActionButton
                                        onClick={() => {
                                            !!languageLockedBy ? renderLockedWarningAlert(languageLockedBy) : setCreatingTranslation(true);
                                        }}
                                        label={isMobile ? '+' : 'Add Translation Key'}
                                        type={'BLUE'}
                                    />
                                </TranslationsPageActions>
                            )}
                        </>
                    )}
                    <TranslationsPaginationBottomContainer>
                        <TranslationsStatisticsContainer>
                            {!!translationStatistics.translations ? (
                                <div>
                                    <b>{((translationStatistics.translated / translationStatistics.translations) * 100).toFixed(2)}%</b>
                                    {!isMobile ? ' Translated | ' : ' | '}
                                    <b>{translationStatistics.translations}</b> Keys, <b>{translationStatistics.translated}</b> Translations
                                </div>
                            ) : null}
                        </TranslationsStatisticsContainer>
                        <Pagination
                            totalPages={pages}
                            pageLimit={pageSize}
                            pageNeighbours={1}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            onPageChanged={(data: any) => {
                                const callback = () => {
                                    loadTranslations(data.currentPage, pageSize, sortConfig, filter, searchTerm);
                                };
                                if (showUnsaved) renderAlertShowUnsaved(() => callback());
                                else callback();
                            }}
                        />
                    </TranslationsPaginationBottomContainer>

                    {showUnsaved && !generalLoading && (
                        <PageActions
                            disabled={{ save: isSaveDisabled }}
                            onCancel={() => {
                                clearState();
                                loadTranslations(currentPage, pageSize, sortConfig, filter, searchTerm);
                            }}
                            onSave={() => {
                                handleSaveClick();
                            }}
                            warnings={
                                editedKey
                                    ? [
                                          {
                                              critical: true,
                                              icon: icons.warningIcon,
                                              onClick: () => {
                                                  dialogAlert('', false, editKeyWarningValues, null, false, icons.warningIcon);
                                              }
                                          }
                                      ]
                                    : undefined
                            }
                        />
                    )}
                </MainContentWrapper>
                <NewTranslation
                    open={openNewTranslationKey}
                    onClose={() => setOpeNewTranslationKey(false)}
                    onChange={() => {
                        loadTranslations(currentPage, pageSize, sortConfig, filter, searchTerm);
                        loadLanguage();
                    }}
                />
            </ApplicationWrapper>
        </>
    );
};

export default Translations;

import React, { FC, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { InputAdornment } from '@material-ui/core';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../hooks/redux';
import GenericDialog, {
    DialogButton,
    DialogColorField,
    DialogFileField,
    DialogRadioButton,
    DialogTextField,
    DialogToggleButton,
    DialogTypes
} from '../../common/Dialog/GenericDialog';
import { DialogRow, ErrorLabel, FileManagerAdornmentWrapper, ToggleButtonContainer } from '../../common/Dialog/GenericDialog.css';
import { ImageFieldsContainer, SupportedLabel, VideoLabel } from '../Items.css';
import { getImgixUrl, getVideoCdnUrl, imgixBaseUrl, imgixDefaultQuery, imgixFMBaseUrl } from '../../../utils/Globals';
import getTheme from '../../../style/themes/theme';
import { FilesState, uploadFilesSync } from '../../../redux/slices/fileManagerSlice';
import { ActiveItemState } from '../../../redux/slices/activeItemSlice';
import { extractFileNameFromAzureURL, isOpenFileUrl } from '../../../utils/fnUrl';
import SVGInline from 'react-inlinesvg';
import icons from '../../../assets/images/icons';
import FileManagerDialog from '../../common/Dialog/FileDialogs/FileManagerDialog';
import { videoExtensions } from '../../FileManager/FileManager';
import { dialogAlert } from '../../../utils/fnDialogs';

export type BackgroundDialogProps = {
    open: boolean;
    onSave: (value: string | supportedResolutionsType, autoplay?: string, isColor?: boolean) => void;
    onClose: () => void;
    background?: string | supportedResolutionsType;
    isColor?: boolean;
    backgroundAutoplay?: string;
    isHero?: boolean;
    droppedFile?: any;
    extensionError?: any;
    isTemplate?: boolean;
};

export type supportedResolutionsType = {
    bigScreen?: any;
    pc?: any;
    tablet?: any;
    mobile?: any;
};

export const supportedResolutions: supportedResolutionsType = {
    bigScreen: '1920x1080',
    pc: '1280x720',
    tablet: '960x540',
    mobile: '360x202'
};

const theme: any = getTheme();

const BackgroundDialog: FC<BackgroundDialogProps> = ({
    open,
    onSave,
    onClose,
    background,
    backgroundAutoplay,
    isColor,
    isHero,
    droppedFile,
    extensionError,
    isTemplate
}) => {
    const [isOpen, setIsOpen] = useState(open);
    const [view, setView] = useState<'IMAGE' | 'COLOR'>('IMAGE');
    const [applyForAll, setApplyForAll] = useState(true);
    const [color, setColor] = useState(theme.palette.text.main);
    const [imageFiles, setImageFiles] = useState<supportedResolutionsType>({});
    const [imageUrls, setImageUrls] = useState<supportedResolutionsType>({});
    const [autoplay, setAutoplay] = useState(backgroundAutoplay || '');
    const [initialBackground, setInitialBackground] = useState(background);
    const [openFileManagerDialog, setOpenFileManagerDialog] = useState(false);
    const autoplayInputRef = useRef<any>(null);

    const { activeProjectId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const { loading }: FilesState = useAppSelector((state) => state.files);

    const title = `Image & Video / BG Colour`;

    const dispatch = useDispatch();

    useEffect(() => {
        const calculateApplyForAll = () => {
            if (isColor || !background || typeof background === 'string') return true;
            const values = Object.values(background);
            if (!values.length) return true;
            const firstUrl = decodeURIComponent(values[0]);
            const isOpenUrl = isOpenFileUrl(firstUrl);
            const firstFileName = !isOpenUrl
                ? firstUrl.split(values[0].includes(imgixBaseUrl) ? imgixBaseUrl : imgixFMBaseUrl)[1].split('?')[0]
                : firstUrl.split('/').pop();
            return (
                !values
                    .filter((_, index) => index > 0)
                    .some((value) => {
                        const url = decodeURIComponent(value);
                        const isOpenUrl = isOpenFileUrl(url);
                        const fileName = !isOpenUrl
                            ? url.split(value.includes(imgixBaseUrl) ? imgixBaseUrl : imgixFMBaseUrl)[1].split('?')[0]
                            : url.split('/').pop();
                        return fileName !== firstFileName;
                    }) && values.length === 4
            );
        };
        if (open) {
            setApplyForAll(calculateApplyForAll());
            setInitialBackground(background);
            setAutoplay(backgroundAutoplay || '');
            setColor(isColor && typeof background === 'string' ? background : theme.palette.text.main);
            setView(isColor ? 'COLOR' : 'IMAGE');
            setImageUrls({});
            setImageFiles({});
        }
        setIsOpen(open);
    }, [open]);

    const createFiles = async (files: File[]) => {
        const prefix = isTemplate ? 'system_templates/items' : `${activeProjectId}/item_backgrounds`;
        const response = await dispatch(uploadFilesSync({ files, prefix, overwrite: true })).unwrap();
        return response.urls;
    };

    const handleSaveClick = async () => {
        if (!onSave) return;
        if (view === 'COLOR') {
            // if color simply return it
            return onSave(color, autoplay, true);
        }
        let imgixUrls: any = initialBackground && typeof initialBackground !== 'string' ? { ...initialBackground } : {};
        const imageUrl = Object.values(imageUrls).find((elem) => elem);

        if (imageUrl) {
            if (applyForAll) {
                const isOpenUrl = isOpenFileUrl(imageUrl);
                Object.values(supportedResolutions).forEach((value, index) => {
                    if (isOpenUrl) {
                        imgixUrls[Object.keys(supportedResolutions)[index]] = encodeURIComponent(imageUrl);
                        return;
                    }
                    const [width, height] = value.split('x');
                    const query = `&width=${width}&height=${height}`;
                    imgixUrls[Object.keys(supportedResolutions)[index]] = encodeURIComponent(`${imageUrl}${query}`);
                });
                return onSave(imgixUrls, autoplay);
            }

            const newBackgrounds = {
                bigScreen: imgixUrls.bigScreen || imageUrls.bigScreen || '',
                pc: imgixUrls.pc || imageUrls.pc || '',
                tablet: imgixUrls.tablet || imageUrls.tablet || '',
                mobile: imgixUrls.mobile || imageUrls.mobile || ''
            };

            return onSave(newBackgrounds, autoplay);
        }
        const imagesToUpload = Object.values(imageFiles).filter((image): image is File => typeof image !== 'undefined');
        if (!imagesToUpload.length) {
            const valueToReturn = initialBackground && typeof initialBackground !== 'string' ? { ...initialBackground } : '';

            // if the applyForAll is set, and it's URL doesn't contain the imgifFmBaseUrl, means we have an openURL set, so we skip the if
            if (valueToReturn && applyForAll && valueToReturn.bigScreen?.includes(imgixFMBaseUrl)) {
                const fileName = valueToReturn.bigScreen
                    ? decodeURIComponent(valueToReturn.bigScreen).split(imgixFMBaseUrl).pop()?.split('?')[0] || ''
                    : '';
                if (!fileName) return onSave({}, autoplay);
                (Object.keys(supportedResolutions) as Array<keyof supportedResolutionsType>).forEach((key) => {
                    const [width, height] = supportedResolutions[key].split('x');
                    const query = `${imgixDefaultQuery}&width=${width}&height=${height}`;
                    valueToReturn[key] = encodeURIComponent(getImgixUrl(fileName, query, true));
                });
            }
            return onSave(valueToReturn, autoplay);
        }
        const urls = await createFiles(imagesToUpload);
        if (applyForAll) {
            // if apply for all, generate imgix urls for the save picture, but use width and height in query
            const fileName = extractFileNameFromAzureURL(urls[0]);
            Object.values(supportedResolutions).forEach((value, index) => {
                const [width, height] = value.split('x');
                const query = `${imgixDefaultQuery}&width=${width}&height=${height}`;
                imgixUrls[Object.keys(supportedResolutions)[index]] = encodeURIComponent(getImgixUrl(fileName, query, true));
            });
            return onSave(imgixUrls, autoplay);
        }
        Object.keys(imageFiles).forEach((key, index) => {
            // if not apply for all, still generate imgix url, but the resolution is kept as inputed
            const fileName = extractFileNameFromAzureURL(urls[index]);
            imgixUrls[key] = encodeURIComponent(getImgixUrl(fileName, undefined, true));
        });
        onSave(imgixUrls, autoplay);
    };
    const handleCloseClick = () => {
        onClose && onClose();
    };
    const handleImageSelect = (image: File, key: keyof supportedResolutionsType) => {
        const newImages = { ...imageFiles };
        if (!image) {
            if (applyForAll) {
                setInitialBackground({});
                setImageFiles({});
                return;
            }

            if (typeof initialBackground !== 'string' && initialBackground) {
                setInitialBackground(_.omit(initialBackground, key));
            }
            delete newImages[key];
        } else {
            newImages[key] = image;
        }
        setImageFiles(newImages);
    };

    const handleFileManagerSelect = (url: string, key: keyof supportedResolutionsType) => {
        setImageUrls(url ? _.set({ ...imageUrls }, key, getImgixUrl(url, undefined, true)) : _.omit(imageUrls, key));
        setImageFiles(_.omit(imageFiles, key));
    };

    const videoExtensionAlert = () => {
        const values = {
            title: 'File not supported!',
            subtitle: `Supported files are: ${videoExtensions.join(', ')}`
        };
        dialogAlert('', false, values);
    };

    const handleVideoAutoPlaySelect = (url: string) => {
        const fileExtension = _.last(url?.split('.')) || '';
        const isSupported = videoExtensions.includes(fileExtension);
        if (!isSupported) return videoExtensionAlert();
        setAutoplay(getVideoCdnUrl(extractFileNameFromAzureURL(url)));
        setOpenFileManagerDialog(false);
    };

    const handleVideoAutoplayBrowse = async (e: React.ChangeEvent<HTMLInputElement>) => {
        //used for items templates
        const video = e.target?.files?.[0];
        if (!video) return;

        const url = (await createFiles([video]))[0];
        const videoForAutoplay = getVideoCdnUrl(extractFileNameFromAzureURL(url));
        setAutoplay(videoForAutoplay);
    };

    const handleFileUrlInput = (url: string, key: keyof supportedResolutionsType) => {
        setImageUrls(url ? _.set({ ...imageUrls }, key, url) : _.omit(imageUrls, key));
        setImageFiles(_.omit(imageFiles, key));
    };

    const saveButton: DialogButton = {
        label: 'Save',
        type: 'BLUE',
        onClick: handleSaveClick,
        loading: loading
    };

    const cancelButton: DialogButton = {
        label: 'Cancel',
        type: 'DEFAULT',
        onClick: handleCloseClick
    };

    if (!isOpen) {
        return null;
    }

    const renderImageFields = () => {
        const values: supportedResolutionsType = {};
        if (!isColor && initialBackground && typeof initialBackground !== 'string') {
            (Object.keys(initialBackground) as Array<keyof supportedResolutionsType>).forEach((key) => {
                values[key] = decodeURIComponent(initialBackground[key]);
            });
        }
        if (!isColor && typeof initialBackground === 'string') {
            values.bigScreen = decodeURIComponent(initialBackground);
        }
        const fileManagerAdornment = (
            <InputAdornment position="end">
                <FileManagerAdornmentWrapper
                    onClick={() => (!isTemplate ? setOpenFileManagerDialog(true) : autoplayInputRef?.current?.click())}
                >
                    <SVGInline src={icons.fileManagerIcon} />
                </FileManagerAdornmentWrapper>
            </InputAdornment>
        );

        return (
            <>
                <ImageFieldsContainer>
                    <DialogFileField
                        imageInfo={{ previewImage: true, height: 128, width: 128, um: 'px' }}
                        preview={imageUrls.bigScreen || values.bigScreen}
                        fieldKey={'Big Screen (1920x1080)'}
                        customLabel={applyForAll ? 'All Screens' : 'Big Screen (1920x1080)'}
                        localFileCallBack={(file) => handleImageSelect(file, 'bigScreen')}
                        fileManagerCallback={(url: string) => handleFileManagerSelect(url, 'bigScreen')}
                        openUrlCallback={(url: string) => handleFileUrlInput(url, 'bigScreen')}
                        hideAccepted
                        alwaysShowLabel
                        droppedFile={droppedFile}
                        error={extensionError}
                        withoutFileManager={isTemplate}
                    />

                    {!applyForAll && (
                        <>
                            <DialogFileField
                                imageInfo={{ previewImage: true, height: 128, width: 128, um: 'px' }}
                                preview={imageUrls.pc || values.pc}
                                fieldKey={'PC (1280x720)'}
                                customLabel={'PC (1280x720)'}
                                localFileCallBack={(file) => handleImageSelect(file, 'pc')}
                                fileManagerCallback={(url: string) => handleFileManagerSelect(url, 'pc')}
                                openUrlCallback={(url: string) => handleFileUrlInput(url, 'pc')}
                                hideAccepted
                                alwaysShowLabel
                                withoutFileManager={isTemplate}
                            />
                            <DialogFileField
                                imageInfo={{ previewImage: true, height: 128, width: 128, um: 'px' }}
                                preview={imageUrls.tablet || values.tablet}
                                fieldKey={'Tablet (960x540)'}
                                customLabel={'Tablet (960x540)'}
                                localFileCallBack={(file) => handleImageSelect(file, 'tablet')}
                                fileManagerCallback={(url: string) => handleFileManagerSelect(url, 'tablet')}
                                openUrlCallback={(url: string) => handleFileUrlInput(url, 'tablet')}
                                hideAccepted
                                alwaysShowLabel
                                withoutFileManager={isTemplate}
                            />
                            <DialogFileField
                                imageInfo={{ previewImage: true, height: 128, width: 128, um: 'px' }}
                                preview={imageUrls.mobile || values.mobile}
                                fieldKey={'Mobile (360x202)'}
                                customLabel={'Mobile (360x202)'}
                                localFileCallBack={(file) => handleImageSelect(file, 'mobile')}
                                fileManagerCallback={(url: string) => handleFileManagerSelect(url, 'mobile')}
                                openUrlCallback={(url: string) => handleFileUrlInput(url, 'mobile')}
                                hideAccepted
                                alwaysShowLabel
                                withoutFileManager={isTemplate}
                            />
                        </>
                    )}
                </ImageFieldsContainer>
                <SupportedLabel>*Supported files: PNG, JPG</SupportedLabel>
                <ErrorLabel error={!!extensionError}>{extensionError}</ErrorLabel>

                <>
                    <ToggleButtonContainer>
                        <DialogToggleButton
                            checked={applyForAll}
                            toggleCallback={() => {
                                if (applyForAll && background && typeof background !== 'string') {
                                    const { bigScreen, pc, tablet, mobile, ...newBackground } = background;
                                    setInitialBackground(newBackground);
                                } else {
                                    setInitialBackground(background);
                                }
                                setApplyForAll(!applyForAll);
                            }}
                            text={'Apply for all screens'}
                            tooltipText={applyForAll ? 'apply_for_all_on' : 'apply_for_all_off'}
                        />
                    </ToggleButtonContainer>
                    {!isHero && (
                        <>
                            <VideoLabel>Video for Auto-Play (Optional)</VideoLabel>
                            <DialogTextField
                                value={autoplay}
                                onChange={(evt: any) => setAutoplay(evt.target.value)}
                                label={'Browse or type URL'}
                                placeholder={'Browse Files or type URL'}
                                InputProps={{ endAdornment: fileManagerAdornment }}
                                helperText={'*Supported files: MP4'}
                            />
                        </>
                    )}
                </>
            </>
        );
    };

    return (
        <>
            <GenericDialog type={DialogTypes.Form} title={`${title}`} onClose={handleCloseClick} actionButtons={[cancelButton, saveButton]}>
                <DialogRow>
                    <DialogRadioButton
                        active={view === 'IMAGE'}
                        onClick={() => {
                            setView('IMAGE');
                        }}
                        text={'Image'}
                    />
                    <DialogRadioButton
                        active={view === 'COLOR'}
                        onClick={() => {
                            setView('COLOR');
                        }}
                        text={'Color'}
                    />
                </DialogRow>
                {view === 'IMAGE' && renderImageFields()}
                {view === 'COLOR' && (
                    <DialogColorField width="128px" height="128px" onColorPicked={(newColor) => setColor(newColor)} value={color} />
                )}
            </GenericDialog>
            <FileManagerDialog
                open={openFileManagerDialog}
                onClose={() => setOpenFileManagerDialog(false)}
                onFileClick={handleVideoAutoPlaySelect}
            />

            <input
                id="file-upload-input"
                type="file"
                accept={videoExtensions.join(',')}
                onChange={(e) => {
                    handleVideoAutoplayBrowse(e);
                }}
                ref={autoplayInputRef}
                hidden
            />
        </>
    );
};

export default BackgroundDialog;

import { TableRow, Tooltip } from '@material-ui/core';
import _ from 'lodash';
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import SVGInline from 'react-inlinesvg';
import icons from '../../assets/images/icons';
import { useAppDispatch as useDispatch, useAppSelector } from '../../hooks/redux';
import { ActiveItemState } from '../../redux/slices/activeItemSlice';
import {
    abortPublishMenu,
    createMenu,
    deleteMenu,
    fetchMenus,
    fetchMenuTypes,
    menusState,
    publishMenu,
    unsetMenus,
    updateMenu
} from '../../redux/slices/menusSlice';
import { createPage, fetchAllPages, pagesState } from '../../redux/slices/pagesSlice';
import { setUserPermissions } from '../../redux/slices/permissionsSlice';
import {
    ApplicationWrapper,
    DeepLinkSpan,
    DropdownLabel,
    IconPreviewImage,
    MainContentWrapper,
    NoResourcesContainer,
    PageActionButton,
    PageActionsWrapper,
    TruncatedText
} from '../../style/styled-components/reusable.css';
import { Menu, MenuItem, MenuItemTypes, MenuTypes } from '../../types/Menu';
import { generateDateStringForTables } from '../../utils/fnDate';
import { DIALOG_NAMES, dialogConfirm, handleCheckBeforeSave, ToastAlert } from '../../utils/fnDialogs';
import { ResourceCard } from '../Cards/ResourceCard/ResourceCard';
import ScreenTitle from '../common/DashboardTitle/ScreenTitle';
import BackendErrorDialog from '../common/Dialog/BackendErrorDialog';
import { DialogCheckbox, DialogDropdownSingle, DialogTextField } from '../common/Dialog/GenericDialog';
import { MoreInfoTypes } from '../common/Dialog/MoreInfoDialog';
import PageActions from '../common/PageActions/PageActions';
import Sidebar from '../common/Sidebar/Sidebar';
import { RemoveModuleWrapper } from '../Modules/Modules.css';
import { NewMenuItemDialog } from './Dialogs/NewMenuItem';
import {
    AbTestingSelectWrapper,
    ActionIconHolder,
    ActionIconWrapper,
    ExpandedListViewHolder,
    GridViewHolder,
    MenuFieldsContainer,
    MenuFieldsSubContainer,
    MenuItemCard,
    MenuItemComponentHorizontal,
    MenuItemContainer,
    MenuItemDrag,
    MenuItemIcon,
    MenuItemTitle,
    MenuItemType,
    MenuItemWrapper,
    MenuListHeader,
    MenuViewBindToPages,
    MenuViewLastModified,
    MenuViewName,
    MenuViewNoOfItems
} from './Menus.css';
import ToggleViewSwitch from '../common/Switch/ToggleViewSwitch';
import { useDrag, useDrop } from 'react-dnd';
import { API_ERROR_CODES, DRAGGABLE_TYPES, DROPDOWN_GROUP_LABEL_VALUE, EMPTY_WORD_STRING } from '../../utils/Globals';
import { CardActions, DragContainer } from '../PageEdit/VisualEditor.css';
import { renderTooltipWithKey } from '../common/Tooltips/Tooltips';
import { Loader } from '../common/Loader/Loader';
import { audiencesState, fetchAudiences } from '../../redux/slices/audienceSlice';
import { DisplayConditionsState, fetchDisplayConditions } from '../../redux/slices/displayConditionsSlice';
import { resourceCardImages } from '../../assets/images/resourceCards';
import TranslationTooltip from '../common/TranslationTooltip/TranslationTooltip';
import useBlockNavigation from '../../hooks/useBlockNavigation';
import { useLocation, useNavigate } from 'react-router-dom';
import useScreenSize from '../../hooks/useScreenSize';
import circleSlugs from '../../utils/circle_slugs.json';
import { validator } from '../../utils/fnValidator';
import { TargetConditionsSelect } from '../common/Select/TargetConditionsSelect';
import { NewPageDialog } from '../PageEdit/Dialogs/NewPageDialog';
import { Page } from '../../types/Page';
import { buildPathWithProjectId, PageRoutes } from '../../types/RouteTypes';
import { PUBLISHED_STATUS, renderPublishStatusIcon } from '../../utils/fnPublish';
import useLockSystem, { LockableObjectTypes } from '../../hooks/useLockSystem';
import { renderABIcon, renderLockedError, renderLockedWarningAlert, renderLockIcon } from '../../utils/fnLockingSystem';
import usePrevious from '../../hooks/usePrevious';
import { openDocumentation } from '../../utils/parsers';
import { ObjectActions } from '../common/Actions/Actions';
import GenericTable, { HeaderTableCell, SortableHeaderTableCell, tableActions } from '../common/Table/Table';
import { getSearchParam } from '../../utils/fnUrl';
import { fetchTargetGroups, targetGroupsState } from '../../redux/slices/targetGroupsSlice';
import { MenusTableSizes } from '../../types/TableSizes';
import {
    ACCEPTED_SORT_FIELDS,
    AcceptedSortField,
    calculateOrderByFromSortConfig,
    DEFAULT_SORT_CONFIG,
    ISortConfig
} from '../../utils/fnSort';
import { TableRowWrapper, WidthTableCell } from '../common/Table/Table.css';
import Labels from '../common/Labels/Labels';
import { withScroll } from '../../HOCs/ScrollableWrapper/ScrollableWrapper';
import { ObjectTypes } from '../../types/Object';
import { OTHER_APPLICATION_VALUE } from '../Items/Dialogs/ActionDialog';
import { CIRCLE_SLUGS, ONBOARDING_CIRCLE_SLUGS } from '../common/HelpIcon/HelpIcon';
import { ApplicationsState } from '../../redux/slices/applicationsSlice';
import { SearchBarContainer } from '../common/SearchBar/SearchBar.css';
import { ABTestingGroupSelect } from '../common/Select/ABTestingGroupSelect';
import useTranslation from '../../hooks/useTranslation';
import { FancyFilter, searchTermUnsetValue } from '../common/Select/FancyFilter';
import { ObjectFilter } from '../../utils/fnFilter';
import { SearchBarWrapper } from '../PaginationWrapper/PaginationWrapper.css';
import { renderUnsavedAlertBeforeAction, ResetCallbackProps } from '../PaginationWrapper/PaginationWrapper';

export const ScrollableTableRowWrapper = withScroll(TableRowWrapper);

export const buildNoOfItemsOptions = () => {
    const options = [];
    for (let i = 1; i < 11; i++) {
        if (i === 1) {
            options.push({
                value: DROPDOWN_GROUP_LABEL_VALUE,
                label: (
                    <DropdownLabel>
                        <span>Recommended for Phone</span>
                    </DropdownLabel>
                )
            });
        }
        if (i === 5) {
            options.push({
                value: DROPDOWN_GROUP_LABEL_VALUE,
                label: (
                    <DropdownLabel>
                        <span>Recommended for TV</span>
                    </DropdownLabel>
                )
            });
        }
        if (i === 7) {
            options.push({
                value: DROPDOWN_GROUP_LABEL_VALUE,
                label: (
                    <DropdownLabel>
                        <span>Recommended for Web</span>
                    </DropdownLabel>
                )
            });
        }
        options.push({ value: i, label: `${i}` });
    }
    return options;
};

const Menus: React.FC<any> = () => {
    const [menus, setMenus] = useState<Menu[]>([]);
    const [filteredMenus, setFilteredMenus] = useState<Menu[] | null>(null);
    const [creatingMenu, setCreatingMenu] = useState(false);

    // Hold the MENU object which is being worked on
    const [menuToEdit, setMenuToEdit] = useState<Partial<Menu> | undefined>(undefined);
    // Tracks whether the top values can be edited or not
    const [isEditing, setIsEditing] = useState<boolean>(false);
    // Tracks the number of items selected in the dropdown,
    // when editing, it's initialised with the number of items the given menu has
    const [noOfItems, setNoOfItems] = useState(1);

    const previousMenu = usePrevious(menuToEdit);
    const location = useLocation();
    const locationState = location.state as any;
    const { translate } = useTranslation();

    // Used to show edit warning for bindToPages
    const [showEditWarning, setShowEditWarning] = useState(false);

    const [showUnsaved, setShowUnsaved] = useState<boolean>(false);
    //Used to keep the menusWithDragIds saved
    const [initialMenus, setInitialMenus] = useState<any[]>([]);

    const [editingIndex, setEditingIndex] = useState<number>(-1);

    // Menu Items
    const [openNewMenuItem, setOpenNewMenuItem] = useState<boolean>(false);
    const [itemToEdit, setItemToEdit] = useState<any>(null);
    const [view, setView] = useState<'LIST' | 'GRID'>('LIST');
    const [errors, setErrors] = useState<{ name?: string; type?: string }>({});

    const [showNewPageDialog, setShowNewPageDialog] = useState<boolean>(false);
    const [newPageId, setNewPageId] = useState<string>('');

    // SEARCH AND FILTERING/SORTING RELATED FIELDS
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [sortConfig, setSortConfig] = useState<ISortConfig>(DEFAULT_SORT_CONFIG);
    const [activeSortingKey, setActiveSortingKey] = useState<string | null>('lastModified');
    const [showSortArrows, setShowSortArrows] = useState<boolean>(false);
    const [activeObjectFilter, setActiveObjectFilter] = useState<ObjectFilter | undefined>(undefined);

    const { allPages: pages }: pagesState = useAppSelector((state) => state.pages);
    const { menus: storeMenus, error, loading, menuTypes }: menusState = useAppSelector((state) => state.menus);
    const { error: pagesError }: pagesState = useAppSelector((state) => state.pages);
    const { error: applicationsError }: ApplicationsState = useAppSelector((state) => state.applications);

    const { activeProjectId, activeTenantId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const { loading: conditionsLoading }: DisplayConditionsState = useAppSelector((state) => state.displayConditions);
    const { targetGroups }: targetGroupsState = useAppSelector((state) => state.targetGroups);
    const { audiences: storeAudiences }: audiencesState = useAppSelector((state) => state.audiences);

    // used for location state variable
    const [menuId, setMenuId] = useState<string>('');
    const [openCreateMenu, setOpenCreateMenu] = useState<boolean>(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isDesktop, isMobile, isSmallMobile, isTablet, isLargeDesktop } = useScreenSize();

    const orderBy = `${sortConfig.field}[${sortConfig.direction}]`;

    // Locking
    const { lock, unlock, unlockOnClose, isObjectLocked, objectIsLockedBy } = useLockSystem(LockableObjectTypes.MENUS);

    const lockMenu = (id: string) => {
        lock(id);
        unlockOnClose(id);
    };

    const unsavedDependencyArray = [menuToEdit];

    // Blocking navigation when there are unsaved changes
    useBlockNavigation(showUnsaved, () => renderAlertUnsavedChanges(), unsavedDependencyArray);

    const resetCallback = (keep?: ResetCallbackProps) => {
        !keep?.searchTerm && setSearchTerm('');
        !keep?.sortConfig && setActiveSortingKey(DEFAULT_SORT_CONFIG.field as AcceptedSortField);
        !keep?.sortConfig && setSortConfig(DEFAULT_SORT_CONFIG);
        !keep?.filterObject && setActiveObjectFilter(undefined);
        if (!keep?.visualEditor) {
            menuToEdit?._id && unlock(menuToEdit?._id);
            setMenuToEdit(undefined);
            setMenuId('');
        }
    };

    const handleCancelClick = () => {
        if (!creatingMenu && menuToEdit?._id) {
            unlock(menuToEdit._id);
        }
        setMenuToEdit(undefined);
        setNoOfItems(1);
        setIsEditing(false);
        setCreatingMenu(false);
        // Reset the menus with the ones that have items with dragIds
        setMenus(initialMenus);
        setNewPageId('');
        setMenuId('');
        setOpenCreateMenu(false);
        setErrors({});
    };

    const validateCreateMenu = () => {
        const newErrors = { ...errors };
        newErrors.name = validator({ required: true }, menuToEdit?.name || '');

        setErrors(newErrors);
        return Object.values(newErrors).filter((value) => !!value).length === 0;
    };

    const createNewPage = async (page: Page) => {
        const response = await dispatch(createPage({ page })).unwrap();
        if (response.id) {
            setNewPageId(response.id);
            loadPages(activeProjectId || '');
        }
    };

    const loadMenus = async (
        addPermissions?: boolean,
        projectId?: string,
        orderBy?: string,
        searchTerm?: string,
        filter?: ObjectFilter
    ) => {
        setMenus([]);
        if (storeMenus.length && getSearchParam('id')) {
            navigate(buildPathWithProjectId(activeProjectId, PageRoutes.MENUS));
        }
        // if redirected, reset the search term and filter after any action
        const isRedirected = !!activeObjectFilter?._id;
        if (isRedirected) {
            setActiveObjectFilter(undefined);
            setSearchTerm('');
        } else {
            // there might be a searchTerm in the fancy filter but a search was not conducted, so we need to clear the searchTerm inside the fancy filter
            // to do that we assign the searchTerm a special value which will be used in the fancy filter to clear both search terms
            const menuId = location?.state?.menuId || getSearchParam('id');
            if (!menuId && !searchTerm) setSearchTerm(searchTermUnsetValue);
        }
        return await dispatch(
            fetchMenus({
                addPermissions,
                projectId,
                orderBy,
                ...(!isRedirected && { searchTerm }),
                ...(!isRedirected && { filter })
            })
        ).unwrap();
    };

    const handleOnSearch = (filter: ObjectFilter | string) => {
        if (typeof filter === 'string') {
            setSearchTerm(filter);
            loadMenus(false, activeProjectId, orderBy, filter);
        } else {
            if (!Object.keys(filter).length) resetCallback({ sortConfig: true });
            setActiveObjectFilter(filter);
            loadMenus(true, activeProjectId, orderBy, '', filter);
        }
    };

    const loadMenuTypes = async (projectId?: string) => {
        return await dispatch(fetchMenuTypes(projectId || '')).unwrap();
    };

    const loadAudiences = async () => {
        return await dispatch(fetchAudiences({ addPermissions: false, projectId: activeProjectId }));
    };

    const loadConditions = async () => {
        return await dispatch(fetchDisplayConditions({ addPermissions: true, projectId: activeProjectId }));
    };

    const loadTargetGroups = () => {
        return dispatch(fetchTargetGroups({ projectId: activeProjectId }));
    };

    const modifyMenu = async (newMenu: Menu, addObjectToGroup?: boolean, publishing?: boolean) => {
        const response = await dispatch(updateMenu({ menu: newMenu, shouldUnlockAfterSave: true, addObjectToGroup })).unwrap();
        const showScheduledPublishAlert = newMenu.publishAt && newMenu.publishAt !== menuToEdit?.publishAt;
        if (response.ok) {
            showScheduledPublishAlert
                ? ToastAlert('success', '', '', icons.scheduledPublishWhiteIcon, DIALOG_NAMES.MENU_SCHEDULED_PUBLISH_AND_SAVED)
                : ToastAlert('success', '', '', undefined, publishing ? DIALOG_NAMES.MENU_PUBLISHED_AND_SAVED : DIALOG_NAMES.MENU_UPDATED);
            loadTargetGroups();
        }
        return response.ok;
    };

    const saveMenu = async (newMenu: Menu, addObjectToGroup?: boolean, publishing?: boolean) => {
        const response = await dispatch(createMenu({ menu: newMenu, addObjectToGroup })).unwrap();
        if (response.id) {
            ToastAlert(
                'success',
                '',
                '',
                undefined,
                publishing
                    ? newMenu.publishAt
                        ? DIALOG_NAMES.MENU_SCHEDULED_PUBLISH_AND_SAVED
                        : DIALOG_NAMES.MENU_PUBLISHED_AND_SAVED
                    : DIALOG_NAMES.MENU_CREATED
            );
            setSearchTerm('');
            setSortConfig(DEFAULT_SORT_CONFIG);
            setActiveSortingKey(ACCEPTED_SORT_FIELDS.lastModified);
            loadTargetGroups();
        }
        return response.id;
    };

    const removeMenu = async (id: string) => {
        ToastAlert('success', '', '', undefined, DIALOG_NAMES.MENU_DELETED);
        await dispatch(deleteMenu(id)).unwrap();
    };

    const loadPages = async (projectId: string) => {
        return await dispatch(fetchAllPages({ projectId })).unwrap();
    };

    const _publishMenu = async (id: string) => {
        await dispatch(publishMenu(id)).unwrap();
        if (!showUnsaved) {
            ToastAlert('success', '', '', undefined, DIALOG_NAMES.MENU_PUBLISHED);
        }
    };

    const _abortPublishMenu = async (id: string) => {
        const response = await dispatch(abortPublishMenu({ menuId: id })).unwrap();
        if (response.ok) {
            ToastAlert('success', '', '', undefined, DIALOG_NAMES.MENU_ABORT_PUBLISH);
        }
        return response;
    };

    const handleAbortPublishMenuClick = async (id: string = '') => {
        await _abortPublishMenu(id);
        loadMenus(false, activeProjectId, orderBy, searchTerm, activeObjectFilter);
        handleCancelClick();
    };

    useEffect(() => {
        if (locationState?.openCreate) {
            setOpenCreateMenu(true);
            window.history.replaceState({}, '');
        }

        // Used to open the editor in a new tab
        const redirectMenuId = getSearchParam('id');

        if (locationState?.menuId || locationState?.editingId || redirectMenuId) {
            setMenuId(locationState?.menuId || locationState?.editingId || redirectMenuId);
            window.history.replaceState({}, '');
        }
    }, [activeProjectId]);

    useEffect(() => {
        if (!activeProjectId) return;
        if (storeMenus.length) {
            dispatch(unsetMenus());
        }
        setSearchTerm('');
        setSortConfig(DEFAULT_SORT_CONFIG);
        setActiveSortingKey(ACCEPTED_SORT_FIELDS.lastModified);

        const redirectMenuId = getSearchParam('id');
        const menuId = location?.state?.menuId || location?.state?.editingId || redirectMenuId;

        let filter: ObjectFilter = {};
        if (menuId) {
            filter._id = [menuId];
            setActiveObjectFilter(filter);
        }

        loadMenus(true, activeProjectId, calculateOrderByFromSortConfig(DEFAULT_SORT_CONFIG), '', filter).then((response: any) => {
            if (response.permissions) {
                dispatch(setUserPermissions(response.permissions));
            }
            loadMenuTypes(activeProjectId);
            loadPages(activeProjectId);
            loadAudiences();
            loadConditions();
            loadTargetGroups();
        });
    }, [activeProjectId]);

    // When redirected from Dashboard open Edit Or Create
    useEffect(() => {
        if (loading) return;
        if (openCreateMenu) {
            handleCreateClick();
        }
        if (menuId) {
            const menu = menus.find((m) => m._id === menuId);
            if (!menu) return;
            const locked = isObjectLocked(menu);
            const lockedBy = objectIsLockedBy(menu);
            locked && renderLockedWarningAlert(lockedBy);
            if (!locked) {
                setIsEditing(true); // If the menu is NOT locked, only then we allow editing for the header values(name, target conditions) to be editable
                lockMenu(menuId);
            }
            if (activeObjectFilter?._id) {
                setSearchTerm(translate(menu.name) || `${EMPTY_WORD_STRING} ID: ${menu._id.substring(0, 8)}...`);
            }
            setMenuToEdit(menu);
            setNoOfItems(menu.items.length || 1);
        }
    }, [menus]);

    useEffect(() => {
        return () => {
            setMenuId('');
            setOpenCreateMenu(false);
        };
    }, []);

    useEffect(() => {
        if (!menuToEdit) return;
        if (menuToEdit.type !== MenuTypes.MAIN && menuToEdit.bindToPages) {
            setMenuToEdit({ ...menuToEdit, bindToPages: false });
        }
        if (creatingMenu && menuToEdit.items?.length && previousMenu?.type && menuToEdit.type !== previousMenu?.type) {
            setMenuToEdit({ ...menuToEdit, items: [] });
        }
    }, [menuToEdit]);

    useEffect(() => {
        if (loading || error) return;
        const newMenus = [...storeMenus];
        const menuWithDragIds = calculateMenuItemIdsForDragging(newMenus);
        setMenus(menuWithDragIds);
        setInitialMenus(menuWithDragIds);
        setShowSortArrows(false);
    }, [loading]);

    useEffect(() => {
        if (filteredMenus && filteredMenus.length !== menus.length) {
            setMenus(calculateMenuItemIdsForDragging(filteredMenus));
        }
    }, [filteredMenus]);

    const calculateMenuItemIdsForDragging = (menus: any[]) => {
        return menus.map((m) => ({
            ...m,
            items: m.items.map((item: any) => ({ ...item, id: `drag_id_${Math.floor(Math.random() * 99999)}` }))
        }));
    };

    const handleCreateClick = () => {
        menuToEdit?._id && unlock(menuToEdit._id);
        setCreatingMenu(true);
        setMenuToEdit({ _id: '-1', type: MenuTypes.MAIN });
        setNoOfItems(1);
    };

    const handleSortIconClick = (field: string) => {
        if (showUnsaved) return renderUnsavedAlertBeforeAction();

        setActiveSortingKey(field);
        let direction: 'asc' | 'desc' = 'asc';
        if (sortConfig && (sortConfig.field === field || sortConfig.field.split('.')[0] === field) && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        const config = {
            field,
            direction
        };
        setSortConfig(config);
        const orderBy = `${config.field}[${config.direction}]`;
        loadMenus(true, activeProjectId, orderBy, searchTerm, activeObjectFilter);
        setMenuToEdit(undefined);
        setMenuId('');
    };

    const renderAlertUnsavedChanges = () => {
        dialogConfirm(
            DIALOG_NAMES.UNSAVED_CHANGES,
            () => {
                handleSaveClick();
            },
            null,
            null,
            {
                noButton: { label: 'Discard Changes' },
                confirmButton: { label: 'Save' }
            },
            { warningIcon: true },
            () => {
                handleCancelClick();
            },
            true
        );
    };

    const handlePublishClick = async () => {
        // If there are changes we save first then publish the new menu!
        let mId;
        if (showUnsaved) {
            // when CREATING a menu, the ID will be returned and we use it for publishing
            mId = await handleSaveClick(true, undefined, true);
            if (!mId) return;
        }
        await _publishMenu(mId || menuToEdit?._id || '');
        creatingMenu && resetCallback();
        loadMenus(
            false,
            activeProjectId,
            creatingMenu ? '' : orderBy,
            creatingMenu ? '' : searchTerm,
            creatingMenu ? undefined : activeObjectFilter
        );
        handleCancelClick();
    };

    const handleSaveClick = (withoutLoading: boolean = false, publishAt?: number, publishing?: boolean): Promise<string> | undefined => {
        if (!menuToEdit) return;
        const oldMenu = menus.find((m) => m._id === menuToEdit._id) || null;

        return new Promise((resolve) => {
            const onSave = async (addObjectToGroup?: boolean) => {
                const id = await handleSave(withoutLoading, publishAt, addObjectToGroup, publishing);
                resolve(id || '');
            };

            handleCheckBeforeSave(storeAudiences, targetGroups, oldMenu, menuToEdit, 'menus', onSave, translate);
        });
    };

    const handleSave = async (withoutLoading: boolean = false, publishAt?: number, addObjectToGroup?: boolean, publishing?: boolean) => {
        if (!menuToEdit) return;
        let menu: Menu = {
            _id: creatingMenu ? '' : menuToEdit._id === '-1' ? '' : menuToEdit._id!!,
            type: menuToEdit?.type || MenuTypes.MAIN,
            projectId: activeProjectId || '',
            tenantId: activeTenantId || '',
            name: menuToEdit?.name || '',
            conditionIds: menuToEdit.conditionIds || [],
            abTestingGroupIds: menuToEdit.abTestingGroupIds || [],
            items: menuToEdit.items || [],
            bindToPages: menuToEdit.bindToPages || false,
            lastModified: menuToEdit.lastModified
        };

        if (publishAt) {
            menu.publishAt = publishAt;
        }

        // omit drag_id from items before save
        menu.items.forEach((item: any, index: number) => (menu.items[index] = _.omit(item, 'id') as MenuItem));

        if ((creatingMenu || menuToEdit) && !validateCreateMenu()) return;

        handleCancelClick();

        let id = '';

        if (creatingMenu) {
            id = await saveMenu(menu, addObjectToGroup, publishing);
        } else {
            const modifiedMenu = await modifyMenu(menu, addObjectToGroup, publishing);

            if (modifiedMenu) {
                id = menu._id;
            }
        }

        if (!withoutLoading) {
            resetCallback(creatingMenu ? undefined : { searchTerm: true, visualEditor: true, filterObject: true });
            loadMenus(
                false,
                activeProjectId,
                calculateOrderByFromSortConfig(DEFAULT_SORT_CONFIG),
                creatingMenu ? '' : searchTerm,
                creatingMenu ? undefined : activeObjectFilter
            );
        }
        return id;
    };

    const handleMenuDeleteClick = (id: string) => {
        const values = {
            title: 'Remove Menu',
            text: ''
        };

        dialogConfirm(
            '',
            async () => {
                await removeMenu(id);
                loadMenus(false, activeProjectId, orderBy, searchTerm, activeObjectFilter);
            },
            values,
            <RemoveModuleWrapper>
                <p>
                    <strong>Are you sure you want to remove this Menu?</strong>
                    <br />
                    By Pressing “Remove” you still will be able to create new one from list
                </p>
            </RemoveModuleWrapper>,
            {
                noButton: {
                    label: 'Cancel'
                },
                confirmButton: {
                    label: 'Remove'
                }
            },
            { warningIcon: true },
            undefined,
            true
        );
    };

    const handleItemDeleteClick = (index: number) => {
        dialogConfirm(DIALOG_NAMES.GENERIC_DELETE_CONFIRM, () => {
            if (!menuToEdit) return;
            const newItems = [...(menuToEdit.items || [])];
            newItems.splice(index, 1);
            setMenuToEdit({ ...menuToEdit, items: newItems });
        });
    };

    const handleItemAddClick = (item: any) => {
        // CASE OF ADDING NEW
        if (editingIndex < 0) {
            setMenuToEdit({ ...menuToEdit, items: [...(menuToEdit?.items || []), item] });
            setItemToEdit(null);
            return;
        }

        //CASE OF EDITING
        const newItems = [...(menuToEdit?.items || [])];
        newItems.splice(editingIndex, 1, item);
        setMenuToEdit({ ...menuToEdit, items: newItems });
        setEditingIndex(-1);
    };
    const calculateShowUnsaved = () => {
        if (creatingMenu) return true;

        if (menuToEdit) {
            const menu = menus.find((item) => item._id === menuToEdit._id);
            return JSON.stringify(menu) !== JSON.stringify(menuToEdit);
        }

        return false;
    };

    useEffect(() => {
        setShowUnsaved(calculateShowUnsaved());
    }, [...unsavedDependencyArray]);

    const handleValueChange = (value: any, key: keyof Menu) => {
        switch (key) {
            case 'name':
            case 'conditionIds':
            case 'abTestingGroupIds':
            case 'type':
                setMenuToEdit((prev) => {
                    return { ...prev, [key]: value };
                });
                break;
            default:
                break;
        }
    };
    const menuTypeOptions = menuTypes.map((menuType: any) => {
        return {
            value: menuType.value,
            label: menuType.key === 'MAIN' ? 'Main' : 'Customer Service'
        };
    });

    const renderNoMenus = () => (
        <NoResourcesContainer>
            <ResourceCard
                image={resourceCardImages.menusCard}
                title={'New Menu'}
                subtitle={'Create menu from scratch'}
                primaryButtonLabel={'Create Menu'}
                secondaryButtonLabel={'Learn more'}
                onPrimaryButtonClick={() => {
                    handleCreateClick();
                }}
                onSecondaryButtonClick={() => {
                    openDocumentation(circleSlugs.menus);
                }}
                createResource
            />
        </NoResourcesContainer>
    );
    const findMenuItem = useCallback(
        (id: any, menuIndex: number) => {
            const menuItems = [...(menuToEdit?.items || [])];
            const menuItem = menuItems.find((mi) => _.get(mi, 'id') === id) || menuItems[0];
            return {
                menuItem,
                index: menuItems.indexOf(menuItem)
            };
        },
        [menuToEdit, creatingMenu]
    );

    const moveMenuItem = useCallback(
        (id: any, menuIndex: number, atIndex: number) => {
            if (creatingMenu) return;
            const { menuItem, index } = findMenuItem(id, menuIndex);
            const newMenuItems = [...(menuToEdit?.items || [])];
            newMenuItems.splice(index, 1);
            newMenuItems.splice(atIndex, 0, menuItem);
            setMenuToEdit({ ...menuToEdit, items: newMenuItems });
        },
        [findMenuItem, menus, setMenus, creatingMenu, menuToEdit]
    );

    const renderMenuItemsListView = (menu?: Menu) => {
        const renderComponent = (itemObj: any) => {
            let component: any = <MenuItemComponentHorizontal />;
            if (!menu) return component;

            const { type, pageId, appName, subtitle } = itemObj;
            if (menu.type === MenuTypes.CUSTOMER_SERVICE) {
                component = (
                    <>
                        <MenuItemComponentHorizontal>
                            {translate(subtitle)}
                            <TranslationTooltip translationKey={subtitle} />
                        </MenuItemComponentHorizontal>
                    </>
                );
            } else {
                switch (type) {
                    case MenuItemTypes.PAGE:
                        const page: any = pages.find((elem) => elem._id === pageId) || {};
                        component = (
                            <MenuItemComponentHorizontal>
                                Page:{' '}
                                <DeepLinkSpan
                                    onClick={() =>
                                        navigate(buildPathWithProjectId(activeProjectId, PageRoutes.PAGE.replace(':page_id', page._id)))
                                    }
                                >
                                    {translate(page.name || EMPTY_WORD_STRING)}
                                </DeepLinkSpan>
                                <TranslationTooltip translationKey={page.name} />
                                {renderPublishStatusIcon(page.publishStatus, page.publishAt)}
                            </MenuItemComponentHorizontal>
                        );

                        break;
                    case MenuItemTypes.APPLICATION:
                        component = <MenuItemComponentHorizontal>App: {appName || OTHER_APPLICATION_VALUE}</MenuItemComponentHorizontal>;
                        break;
                    default:
                        break;
                }
            }
            return component;
        };

        if (!menu) return [];
        const locked = isObjectLocked(menu);
        const lockedBy = objectIsLockedBy(menu);
        const items = [];
        for (let i = 0; i < (noOfItems || 1); i++) {
            const itemObj = !menu.items || menu.items.length < i + 1 ? null : menu.items[i];
            if (!itemObj) {
                items.push(
                    <MenuItemWrapper
                        $isEmpty
                        onClick={() => {
                            if (locked) {
                                return renderLockedWarningAlert(lockedBy);
                            }

                            setOpenNewMenuItem(true);
                        }}
                    >
                        <MenuItemContainer>
                            <MenuItemDrag>&nbsp;</MenuItemDrag>
                            <MenuItemTitle>
                                <TruncatedText>Create Menu Item</TruncatedText>
                                <SVGInline src={icons.addIcon} />
                            </MenuItemTitle>
                        </MenuItemContainer>
                    </MenuItemWrapper>
                );
            } else {
                const icon: any = _.get(itemObj, 'icon');
                const url = _.get(itemObj, 'url');
                items.push(
                    <DraggableMenuItem
                        id={_.get(itemObj, 'id')}
                        findItem={findMenuItem}
                        moveItem={moveMenuItem}
                        menuIndex={menus.indexOf(menu)}
                        dragType={DRAGGABLE_TYPES.MENU_ITEM}
                        creating={creatingMenu}
                    >
                        <MenuItemContainer $creating={creatingMenu}>
                            <MenuItemTitle>
                                <TruncatedText>{translate(itemObj.title || EMPTY_WORD_STRING)}</TruncatedText>
                                {<TranslationTooltip translationKey={itemObj.title} />}
                            </MenuItemTitle>
                            {renderComponent(itemObj)}
                            <MenuItemIcon>
                                {menu.type === MenuTypes.MAIN
                                    ? icon && <IconPreviewImage src={icon} $invert={icon.includes('.svg')} />
                                    : url}
                            </MenuItemIcon>
                        </MenuItemContainer>
                        <ObjectActions
                            actions={[tableActions.EDIT, tableActions.REMOVE]}
                            onEdit={() => {
                                if (locked) {
                                    return renderLockedWarningAlert(lockedBy);
                                }
                                setOpenNewMenuItem(true);
                                setItemToEdit(itemObj);
                                setEditingIndex(i);
                            }}
                            onRemove={() => {
                                if (locked) {
                                    return renderLockedWarningAlert(lockedBy);
                                }
                                handleItemDeleteClick(i);
                            }}
                            tooltipTexts={{ delete: 'menus_components_icon_delete', edit: 'menus_components_icon_edit' }}
                        />
                    </DraggableMenuItem>
                );
            }
        }
        return (
            <ExpandedListViewHolder>
                <MenuListHeader creating={creatingMenu}>
                    <MenuItemTitle>{`Title`}</MenuItemTitle>
                    <MenuItemType>{menuToEdit?.type === MenuTypes.CUSTOMER_SERVICE ? 'Subtitle' : `Component`}</MenuItemType>
                    <MenuItemIcon>{menuToEdit?.type === MenuTypes.CUSTOMER_SERVICE ? 'Url' : `Icon`}</MenuItemIcon>
                </MenuListHeader>
                {items}
            </ExpandedListViewHolder>
        );
    };

    const renderMenuItemsGridView = (menu?: Menu) => {
        if (!menu) return [];
        const locked = isObjectLocked(menu);
        const lockedBy = objectIsLockedBy(menu);
        const mIndex = menus.indexOf(menu);
        const items = [];
        for (let i = 0; i < noOfItems; i++) {
            const itemObj = !menu.items || menu.items.length < i + 1 ? null : menu.items[i];
            if (!itemObj) {
                items.push(
                    <MenuItemCard
                        onClick={() => {
                            if (locked) {
                                return renderLockedWarningAlert(lockedBy);
                            }
                            setOpenNewMenuItem(true);
                        }}
                    >
                        <MenuItemTitle>
                            <span>Create Menu Item</span>
                        </MenuItemTitle>
                        <SVGInline src={icons.addIcon} style={{ marginLeft: 'auto' }} onClick={() => setOpenNewMenuItem(true)} />
                    </MenuItemCard>
                );
            } else {
                const icon = _.get(itemObj, 'icon');
                items.push(
                    <DraggableActionableMenuItemCard
                        id={_.get(itemObj, 'id')}
                        findItem={findMenuItem}
                        moveItem={moveMenuItem}
                        menuIndex={mIndex}
                        onEdit={() => {
                            if (locked) {
                                return renderLockedWarningAlert(lockedBy);
                            }
                            setOpenNewMenuItem(true);
                            setItemToEdit(itemObj);
                            setEditingIndex(i);
                        }}
                        onDelete={() => {
                            if (locked) {
                                return renderLockedWarningAlert(lockedBy);
                            }
                            handleItemDeleteClick(i);
                        }}
                        dragType={DRAGGABLE_TYPES.MENU_ITEM}
                        creating={creatingMenu}
                    >
                        {icon && <SVGInline src={_.get(icons, `${icon}`, '')} />}
                        <MenuItemTitle>
                            {_.truncate(translate(itemObj.title || ''), { length: 15 }) || EMPTY_WORD_STRING}{' '}
                            {<TranslationTooltip translationKey={itemObj.title} />}
                        </MenuItemTitle>
                        <SVGInline src={icons.moreIcon} style={{ marginLeft: 'auto' }} />
                    </DraggableActionableMenuItemCard>
                );
            }
        }
        return <GridViewHolder>{items}</GridViewHolder>;
    };

    const expandMenu = (menu: Menu) => {
        if (menu._id === menuToEdit?._id) {
            unlock(menu._id);
            setMenuToEdit(undefined);
            setIsEditing(false);
        } else {
            lockMenu(menu._id);
            setMenuToEdit(menu);
            setNoOfItems(menu?.items.length || 1);
        }
        setCreatingMenu(false);
    };
    const isEveryMenuItemPage = menuToEdit?.items?.every((item) => _.get(item, 'type') === MenuItemTypes.PAGE);

    const buildTableColumns = () => {
        const columns = Object.entries({
            name: 'Menu Name',
            no_items: isDesktop ? 'Amount Of items' : 'Items No',
            placed: 'Part of Group(s)',
            conditions: 'Target Conditions',
            bind_to: 'Bind To Pages',
            lastModified: 'Last Modified'
        }).map(([key, value], index) => {
            const isAcceptedSorField = ACCEPTED_SORT_FIELDS[key as keyof typeof ACCEPTED_SORT_FIELDS];
            return isAcceptedSorField ? (
                <SortableHeaderTableCell
                    text={value}
                    key={`${index}_cell`}
                    onClick={() => handleSortIconClick(key)}
                    onMouseEnter={() => setShowSortArrows(true)}
                    onMouseLeave={() => setShowSortArrows(false)}
                    hideArrow={!showSortArrows && activeSortingKey !== key}
                    columnSize={MenusTableSizes[key as keyof typeof MenusTableSizes]}
                    direction={((sortConfig?.field === key || sortConfig?.field.split('.')[0] === key) && sortConfig?.direction) || 'asc'}
                />
            ) : (
                <HeaderTableCell key={`${index}_cell`} text={value} columnSize={MenusTableSizes[key as keyof typeof MenusTableSizes]} />
            );
        });

        isMobile && columns.splice(5, 1); // Remove last modified column on mobile
        return columns;
    };

    const buildTableBody = () => {
        return (
            <>
                {menus.map((menu, index) => {
                    const noOfItems = menu.items.length === 0 ? (isDesktop ? '[No Items]' : '0') : menu.items.length;
                    const dateString = generateDateStringForTables(menu.lastModified || 0);
                    const locked = isObjectLocked(menu);
                    const lockedBy = objectIsLockedBy(menu);
                    const isLast = menus.length - 1 === index;

                    return (
                        <>
                            <ScrollableTableRowWrapper
                                shouldScroll={menuId === menu._id}
                                key={menu._id}
                                id={menu._id}
                                data-cy={`menu-container-${index}`}
                            >
                                {menuToEdit?._id === menu._id && isEditing ? (
                                    <WidthTableCell colSpan={isMobile ? 5 : 6} $um={'px'}>
                                        {/* EDIT MODE TABLE ROW */}
                                        <MenuFieldsContainer>
                                            <MenuFieldsSubContainer>
                                                <DialogTextField
                                                    label={'Menu Name'}
                                                    value={menuToEdit?.name || ''}
                                                    onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                                                        handleValueChange(evt.target.value, 'name');
                                                        setErrors(_.omit(errors, 'name'));
                                                    }}
                                                    error={errors.name}
                                                    withTranslations
                                                />
                                                <DialogDropdownSingle
                                                    options={menuTypeOptions}
                                                    placeholder={'Menu Type'}
                                                    onChange={(evt: any) => {
                                                        handleValueChange(evt.value, 'type');
                                                        setErrors(_.omit(errors, 'type'));
                                                    }}
                                                    value={menuTypeOptions.find((opt) => opt.value === menuToEdit?.type)}
                                                    isDisabled
                                                    labeledSelect
                                                />
                                                <DialogDropdownSingle
                                                    options={noOfItemsOptions}
                                                    placeholder={isDesktop ? 'Amount of items' : 'Items No'}
                                                    onChange={(evt: any) => {
                                                        setNoOfItems(evt.value);
                                                    }}
                                                    value={noOfItemsValue}
                                                    labeledSelect
                                                    unclickableIndexes={[0, 5, 8]}
                                                    notSorted
                                                />
                                            </MenuFieldsSubContainer>

                                            <MenuFieldsSubContainer>
                                                <TargetConditionsSelect
                                                    noOfLabels={1}
                                                    targets={menuToEdit.conditionIds || []}
                                                    onChange={(val) => handleValueChange(val, 'conditionIds')}
                                                />
                                                <AbTestingSelectWrapper>
                                                    <ABTestingGroupSelect
                                                        noOfLabels={1}
                                                        selectedGroups={menuToEdit.abTestingGroupIds || []}
                                                        onChange={(val) => handleValueChange(val, 'abTestingGroupIds')}
                                                        selectedTargetConditions={menuToEdit.conditionIds}
                                                    />
                                                </AbTestingSelectWrapper>
                                                {menuToEdit?.type === MenuTypes.MAIN && (
                                                    <DialogCheckbox
                                                        onClick={() => {
                                                            setMenuToEdit({ ...menuToEdit, bindToPages: !menuToEdit?.bindToPages });
                                                        }}
                                                        position={'center'}
                                                        text={'Bind To Pages'}
                                                        value={!!menuToEdit.bindToPages}
                                                        active={!!menuToEdit.bindToPages}
                                                        disabled={!isEveryMenuItemPage}
                                                        tooltipText={
                                                            isEveryMenuItemPage ? 'menu_bind_to_pages_info' : 'menu_bind_to_pages_warning'
                                                        }
                                                    />
                                                )}
                                            </MenuFieldsSubContainer>
                                        </MenuFieldsContainer>
                                    </WidthTableCell>
                                ) : (
                                    <>
                                        {/* MENU TITLE TABLE CELL */}
                                        <WidthTableCell {...MenusTableSizes.name}>
                                            <MenuViewName
                                                onClick={() => {
                                                    if (showUnsaved) return renderAlertUnsavedChanges();
                                                    expandMenu(menu);
                                                    // Show only on expand not on collapse as well
                                                    if (locked && menuToEdit?._id !== menu._id) {
                                                        return renderLockedWarningAlert(lockedBy);
                                                    }
                                                }}
                                            >
                                                <TruncatedText>{translate(menu.name || EMPTY_WORD_STRING)}</TruncatedText>
                                                <TranslationTooltip translationKey={menu.name} />
                                                {locked && renderLockIcon(lockedBy)}
                                                {renderABIcon(menu)}
                                            </MenuViewName>
                                        </WidthTableCell>

                                        {/* NO OF ITEMS TABLE CELL */}
                                        <WidthTableCell {...MenusTableSizes.no_items}>
                                            <MenuViewNoOfItems>{noOfItems}</MenuViewNoOfItems>
                                        </WidthTableCell>

                                        {/* PLACED TABLE CELL */}
                                        <WidthTableCell {...MenusTableSizes.placed}>
                                            <Labels
                                                withTranslationTooltip
                                                values={menu.placed || []}
                                                type={MoreInfoTypes.PLACED}
                                                noOfLabels={isLargeDesktop ? 2 : isTablet ? 0 : 1}
                                                onClickLabel={(obj) => {
                                                    navigate(
                                                        buildPathWithProjectId(
                                                            activeProjectId,
                                                            PageRoutes.TARGET_GROUP.replace(':group_id', obj._id)
                                                        )
                                                    );
                                                }}
                                            />
                                        </WidthTableCell>

                                        {/* TARGET CONDITIONS TABLE CELL */}
                                        <WidthTableCell {...MenusTableSizes.conditions}>
                                            <Labels
                                                values={menu.conditions || []}
                                                type={MoreInfoTypes.TARGETS}
                                                noOfLabels={isLargeDesktop ? 2 : isTablet ? 0 : 1}
                                                onClickLabel={(obj) => {
                                                    const isAudience = obj?.objectType === ObjectTypes.AUDIENCES;

                                                    navigate(
                                                        buildPathWithProjectId(
                                                            activeProjectId,
                                                            isAudience ? PageRoutes.AUDIENCES : PageRoutes.TARGET_CONDITIONS
                                                        ),
                                                        {
                                                            state: isAudience ? { audienceId: obj._id } : { conditionId: obj._id }
                                                        }
                                                    );
                                                }}
                                            />
                                        </WidthTableCell>

                                        {/* BIND TO PAGES TABLE CELL */}
                                        <WidthTableCell {...MenusTableSizes.bind_to}>
                                            <MenuViewBindToPages
                                                onClick={() => {
                                                    setShowEditWarning(true);
                                                    setTimeout(() => setShowEditWarning(false), 3000);
                                                }}
                                                onMouseLeave={() => setShowEditWarning(false)}
                                            >
                                                {menu.type === MenuTypes.MAIN && (
                                                    <DialogCheckbox
                                                        onClick={() => {}}
                                                        tooltipText={
                                                            showEditWarning ? 'menu_bind_to_pages_edit_info' : 'menu_bind_to_pages_info'
                                                        }
                                                        disabled
                                                        text={isSmallMobile ? '' : 'Bind To Pages'}
                                                        value={menu.bindToPages}
                                                        active={menu.bindToPages}
                                                        position={'center'}
                                                    />
                                                )}
                                            </MenuViewBindToPages>
                                        </WidthTableCell>

                                        {/* LAST MODIFIED TABLE CELL */}
                                        {!isMobile && (
                                            <WidthTableCell {...MenusTableSizes.lastModified}>
                                                <MenuViewLastModified>
                                                    <span>{dateString}</span>
                                                    <Tooltip title={menu?.modifiedByUser?.name || ''} placement="right-start">
                                                        <img src={menu?.modifiedByUser?.icon || icons.avatarIcon} alt="" />
                                                    </Tooltip>
                                                </MenuViewLastModified>
                                            </WidthTableCell>
                                        )}
                                    </>
                                )}

                                {/* ACTIONS TABLE CELL */}
                                <WidthTableCell {...MenusTableSizes.actions}>
                                    <ObjectActions
                                        actions={[tableActions.EDIT, tableActions.REMOVE]}
                                        withArrow
                                        onArrowToggle={() => {
                                            if (showUnsaved) return renderAlertUnsavedChanges();
                                            expandMenu(menu);
                                            // Show only on expand not on collapse as well
                                            if (locked && menu._id !== menuToEdit?._id) {
                                                return renderLockedWarningAlert(lockedBy);
                                            }
                                        }}
                                        onEdit={() => {
                                            // This if checks the case when you have unsavedChanges, and you try to click an another Menu EditIcon
                                            // and also checks the case where you have the Menu Expanded, do a reorder for the items or edit one,
                                            // and then try to hit EditIcon on the same Menu
                                            if (
                                                showUnsaved &&
                                                (menu._id !== menuToEdit?._id || (isEditing && menu._id === menuToEdit?._id))
                                            )
                                                return renderAlertUnsavedChanges();
                                            // If we click the currently expanded menu we toggle between editing,
                                            // otherwise a new Menu is clicked and the editing is set to TRUE
                                            !locked && setIsEditing(menu._id === menuToEdit?._id ? !isEditing : true);
                                            if (menu._id !== menuToEdit?._id) {
                                                setNoOfItems(menu?.items.length || 1);
                                                setMenuToEdit(menu);
                                                lockMenu(menu._id);
                                            }
                                            setCreatingMenu(false);
                                            if (locked) {
                                                renderLockedWarningAlert(lockedBy);
                                            }
                                        }}
                                        onRemove={() => {
                                            if (locked) {
                                                return renderLockedWarningAlert(lockedBy);
                                            }
                                            if (showUnsaved) return renderAlertUnsavedChanges();
                                            handleMenuDeleteClick(menu._id);
                                        }}
                                        publishedStatus={menu.publishStatus}
                                        publishAt={menu.publishAt}
                                        tooltipTexts={{
                                            edit: 'menus_icon_edit',
                                            delete: 'menus_icon_delete',
                                            arrowClose: 'menus_icon_hide_components',
                                            arrowOpen: 'menus_icon_show_components'
                                        }}
                                        open={menuToEdit?._id === menu._id}
                                    />
                                </WidthTableCell>
                            </ScrollableTableRowWrapper>

                            {/* MENU ITEMS TABLE ROW */}
                            {menuToEdit?._id === menu._id && (
                                <>
                                    {/* SPACE BETWEEN ROWS */}
                                    {view === 'GRID' && <TableRow style={{ height: '8px' }} />}
                                    <TableRowWrapper>
                                        <WidthTableCell style={{ padding: 0 }} colSpan={isMobile ? 6 : 7} $um={'px'}>
                                            {view === 'LIST'
                                                ? renderMenuItemsListView(menuToEdit as Menu)
                                                : renderMenuItemsGridView(menuToEdit as Menu)}
                                        </WidthTableCell>
                                    </TableRowWrapper>
                                </>
                            )}

                            {/* SPACE BETWEEN ROWS */}
                            {!isLast && <TableRow style={{ height: '24px' }} />}
                        </>
                    );
                })}
                {creatingMenu && renderCreatingMenu()}
            </>
        );
    };

    const renderCreatingMenu = () => {
        return (
            <>
                <TableRow style={{ height: '16px' }} />
                <ScrollableTableRowWrapper shouldScroll={creatingMenu} id={'create-menu-container'} data-cy={`menu-container-create`}>
                    <WidthTableCell colSpan={isMobile ? 5 : 6} $um={'px'}>
                        <MenuFieldsContainer>
                            <MenuFieldsSubContainer>
                                <DialogTextField
                                    label={'Menu Name'}
                                    value={menuToEdit?.name || ''}
                                    onChange={(evt: any) => {
                                        handleValueChange(evt.target.value, 'name');
                                        setErrors(_.omit(errors, 'name'));
                                    }}
                                    error={errors.name}
                                    dataCy={'menu-name-input'}
                                    withTranslations
                                />
                                <DialogDropdownSingle
                                    options={menuTypeOptions}
                                    placeholder={'Menu Type'}
                                    onChange={(evt: any) => {
                                        handleValueChange(evt.value, 'type');
                                        setErrors(_.omit(errors, 'type'));
                                    }}
                                    value={menuTypeOptions.find((opt) => opt.value === menuToEdit?.type)}
                                    labeledSelect
                                />
                                <DialogDropdownSingle
                                    options={noOfItemsOptions}
                                    placeholder={isDesktop ? 'Amount of items' : 'Items No'}
                                    onChange={(evt: any) => {
                                        setNoOfItems(evt.value);
                                    }}
                                    value={noOfItemsValue}
                                    labeledSelect
                                    unclickableIndexes={[0, 5, 8]}
                                    notSorted
                                />
                            </MenuFieldsSubContainer>
                            <MenuFieldsSubContainer>
                                <TargetConditionsSelect
                                    targets={menuToEdit?.conditionIds || []}
                                    onChange={(val) => handleValueChange(val, 'conditionIds')}
                                />
                                <AbTestingSelectWrapper>
                                    <ABTestingGroupSelect
                                        selectedGroups={menuToEdit?.abTestingGroupIds || []}
                                        onChange={(val) => handleValueChange(val, 'abTestingGroupIds')}
                                        selectedTargetConditions={menuToEdit?.conditionIds}
                                    />
                                </AbTestingSelectWrapper>
                            </MenuFieldsSubContainer>
                        </MenuFieldsContainer>
                    </WidthTableCell>

                    {/* ACTIONS TABLE CELL */}
                    <WidthTableCell {...MenusTableSizes.actions}>
                        <ObjectActions
                            actions={[tableActions.REMOVE]}
                            withArrow
                            open={menuToEdit?._id === '-1'}
                            onArrowToggle={() => {
                                setMenuToEdit({ ...menuToEdit, _id: menuToEdit?._id !== '-1' ? '-1' : '' });
                            }}
                            onRemove={() => {
                                setCreatingMenu(false);
                                setMenuToEdit(undefined);
                            }}
                            tooltipTexts={{
                                delete: 'menus_icon_delete',
                                arrowClose: 'menus_icon_hide_components',
                                arrowOpen: 'menus_icon_show_components'
                            }}
                        />
                    </WidthTableCell>
                </ScrollableTableRowWrapper>

                {/* MENU ITEMS TABLE ROW */}
                {menuToEdit?._id === '-1' && (
                    <>
                        {/* SPACE BETWEEN ROWS */}
                        <TableRow style={{ height: '8px' }} />
                        <TableRowWrapper>
                            <WidthTableCell style={{ padding: 0 }} colSpan={isMobile ? 6 : 7} $um={'px'}>
                                {view === 'LIST'
                                    ? renderMenuItemsListView(menuToEdit as Menu)
                                    : renderMenuItemsGridView(menuToEdit as Menu)}
                            </WidthTableCell>
                        </TableRowWrapper>
                    </>
                )}
            </>
        );
    };

    const noOfItemsOptions = buildNoOfItemsOptions();
    const noOfItemsValue = noOfItemsOptions.find((opt) => opt.value === noOfItems) || noOfItemsOptions[1];
    const isEmpty = !storeMenus?.length;

    const renderError = (error: any) => {
        switch (error.code) {
            case API_ERROR_CODES.LOCKED_ERROR:
                return renderLockedError(error);
            default:
                return <BackendErrorDialog error={error} />;
        }
    };
    const generalError = error || pagesError || applicationsError;
    return (
        <>
            {generalError && renderError(generalError)}
            <ApplicationWrapper>
                <Sidebar />
                <MainContentWrapper>
                    <ScreenTitle
                        loading={loading}
                        title="Menus"
                        withAddButton={!isEmpty}
                        withProfile
                        addLabel={'Create Menu'}
                        onAdd={() => {
                            showUnsaved ? renderAlertUnsavedChanges() : handleCreateClick();
                        }}
                        circlesSlugOptions={{ default: CIRCLE_SLUGS.menus, onboarding: ONBOARDING_CIRCLE_SLUGS.menus }}
                    />
                    <SearchBarContainer>
                        <SearchBarWrapper>
                            <FancyFilter
                                searchTerm={searchTerm}
                                type={ObjectTypes.MENUS}
                                showUnsaved={showUnsaved}
                                onChange={handleOnSearch}
                                setSearchTerm={setSearchTerm}
                                activeObjectFilter={activeObjectFilter}
                            />
                        </SearchBarWrapper>

                        <ToggleViewSwitch
                            checked={view === 'GRID'}
                            toggleCallback={() => setView(view === 'LIST' ? 'GRID' : 'LIST')}
                            tooltipTexts={{ list: 'menus_icon_switch_view_list', grid: 'menus_icon_switch_view_grid' }}
                        />
                    </SearchBarContainer>
                    {loading ? (
                        <Loader title="Menus" />
                    ) : isEmpty && !creatingMenu ? (
                        renderNoMenus()
                    ) : (
                        <>
                            <GenericTable columns={buildTableColumns()} body={buildTableBody()} />
                            {!creatingMenu && !menuToEdit && !loading && (
                                <PageActionsWrapper>
                                    <PageActionButton
                                        onClick={() => {
                                            handleCreateClick();
                                        }}
                                        label={'Create Menu'}
                                        type={'BLUE'}
                                    />
                                </PageActionsWrapper>
                            )}
                        </>
                    )}
                </MainContentWrapper>
                {(creatingMenu || menuToEdit) && (
                    <PageActions
                        onSave={() => {
                            handleSaveClick();
                        }}
                        onCancel={() => {
                            showUnsaved ? renderAlertUnsavedChanges() : handleCancelClick();
                        }}
                        disabled={{
                            save: !showUnsaved || conditionsLoading,
                            publish: menuToEdit?.publishStatus === PUBLISHED_STATUS.PUBLISHED && !showUnsaved
                        }}
                        onPublish={(publishAt) => {
                            publishAt ? handleSaveClick(false, publishAt, true) : handlePublishClick();
                        }}
                        publishedStatus={menuToEdit?.publishStatus || PUBLISHED_STATUS.UNPUBLISHED}
                        onAbortPublish={() => handleAbortPublishMenuClick(menuToEdit?._id)}
                        withSchedule
                        timestamp={menuToEdit?.publishAt}
                    />
                )}
                <NewMenuItemDialog
                    open={openNewMenuItem}
                    onClose={() => {
                        setOpenNewMenuItem(false);
                        setEditingIndex(-1);
                        setItemToEdit(null);
                    }}
                    onSave={(item) => {
                        handleItemAddClick(item);
                    }}
                    menuItem={itemToEdit}
                    onAddNewItem={() => setShowNewPageDialog(true)}
                    newPageId={newPageId}
                    showOnlyTypePage={menuToEdit?.bindToPages}
                    menuType={menuToEdit?.type || MenuTypes.MAIN}
                />

                <NewPageDialog
                    isMenu
                    open={showNewPageDialog}
                    onClose={() => setShowNewPageDialog(false)}
                    onSave={(page) => {
                        createNewPage(page);
                    }}
                />
            </ApplicationWrapper>
        </>
    );
};

export default Menus;

interface IDraggableMenuItem {
    id: any;
    moveItem: (id: any, menuIndex: number, to: number) => void;
    findItem: (id: any, menuIndex: number) => { menuItem: any; index: number };
    menuIndex: number;
    dragType: string;
    creating?: boolean;
}
const DraggableMenuItem: FC<IDraggableMenuItem> = ({ id, moveItem, findItem, menuIndex, dragType, creating, children }) => {
    const originalIndex = findItem(id, menuIndex).index;
    const [{ isDragging }, drag, preview] = useDrag(
        () => ({
            type: dragType,
            item: { id, originalIndex },
            collect: (monitor) => ({
                isDragging: monitor.isDragging()
            }),
            end: (item, monitor) => {
                const { id: droppedId, originalIndex } = item;
                const didDrop = monitor.didDrop();
                if (!didDrop) {
                    moveItem(droppedId, menuIndex, originalIndex);
                }
            }
        }),
        [id, originalIndex, moveItem]
    );
    const [, drop] = useDrop(
        () => ({
            accept: dragType,
            hover({ id: draggedId }: any, monitor) {
                if (draggedId !== id) {
                    const { index: overIndex } = findItem(id, menuIndex);
                    monitor.isOver() && moveItem(draggedId, menuIndex, overIndex);
                }
            }
        }),
        [findItem, moveItem]
    );
    const opacity = isDragging ? 0.5 : 1;

    return (
        <MenuItemWrapper style={{ opacity }} ref={(node) => drag(drop(node))}>
            {!creating && (
                <MenuItemDrag>
                    <ActionIconHolder>
                        {renderTooltipWithKey(<SVGInline src={icons.dragIcon} />, 'menus_components_icon_drag_and_drop')}
                    </ActionIconHolder>
                </MenuItemDrag>
            )}
            {children}
        </MenuItemWrapper>
    );
};

interface IDraggableActionableMenuItem {
    id: any;
    moveItem: (id: any, menuIndex: number, to: number) => void;
    findItem: (id: any, menuIndex: number) => { menuItem: any; index: number };
    menuIndex: number;
    dragType: string;
    creating?: boolean;
    onEdit?: any;
    onDelete?: any;
}

const DraggableActionableMenuItemCard: FC<IDraggableActionableMenuItem> = ({
    id,
    moveItem,
    findItem,
    menuIndex,
    dragType,
    onEdit,
    onDelete,
    creating,
    children
}) => {
    const originalIndex = findItem(id, menuIndex).index;
    const [{ isDragging }, drag, preview] = useDrag(
        () => ({
            type: dragType,
            item: { id, originalIndex },
            collect: (monitor) => ({
                isDragging: monitor.isDragging()
            }),
            end: (item, monitor) => {
                const { id: droppedId, originalIndex } = item;
                const didDrop = monitor.didDrop();
                if (!didDrop) {
                    moveItem(droppedId, menuIndex, originalIndex);
                }
            }
        }),
        [id, originalIndex, moveItem]
    );
    const [, drop] = useDrop(
        () => ({
            accept: dragType,
            hover({ id: draggedId }: any) {
                if (draggedId !== id) {
                    const { index: overIndex } = findItem(id, menuIndex);
                    moveItem(draggedId, menuIndex, overIndex);
                }
            }
        }),
        [findItem, moveItem]
    );
    const opacity = isDragging ? 0.5 : 1;
    const handleEditClick = (evt: any) => {
        evt.preventDefault();
        onEdit?.();
    };

    const handleDeleteClick = (evt: any) => {
        evt.preventDefault();
        onDelete?.();
    };

    return (
        <div style={{ display: 'inline' }} ref={(ref) => drag(drop(ref))}>
            <MenuItemCard style={{ opacity }}>
                <CardActions>
                    {!creating && (
                        <DragContainer ref={(node) => drag(drop(node))}>
                            <ActionIconHolder>
                                {renderTooltipWithKey(<SVGInline src={icons.dragLightIcon} />, 'menus_components_icon_drag_and_drop')}
                            </ActionIconHolder>
                        </DragContainer>
                    )}
                    <ActionIconWrapper>
                        <ActionIconHolder onClick={handleEditClick}>
                            {renderTooltipWithKey(<SVGInline src={icons.editLightIcon} />, 'menus_components_icon_edit')}
                        </ActionIconHolder>
                    </ActionIconWrapper>
                    <ActionIconWrapper>
                        <ActionIconHolder onClick={handleDeleteClick}>
                            {renderTooltipWithKey(<SVGInline src={icons.trashLightIcon} />, 'menus_components_icon_delete')}
                        </ActionIconHolder>
                    </ActionIconWrapper>
                </CardActions>
                {children}
            </MenuItemCard>
        </div>
    );
};

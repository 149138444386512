import React, { useEffect, useState } from 'react';
import { ApplicationWrapper, MainContentWrapper, TruncatedText } from '../../style/styled-components/reusable.css';
import Sidebar from '../common/Sidebar/Sidebar';
import ScreenTitle from '../common/DashboardTitle/ScreenTitle';
import { useAppDispatch as useDispatch, useAppSelector } from '../../hooks/redux';
import {
    fetchAllWithUnpublishedChanges,
    revertObjects,
    unpublishedChangesSlice,
    unsetUnpublishedChangesObjects
} from '../../redux/slices/unpublishedChangesSlice';
import BackendErrorDialog from '../common/Dialog/BackendErrorDialog';
import { Loader } from '../common/Loader/Loader';
import { ActiveItemState } from '../../redux/slices/activeItemSlice';
import GenericTable, {
    ActionsTableCell,
    HeaderTableCell,
    ImageTableCell,
    SelectAllTableCell,
    SortableHeaderTableCell,
    tableActions
} from '../common/Table/Table';
import { generateDateStringForTables } from '../../utils/fnDate';
import { TableBody, TableRow } from '@material-ui/core';
import { WidthTableCell } from '../common/Table/Table.css';
import TranslationTooltip from '../common/TranslationTooltip/TranslationTooltip';
import avatarIcon from '../../assets/images/icons/ico-avatar.svg';
import { buildPathWithProjectId, PageRoutes } from '../../types/RouteTypes';
import TableCell from '@material-ui/core/TableCell';
import useScreenSize from '../../hooks/useScreenSize';
import { useMultiSelect } from '../../hooks/useMultiSelect';
import { API_ERROR_CODES, EMPTY_WORD_STRING, getIconByObjectType } from '../../utils/Globals';
import { MoreInfoTypes } from '../common/Dialog/MoreInfoDialog';
import {
    EmptyUnpublishedChangesTextHolder,
    ObjectWithUnpublishedChangesIcon,
    ObjectWithUnpublishedChangesNameContainer,
    RevertObjectsWrapper,
    UnpublishedContentWrapper
} from './UnpublishedChanges.css';
import { useNavigate } from 'react-router-dom';
import { CC3BaseObject, ObjectType, ObjectTypes } from '../../types/Object';
import useLockSystem, { LockableObjectTypes } from '../../hooks/useLockSystem';
import { renderLockedError, renderLockedListActionError, renderLockedWarningAlert, renderLockIcon } from '../../utils/fnLockingSystem';
import PageActions from '../common/PageActions/PageActions';
import { dialogConfirm } from '../../utils/fnDialogs';
import { ACCEPTED_SORT_FIELDS, calculateOrderByFromSortConfig, DEFAULT_SORT_CONFIG_FOR_UNPUBLISHED, ISortConfig } from '../../utils/fnSort';
import { SearchBar } from '../common/SearchBar/SearchBar';
import _ from 'lodash';
import { UnpublishedTableSizes } from '../../types/TableSizes';
import Labels from '../common/Labels/Labels';
import {
    renderAdminLockedError,
    renderAdminLockedListActionError,
    renderAdminLockedWarningAlert,
    renderAdminLockIcon
} from '../../utils/fnAdminLocking';
import { SearchBarContainer } from '../common/SearchBar/SearchBar.css';
import useTranslation from '../../hooks/useTranslation';

const UnpublishedChanges = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { select } = useMultiSelect();
    const { isMobile, isDesktop, isTablet } = useScreenSize();
    const { isObjectLocked, objectIsLockedBy } = useLockSystem(LockableObjectTypes.CC3BASE_OBJECT);
    const { translate } = useTranslation();

    const { objectsWithUnpublishedChanges, loading, error }: unpublishedChangesSlice = useAppSelector((state) => state.unpublishedChanges);
    const { activeProjectId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const unpublishedObjects = objectsWithUnpublishedChanges as CC3BaseObject[];

    const [multiSelectedIds, setMultiSelectedIds] = useState<string[]>([]);
    const [allObjectsSelected, setAllObjectsSelected] = useState<boolean>(false);

    // PAGINATION, SEARCH AND FILTERING/SORTING RELATED FIELDS
    const [sortConfig, setSortConfig] = useState<ISortConfig>(DEFAULT_SORT_CONFIG_FOR_UNPUBLISHED);
    const [activeSortingKey, setActiveSortingKey] = useState<string>(ACCEPTED_SORT_FIELDS.objectType);
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const [showSortArrows, setShowSortArrows] = useState<boolean>(false);

    const orderBy = `${sortConfig.field}[${sortConfig.direction}]`;

    const loadAllWithUnpublishedChanges = async (addPermissions: boolean, projectId: string, orderBy?: string, searchTerm?: string) => {
        return await dispatch(fetchAllWithUnpublishedChanges({ addPermissions, projectId, orderBy, searchTerm })).unwrap();
    };
    const _revertObjects = async (objectIds: string[]) => {
        await dispatch(revertObjects({ objectIds })).unwrap();
        loadAllWithUnpublishedChanges(false, activeProjectId || '', orderBy, searchTerm);
    };

    useEffect(() => {
        if (!activeProjectId) return;
        setSearchTerm(undefined);
        setSortConfig(DEFAULT_SORT_CONFIG_FOR_UNPUBLISHED);
        setActiveSortingKey(ACCEPTED_SORT_FIELDS.objectType);
        if (unpublishedObjects.length) {
            dispatch(unsetUnpublishedChangesObjects());
        }
        loadAllWithUnpublishedChanges(true, activeProjectId, calculateOrderByFromSortConfig(DEFAULT_SORT_CONFIG_FOR_UNPUBLISHED));
    }, [activeProjectId]);

    useEffect(() => {
        if (!unpublishedObjects?.length) return;
        setAllObjectsSelected(multiSelectedIds.length === unpublishedObjects.length);
    }, [multiSelectedIds, unpublishedObjects]);

    useEffect(() => {
        if (loading) return;
    }, [loading]);

    const handleOnSearch = (searchTerm: string) => {
        loadAllWithUnpublishedChanges(true, activeProjectId || '', orderBy, searchTerm);
    };

    const handleSortIconClick = (field: string) => {
        setActiveSortingKey(field);
        let direction: 'asc' | 'desc' = 'asc';
        if (sortConfig && (sortConfig.field === field || sortConfig.field.split('.')[0] === field) && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        const config = {
            field,
            direction
        };
        setSortConfig(config);
        const orderBy = `${config.field}[${config.direction}]`;
        loadAllWithUnpublishedChanges(true, activeProjectId || '', orderBy, searchTerm);
        setShowSortArrows(false);
    };

    const handleRedirectOnPlacedLabelClick = (id: string, objectType: ObjectType) => {
        switch (objectType) {
            case ObjectTypes.PAGES:
            case ObjectTypes.SETTINGS:
            case ObjectTypes.MENUS:
                navigate(buildPathWithProjectId(activeProjectId, PageRoutes.TARGET_GROUP.replace(':group_id', id)));
                break;
            case ObjectTypes.MODULES:
                navigate(buildPathWithProjectId(activeProjectId, PageRoutes.PAGE.replace(':page_id', id)));
                break;
            case ObjectTypes.ITEMS:
                navigate(buildPathWithProjectId(activeProjectId, PageRoutes.MODULES), {
                    state: {
                        moduleId: id,
                        redirected: true,
                        openVisualEditor: true
                    }
                });
                break;
            default:
                break;
        }
    };

    const handleRedirectOnObjectNameClick = (id: string, objectType: ObjectType) => {
        switch (objectType) {
            case ObjectTypes.PAGES:
                navigate(buildPathWithProjectId(activeProjectId, PageRoutes.PAGE.replace(':page_id', id)));
                break;
            case ObjectTypes.MODULES:
                navigate(buildPathWithProjectId(activeProjectId, PageRoutes.MODULES), {
                    state: {
                        moduleId: id,
                        redirected: true
                    }
                });
                break;
            case ObjectTypes.ITEMS:
                navigate(buildPathWithProjectId(activeProjectId, PageRoutes.ITEMS), {
                    state: {
                        itemId: id,
                        openVisualEditor: true
                    }
                });
                break;
            case ObjectTypes.MENUS:
                navigate(buildPathWithProjectId(activeProjectId, PageRoutes.MENUS), { state: { menuId: id } });
                break;
            case ObjectTypes.SETTINGS:
                navigate(buildPathWithProjectId(activeProjectId, PageRoutes.SETTINGS), {
                    state: {
                        editingId: id
                    }
                });
                break;
            default:
                break;
        }
    };

    const handleRevertClick = async () => {
        await _revertObjects(multiSelectedIds);
        setMultiSelectedIds([]);
    };

    const renderRevertDialog = () => {
        const selectedObjects = unpublishedObjects.filter((obj) => multiSelectedIds.includes(obj._id));
        const shouldShowReferenced = selectedObjects.some((obj) =>
            [ObjectTypes.PAGES as string, ObjectTypes.MODULES as string].includes(obj.objectType)
        ); // extend array in future with types that have referenced objects

        const values = {
            title: 'Revert Objects',
            text: ''
        };

        dialogConfirm(
            '',
            () => {
                handleRevertClick();
            },
            values,
            <RevertObjectsWrapper>
                <p>
                    <strong>Are you sure you want to revert the selected Object(s)?</strong>
                    <br />
                    {shouldShowReferenced
                        ? 'By Pressing “Revert” all the referenced objects that are publishable will be reverted!'
                        : 'By Pressing “Revert” all the  objects that are publishable will be reverted too!'}
                </p>
            </RevertObjectsWrapper>,
            {
                noButton: {
                    label: 'Cancel'
                },
                confirmButton: {
                    label: 'Revert'
                }
            },
            { warningIcon: true },
            undefined,
            true
        );
    };

    const buildTableColumns = () => {
        const columns = [
            <SortableHeaderTableCell
                key={`name_cell`}
                text={'Object Name'}
                onClick={() => handleSortIconClick(ACCEPTED_SORT_FIELDS.name)}
                onMouseEnter={() => setShowSortArrows(true)}
                onMouseLeave={() => setShowSortArrows(false)}
                hideArrow={!showSortArrows && activeSortingKey !== ACCEPTED_SORT_FIELDS.name}
                columnSize={UnpublishedTableSizes.name}
                direction={(sortConfig?.field === ACCEPTED_SORT_FIELDS.name && sortConfig?.direction) || 'asc'}
            />,
            <SortableHeaderTableCell
                key={`name_cell`}
                text={'Object Type'}
                onClick={() => handleSortIconClick(ACCEPTED_SORT_FIELDS.objectType)}
                onMouseEnter={() => setShowSortArrows(true)}
                onMouseLeave={() => setShowSortArrows(false)}
                hideArrow={!showSortArrows && activeSortingKey !== ACCEPTED_SORT_FIELDS.objectType}
                columnSize={UnpublishedTableSizes.objectType}
                direction={(sortConfig?.field === ACCEPTED_SORT_FIELDS.objectType && sortConfig?.direction) || 'asc'}
            />,
            <HeaderTableCell text={'Part of Object(s)'} key={`placed_cell`} columnSize={UnpublishedTableSizes.placed} />,
            <SortableHeaderTableCell
                key={`lastModified_cell`}
                text={'Last Modified'}
                onClick={() => handleSortIconClick(ACCEPTED_SORT_FIELDS.lastModified)}
                onMouseEnter={() => setShowSortArrows(true)}
                onMouseLeave={() => setShowSortArrows(false)}
                hideArrow={!showSortArrows && activeSortingKey !== ACCEPTED_SORT_FIELDS.lastModified}
                columnSize={UnpublishedTableSizes.lastModified}
                direction={(sortConfig?.field === ACCEPTED_SORT_FIELDS.lastModified && sortConfig?.direction) || 'asc'}
            />
        ];

        columns.push(
            <>
                {isDesktop && <TableCell key={`empty_cell_pages`} />}
                <SelectAllTableCell
                    onSelectAll={(selected) => {
                        setMultiSelectedIds(selected ? unpublishedObjects.map((object) => object._id) : []);
                    }}
                    allValuesSelected={allObjectsSelected}
                    isMultiSelectVisible
                    setIsMultiSelectVisible={() => {}}
                    isDeleteDisabled
                    onDelete={() => {}}
                    withoutDeleteIcon
                />
            </>
        );
        isMobile && columns.splice(3, 1); // remove last modified column if not in desktop view
        return columns;
    };

    const buildTableBody = () => {
        const rows = unpublishedObjects.map((object, index) => {
            const dateString = generateDateStringForTables(object.lastModified || 0);

            const locked = isObjectLocked(object);
            const lockedBy = objectIsLockedBy(object);
            return (
                <TableRow key={object._id} style={{ cursor: 'pointer' }} data-cy={`object-row-${index}`}>
                    {/* OBJECT NAME TABLE CELL */}
                    <WidthTableCell {...UnpublishedTableSizes.name}>
                        <ObjectWithUnpublishedChangesNameContainer
                            onClick={() => {
                                handleRedirectOnObjectNameClick(object._id, object.objectType);
                            }}
                        >
                            {!isMobile && (
                                <ObjectWithUnpublishedChangesIcon>
                                    {getIconByObjectType(object.objectType)}
                                </ObjectWithUnpublishedChangesIcon>
                            )}

                            <TruncatedText>{translate(object.name || EMPTY_WORD_STRING)}</TruncatedText>
                            {<TranslationTooltip translationKey={object.name} />}
                            {locked && renderLockIcon(lockedBy)}
                            {object.adminLocked && renderAdminLockIcon()}
                        </ObjectWithUnpublishedChangesNameContainer>
                    </WidthTableCell>

                    {/* OBJECT TYPE TABLE CELL */}
                    <WidthTableCell {...UnpublishedTableSizes.objectType}>{_.capitalize(object.objectType)}</WidthTableCell>

                    {/* PLACED TABLE CELL */}
                    <WidthTableCell {...UnpublishedTableSizes.placed}>
                        <Labels
                            values={object.placed || []}
                            type={MoreInfoTypes.PLACED}
                            noOfLabels={isDesktop ? 3 : isTablet ? 2 : 0}
                            onClickLabel={(obj: any) => {
                                handleRedirectOnPlacedLabelClick(obj._id, object.objectType);
                            }}
                            withTranslationTooltip
                        />
                    </WidthTableCell>

                    {/* LAST MODIFIED TABLE CELL */}
                    {!isMobile && <WidthTableCell {...UnpublishedTableSizes.lastModified}>{dateString}</WidthTableCell>}

                    {/* MODIFIED BY TABLE CELL */}
                    {isDesktop && (
                        <ImageTableCell
                            um={'px'}
                            shape="round"
                            src={object?.modifiedByUser?.icon || avatarIcon}
                            toolTipName={object?.modifiedByUser?.name}
                            imageSize={{ width: 32, height: 32 }}
                        />
                    )}

                    {/* ACTIONS TABLE CELL */}
                    <WidthTableCell {...UnpublishedTableSizes.actions}>
                        <ActionsTableCell
                            actions={[tableActions.MULTI_SELECT]}
                            onMultiSelect={() => {
                                if (object.adminLocked) {
                                    return renderAdminLockedWarningAlert(object.name);
                                }
                                if (locked && lockedBy) {
                                    return renderLockedWarningAlert(lockedBy);
                                }
                                const pagesIds = unpublishedObjects.map((object) => object._id);
                                const newValues = select(pagesIds, multiSelectedIds, object._id);
                                setMultiSelectedIds([...newValues]);
                            }}
                            selected={multiSelectedIds.includes(object._id)}
                        />
                    </WidthTableCell>
                </TableRow>
            );
        });

        return <TableBody>{rows}</TableBody>;
    };
    const isEmpty = !objectsWithUnpublishedChanges?.length;

    const renderError = (error: any) => {
        switch (error.code) {
            case API_ERROR_CODES.LOCKED_ERROR:
                return multiSelectedIds?.length ? renderLockedListActionError(error) : renderLockedError(error);
            case API_ERROR_CODES.ADMIN_LOCKED_ERROR:
                return multiSelectedIds?.length ? renderAdminLockedListActionError(error) : renderAdminLockedError(error);
            default:
                return <BackendErrorDialog error={error} />;
        }
    };
    return (
        <>
            {error && renderError(error)}
            <ApplicationWrapper>
                <Sidebar />
                <MainContentWrapper>
                    <ScreenTitle loading={loading} title="Unpublished Changes" withProfile />
                    <SearchBarContainer>
                        <SearchBar
                            title={'Search objects'}
                            disabled={loading}
                            searchTerm={searchTerm}
                            onSearch={handleOnSearch}
                            setSearchTerm={setSearchTerm}
                            tooltipText={'unpublished_changes_icon_search'}
                        />
                    </SearchBarContainer>
                    {loading ? (
                        <Loader title={'Unpublished Changes'} />
                    ) : isEmpty ? (
                        <EmptyUnpublishedChangesTextHolder>There Are No Objects With Unpublished Changes</EmptyUnpublishedChangesTextHolder>
                    ) : (
                        <UnpublishedContentWrapper $extraPadding={!!multiSelectedIds.length}>
                            <GenericTable body={buildTableBody()} columns={buildTableColumns()} dataCy={'unpublished-changes-table'} />
                        </UnpublishedContentWrapper>
                    )}
                    {!!multiSelectedIds.length && (
                        <PageActions
                            onCancel={() => setMultiSelectedIds([])}
                            onSave={() => {
                                renderRevertDialog();
                            }}
                            customSaveLabel={'Revert'}
                            loading={{ save: loading }}
                        />
                    )}
                </MainContentWrapper>
            </ApplicationWrapper>
        </>
    );
};

export default UnpublishedChanges;

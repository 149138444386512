import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import GenericDialog, { DialogButton, DialogDropdownMultiple, DialogTextField, DialogTypes } from '../../../common/Dialog/GenericDialog';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../../hooks/redux';
import {
    AvatarHolder,
    CameraIconHolder,
    MyProfileAvatar,
    MyProfileCameraSVGInline,
    MyProfileCameraSVGInlineWrapper,
    MyProfileNameAndRole,
    MyProfileTenantsAndGroups
} from '../Profile.css';
import { DialogDoubleFieldWrapper, DoubleFieldSecondContainer, ErrorLabel } from '../../Dialog/GenericDialog.css';
import { StyledImage } from '../../../../style/styled-components/reusable.css';
import icons from '../../../../style';
import { UserGroup } from '../../../../types/UserGroup';
import ChangePasswordFields, { ChangePasswordValues } from './ChangePasswordFields';
import { validator } from '../../../../utils/fnValidator';
import { changePasswordErrorParser } from '../../../../utils/parsers';
import { dialogAlert } from '../../../../utils/fnDialogs';
import { getImgixUrl, USERROLES } from '../../../../utils/Globals';
import { renderTooltipWithKey } from '../../Tooltips/Tooltips';
import { FilesState, unsetFilesError, uploadFilesSync } from '../../../../redux/slices/fileManagerSlice';
import { extractFileNameFromAzureURL } from '../../../../utils/fnUrl';
import { CIRCLE_SLUGS } from '../../HelpIcon/HelpIcon';

export type MyProfileProps = {
    open: boolean;
    onSave: any;
    onClose: any;
    onChangePassword: (arg: any) => Promise<any>;
    errorMsg: string;
    profile: any;
    role?: string;
};

const MyProfileDialog: React.FC<MyProfileProps> = ({ open, onSave, onClose, onChangePassword, errorMsg, profile, role }) => {
    const [isOpen, setIsOpen] = useState(open);
    const [errors, setErrors] = useState<{ name?: string }>({ name: errorMsg });
    const [profileName, setProfileName] = useState(profile?.fullName);
    const [profileEmail, setProfileEmail] = useState(profile?.email);
    const [file, setFile] = useState<File | null>(null);
    const [preview, setPreview] = useState<string | ArrayBuffer | null>(null);
    const [changePasswordValues, setChangePasswordValues] = useState<ChangePasswordValues>({
        oldPassword: '',
        newPassword: '',
        passwordConfirm: ''
    });
    const [changePasswordErrors, setChangePasswordErrors] = useState<ChangePasswordValues | undefined>();
    const [selectedTenant, setSelectedTenant] = useState('');
    const [deleteFile, setDeleteFile] = useState(false);

    const acceptedExtensions = ['.jpg', '.png'];
    const inputRef = React.createRef<HTMLInputElement>();
    const title = 'My Profile';

    const { error: imageError, loading: imageLoading }: FilesState = useAppSelector((state) => state.files);
    const dispatch = useDispatch();

    useEffect(() => {
        if (profile && Object.keys(profile).length !== 0) {
            setProfileName(profile.fullName);
            setProfileEmail(profile.email);
            setPreview(profile.icon || null);
            setSelectedTenant(profile?.role?.name !== USERROLES.SUPER_ADMIN && profile?.tenants?.length ? profile.tenants[0]._id : '');
        }
    }, [profile]);

    useEffect(() => {
        setIsOpen(open);
        setDeleteFile(false);
    }, [open]);

    useEffect(() => {
        setErrors({
            name: errorMsg
        });
    }, [errorMsg]);

    const validateNewProfile = () => {
        const newErrors = { ...errors };
        newErrors.name = validator({ required: true, minLength: 4 }, profileName);
        setErrors(newErrors);
        return (
            Object.values(newErrors).filter((value) => !!value).length === 0 &&
            (!changePasswordErrors || Object.values(changePasswordErrors).filter((value) => !!value).length === 0)
        );
    };

    const handleCloseClick = (evt: any) => {
        evt.preventDefault();
        if (onClose) {
            onClose();
            setErrors({});
            dispatch(unsetFilesError());
            setProfileName(profile?.name);
            setProfileEmail(profile?.email);
            setFile(null);
            setPreview(null);
        }
    };

    const handleSaveClick = async (evt: any) => {
        evt.preventDefault();
        // to be used when file can be saved
        if (!onSave) return;
        if (!validateNewProfile()) return;

        if (Object.values(changePasswordValues).filter((value) => !!value).length) {
            const changePasswordResult = await onChangePassword(changePasswordValues);
            if (changePasswordResult !== true) {
                const error = changePasswordErrorParser(changePasswordResult);
                if (error.isWrongInput) {
                    const newErrors = {
                        oldPassword: error.oldPassword ? error.message : '',
                        newPassword: error.oldPassword ? '' : error.message,
                        passwordConfirm: ''
                    };
                    return setChangePasswordErrors(newErrors);
                }
                const values = {
                    title: 'Oops.. Could not change password!',
                    subtitle: `Something went wrong while changing the user's password.`,
                    text: 'An error occured: ' + changePasswordResult.message
                };
                return dialogAlert('', false, values, null, true);
            }
        }

        const newValues: any = {
            _id: profile?._id,
            fullName: profileName
        };

        if (file) {
            const urls = await createFile(file);
            if (urls?.length) {
                const fileName = extractFileNameFromAzureURL(urls[0]);
                newValues.icon = encodeURIComponent(getImgixUrl(fileName, undefined, true));
            }
        } else if (deleteFile) {
            newValues.icon = '';
        }

        onSave(newValues);
        setErrors({});
        setProfileName('');
        setProfileEmail('');
        setFile(null);
        setPreview(null);
        dispatch(unsetFilesError());
        onClose();
    };

    const createFile = async (file: File) => {
        try {
            const response = await dispatch(uploadFilesSync({ files: [file], prefix: 'user_icons', overwrite: true })).unwrap();
            return response.urls;
        } catch (ex) {
            return [];
        }
    };

    const saveButton: DialogButton = {
        label: 'Save',
        type: 'BLUE',
        onClick: handleSaveClick,
        loading: imageLoading
    };

    const cancelButton: DialogButton = {
        label: 'Cancel',
        type: 'DEFAULT',
        onClick: handleCloseClick
    };

    const profileGroupOptions =
        profile?.userGroups?.length && selectedTenant
            ? profile.userGroups
                  .filter((group: UserGroup) => group.tenantId === selectedTenant)
                  .map((group: UserGroup) => {
                      return {
                          label: group.name,
                          value: group._id
                      };
                  })
            : [];

    const handleBrowseClick = (evt: any) => {
        evt.preventDefault();
        let input = inputRef.current;
        if (input) {
            input.click();
        }
    };

    const handleFileInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        evt.preventDefault();
        const newFile = evt?.target?.files?.[0];
        if (newFile) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setFile(newFile);
                setPreview(reader.result);
            };
            reader.readAsDataURL(newFile);
        }
        setDeleteFile(!newFile);
        setErrors(_.omit(errors, 'icon'));
        dispatch(unsetFilesError());
    };

    return isOpen ? (
        <GenericDialog
            type={DialogTypes.Form}
            title={`${title}`}
            onClose={handleCloseClick}
            actionButtons={[cancelButton, saveButton]}
            circlesSlugOptions={{ default: CIRCLE_SLUGS.profile }}
        >
            <MyProfileAvatar id="profile_avatar">
                <AvatarHolder>
                    <StyledImage src={(preview as string) || icons.userIcon} />
                    <CameraIconHolder onClick={handleBrowseClick}>
                        {renderTooltipWithKey(
                            <MyProfileCameraSVGInlineWrapper>
                                <MyProfileCameraSVGInline src={icons.cameraIcon2} />
                            </MyProfileCameraSVGInlineWrapper>,
                            'my_profile_icon_camera'
                        )}
                    </CameraIconHolder>
                </AvatarHolder>
                <input
                    id="file-upload-input"
                    type="file"
                    accept={acceptedExtensions.join(',')}
                    onChange={handleFileInputChange}
                    ref={inputRef}
                    hidden
                />
            </MyProfileAvatar>
            <ErrorLabel error={imageError?.message !== undefined}>{imageError?.message || ''}</ErrorLabel>
            <MyProfileNameAndRole>
                <DialogDoubleFieldWrapper $width1={368} modifyChildStyle>
                    <DialogTextField
                        value={profileName}
                        error={errors?.name}
                        label={'User name'}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                            setProfileName(evt.target.value);
                            setErrors(_.omit(errors, 'name'));
                        }}
                    />

                    <DoubleFieldSecondContainer>
                        <DialogTextField
                            value={_.startCase(role) || 'Unkown Role'}
                            label={' '}
                            isDisabled
                            noLabel
                            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {}}
                        />
                    </DoubleFieldSecondContainer>
                </DialogDoubleFieldWrapper>
            </MyProfileNameAndRole>
            <DialogTextField
                value={profileEmail || profile.email || ''}
                label={'Email'}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                    setProfileEmail(evt.target.value);
                    setErrors(_.omit(errors, 'email'));
                }}
                isDisabled={true}
                withTopMargin
            />
            <ChangePasswordFields
                initialValues={changePasswordValues}
                initialErrors={changePasswordErrors}
                onChange={(values, errors) => {
                    setChangePasswordValues(values);
                    setChangePasswordErrors(errors);
                }}
            />
            {profile?.role?.name !== USERROLES.SUPER_ADMIN && (
                <MyProfileTenantsAndGroups>
                    {!!profileGroupOptions?.length && (
                        <DialogDropdownMultiple
                            labelText={'Groups'}
                            value={profileGroupOptions}
                            options={profileGroupOptions}
                            placeholder={''}
                            isDisabled={true}
                            allowSelectAll={true}
                            onChange={(value: any) => {}}
                        />
                    )}
                </MyProfileTenantsAndGroups>
            )}
        </GenericDialog>
    ) : null;
};

export default MyProfileDialog;

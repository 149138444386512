import { FC, useEffect, useState } from 'react';
import _ from 'lodash';
import GenericDialog, { DialogDropdownSingle, DialogTypes } from '../../common/Dialog/GenericDialog';
import {
    CreatePageIconContainer,
    FilterSelectWrapper,
    OptionText,
    OptionWrapper,
    SortSelectWrapper,
    TemplateCard,
    TemplateCardsContainer,
    TemplateFiltersContainer,
    TemplateName,
    TemplatePlacedContainer,
    TemplatesContainer,
    TemplatesLoaderWrapper,
    TemplateType
} from '../Pages.css';
import icons from '../../../style';
import { ActiveItemState } from '../../../redux/slices/activeItemSlice';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../hooks/redux';
import { useNavigate } from 'react-router-dom';
import { Template, templateType, templateTypes } from '../../../types/Template';
import { fetchTemplates, fetchTemplatesTags, templatesState } from '../../../redux/slices/templatesSlice';
import { ObjectNameLabel, ShowMoreButton } from '../../../style/styled-components/reusable.css';
import { Tooltip } from '@material-ui/core';
import { MoreInfoDialog, MoreInfoTypes } from '../../common/Dialog/MoreInfoDialog';
import { EMPTY_WORD_STRING, moduleTypes } from '../../../utils/Globals';
import { actionNameMissingAlert, dialogAlert, ToastAlert } from '../../../utils/fnDialogs';
import { Loader } from '../../common/Loader/Loader';
import { CIRCLE_SLUGS } from '../../common/HelpIcon/HelpIcon';
import { search, SearchBar } from '../../common/SearchBar/SearchBar';
import { itemTypes } from '../../../types/Item';
import { VirtualizedList } from '../../common/Select/VirtualizedList';
import useScreenSize from '../../../hooks/useScreenSize';

interface ITemplateSelection {
    open: boolean;
    onClose: () => void;
    templateUrl: string;
    resourceType: templateType;
    callback?: (id?: string) => void;
    isAutoCollection?: boolean;
    collectionType?: itemTypes;
    isAppliedInGalleryOrButton?: boolean;
    moduleItemsNumber?: number;
    restrictedCollection?: boolean;
    isContentWorldModule?: boolean;
}

const sortOptions = [
    { value: 1, label: 'Alphabetical (A-Z)' },
    { value: -1, label: 'Alphabetical (Z-A)' }
];

const { templateIcon } = icons;
const TemplateSelection: FC<ITemplateSelection> = ({
    open,
    onClose,
    templateUrl,
    callback,
    resourceType,
    isAutoCollection,
    collectionType,
    moduleItemsNumber,
    isAppliedInGalleryOrButton,
    restrictedCollection,
    isContentWorldModule
}) => {
    const { activeProjectId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const { isDesktop, isMobile } = useScreenSize();

    const defaultSortOption = sortOptions.find((s) => s.value === 1);

    const [isOpen, setIsOpen] = useState(false);
    const [type, setType] = useState<any>(null);
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const [sort, setSort] = useState<any>(defaultSortOption);
    const [templates, setTemplates] = useState<any>([]);
    const [templatesToRender, setTemplatesToRender] = useState<any>([]);
    const [typeOptions, setTypeOptions] = useState<any[]>([]);
    const [showMoreDialog, setShowMoreDialog] = useState<{ type: MoreInfoTypes | null; show: boolean; data: any[] }>({
        type: null,
        show: false,
        data: []
    });

    const {
        templates: storeTemplates,
        loading,
        error,
        templatesTags,
        tagsLoading
    }: templatesState = useAppSelector((state) => state.templates);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // SINCE WE HAVE ONLY EDITORIAL ITEM TEMPLATES WE DON'T NEED TO CHECK SEPARATELY FOR EACH CARD WHETHER IT'S EDITORIAL OR NOT
    const shouldRenderAutoCollectionAlert =
        (moduleItemsNumber && moduleItemsNumber > 0 && isAutoCollection) ||
        (moduleItemsNumber === 0 && isAutoCollection && resourceType === templateTypes.ITEM);

    const autoCollectionAlertValues = {
        title: 'Dynamic Item with Auto-Collection',
        text: 'You can have only one Dynamic Item with Auto-Collection. Adding or having additional items will remove the Auto-Collection!'
    };

    const renderRestrictedCollectionDialog = () => {
        const values = {
            title: 'Module with Collection Type',
            text: 'Adding a collection is not possible to this page because there is more than one module added to the page, the page already contains a collection or the page has an AUTO_COLLECTION Intent.'
        };
        ToastAlert('error', values.title, values.text, undefined, undefined, () => {
            dialogAlert('', false, values, undefined, undefined, icons.warningIcon);
        });
    };

    const renderRestrictedContentWorldDialog = () => {
        const values = {
            title: 'Module with Content World',
            text: 'This Page template contains one Content World module, if you already have a a content World module configured in the project, this will be automatically set to false'
        };
        ToastAlert('warning', values.title, values.text, undefined, undefined, () => {
            dialogAlert('', false, values, undefined, undefined, icons.warningYellowIcon);
        });
    };

    useEffect(() => {
        if (open) {
            loadTemplates();
            !templatesTags.length && loadTemplatesTags();
        }
        setIsOpen(open);
    }, [open, resourceType]);

    useEffect(() => {
        const types = [
            { value: -1, label: 'All' },
            ...templatesTags.map((key) => {
                return { value: key, label: _.capitalize(key).replace('_', ' ') };
            })
        ];

        const options = types
            .map((option: any) => {
                const items =
                    option.value === -1 ? templates?.length : templates?.filter((t: any) => t?.tags?.includes(option.value))?.length;
                if (!items && option.value !== -1) return null;
                return {
                    value: option.value,
                    label: (
                        <OptionWrapper>
                            <OptionText>{option.label}</OptionText>
                            <span>{items}</span>
                        </OptionWrapper>
                    ),
                    valueForSearch: option.label
                };
            })
            .filter((val) => !!val);

        setTypeOptions(options);
    }, [templates, resourceType, templatesTags]);

    useEffect(() => {
        type && sortAndFilterTemplates(templates, type.value, sort.value);
    }, [templates, type, sort]);

    useEffect(() => {
        if (loading || error) return;
        let templates = _.get(storeTemplates, resourceType, []);
        // in content world module only editorial items are allowed
        if ((resourceType === templateTypes.ITEM && isContentWorldModule) || (collectionType && collectionType !== itemTypes.DYNAMIC)) {
            templates = templates.filter((template) => template.values?.itemType === itemTypes.EDITORIAL);
        }

        setTemplates(templates);
    }, [loading, storeTemplates]);

    useEffect(() => {
        typeOptions.length && setType(typeOptions?.find((t) => t.value === -1));
    }, [typeOptions]);

    const loadTemplates = async () => {
        await dispatch(fetchTemplates({ type: resourceType, released: true, projectId: activeProjectId })).unwrap();
    };
    const loadTemplatesTags = async () => {
        await dispatch(fetchTemplatesTags()).unwrap();
    };

    const resetState = () => {
        setTypeOptions([]);
        setType(null);
        setSearchTerm(undefined);
        setSort(defaultSortOption);
    };

    const handleCloseClick = () => {
        resetState();
        onClose();
    };

    const handleTemplateClick = (template: Template) => {
        if (!callback) {
            navigate(templateUrl);
            resetState();
            return;
        }
        const showRestrictedCW =
            template?.type === templateTypes.PAGE && template?.moduleTemplates?.some((module) => !!module?.values?.contentWorld);

        const showActionNameDialog = template?.type === templateTypes.ITEM && !template.values?.actionName && isAppliedInGalleryOrButton;
        const showRestrictedCollection =
            restrictedCollection && template?.type === templateTypes.MODULE && template.values?.moduleType === moduleTypes.COLLECTION;

        if (showRestrictedCollection) {
            return renderRestrictedCollectionDialog();
        }

        if (showRestrictedCW) {
            renderRestrictedContentWorldDialog();
        }

        if (shouldRenderAutoCollectionAlert) {
            ToastAlert(
                'critical_warning',
                autoCollectionAlertValues.title,
                autoCollectionAlertValues.text,
                undefined,
                undefined,
                () => {
                    dialogAlert('', undefined, autoCollectionAlertValues, undefined, undefined, icons.warningIcon);
                },
                showActionNameDialog ? () => actionNameMissingAlert() : undefined
            );
        }

        if (showActionNameDialog && !shouldRenderAutoCollectionAlert) {
            actionNameMissingAlert();
        }
        callback(template._id);
        resetState();
    };

    const sortAndFilterTemplates = (templates: any[], filterType: any, sortOrder: any) => {
        let filteredAndSorted = [];
        //Filtering by Type
        filteredAndSorted = filterType === -1 ? [...templates] : templates.filter((t: any) => t.tags?.includes(filterType));

        //Sorting
        filteredAndSorted.sort((a, b) => {
            let result = a.values?.name.localeCompare(b.values?.name);
            return result * sortOrder;
        });
        setTemplatesToRender(filteredAndSorted);
    };

    const renderTemplatesTags = (tags: string[] = []) => {
        const moreInfoTags = tags.map((tag, i) => {
            return { _id: `tag${i}`, name: tag };
        });
        const labels: JSX.Element[] = [];
        const noOfTagsToShow = 1;
        tags?.forEach((tag, i) => {
            const tagName = _.capitalize(tag).replace('_', ' ');
            if (i < noOfTagsToShow) {
                labels.push(
                    <Tooltip key={i} title={tagName || EMPTY_WORD_STRING} placement={'top'}>
                        <ObjectNameLabel $isBlack key={'tag_' + i}>
                            <span>{tagName || EMPTY_WORD_STRING}</span>
                        </ObjectNameLabel>
                    </Tooltip>
                );
            }
        });

        (tags?.length || 0) > noOfTagsToShow &&
            labels.push(
                <ShowMoreButton
                    $isBlack
                    onClick={(e: React.MouseEvent<any>) => {
                        setShowMoreDialog({
                            type: MoreInfoTypes.TAGS,
                            show: true,
                            data: moreInfoTags!
                        });
                        e.stopPropagation();
                    }}
                >
                    <span>+{(tags.length || 0) - noOfTagsToShow}</span>
                </ShowMoreButton>
            );

        return labels;
    };

    const renderTemplates = () => {
        const noOfColumns = isDesktop ? 3 : isMobile ? 1 : 2;
        const itemWidth = isDesktop ? 350 : 344;
        const templatesToShow = templatesToRender.map((template: Template, index: number) => (
            <TemplateCard key={index} onClick={() => handleTemplateClick(template)}>
                <CreatePageIconContainer $background={template.iconBackground}>
                    <img src={template.icon || templateIcon} />
                </CreatePageIconContainer>
                <div>
                    <TemplateName>{template.values?.name}</TemplateName>
                    <TemplateType>
                        <TemplatePlacedContainer>{renderTemplatesTags(template.tags)}</TemplatePlacedContainer>{' '}
                    </TemplateType>
                </div>
            </TemplateCard>
        ));

        return (
            <VirtualizedList isGrid itemHeight={92} itemWidth={itemWidth} columns={noOfColumns}>
                {templatesToShow}
            </VirtualizedList>
        );
    };

    if (!isOpen) return null;
    const generalLoading = loading || tagsLoading;
    return (
        <GenericDialog
            title={'Select Templates'}
            type={DialogTypes.Template}
            onClose={() => handleCloseClick()}
            circlesSlugOptions={{ default: CIRCLE_SLUGS.templates }}
        >
            <TemplatesContainer id="templates_container">
                <TemplateFiltersContainer>
                    <SearchBar
                        title={`Search`}
                        disabled={generalLoading}
                        searchTerm={searchTerm}
                        setSearchTerm={(value) => {
                            setSearchTerm(value);
                            setTemplatesToRender(search(templates, value, undefined, 'values'));
                        }}
                        onSearch={() => {}} // because of the specific filters the templates have we will search/filter/sort locally for now
                    />

                    <FilterSelectWrapper>
                        <DialogDropdownSingle
                            value={type}
                            placeholder={type?.value}
                            onChange={(newValue: any) => {
                                setType(newValue);
                            }}
                            options={typeOptions}
                            noLabel
                            noError
                        />
                    </FilterSelectWrapper>
                    <SortSelectWrapper>
                        <DialogDropdownSingle
                            value={sort}
                            placeholder={sort.label}
                            onChange={(newValue: any) => {
                                setSort(newValue);
                            }}
                            options={sortOptions}
                            noLabel
                            noError
                        />
                    </SortSelectWrapper>
                </TemplateFiltersContainer>
                <TemplateCardsContainer>
                    {!generalLoading ? (
                        renderTemplates()
                    ) : (
                        <TemplatesLoaderWrapper>
                            <Loader />
                        </TemplatesLoaderWrapper>
                    )}{' '}
                </TemplateCardsContainer>

                <MoreInfoDialog
                    open={showMoreDialog.show}
                    onClose={() => setShowMoreDialog({ type: null, show: false, data: [] })}
                    type={showMoreDialog.type!}
                    data={showMoreDialog.data}
                />
            </TemplatesContainer>
        </GenericDialog>
    );
};

export default TemplateSelection;

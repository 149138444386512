import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../hooks/redux';
import icons from '../../../style';
import {
    ApplicationWrapper,
    MainContentWrapper,
    NoResourcesContainer,
    ObjectNameLabel,
    PageActionButton,
    PageActionsWrapper,
    PlacedInPublishedWarningMessageWrapper,
    TruncatedText
} from '../../../style/styled-components/reusable.css';
import { DialogTextField } from '../../common/Dialog/GenericDialog';
import { ConditionTitle, ConditionTitleInput, ConditionValues, LanguageFlag, LastModified, SubTitleWrapper } from './Conditions.css';
import { NewConditionDialog } from './Dialogs/NewCondition';
import SVGInline from 'react-inlinesvg';
import { ActiveItemState } from '../../../redux/slices/activeItemSlice';
import {
    createDisplayCondition,
    deleteDisplayCondition,
    DisplayConditionsState,
    fetchDisplayConditions,
    unsetDisplayConditions,
    updateDisplayCondition
} from '../../../redux/slices/displayConditionsSlice';
import { TableRow, Tooltip } from '@material-ui/core';
import { generateDateStringForTables, generateTimeString, getFullDate } from '../../../utils/fnDate';
import _ from 'lodash';
import PageActions from '../../common/PageActions/PageActions';
import { Loader } from '../../common/Loader/Loader';
import { fetchCountryCodes, fetchLanguageCodes, fetchLanguages, LanguagesState } from '../../../redux/slices/languagesSlice';
import { CategoriesKeys, DisplayCondition, keysTitle, valueKeys } from '../../../types/DisplayCondition';
import BackendErrorDialog from '../../common/Dialog/BackendErrorDialog';
import { setUserPermissions } from '../../../redux/slices/permissionsSlice';
import { DIALOG_NAMES, dialogConfirm, ToastAlert } from '../../../utils/fnDialogs';
import { RemoveModuleWrapper } from '../../Modules/Modules.css';
import { ResourceCard } from '../../Cards/ResourceCard/ResourceCard';
import { renderTooltipWithKey } from '../../common/Tooltips/Tooltips';
import {
    ACCEPTED_SORT_FIELDS,
    AcceptedSortField,
    calculateOrderByFromSortConfig,
    DEFAULT_SORT_CONFIG,
    ISortConfig
} from '../../../utils/fnSort';
import { MoreInfoDialog, MoreInfoTypes } from '../../common/Dialog/MoreInfoDialog';
import { useLocation, useNavigate } from 'react-router-dom';
import { API_ERROR_CODES, EMPTY_WORD_STRING } from '../../../utils/Globals';
import { resourceCardImages } from '../../../assets/images/resourceCards';
import { validator } from '../../../utils/fnValidator';
import useBlockNavigation from '../../../hooks/useBlockNavigation';
import useScreenSize from '../../../hooks/useScreenSize';
import { SearchBar } from '../../common/SearchBar/SearchBar';
import circleSlugs from '../../../utils/circle_slugs.json';
import Sidebar from '../../common/Sidebar/Sidebar';
import ScreenTitle from '../../common/DashboardTitle/ScreenTitle';
import useLockSystem, { LockableObjectTypes } from '../../../hooks/useLockSystem';
import { renderLockedError, renderLockedWarningAlert, renderLockIcon } from '../../../utils/fnLockingSystem';
import { openDocumentation } from '../../../utils/parsers';
import { getSearchParam } from '../../../utils/fnUrl';
import { ObjectActions } from '../../common/Actions/Actions';
import GenericTable, { HeaderTableCell, SortableHeaderTableCell, tableActions } from '../../common/Table/Table';
import { buildPathWithProjectId, PageRoutes } from '../../../types/RouteTypes';
import { ConditionsTableSizes } from '../../../types/TableSizes';
import { SmallTableRowWrapper, WidthTableCell } from '../../common/Table/Table.css';
import { ScrollableTableRowWrapper } from '../../Menus/Menus';
import Labels from '../../common/Labels/Labels';
import { SearchBarContainer } from '../../common/SearchBar/SearchBar.css';
import { ObjectTypes } from '../../../types/Object';

export const DISPLAY_CONDITION_ADD_BUTTON_ID = 'DISPLAY_CONDITION_ADD_BUTTON_ID';

export const DisplayConditions: FC = () => {
    const [creatingDisplayCondition, setCreatingDisplayCondition] = useState(false);
    // Tracks the displayCondition Id which is being expanded
    const [expandedDisplayCondition, setExpandedDisplayCondition] = useState<string>('');
    // Tracks whether the top values can be edited or not
    const [isEditing, setIsEditing] = useState(false);
    const [isNewConditionOpen, setIsNewConditionOpen] = useState<boolean>(false);
    const [creatingConditionItems, setCreatingConditionItems] = useState<{}>({});
    const [editingKey, setEditingKey] = useState<string>('');
    const [conditionItemToEdit, setConditionItemToEdit] = useState<any>(null);
    const [name, setName] = useState<string>('');
    const [displayConditions, setDisplayConditions] = useState<DisplayCondition[]>([]);
    const [showUnsaved, setShowUnsaved] = useState<boolean>(false);

    const [nameError, setNameError] = useState<string>('');
    const [showMoreDialog, setShowMoreDialog] = useState<{ type: MoreInfoTypes | null; show: boolean; data: any[] }>({
        type: null,
        show: false,
        data: []
    });
    //used when the user is redirected to conditions
    const [conditionId, setConditionId] = useState<string>('');
    const [createNewCondition, setCreateNewCondition] = useState<boolean>(false);

    const { activeTenantId, activeProjectId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const {
        displayConditions: storeConditions,
        loading,
        error: conditionsError
    }: DisplayConditionsState = useAppSelector((state) => state.displayConditions);
    const { languageCodes, countryCodes }: LanguagesState = useAppSelector((state) => state.languages);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location: any = useLocation();
    const { isDesktop, isMobile } = useScreenSize();

    // PAGINATION, SEARCH AND FILTERING/SORTING RELATED FIELDS

    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const [sortConfig, setSortConfig] = useState<ISortConfig>(DEFAULT_SORT_CONFIG);
    const [showSortArrows, setShowSortArrows] = useState<boolean>(false);
    const [activeSortingKey, setActiveSortingKey] = useState<AcceptedSortField>(ACCEPTED_SORT_FIELDS.lastModified);

    const orderBy = `${sortConfig.field}[${sortConfig.direction}]`;

    // Locking
    const { lock, unlock, unlockOnClose, isObjectLocked, objectIsLockedBy } = useLockSystem(LockableObjectTypes.DISPLAY_CONDITIONS);

    const lockCondition = (id: string) => {
        lock(id);
        unlockOnClose(id);
    };

    const unsavedDependencyArray = [name, creatingConditionItems, displayConditions];

    // Blocking navigation when there are unsaved changes
    useBlockNavigation(showUnsaved, () => renderAlertUnsavedChanges(), unsavedDependencyArray);

    const isEmpty = !displayConditions.length;

    const loadDisplayConditions = async (addPermissions?: boolean, projectId?: string, orderBy?: string, searchTerm?: string) => {
        if (storeConditions.length && getSearchParam('id')) {
            navigate(buildPathWithProjectId(activeProjectId, PageRoutes.TARGET_CONDITIONS));
        }
        return await dispatch(fetchDisplayConditions({ addPermissions, projectId, orderBy, searchTerm })).unwrap();
    };

    const addDisplayCondition = async (condition: any) => {
        const response = await dispatch(createDisplayCondition(condition)).unwrap();
        if (response.id) {
            loadDisplayConditions(false, activeProjectId);
            ToastAlert('success', '', '', undefined, DIALOG_NAMES.DISPLAY_CONDITIONS_CREATED);
            setSearchTerm(undefined);
            setSortConfig(DEFAULT_SORT_CONFIG);
            setActiveSortingKey(ACCEPTED_SORT_FIELDS.lastModified);
        }
    };

    const modifyDisplayCondition = async (condition: any) => {
        const response = await dispatch(updateDisplayCondition({ condition, shouldUnlockAfterSave: true })).unwrap();
        if (response.ok) {
            loadDisplayConditions(false, activeProjectId, orderBy, searchTerm);
            ToastAlert('success', '', '', undefined, DIALOG_NAMES.DISPLAY_CONDITIONS_UPDATED);
        }
    };

    const removeDisplayCondition = async (id: string) => {
        return await dispatch(deleteDisplayCondition(id)).unwrap();
    };
    const loadLanguageCodes = async () => {
        return await dispatch(fetchLanguageCodes()).unwrap();
    };

    const loadCountryCodes = async () => {
        return await dispatch(fetchCountryCodes()).unwrap();
    };

    const loadLanguages = async () => {
        return await dispatch(fetchLanguages({ addPermissions: false, projectId: activeProjectId })).unwrap();
    };

    const renderAlertUnsavedChanges = () => {
        dialogConfirm(
            DIALOG_NAMES.UNSAVED_CHANGES,
            () => {
                handleSaveClick();
            },
            null,
            null,
            {
                noButton: { label: 'Discard Changes' },
                confirmButton: { label: 'Save' }
            },
            { warningIcon: true },
            () => {
                handleCancelClick();
            },
            true
        );
    };

    const handleCancelClick = () => {
        setDisplayConditions([...storeConditions]);
        expandedDisplayCondition && unlock(expandedDisplayCondition);
        setExpandedDisplayCondition('');
        setCreatingDisplayCondition(false);
        setCreatingConditionItems({});
        setIsEditing(false);
        setNameError('');
        setName('');
        setConditionId('');
        setCreateNewCondition(false);
    };

    const handleCreate = () => {
        expandedDisplayCondition && unlock(expandedDisplayCondition);
        setCreatingDisplayCondition(true);
        setIsEditing(true);
        setName('');
        setExpandedDisplayCondition('-1');
        setCreatingConditionItems({});
    };

    const handleSaveClick = async () => {
        let condition: any = {};
        const invalidName = validator({ required: true }, name);

        if (creatingDisplayCondition) {
            if (invalidName) return setNameError(invalidName);

            condition = {
                name,
                tenantId: activeTenantId,
                projectId: activeProjectId,
                ...creatingConditionItems
            };
        } else {
            let oldCondition: any = {};
            if (expandedDisplayCondition) {
                oldCondition = displayConditions.find((condition) => condition._id === expandedDisplayCondition);
            }

            if (isEditing) {
                if (invalidName) return setNameError(invalidName);

                oldCondition = displayConditions.find((condition) => condition._id === expandedDisplayCondition);
            }

            condition = {
                ...oldCondition,
                name: isEditing ? name : oldCondition.name
            };
        }

        creatingDisplayCondition ? addDisplayCondition(condition) : modifyDisplayCondition(condition);

        handleCancelClick();
    };

    const handleDeleteClick = async (id: string) => {
        const values = {
            title: 'Remove Condition',
            text: ''
        };

        dialogConfirm(
            '',
            async () => {
                await removeDisplayCondition(id);
                loadDisplayConditions(false, activeProjectId, orderBy, searchTerm);
            },
            values,
            <RemoveModuleWrapper>
                <p>
                    <strong>Are you sure you want to remove this Condition?</strong>
                    <br />
                    By Pressing “Remove” you still will be able to create new one!
                    <PlacedInPublishedWarningMessageWrapper>
                        If the Condition is placed in a published object, it will also be removed from it!
                    </PlacedInPublishedWarningMessageWrapper>
                </p>
            </RemoveModuleWrapper>,
            {
                noButton: {
                    label: 'Cancel'
                },
                confirmButton: {
                    label: 'Remove'
                }
            },
            { warningIcon: true },
            undefined,
            true
        );
    };

    const calculateShowUnsaved = () => {
        if (creatingDisplayCondition) return true;

        if (expandedDisplayCondition) {
            const condition = displayConditions.find((elem) => elem._id === expandedDisplayCondition);
            return name !== condition?.name || JSON.stringify(displayConditions) !== JSON.stringify(storeConditions);
        }

        if (expandedDisplayCondition) {
            return JSON.stringify(displayConditions) !== JSON.stringify(storeConditions);
        }
        return false;
    };

    useEffect(() => {
        setShowUnsaved(calculateShowUnsaved());
    }, [...unsavedDependencyArray]);

    useEffect(() => {
        if (!activeProjectId) return;
        setSearchTerm('');
        setSortConfig(DEFAULT_SORT_CONFIG);
        setActiveSortingKey(ACCEPTED_SORT_FIELDS.lastModified);

        if (storeConditions.length) {
            dispatch(unsetDisplayConditions());
        }
        loadDisplayConditions(true, activeProjectId, calculateOrderByFromSortConfig(DEFAULT_SORT_CONFIG)).then((response: any) => {
            if (response.permissions) {
                dispatch(setUserPermissions(response.permissions));
            }
        });
        !languageCodes.length && loadLanguageCodes();
        !countryCodes.length && loadCountryCodes();
        loadLanguages();

        handleCancelClick();

        if (location?.state?.openCreate) {
            setCreateNewCondition(true);
            window.history.replaceState({}, '');
        }

        // Used to open the editor in a new tab
        const redirectConditionId = getSearchParam('id');
        // When redirected from Dashboard or in new tab, open Edit Or Create
        if (location?.state?.conditionId || redirectConditionId) {
            setConditionId(location?.state?.conditionId || redirectConditionId);
            window.history.replaceState({}, '');
        }
    }, [activeProjectId]);

    useEffect(() => {
        if (loading || error) return;
        setDisplayConditions([...storeConditions]);
    }, [loading]);

    useEffect(() => {
        if (loading || error) return;

        if (createNewCondition) {
            handleCreate();
            window.history.replaceState({}, '');
        }
        if (conditionId) {
            const condition = storeConditions.find((c) => c._id === conditionId);
            if (!condition) return;
            const locked = isObjectLocked(condition);
            const lockedBy = objectIsLockedBy(condition);
            locked && renderLockedWarningAlert(lockedBy);
            setExpandedDisplayCondition(conditionId);
            if (!locked) {
                lockCondition(conditionId);
                setIsEditing(!!conditionId);
            }
            window.history.replaceState({}, '');
        }
    }, [displayConditions]);

    useEffect(() => {
        if (!displayConditions.length) return;
        if (expandedDisplayCondition && expandedDisplayCondition !== '-1') {
            const condition = displayConditions.find((elem) => elem._id === expandedDisplayCondition)!;
            setName(condition?.name);
        }
    }, [isEditing, displayConditions]);

    const handleOnSearch = (searchTerm: string) => {
        loadDisplayConditions(false, activeProjectId, orderBy, searchTerm);
    };

    const toggleArrows = () => {
        setShowSortArrows(!showSortArrows);
    };

    const handleSortIconClick = (field: AcceptedSortField) => {
        setActiveSortingKey(field);
        let direction: 'asc' | 'desc' = 'asc';
        if (sortConfig && sortConfig.field === field && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        const config = {
            field,
            direction
        };
        setSortConfig(config);
        const orderBy = `${config.field}[${config.direction}]`;
        loadDisplayConditions(true, activeProjectId, orderBy, searchTerm);
        setShowSortArrows(false);
    };

    const handleAddCondition = (value: any) => {
        if (creatingDisplayCondition) {
            setCreatingConditionItems((items) => {
                if (!editingKey) {
                    return { ...items, ...value };
                }

                _.set(items, editingKey, value[editingKey]);
                return items;
            });
        }

        if (!expandedDisplayCondition) return;

        const newConditions = [...displayConditions];
        const conditionIndex = displayConditions.findIndex((item) => item._id === expandedDisplayCondition);

        if (conditionIndex < 0) return newConditions;

        let newCondition = { ...newConditions[conditionIndex] };

        if (editingKey) {
            _.set(newCondition, editingKey, value[editingKey]);
        } else {
            newCondition = { ...newCondition, ...value };
        }

        newConditions[conditionIndex] = newCondition;
        setDisplayConditions(newConditions);
        setEditingKey('');
    };

    const handleDeleteCondition = (key: CategoriesKeys) => {
        dialogConfirm(DIALOG_NAMES.GENERIC_DELETE_CONFIRM, () => {
            if (creatingDisplayCondition) {
                setCreatingConditionItems(_.omit(creatingConditionItems, key));
            }

            const newConditions = [...displayConditions];
            const conditionIndex = displayConditions.findIndex((item) => item._id === expandedDisplayCondition);
            if (conditionIndex < 0) return newConditions;
            const newCondition = _.omit({ ...newConditions[conditionIndex] }, key);
            newConditions[conditionIndex] = newCondition;
            setDisplayConditions(newConditions);
        });
    };

    const getKeysToExclude = () => {
        if (creatingDisplayCondition && !conditionItemToEdit) {
            return Object.keys(creatingConditionItems);
        }

        if (conditionItemToEdit) {
            const conditionToShow = Object.keys(conditionItemToEdit)?.[0];
            return valueKeys.filter((elem) => elem !== conditionToShow);
        }

        if (!expandedDisplayCondition) return [];

        const condition = displayConditions.find((elem) => elem._id === expandedDisplayCondition);
        if (!condition) return [];

        return valueKeys.filter((key) => Object.keys(condition).includes(key));
    };

    const renderConditionValues = (key: CategoriesKeys, value: any) => {
        const itemsToDisplay = isMobile ? 1 : isDesktop ? 3 : 2;
        switch (key) {
            case CategoriesKeys.DATA_PROTECTION_REGULATION:
                const dprLabels: JSX.Element[] = value?.map((value: any, i: number) => {
                    if (i < itemsToDisplay) {
                        return <ObjectNameLabel key={`data_protection_regulation_${i}`}>{value}</ObjectNameLabel>;
                    }
                });
                if (value.length > itemsToDisplay) {
                    dprLabels.push(
                        <ObjectNameLabel
                            onClick={(e: any) => {
                                e.stopPropagation();
                                setShowMoreDialog({
                                    type: MoreInfoTypes.DATA_PROTECTION_REGULATION,
                                    show: true,
                                    data:
                                        value.map((v: any) => {
                                            return { name: v };
                                        }) || []
                                });
                            }}
                        >
                            ...
                        </ObjectNameLabel>
                    );
                }
                return dprLabels;
            case CategoriesKeys.SEGMENTS:
                const segmentsLabels: JSX.Element[] = value?.map((value: any, i: number) => {
                    if (i < itemsToDisplay) {
                        return <ObjectNameLabel key={`segment_${i}`}>{value}</ObjectNameLabel>;
                    }
                });
                if (value.length > itemsToDisplay) {
                    segmentsLabels.push(
                        <ObjectNameLabel
                            onClick={(e: any) => {
                                e.stopPropagation();
                                setShowMoreDialog({
                                    type: MoreInfoTypes.SEGMENTS,
                                    show: true,
                                    data:
                                        value.map((v: any) => {
                                            return { name: v };
                                        }) || []
                                });
                            }}
                        >
                            ...
                        </ObjectNameLabel>
                    );
                }
                return segmentsLabels;

            case CategoriesKeys.WEEK_DAYS:
                const daysLabels: JSX.Element[] = value?.map((value: any, i: number) => {
                    if (i < itemsToDisplay) {
                        return <ObjectNameLabel key={`day_${i}`}>{_.capitalize(value)}</ObjectNameLabel>;
                    }
                });
                if (value.length > itemsToDisplay) {
                    daysLabels.push(
                        <ObjectNameLabel
                            onClick={(e: any) => {
                                e.stopPropagation();
                                setShowMoreDialog({
                                    type: MoreInfoTypes.DAYS,
                                    show: true,
                                    data:
                                        value.map((v: any) => {
                                            return { name: v };
                                        }) || []
                                });
                            }}
                        >
                            ...
                        </ObjectNameLabel>
                    );
                }
                return daysLabels;
            case CategoriesKeys.LANGUAGES:
                const languageLabels: JSX.Element[] = value?.map((value: any, i: number) => {
                    if (i < itemsToDisplay) {
                        const name = languageCodes.find((elem) => elem.code === value)?.name;
                        const img = languageCodes.find((elem) => elem.code === value)?.flag;
                        return (
                            <ObjectNameLabel key={`lang_${i}`}>
                                <LanguageFlag src={img} />
                                <TruncatedText>{name}</TruncatedText>
                            </ObjectNameLabel>
                        );
                    }
                });
                if (value.length > itemsToDisplay) {
                    languageLabels.push(
                        <ObjectNameLabel
                            onClick={(e: any) => {
                                e.stopPropagation();
                                setShowMoreDialog({
                                    type: MoreInfoTypes.LANGUAGES,
                                    show: true,
                                    data:
                                        value.map((v: any) => {
                                            const name = languageCodes.find((elem) => elem.code === v)?.name;
                                            const img = languageCodes.find((elem) => elem.code === v)?.flag;
                                            return { name, img };
                                        }) || []
                                });
                            }}
                        >
                            ...
                        </ObjectNameLabel>
                    );
                }
                return languageLabels;
            case CategoriesKeys.COUNTRIES:
                const countryLabels: JSX.Element[] = value?.map((value: any, i: number) => {
                    if (i < itemsToDisplay) {
                        const { flag: img, name } =
                            countryCodes.find(
                                (elem) => elem.code === value.toLowerCase() || elem.name.toLowerCase() === value.toLowerCase()
                            ) || {};
                        return (
                            <ObjectNameLabel key={`lang_${i}`}>
                                <LanguageFlag src={img} />
                                <TruncatedText>{name}</TruncatedText>
                            </ObjectNameLabel>
                        );
                    }
                });
                if (value.length > itemsToDisplay) {
                    countryLabels.push(
                        <ObjectNameLabel
                            onClick={(e: any) => {
                                e.stopPropagation();
                                setShowMoreDialog({
                                    type: MoreInfoTypes.COUNTRIES,
                                    show: true,
                                    data:
                                        value.map((v: any) => {
                                            const { flag: img, name } =
                                                countryCodes.find(
                                                    (elem) => elem.code === v.toLowerCase() || elem.name.toLowerCase() === v.toLowerCase()
                                                ) || {};
                                            return { name, img };
                                        }) || []
                                });
                            }}
                        >
                            ...
                        </ObjectNameLabel>
                    );
                }
                return countryLabels;
            case CategoriesKeys.DATES:
                if (!value.length) return <></>;
                return (
                    <>
                        <div>{`${getFullDate(value?.[0]?.startDate)} - ${getFullDate(value?.[0]?.endDate)}`}</div>
                        {value.length > 1 && (
                            <ObjectNameLabel
                                style={{ marginLeft: '10px' }}
                                onClick={(e: any) => {
                                    e.stopPropagation();
                                    setShowMoreDialog({
                                        type: MoreInfoTypes.DATE_INTERVAL,
                                        show: true,
                                        data:
                                            value.map((v: any) => {
                                                return { name: `${getFullDate(v.startDate)} - ${getFullDate(v.endDate)}` };
                                            }) || []
                                    });
                                }}
                            >
                                ...
                            </ObjectNameLabel>
                        )}
                    </>
                );
            case CategoriesKeys.TIMES:
                if (!value.length) return <></>;
                return (
                    <>
                        <div>{`${generateTimeString(value?.[0]?.startTime)} - ${generateTimeString(value?.[0]?.endTime)}`}</div>
                        {value.length > 1 && (
                            <ObjectNameLabel
                                style={{ marginLeft: '10px' }}
                                onClick={(e: any) => {
                                    e.stopPropagation();
                                    setShowMoreDialog({
                                        type: MoreInfoTypes.TIME_INTERVAL,
                                        show: true,
                                        data:
                                            value.map((v: any) => {
                                                return { name: `${generateTimeString(v.startTime)} - ${generateTimeString(v.endTime)}` };
                                            }) || []
                                    });
                                }}
                            >
                                ...
                            </ObjectNameLabel>
                        )}
                    </>
                );
            case CategoriesKeys.RATING:
                return <ObjectNameLabel>{`${value} + `}</ObjectNameLabel>;
            case CategoriesKeys.SUBSCRIBED:
            case CategoriesKeys.PERSONA:
            case CategoriesKeys.HAPPINESS:
                return <div>{value}</div>;
            case CategoriesKeys.GUEST_MODE:
                return <div>{value ? 'Guest' : 'Logged-In'}</div>;
            case CategoriesKeys.COLD_START:
                return <div>{value ? 'Completed' : 'Not Completed'}</div>;
            default:
                return null;
        }
    };

    const onPlacedLabelClick = (object: any) => {
        switch (object.type) {
            case ObjectTypes.PAGES:
                navigate(buildPathWithProjectId(activeProjectId, PageRoutes.PAGE.replace(':page_id', object._id)));
                break;
            case ObjectTypes.MODULES:
                navigate(buildPathWithProjectId(activeProjectId, PageRoutes.MODULES), {
                    state: {
                        moduleId: object._id,
                        redirected: true
                    }
                });
                break;
            case ObjectTypes.ITEMS:
                navigate(buildPathWithProjectId(activeProjectId, PageRoutes.ITEMS), {
                    state: { itemId: object._id, openVisualEditor: true }
                });
                break;
            case ObjectTypes.SETTINGS:
                navigate(buildPathWithProjectId(activeProjectId, PageRoutes.SETTINGS), {
                    state: {
                        settingsId: object._id
                    }
                });
                break;
            case ObjectTypes.MENUS:
                navigate(buildPathWithProjectId(activeProjectId, PageRoutes.MENUS), { state: { menuId: object._id } });
                break;

            default:
                break;
        }
    };

    const buildTableColumns = () => {
        const columns = [
            <SortableHeaderTableCell
                key={`name_cell`}
                text={'Condition Name'}
                hideArrow={!showSortArrows && activeSortingKey !== ACCEPTED_SORT_FIELDS.name}
                onClick={() => handleSortIconClick(ACCEPTED_SORT_FIELDS.name)}
                onMouseEnter={() => toggleArrows()}
                onMouseLeave={() => toggleArrows()}
                columnSize={ConditionsTableSizes['name']}
                direction={(sortConfig?.field === ACCEPTED_SORT_FIELDS.name && sortConfig?.direction) || 'asc'}
            />,
            <HeaderTableCell key={'placed_cell'} text={'Part of Object(s)'} columnSize={ConditionsTableSizes.placed} />,
            <SortableHeaderTableCell
                key={`last_modified_cell`}
                text={'Last Modified'}
                hideArrow={!showSortArrows && activeSortingKey !== ACCEPTED_SORT_FIELDS.lastModified}
                onClick={() => handleSortIconClick(ACCEPTED_SORT_FIELDS.lastModified)}
                onMouseEnter={() => toggleArrows()}
                onMouseLeave={() => toggleArrows()}
                columnSize={ConditionsTableSizes['lastModified']}
                direction={(sortConfig?.field === ACCEPTED_SORT_FIELDS.lastModified && sortConfig?.direction) || 'asc'}
            />
        ];

        isMobile && columns.splice(2, 1); // remove last modified on mobile
        return columns;
    };

    const buildTableBody = () => {
        return (
            <>
                {displayConditions.map((condition, index) => {
                    const dateString = generateDateStringForTables(condition.lastModified || 0);
                    const locked = isObjectLocked(condition);
                    const lockedBy = objectIsLockedBy(condition);
                    const isLast = displayConditions.length - 1 === index;
                    const hasConditions = Object.keys(condition).some((key) => valueKeys.includes(key as CategoriesKeys));
                    return (
                        <>
                            <ScrollableTableRowWrapper key={index} shouldScroll={conditionId === condition._id}>
                                {expandedDisplayCondition === condition._id && isEditing ? (
                                    <WidthTableCell colSpan={isMobile ? 2 : 3} $um={'px'}>
                                        <ConditionTitleInput>
                                            <DialogTextField
                                                value={name}
                                                label={'Condition Name'}
                                                onChange={(e: any) => {
                                                    setName(e.target.value);
                                                    setNameError('');
                                                }}
                                                error={nameError}
                                            />
                                        </ConditionTitleInput>
                                    </WidthTableCell>
                                ) : (
                                    <>
                                        {/* CONDITION TITLE TABLE CELL */}
                                        <WidthTableCell {...ConditionsTableSizes.name}>
                                            <ConditionTitle
                                                onClick={() => {
                                                    if (showUnsaved) {
                                                        return renderAlertUnsavedChanges();
                                                    }
                                                    setName(condition.name);
                                                    setExpandedDisplayCondition(
                                                        expandedDisplayCondition === condition._id ? '' : condition._id
                                                    );
                                                    setCreatingDisplayCondition(false);
                                                    if (expandedDisplayCondition === condition._id) {
                                                        return unlock(expandedDisplayCondition);
                                                    }
                                                    if (locked && expandedDisplayCondition !== condition._id) {
                                                        return renderLockedWarningAlert(lockedBy);
                                                    }

                                                    lockCondition(condition._id);
                                                }}
                                            >
                                                <TruncatedText>{condition.name || EMPTY_WORD_STRING}</TruncatedText>
                                                {locked && renderLockIcon(lockedBy)}
                                            </ConditionTitle>
                                        </WidthTableCell>

                                        {/* PLACED TABLE CELL */}
                                        <WidthTableCell {...ConditionsTableSizes.placed}>
                                            <Labels
                                                values={condition.placed || []}
                                                type={MoreInfoTypes.PLACED}
                                                noOfLabels={isDesktop ? 3 : 1}
                                                onClickLabel={(obj) => {
                                                    onPlacedLabelClick(obj);
                                                }}
                                            />
                                        </WidthTableCell>

                                        {/* LAST MODIFIED TABLE CELL */}
                                        {!isMobile && (
                                            <WidthTableCell {...ConditionsTableSizes.lastModified}>
                                                <LastModified>
                                                    {dateString}
                                                    <Tooltip title={condition?.modifiedByUser?.name || ''} placement="right-start">
                                                        <img src={condition?.modifiedByUser?.icon || icons.avatarIcon} alt="" />
                                                    </Tooltip>
                                                </LastModified>
                                            </WidthTableCell>
                                        )}
                                    </>
                                )}

                                {/* ACTIONS TABLE CELL */}
                                <WidthTableCell {...ConditionsTableSizes.actions}>
                                    <ObjectActions
                                        actions={[tableActions.EDIT, tableActions.REMOVE]}
                                        withArrow
                                        onArrowToggle={() => {
                                            if (showUnsaved) {
                                                return renderAlertUnsavedChanges();
                                            }
                                            setName(condition.name);
                                            setExpandedDisplayCondition(expandedDisplayCondition === condition._id ? '' : condition._id);
                                            setCreatingDisplayCondition(false);
                                            if (expandedDisplayCondition === condition._id) {
                                                return unlock(expandedDisplayCondition);
                                            }
                                            if (locked && expandedDisplayCondition !== condition._id) {
                                                return renderLockedWarningAlert(lockedBy);
                                            }
                                            lockCondition(condition._id);
                                        }}
                                        open={expandedDisplayCondition === condition._id}
                                        onEdit={() => {
                                            if (showUnsaved) return renderAlertUnsavedChanges();
                                            // Don't allow editing mode if object is locked
                                            !locked && setIsEditing(expandedDisplayCondition === condition._id ? !isEditing : true);
                                            expandedDisplayCondition !== condition._id && setExpandedDisplayCondition(condition._id);
                                            setName(condition.name);
                                            setCreatingDisplayCondition(false);
                                            if (locked) {
                                                return renderLockedWarningAlert(lockedBy);
                                            }
                                            lockCondition(condition._id);
                                        }}
                                        onRemove={() => {
                                            if (showUnsaved) return renderAlertUnsavedChanges();
                                            if (locked) {
                                                return renderLockedWarningAlert(lockedBy);
                                            }
                                            handleDeleteClick(condition._id);
                                        }}
                                        tooltipTexts={{
                                            arrowOpen: 'conditions_icon_show',
                                            arrowClose: 'conditions_icon_hide',
                                            edit: 'conditions_icon_edit',
                                            delete: 'conditions_icon_delete'
                                        }}
                                    />
                                </WidthTableCell>
                            </ScrollableTableRowWrapper>

                            {/* CONDITION ITEMS TABLE ROW */}
                            {expandedDisplayCondition === condition._id && (
                                <WidthTableCell
                                    style={{
                                        padding: hasConditions ? '16px 24px 24px 24px' : 24,
                                        background: 'rgba(0,0,0,0.06)',
                                        maxWidth: 0
                                    }}
                                    colSpan={5}
                                    $um={'px'}
                                >
                                    <GenericTable
                                        columns={
                                            hasConditions
                                                ? [
                                                      <HeaderTableCell
                                                          key={`name_cell`}
                                                          text={'Condition Name'}
                                                          columnSize={ConditionsTableSizes['name']}
                                                      />,
                                                      <HeaderTableCell
                                                          key={'conditions_cell'}
                                                          text={'State'}
                                                          columnSize={ConditionsTableSizes.state}
                                                      />,
                                                      <HeaderTableCell
                                                          key={'placed_cell'}
                                                          text={'Value'}
                                                          columnSize={ConditionsTableSizes.placed}
                                                      />
                                                  ]
                                                : []
                                        }
                                        body={renderConditionsItems(condition)}
                                    />
                                </WidthTableCell>
                            )}

                            {/* SPACE BETWEEN ROWS */}
                            {!isLast && <TableRow style={{ height: '24px' }} />}
                        </>
                    );
                })}
                {creatingDisplayCondition && renderCreatingDisplayCondition()}
            </>
        );
    };

    const renderCreatingDisplayCondition = () => {
        const hasConditions = Object.keys(creatingConditionItems).some((key) => valueKeys.includes(key as CategoriesKeys));

        return (
            <>
                <TableRow style={{ height: '16px' }} />
                <ScrollableTableRowWrapper shouldScroll={creatingDisplayCondition}>
                    <WidthTableCell colSpan={isMobile ? 2 : 3} $um={'px'}>
                        <ConditionTitleInput>
                            <DialogTextField
                                label={'Condition Name'}
                                value={name}
                                onChange={(e: any) => {
                                    setName(e.target.value);
                                    setNameError('');
                                }}
                                error={nameError}
                            />
                        </ConditionTitleInput>
                    </WidthTableCell>
                    <WidthTableCell {...ConditionsTableSizes.actions}>
                        <ObjectActions
                            actions={[tableActions.REMOVE]}
                            withArrow
                            open={!!expandedDisplayCondition}
                            onArrowToggle={() => {
                                setExpandedDisplayCondition(expandedDisplayCondition === '-1' ? '' : '-1');
                            }}
                            onRemove={() => {
                                showUnsaved ? renderAlertUnsavedChanges() : handleCancelClick();
                            }}
                            tooltipTexts={{
                                arrowClose: 'conditions_icon_hide',
                                arrowOpen: 'conditions_icon_show',
                                delete: 'conditions_icon_delete'
                            }}
                        />
                    </WidthTableCell>
                </ScrollableTableRowWrapper>
                {expandedDisplayCondition === '-1' && (
                    <WidthTableCell
                        style={{
                            padding: hasConditions ? '16px 24px 24px 24px' : 24,
                            background: 'rgba(0,0,0,0.06)',
                            maxWidth: 0
                        }}
                        colSpan={5}
                        $um={'px'}
                    >
                        <GenericTable
                            columns={
                                hasConditions
                                    ? [
                                          <HeaderTableCell
                                              key={`name_cell`}
                                              text={'Condition Name'}
                                              columnSize={ConditionsTableSizes['name']}
                                          />,
                                          <HeaderTableCell
                                              key={'conditions_cell'}
                                              text={'State'}
                                              columnSize={ConditionsTableSizes.state}
                                          />,
                                          <HeaderTableCell key={'placed_cell'} text={'Value'} columnSize={ConditionsTableSizes.placed} />
                                      ]
                                    : []
                            }
                            body={renderConditionsItems(creatingConditionItems)}
                        />
                    </WidthTableCell>
                )}
            </>
        );
    };

    const renderConditionsItems = (condition: any) => {
        const locked = isObjectLocked(condition);
        const lockedBy = objectIsLockedBy(condition);
        return (
            <>
                {Object.keys(condition).map((key, index) => {
                    if (!valueKeys.includes(key as CategoriesKeys)) return;
                    return (
                        <SmallTableRowWrapper
                            onClick={() => {
                                if (locked) {
                                    return renderLockedWarningAlert(lockedBy);
                                }
                                setEditingKey(key);
                                setIsNewConditionOpen(true);
                                setConditionItemToEdit({ [key]: condition[key] });
                            }}
                            key={index}
                        >
                            {/* CONDITION TITLE TABLE CELL */}
                            <WidthTableCell {...ConditionsTableSizes.name}>
                                <TruncatedText>{keysTitle[key as CategoriesKeys]}</TruncatedText>
                            </WidthTableCell>

                            {/* STATE TABLE CELL */}
                            {expandedDisplayCondition && (
                                <WidthTableCell {...ConditionsTableSizes.state}>
                                    <SubTitleWrapper>{condition[key].is ? 'Is' : 'Is Not'}</SubTitleWrapper>
                                </WidthTableCell>
                            )}

                            {/* PLACED TABLE CELL */}
                            <WidthTableCell {...ConditionsTableSizes.placed}>
                                <ConditionValues>{renderConditionValues(key as CategoriesKeys, condition[key].value)}</ConditionValues>
                            </WidthTableCell>

                            {/* LAST MODIFIED TABLE CELL */}
                            {!isMobile && <WidthTableCell $um={'px'} />}

                            {/* ACTIONS TABLE CELL */}
                            <WidthTableCell {...ConditionsTableSizes.actions}>
                                <ObjectActions
                                    actions={[tableActions.EDIT, tableActions.REMOVE]}
                                    onEdit={(e) => {
                                        e.stopPropagation();
                                        if (locked) {
                                            return renderLockedWarningAlert(lockedBy);
                                        }
                                        setEditingKey(key);
                                        setIsNewConditionOpen(true);
                                        setConditionItemToEdit({ [key]: condition[key] });
                                    }}
                                    onRemove={(e) => {
                                        e.stopPropagation();
                                        if (locked) {
                                            return renderLockedWarningAlert(lockedBy);
                                        }
                                        handleDeleteCondition(key as CategoriesKeys);
                                    }}
                                    tooltipTexts={{ edit: 'conditions_icon_edit', delete: 'conditions_icon_delete' }}
                                />
                            </WidthTableCell>
                        </SmallTableRowWrapper>
                    );
                })}

                <SmallTableRowWrapper
                    onClick={() => {
                        if (locked) {
                            return renderLockedWarningAlert(lockedBy);
                        }
                        setIsNewConditionOpen(true);
                    }}
                >
                    <WidthTableCell colSpan={isMobile ? 4 : 5} {...ConditionsTableSizes.name}>
                        <ConditionTitle>
                            Add Condition
                            {renderTooltipWithKey(<SVGInline src={icons.addIcon} />, 'conditions_icon_add')}
                        </ConditionTitle>
                    </WidthTableCell>
                </SmallTableRowWrapper>
            </>
        );
    };

    const renderNoConditions = () => (
        <NoResourcesContainer>
            <ResourceCard
                image={resourceCardImages.conditionsCard}
                title={'Create Condition'}
                subtitle={'Create Condition from scratch'}
                primaryButtonLabel={'Create Condition'}
                secondaryButtonLabel={'Learn more'}
                onPrimaryButtonClick={() => {
                    handleCreate();
                }}
                onSecondaryButtonClick={() => {
                    openDocumentation(circleSlugs.conditions);
                }}
                createResource
            />
        </NoResourcesContainer>
    );

    const renderError = (error: any) => {
        switch (error.code) {
            case API_ERROR_CODES.LOCKED_ERROR:
                return renderLockedError(error);
            default:
                return <BackendErrorDialog error={error} />;
        }
    };

    const error = conditionsError;

    return (
        <ApplicationWrapper>
            <Sidebar />
            <MainContentWrapper>
                <ScreenTitle
                    loading={loading}
                    title={'Conditions'}
                    withProfile
                    withAddButton={!!storeConditions.length}
                    addLabel={`Create Condition`}
                    onAdd={() => handleCreate()}
                />
                {error && renderError(error)}
                <SearchBarContainer>
                    <SearchBar
                        title={'Search by Name'}
                        disabled={loading}
                        searchTerm={searchTerm}
                        onSearch={handleOnSearch}
                        setSearchTerm={setSearchTerm}
                        tooltipText={'conditions_icon_search'}
                    />
                </SearchBarContainer>
                {loading ? (
                    <Loader title="Conditions" />
                ) : isEmpty && !creatingDisplayCondition ? (
                    renderNoConditions()
                ) : (
                    <>
                        <GenericTable columns={buildTableColumns()} body={buildTableBody()} />

                        {!creatingDisplayCondition && !expandedDisplayCondition && !loading && (
                            <PageActionsWrapper>
                                <PageActionButton
                                    onClick={() => {
                                        handleCreate();
                                    }}
                                    label={'Create Condition'}
                                    type={'BLUE'}
                                />
                            </PageActionsWrapper>
                        )}
                    </>
                )}
                {(creatingDisplayCondition || expandedDisplayCondition || isEditing) && (
                    <PageActions
                        onSave={() => {
                            handleSaveClick();
                        }}
                        onCancel={() => {
                            showUnsaved ? renderAlertUnsavedChanges() : handleCancelClick();
                        }}
                        disabled={{ save: !showUnsaved }}
                    />
                )}
            </MainContentWrapper>
            <NewConditionDialog
                open={isNewConditionOpen}
                onClose={() => {
                    setIsNewConditionOpen(false);
                    setEditingKey('');
                    setConditionItemToEdit(null);
                }}
                onSave={(condition) => {
                    handleAddCondition(condition);
                    setConditionItemToEdit(null);
                }}
                conditionsKeysToExclude={getKeysToExclude()}
                condition={conditionItemToEdit}
            />
            {/* this button is needed to be able to handle the click listener based on the id */}
            <button
                id={DISPLAY_CONDITION_ADD_BUTTON_ID}
                onClick={() => {
                    handleCreate();
                }}
                hidden
            />
            <MoreInfoDialog
                type={showMoreDialog.type!}
                open={showMoreDialog.show}
                onClose={() => setShowMoreDialog({ type: null, show: false, data: [] })}
                data={showMoreDialog.data}
            />
        </ApplicationWrapper>
    );
};

/*
 * based on the services, the source object will be created - for now we will only have youtube
 * the list will be populated with information we need for each different backend
 * the source object will consisnt of name, alias, service, method, params, url
 * name, alias are free inputs, service and method are dropdown selections and free input/dropdown select fields will be created for each param
 * the url will be automatically created in the background based on the inputs
 */

import { EMPTY_WORD_STRING } from './Globals';

export type Service = {
    key: string;
    title: string;
    url: string;
    methods: { key: string; title: string; url: string; params: any; subService?: string }[];
    subServices?: string[];
    additionalFields?: any;
    tokenDependant?: boolean;
};

// just examples - actual services coming from BE
export const Services: Service[] = [
    {
        key: 'youtube',
        title: 'Youtube',
        url: 'https://www.googleapis.com/youtube/v3',
        methods: [
            {
                key: 'videos',
                title: 'Videos',
                url: '/videos',
                params: {
                    query: {
                        id: 'string'
                    }
                }
            },
            {
                key: 'playlists',
                title: 'Playlists',
                url: '/playlists',
                params: {
                    query: {
                        id: 'string'
                    }
                }
            },
            {
                key: 'channels',
                title: 'Channels',
                url: '/channels',
                params: {
                    query: {
                        id: 'string'
                    }
                }
            },
            {
                key: 'search',
                title: 'Search',
                url: '/search',
                params: {
                    query: {
                        q: 'string'
                    }
                }
            },
            {
                key: 'trending',
                title: 'Trending',
                url: '/videos',
                params: {
                    query: {
                        regionCode: ['DE', 'AT', 'BG', 'BR', 'NO', 'DK', 'SE', 'RO', 'GB', 'US']
                    }
                }
            }
        ]
    },
    {
        key: 'allentegateway',
        title: 'Allente Gateway',
        url: 'https://w-sgprod-zulu.api-canaldigital.com/v1',
        methods: [
            {
                key: 'medialist',
                title: 'Medialist',
                url: '/medialist/:id',
                params: {
                    path: {
                        id: ['ukens-anbefalte-program-se', 'all-catchup-most-watched', 'all-catchup-most-watched-last-day']
                    },
                    query: {
                        source: ['mmpl']
                    }
                }
            }
        ]
    }
];

export const ServiceParamDefaultValues = {
    collapse: true
};

// if a service param is found here as a key, use the value from here as a label instead
export const ServiceSubserviceNames = {
    no_value: EMPTY_WORD_STRING
};

export const ServiceParamNames = {
    q: 'Search Term',
    regionCode: 'Country',
    contentType: 'Content Type',
    medialist_id: 'Medialist Id',
    x: 'X (days ago)',
    channels_api_apps: 'App',
    playstoreFirst: 'Fix Playstore as the First'
};

export const ServiceParamOptionLabels = {
    AR: 'Argentina',
    AT: 'Austria',
    AU: 'Australia',
    BE: 'Belgium',
    BG: 'Bulgaria',
    BO: 'Bolivia',
    BR: 'Brazil',
    CA: 'Canada',
    CH: 'Switzerland',
    CL: 'Chile',
    CO: 'Colombia',
    CY: 'Cyprus',
    CZ: 'Czechia',
    DE: 'Germany',
    DK: 'Denmark',
    EC: 'Ecuador',
    EE: 'Estonia',
    ES: 'Spain',
    FI: 'Finland',
    FR: 'France',
    GB: 'United Kingdom',
    GR: 'Greece',
    HK: 'Hong Kong',
    HR: 'Croatia',
    HU: 'Hungary',
    IE: 'Ireland',
    IL: 'Israel',
    IN: 'India',
    IT: 'Italy',
    JP: 'Japan',
    KR: 'South Korea',
    LT: 'Lithuania',
    LV: 'Latvia',
    MT: 'Malta',
    MX: 'Mexico',
    MY: 'Malaysia',
    NL: 'Netherlands',
    NO: 'Norway',
    NZ: 'New Zealand',
    PE: 'Peru',
    PH: 'Philippines',
    PL: 'Poland',
    PT: 'Portugal',
    PY: 'Paraguay',
    RO: 'Romania',
    QA: 'Qatar',
    SA: 'Saudi Arabia',
    SE: 'Sweden',
    SI: 'Slovenia',
    SK: 'Slovakia',
    TR: 'Turkey',
    UY: 'Uruguay',
    US: 'United States',
    ZA: 'South Africa',
    ':today': 'Today',
    ':today-1d': '1 Day Ago',
    ':today-2d': '2 Days Ago',
    ':today-3d': '3 Days Ago',
    ':today-4d': '4 Days Ago',
    ':today-5d': '5 Days Ago',
    ':today-6d': '6 Days Ago',
    ':today-7d': '7 Days Ago',
    '': EMPTY_WORD_STRING,
    kgId: 'Knowledge Graph IDs',
    topic: 'Topic',
    'playlist_group.playlist_ids': 'Playlist ID',
    '/m/064t9': 'Pop Music (/m/064t9)',
    '/m/025zzc': 'Action Game (/m/025zzc)',
    '/m/022dc6': 'Sports Game (/m/022dc6)',
    '/m/0jm_': 'American Football (/m/0jm_)',
    '/m/02vx4': 'Football (/m/02vx4)',
    '/m/02vxn': 'Movies (/m/02vxn)',
    '/m/027x7n': 'Fitness (/m/027x7n)',
    '/m/068hy': 'Pets (/m/068hy)',
    '/m/09s1f': 'Business (/m/09s1f)',
    ar: 'Arabic',
    bg: 'Bulgarian',
    cs: 'Czech',
    da: 'Danish',
    de: 'German',
    'de-DE': 'German',
    el: 'Greek',
    en: 'English (US)',
    'en-GB': 'English (UK)',
    'en-US': 'English (US)',
    'en-CA': 'English (Canada)',
    es: 'Spanish',
    'es-419': 'Spanish (Latin America)',
    et: 'Estonian',
    fi: 'Finnish',
    fr: 'French',
    'fr-FR': 'French',
    'fr-CA': 'French (Canada)',
    hu: 'Hungarian',
    it: 'Italian',
    'it-IT': 'Italian',
    ja: 'Japanese',
    ko: 'Korean',
    nl: 'Dutch',
    no: 'Norwegian',
    pl: 'Polish',
    'pt-BR': 'Portuguese (Brazil)',
    'pt-PT': 'Portuguese (Portugal)',
    ro: 'Romanian',
    sv: 'Swedish',
    tr: 'Turkish',
    'zh-CN': 'Chinese',
    '289u5typ3vp4ifwh5thalohmq': 'Football',
    '4rheplujt9zryv3fqvtu1jpah': 'Tennis',
    ayp4nebmprfbvzdsisazcw74y: 'Basketball',
    '6by3h89i2eykc341oz7lv1ddd': 'Bundesliga',
    '4oogyu6o156iphvdvphwpck10': 'UEFA Champions League',
    FUTURE_EPG: 'Future EPG'
};

export const FilterParamOptionsLabels = {
    EN: 'English',
    DE: 'German',
    BG: 'Bulgarian',
    RO: 'Romanian',
    'PT-BR': 'Portuguese (BR)',
    SV: 'Swedish',
    ES: 'Spanish'
};

export const SortOptionsMap = {
    'releaseYear[asc]': 'Release Year Ascending',
    'releaseYear[desc]': 'Release Year Descending',
    'title[asc]': 'Alphabetical A-Z',
    'title[desc]': 'Alphabetical Z-A',
    'recency[desc]': 'Recency Descending',
    notSorted: 'No Sorting'
};

export const PrimeVideoParamNames = {
    'genres.title': 'Genres',
    releaseYear: 'Release Year',
    id: 'Individual Assets'
};

// this is to be integrated somehow into the services object
export const MultipleSelectFields = ['genres', 'release_year', 'language', 'contentProviders', 'cwShowTypes', 'favoriteType'];

import { Link, useNavigate, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { IconButton, InputAdornment } from '@material-ui/core';
import {
    LeftContainer,
    LoginActionsContainer,
    loginButtonStyle,
    LoginCheckbox,
    LoginCheckboxContainer,
    LoginDescription,
    LoginInputFieldStyle,
    LoginTextContainer,
    Logo,
    MainContainer,
    RightContainer,
    TermsOfServiceButton
} from './Login.css';
import { useAppDispatch as useDispatch, useAppSelector } from '../../hooks/redux';
import Api from '../../utils/api/configServiceAPI';
import Button from '../Buttons/Button/Button';
import ForgotPasswordView from './AuthViews/ForgotPassword';
import ActivateUserView from './AuthViews/ActivateUser';
import { DIALOG_NAMES, dialogAlert } from '../../utils/fnDialogs';
import ResetPasswordView from './AuthViews/ResetPassword';
import { AuthState, logUserIn } from '../../redux/slices/authSlice';
import icons from '../../style';
import SVGInline from 'react-inlinesvg';
import { authenticationErrorParser } from '../../utils/parsers';
import _ from 'lodash';
import { setUserProfile } from '../../redux/slices/profileSlice';
import { setUserPermissions } from '../../redux/slices/permissionsSlice';
import { validator } from '../../utils/fnValidator';
import { renderTooltipWithKey } from '../common/Tooltips/Tooltips';
import { usePersistentState } from '../../hooks/usePersistentState';
import { DialogTextField } from '../common/Dialog/GenericDialog';
import { PageRoutes } from '../../types/RouteTypes';
import TwoFactorAuthentication from './AuthViews/2FactorAuthentication';
import { LOGIN_BACKGROUND_URL } from '../Superadmin/Branding/Branding';
import TermsOfServiceDialog from './AuthViews/TermsOfServiceDialog';

let { showPasswordIcon, hidePasswordIcon } = icons;

type AUTH_VIEWS = 'LOGIN' | 'ACTIVATE_USER' | 'FORGOT_PASSWORD' | 'RESET_PASSWORD';

const Login: React.FC<any> = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { reset_password, activate, token, terms } = queryString.parse(location.search);

    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [currentAuthView, setCurrentAuthView] = useState<AUTH_VIEWS>('LOGIN');
    const [show2FA, setShow2FA] = useState(false);
    const [twoFAData, setTwoFAData] = useState<any>(null);
    const [errors, setErrors] = useState<{ email?: string; password?: string }>({});
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [showTerms, setShowTerms] = useState<boolean>(false);

    // needed only if the user is active but does not have a phone number set
    const [phoneNumberNotSet, setPhoneNumberNotSet] = useState(false);
    const [userId, setUserId] = useState('');

    useEffect(() => {
        if (!!(`${activate}` === 'true' && `${token}`)) {
            setCurrentAuthView('ACTIVATE_USER');
        }
    }, [activate, token]);

    useEffect(() => {
        if (`${terms}` === 'true') setShowTerms(true);
    }, [terms]);

    useEffect(() => {
        !!(`${reset_password}` === 'true' && `${token}`) && setCurrentAuthView('RESET_PASSWORD');
    }, [reset_password, token]);

    const dispatch = useDispatch();
    const { store: rememberMe, set: setRememberMe } = usePersistentState('rememberMe', false);
    const { loading }: AuthState = useAppSelector((state) => state.auth);

    const validateCredentials = () => {
        return Object.values(errors).filter((value) => !!value).length === 0;
    };

    const onSubmit = async () => {
        if (!validateCredentials()) return;
        try {
            const response = await dispatch(logUserIn({ email, password, rememberMe })).unwrap();
            if (response.userData.userInfo) {
                dispatch(setUserProfile(response.userData.userInfo));
            }
            if (response.userData.permissions) {
                dispatch(setUserPermissions(response.userData.permissions));
            }
            navigate(PageRoutes.PROJECTS);
        } catch (err) {
            if (!err) return;
            const error = authenticationErrorParser(err);
            if (error.twoFARequired) {
                setTwoFAData(error.data);
                return setShow2FA(true);
            }
            if (error.phoneNumberNotSet) {
                setUserId(error.data?.userId || '');
                setCurrentAuthView('ACTIVATE_USER');
                return setPhoneNumberNotSet(true);
            }
            if (!error.isWrongInput) {
                const values = {
                    title: 'Could not login!',
                    subtitle: 'An error occurred while trying to login.',
                    text: error.message
                };
                return dialogAlert('', false, values);
            }

            setErrors(error.password ? { ...errors, password: error.message } : { ...errors, email: error.message });
        }
    };

    const sendPasswordModificationEmail = async (email: string) => {
        const result = await Api.forgotPassword(email);
        if (result.error) {
            return dialogAlert(DIALOG_NAMES.PASSWORD_EMAIL_ERROR, false, null, () => {
                setCurrentAuthView('LOGIN');
            });
        }
        dialogAlert(DIALOG_NAMES.PASSWORD_EMAIL_SUCCESS, true, null, () => {
            setCurrentAuthView('LOGIN');
        });
    };

    const activateAccount = async (password: string, phoneNumber: string) => {
        const result = await Api.activateAccount(`${token}`, password, phoneNumber);
        if (result.error) {
            return dialogAlert(DIALOG_NAMES.ACTIVATE_USER_ERROR, false, null, () => {
                setCurrentAuthView('LOGIN');
                navigate(PageRoutes.LOGIN);
            });
        }
        dialogAlert(DIALOG_NAMES.ACTIVATE_USER_SUCCESS, true, null, () => {
            setCurrentAuthView('LOGIN');
            navigate(PageRoutes.LOGIN);
        });
    };

    const resetPassword = async (newPassword: string) => {
        const result = await Api.resetPassword(`${token}`, newPassword);
        if (result.error) {
            return dialogAlert(DIALOG_NAMES.RESET_PASSWORD_ERROR, false, null, () => {
                setCurrentAuthView('LOGIN');
                navigate(PageRoutes.LOGIN);
            });
        }
        dialogAlert(DIALOG_NAMES.RESET_PASSWORD_SUCCESS, true, null, () => {
            setCurrentAuthView('LOGIN');
            navigate(PageRoutes.LOGIN);
        });
    };
    const handleShowPassword = () => setShowPassword((showPassword) => !showPassword);

    let tooltipText = showPassword ? 'login_icon_hide_password' : 'login_icon_show_password';
    const passwordAdornment = (
        <InputAdornment position="end">
            {renderTooltipWithKey(
                <IconButton onClick={handleShowPassword}>
                    <SVGInline src={showPassword ? hidePasswordIcon : showPasswordIcon} />
                </IconButton>,
                tooltipText
            )}
        </InputAdornment>
    );
    const renderLoginForm = () => {
        return (
            <>
                <DialogTextField
                    error={errors['email']}
                    value={email}
                    label="Email"
                    placeholder="Enter your email"
                    onKeyPress={(evt: any) => {
                        if (evt.key === 'Enter') {
                            document.getElementById('password')?.focus();
                        }
                    }}
                    onChange={(e: any) => {
                        setEmail(e.target.value);
                        setErrors(_.set(errors, 'email', validator({ required: true, minLength: 6, email: true }, e.target.value)));
                    }}
                    style={LoginInputFieldStyle}
                    isDisabled={show2FA}
                    dataCy={'email-input'}
                />
                <DialogTextField
                    InputProps={{ endAdornment: passwordAdornment }}
                    error={errors['password']}
                    value={password}
                    label="Password"
                    placeholder="Enter your password"
                    type={showPassword ? 'text' : 'password'}
                    onKeyPress={(evt: any) => {
                        if (evt.key === 'Enter') onSubmit();
                    }}
                    onChange={(e: any) => {
                        setPassword(e.target.value);
                        setErrors(_.set(errors, 'password', validator({ required: true, minLength: 8, password: true }, e.target.value)));
                    }}
                    style={LoginInputFieldStyle}
                    isDisabled={show2FA}
                    dataCy={'password-input'}
                    withTopMargin
                />

                <LoginActionsContainer>
                    <LoginCheckboxContainer>
                        {renderTooltipWithKey(
                            <FormControlLabel
                                control={<LoginCheckbox color="primary" checked={rememberMe} onChange={() => setRememberMe(!rememberMe)} />}
                                label="Remember me"
                            />,
                            'login_icon_remember_me'
                        )}
                    </LoginCheckboxContainer>
                    <Link
                        to="/"
                        onClick={(e: any) => {
                            e.preventDefault();
                            setEmail('');
                            setPassword('');
                            setCurrentAuthView('FORGOT_PASSWORD');
                        }}
                    >
                        Forgot password?
                    </Link>
                </LoginActionsContainer>
                {!show2FA && (
                    <Button
                        onClick={onSubmit}
                        id="login-button"
                        label={'Login'}
                        type="BLUE"
                        style={loginButtonStyle}
                        loading={loading}
                        dataCy={'login-submit-button'}
                    />
                )}
                {show2FA && <TwoFactorAuthentication data={twoFAData} />}
            </>
        );
    };

    return (
        <MainContainer>
            <LeftContainer>
                <Logo />
                {currentAuthView === 'LOGIN' && renderLoginForm()}

                {currentAuthView === 'ACTIVATE_USER' && (
                    <ActivateUserView
                        onActivate={(password: string, phoneNumber: string) => activateAccount(password, phoneNumber)}
                        errorMsg=""
                        token={token?.toString() || ''}
                        phoneOnly={phoneNumberNotSet}
                        returnToLoginCallback={() => setCurrentAuthView('LOGIN')}
                        userId={userId}
                    />
                )}

                {currentAuthView === 'FORGOT_PASSWORD' && (
                    <ForgotPasswordView onSend={(email: string) => sendPasswordModificationEmail(email)} errorMsg="" />
                )}

                {currentAuthView === 'RESET_PASSWORD' && (
                    <ResetPasswordView onReset={(newPassword: string) => resetPassword(newPassword)} errorMsg="" />
                )}
                <span data-cy="login-span">Control Center 3.0</span>

                <TermsOfServiceButton onClick={() => setShowTerms(true)}>Terms of Service</TermsOfServiceButton>

                <LoginTextContainer>
                    <LoginDescription>
                        This is Control Center version <strong>{process.env.REACT_APP_VERSION}</strong>
                        <br />
                        We are happy for any feedback or ideas to improve.
                        <br />
                        Please use the feedback button or share via “<a href="mailto:cc-support@3rdy.tv">cc-support@3rdy.tv</a>”
                    </LoginDescription>
                </LoginTextContainer>
            </LeftContainer>
            <RightContainer customBackground={`${LOGIN_BACKGROUND_URL}`} />
            <TermsOfServiceDialog handleClose={() => setShowTerms(false)} open={showTerms} />
        </MainContainer>
    );
};

export default Login;

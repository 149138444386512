import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../../hooks/redux';
import { fetchPermissions, PermissionsState } from '../../../../redux/slices/permissionsSlice';
import { fetchProjects, ProjectsState } from '../../../../redux/slices/projectsSlice';
import { fetchRoles, RolesState } from '../../../../redux/slices/rolesSlice';
import { fetchTenants, TenantsState } from '../../../../redux/slices/tenantsSlice';
import { UserGroup } from '../../../../types/UserGroup';
import { generateUID } from '../../../../utils/fnGenerator';
import { API_ERROR_CODES } from '../../../../utils/Globals';
import GenericDialog, {
    DialogButton,
    DialogCheckbox,
    DialogDropdownMultiple,
    DialogDropdownSingle,
    DialogRadioButton,
    DialogTextField,
    DialogTypes
} from '../../../common/Dialog/GenericDialog';
import { DialogDoubleFieldWrapper, DialogRow, DoubleFieldColumn, RowTitle } from '../../../common/Dialog/GenericDialog.css';
import BackendErrorDialog from '../../../common/Dialog/BackendErrorDialog';
import { validator } from '../../../../utils/fnValidator';
import { Permission } from '../../../../types/Permission';
import { UsersState } from '../../../../redux/slices/usersSlice';
import { UserAvatar, UserOptionContainer, UserOptionName } from '../../../Projects/Projects.css';
import icons from '../../../../assets/images/icons';
import { CIRCLE_SLUGS } from '../../../common/HelpIcon/HelpIcon';

type groupDialogProps = {
    onClose: () => void;
    open: boolean;
    onSave: (value: any, userIds: string[]) => void;
    group?: any;
};
const permissionsOrder = [
    'groups',
    'audiences',
    'ab_testing',
    'conditions',
    'pages',
    'modules',
    'items',
    'menus',
    'page_styles',
    'settings',
    'sources',
    'languages',
    'file_manager',
    'file_manager_edit',
    'file_manager_delete',
    'file_manager_video_encoding'
] as const;

type PermissionKey = typeof permissionsOrder[number];

const PermissionKeyToNameMapping: { [key in PermissionKey]: string } = {
    groups: 'Groups',
    audiences: 'Audiences',
    ab_testing: 'A/B Testing',
    conditions: 'Conditions',
    file_manager: 'File Manager',
    file_manager_edit: 'File Manager Edit',
    file_manager_delete: 'File Manager Delete',
    file_manager_video_encoding: 'File Manager Video Encoding',
    items: 'Lists & Items',
    languages: 'Languages',
    menus: 'Menus',
    modules: 'Modules',
    page_styles: 'Page Styles',
    pages: 'Pages',
    settings: 'Settings',
    sources: 'Sources'
};

export const NewGroup: FC<groupDialogProps> = ({ open, onClose, onSave, group }) => {
    const { tenants, loading: tenantsLoading, error: tenantsError }: TenantsState = useAppSelector((state) => state.tenants);
    const { projects, loading: projectsLoading, error: projectsError }: ProjectsState = useAppSelector((state) => state.projects);
    const {
        permissions,
        loading: permissionsLoading,
        error: permissionsError
    }: PermissionsState = useAppSelector((state) => state.permissions);
    const { loading: rolesLoading, error: rolesError }: RolesState = useAppSelector((state) => state.roles);
    const { users: storeUsers, loading: usersLoading }: UsersState = useAppSelector((state) => state.users);

    const [tenantId, setTenantId] = useState<string>('');
    const [projectIds, setProjectIds] = useState<string[]>([]);
    const [userIds, setUserIds] = useState<string[]>([]);
    const [permissionValues, setPermissionValues] = useState<string[]>([]);
    const [groupName, setGroupName] = useState<string>('');
    const [activeRole, setActiveRole] = useState<'viewer' | 'editor' | undefined>(undefined);
    const [errors, setErrors] = useState<{ name?: string; tenantId?: string; projectIds?: string }>({});
    const [isOpen, setIsOpen] = useState(open);
    const [permissionsToShow, setPermissionsToShow] = useState<Permission[]>([]);
    const [fileManagerEditPermissionId, setFileManagerEditPermissionEdit] = useState('');
    const [fileManagerDeletePermissionId, setFileManagerDeletePermissionEdit] = useState('');

    const dispatch = useDispatch();

    const validateNewGroup = () => {
        const newErrors = { ...errors };
        newErrors.name = validator({ required: true, minLength: 3 }, groupName);
        newErrors.tenantId = validator({ required: true }, tenantId);
        newErrors.projectIds = validator({ required: true, minLength: 1 }, projectIds);

        setErrors(newErrors);
        return Object.values(newErrors).filter((value) => !!value).length === 0;
    };

    const onCloseDialog = () => {
        if (onClose) {
            onClose();
            setGroupName('');
            setProjectIds([]);
            setTenantId('');
            setPermissionValues([]);
            setActiveRole(undefined);
            setErrors({});
        }
    };

    const onSaveDialog = () => {
        if (onSave) {
            if (!validateNewGroup()) return;
            let newGroup: UserGroup = {
                _id: group?._id || generateUID(),
                name: groupName,
                permissions: permissionValues,
                tenantId,
                projectIds
            };
            onSave(newGroup, userIds);
            onCloseDialog();
        }
    };

    useEffect(() => {
        if (group) {
            setGroupName(group.name);
            setProjectIds(group.projectIds);
            setTenantId(group.tenantId);
            setUserIds(storeUsers.filter((user) => user.userGroupIds?.includes(group._id)).map((user) => user._id));
            setPermissionValues(group.permissions);
            setActiveRole(group.permissions.length ? 'editor' : 'viewer');
        }
    }, [group, storeUsers]);

    useEffect(() => {
        if (!group && !permissionsLoading) {
            setActiveRole('editor');
            setPermissionValues(permissions.map((p) => p._id));
        }
    }, [group, permissionsLoading]);

    const loadProjects = async () => {
        return await dispatch(fetchProjects({})).unwrap();
    };

    const loadTenants = async () => {
        await dispatch(fetchTenants({})).unwrap();
    };

    const loadPermissions = async () => {
        await dispatch(fetchPermissions()).unwrap();
    };

    const loadRoles = async () => {
        await dispatch(fetchRoles()).unwrap();
    };

    useEffect(() => {
        if (open) {
            loadProjects().then((response) => {
                if (response.error?.code !== API_ERROR_CODES.AUTHENTICATION_ERROR) {
                    loadTenants();
                    loadPermissions();
                    loadRoles();
                    setIsOpen(true);
                }
            });
        } else {
            setIsOpen(false);
        }
    }, [open]);

    useEffect(() => {
        if (!tenantId) {
            setProjectIds([]);
        }
    }, [tenantId]);

    const saveButton: DialogButton = {
        label: 'Save',
        type: 'BLUE',
        onClick: onSaveDialog,
        loading: usersLoading || permissionsLoading || rolesLoading || tenantsLoading || projectsLoading
    };

    const cancelButton: DialogButton = {
        label: 'Cancel',
        type: 'DEFAULT',
        onClick: onCloseDialog
    };

    const tenantsOptions = tenants?.map((tenant) => {
        return {
            label: tenant.name,
            value: tenant._id
        };
    });

    const tenantsValue = tenantsOptions.find((option: any) => tenantId === option.value);

    const projectsPerTenant = projects.filter((project) => project.tenantId === tenantsValue?.value);

    const projectOptions = projectsPerTenant.map((option) => {
        return {
            label: option.name,
            value: option._id
        };
    });

    const projectsValues = projectOptions?.filter((option) => {
        return projectIds?.includes(option.value);
    });

    const usersToShow = storeUsers.filter((user) => user.projectIds?.some((pId) => projectIds.includes(pId)));
    const userOptions = usersToShow.map((user) => {
        return {
            label: (
                <UserOptionContainer>
                    <UserAvatar background={user.icon || icons.userIcon} />
                    <UserOptionName>{user.fullName}</UserOptionName>
                </UserOptionContainer>
            ),
            value: user._id,
            valueForSearch: user.fullName
        };
    });

    const userValues = userOptions?.filter((option) => {
        return userIds?.includes(option.value);
    });

    useEffect(() => {
        // When the userOptions changes(this happens when projects are selected/deselected), recalculate the userIds state
        const selectableUserIds = usersToShow.map((user) => user._id);
        setUserIds(userIds.filter((userId) => selectableUserIds.includes(userId)));
    }, [projectIds]);

    const checkActivePermission = (id: string) => {
        return permissionValues.some((permission) => permission === id);
    };

    const changeActivePermissions = (permission: string) => {
        let newActivePermissions: string[] = [];
        if (permissionValues.some((id) => id === permission)) {
            newActivePermissions = permissionValues.filter((item) => item !== permission);
        } else {
            // Since checking DELETE means all access to file_manager, when delete is checked and edit is not, we auto check file_manager_edit too!!!!
            newActivePermissions = [...permissionValues, permission];
            if (
                permission === fileManagerDeletePermissionId &&
                fileManagerEditPermissionId &&
                !newActivePermissions.includes(fileManagerEditPermissionId)
            ) {
                newActivePermissions.push(fileManagerEditPermissionId);
            }
        }
        setPermissionValues(newActivePermissions);
    };

    useEffect(() => {
        if (!permissions?.length) return;
        // Sort permissions in the same order that objects are shown in sidebar
        const newPermissions = [...permissions]?.sort((a, b) => {
            const orderA = permissionsOrder.indexOf(a.name as PermissionKey);
            const orderB = permissionsOrder.indexOf(b.name as PermissionKey);
            if (orderA === -1 || orderB === -1) return 1;
            return orderA - orderB;
        });
        setPermissionsToShow(newPermissions);
        !group && setPermissionValues(newPermissions.map((permission) => permission._id));
        const ePermId = newPermissions.find((permission) => permission.name === 'file_manager_edit')?._id;
        const dPermId = newPermissions.find((permission) => permission.name === 'file_manager_delete')?._id;
        setFileManagerEditPermissionEdit(ePermId || '');
        setFileManagerDeletePermissionEdit(dPermId || '');
    }, [permissions]);

    // this is outside the main return because we do not open the dialog if we have error
    // so isOpen will be false
    const error = tenantsError || projectsError || permissionsError || rolesError;
    if (error) {
        return <BackendErrorDialog error={error} />;
    }

    const renderEditRightsSection = () => {
        return (
            <>
                <RowTitle>Edit rights</RowTitle>
                <DialogDoubleFieldWrapper $width1={500}>
                    <DoubleFieldColumn>
                        {permissionsToShow?.slice(0, Math.ceil(permissions?.length / 2)).map((permission: any, index: number) => {
                            const name = PermissionKeyToNameMapping[permission.name as PermissionKey] || 'UNKOWN_PERMISSION';
                            return (
                                <DialogCheckbox
                                    key={index}
                                    text={name}
                                    onClick={() => {
                                        changeActivePermissions(permission._id);
                                        setErrors(_.omit(errors, 'permissions'));
                                    }}
                                    active={checkActivePermission(permission._id)}
                                    value={checkActivePermission(permission._id)}
                                    disabled={
                                        // if the FM_Delete permission is checked, the FM_Edit cannot be altered
                                        permission._id === fileManagerEditPermissionId &&
                                        checkActivePermission(fileManagerDeletePermissionId)
                                    }
                                    capitalizeText
                                />
                            );
                        })}
                    </DoubleFieldColumn>
                    <DoubleFieldColumn>
                        {permissionsToShow
                            ?.slice(Math.ceil(permissions?.length / 2), permissions.length)
                            ?.map((permission: any, index: number) => {
                                if (permission.name === 'custom_editor') return;
                                const name = PermissionKeyToNameMapping[permission.name as PermissionKey] || 'UNKOWN_PERMISSION';
                                return (
                                    <DialogCheckbox
                                        key={index}
                                        text={name}
                                        onClick={() => {
                                            changeActivePermissions(permission._id);
                                            setErrors(_.omit(errors, 'permissions'));
                                        }}
                                        active={checkActivePermission(permission._id)}
                                        value={false}
                                        disabled={
                                            // if the FM_Delete permission is checked, the FM_Edit cannot be altered
                                            permission._id === fileManagerEditPermissionId &&
                                            checkActivePermission(fileManagerDeletePermissionId)
                                        }
                                        capitalizeText
                                    />
                                );
                            })}
                    </DoubleFieldColumn>
                </DialogDoubleFieldWrapper>
            </>
        );
    };

    if (!isOpen) return null;

    return (
        <GenericDialog
            type={DialogTypes.Form}
            actionButtons={[cancelButton, saveButton]}
            onClose={() => {
                onCloseDialog();
            }}
            title={group ? 'Edit User-Group' : 'Create User-Group'}
            circlesSlugOptions={{ default: CIRCLE_SLUGS.user_groups }}
        >
            <DialogTextField
                label={'Group Name'}
                value={groupName}
                onChange={(e: any) => {
                    setGroupName(e.target.value);
                    setErrors(_.omit(errors, 'name'));
                }}
                placeholder={'Type something'}
                error={errors.name}
            />
            <DialogRow>
                <DialogRadioButton
                    key={'viewer'}
                    active={activeRole === 'viewer'}
                    onClick={() => {
                        setActiveRole('viewer');
                        setPermissionValues([]);
                    }}
                    text={'Viewer'}
                />
                <DialogRadioButton
                    key={'editor'}
                    active={activeRole === 'editor'}
                    onClick={() => {
                        setActiveRole('editor');
                        setPermissionValues(group ? group.permissions : permissions.map((p) => p._id));
                    }}
                    text={'Editor'}
                />
            </DialogRow>
            {activeRole === 'editor' && renderEditRightsSection()}
            <DialogDropdownSingle
                placeholder={'Select Tenant'}
                options={tenantsOptions}
                value={tenantsValue}
                labelText={'Tenant'}
                onChange={(item: any) => {
                    setTenantId(item.value);
                    // when switching tenants, reset projectIds
                    setProjectIds([]);
                    setErrors(_.omit(errors, 'tenantId'));
                }}
                error={errors.tenantId}
                withTopMargin
            />

            <DialogDropdownMultiple
                placeholder={'Select Project(s)'}
                options={projectOptions}
                value={projectsValues}
                labelText={'Projects'}
                onChange={(value: any) => {
                    setProjectIds([...value.map((v: any) => v.value)]);
                    setErrors(_.omit(errors, 'projectIds'));
                }}
                allowSelectAll={true}
                error={errors.projectIds}
                withTopMargin
            />

            <DialogDropdownMultiple
                placeholder={usersLoading ? 'Loading Users... ' : 'Select User(s)'}
                options={userOptions}
                value={userValues}
                labelText={'Users'}
                onChange={(option: any) => {
                    setUserIds([...option.map((v: any) => v.value)]);
                }}
                allowSelectAll={true}
                withTopMargin
            />
        </GenericDialog>
    );
};

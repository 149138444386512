import { TableBody, TableCell } from '@material-ui/core';
import _ from 'lodash';
import React, { ChangeEvent, createRef, useCallback, useEffect, useState } from 'react';
import icons, { iconType } from '../../assets/images/icons';
import { resourceCardImages } from '../../assets/images/resourceCards';
import { useAppDispatch as useDispatch, useAppSelector } from '../../hooks/redux';
import { ActiveItemState } from '../../redux/slices/activeItemSlice';
import {
    deleteFile,
    deleteFolder,
    encodeVideo,
    fetchFile,
    fetchFiles,
    FilesState,
    restoreOldFileAfterCancel,
    setUploadFilesProgress,
    unsetEditingFile,
    unsetOverwritingFile,
    unsetUploadingFiles,
    updateFile,
    uploadFileViaClient,
    uploadFolder
} from '../../redux/slices/fileManagerSlice';
import { fetchProjects, ProjectsState } from '../../redux/slices/projectsSlice';
import { ApplicationWrapper, NoResourcesContainer, PageActionButton, PageActionsWrapper } from '../../style/styled-components/reusable.css';
import { bytesToSize } from '../../utils/fnData';
import { generateDateStringForTables } from '../../utils/fnDate';
import { DIALOG_NAMES, dialogAlert, dialogConfirm, ToastAlert } from '../../utils/fnDialogs';
import { generateUID } from '../../utils/fnGenerator';
import { ACCEPTED_SORT_FIELDS, DEFAULT_SORT_CONFIG, ISortConfig, sortObject } from '../../utils/fnSort';
import { EMPTY_WORD_STRING, FILE_MANAGER_DRAGGABLE_TYPES, getImgixUrl, imgixDefaultQuery } from '../../utils/Globals';
import { ResourceCard } from '../Cards/ResourceCard/ResourceCard';
import ScreenTitle from '../common/DashboardTitle/ScreenTitle';
import BackendErrorDialog from '../common/Dialog/BackendErrorDialog';
import { Loader } from '../common/Loader/Loader';
import Sidebar from '../common/Sidebar/Sidebar';
import GenericTable, { ActionsTableCell, HeaderTableCell, SortableHeaderTableCell, tableActions } from '../common/Table/Table';
import { GroupedByTableRow, WidthTableCell } from '../common/Table/Table.css';
import { FilePreview } from './Dialogs/FilePreview';
import { NewFolder } from './Dialogs/NewFolder';
import {
    ActionsContainer,
    ActionsHolder,
    ExtraFilters,
    FileCancelUploadText,
    FileCardDescription,
    FileCardDetails,
    FileCardDetailsHolder,
    FileCardImage,
    FileCardImageWrapper,
    FileCardTitle,
    FileIconContainer,
    FileImage,
    FileManagerButtonsWrapper,
    FileManagerCardActions,
    FileManagerContainer,
    FileManagerFilterLabel,
    FileManagerFiltersContainer,
    FileManagerFolder,
    FileManagerImage,
    FileManagerName,
    FileManagerNameCell,
    FileManagerUploadingContainer,
    FilePlacedWrapper,
    FilePreviewCell,
    FileSection,
    FilesGridContainer,
    FileSideSection,
    SortDropdown,
    GroupedByBreadcrumbsContainer,
    ScrollWrapper,
    VideoFileCardEncodeStatusHolder,
    VideoFiltersSelectContainer,
    SortContainer,
    FilterAndSortContainer,
    FileManagerContentWrapper
} from './FileManager.css';
import configServiceAPI from '../../utils/api/configServiceAPI';
import FileManagerCustomDragLayer, { FileManagerDNDSuggestion, FileManagerDraggableFile, UploadDropZone } from './FileManagerDNDComponents';
import ToggleViewSwitch from '../common/Switch/ToggleViewSwitch';
import SVGInline from 'react-inlinesvg';
import { renderTooltipWithKey } from '../common/Tooltips/Tooltips';
import Button from '../Buttons/Button/Button';
import { SearchBar } from '../common/SearchBar/SearchBar';
import Breadcrumbs from '../common/Breadcrumbs/Breadcrumbs';
import {
    ENCODE_STATUSES,
    EncodeStatus,
    renderDisabledActionWhileEncodingInProgressAlert,
    renderEncodeStatusIcon,
    renderEncodingInProgressAlert,
    renderEncodingStartedToast
} from '../../utils/fnEncode';
import { sanitizeFileName } from '../../utils/fnString';
import { DialogDropdownSingle } from '../common/Dialog/GenericDialog';
import { CIRCLE_SLUGS, ONBOARDING_CIRCLE_SLUGS } from '../common/HelpIcon/HelpIcon';
import { ProgressBar, WrapperBar } from '../common/Loader/Loader.css';
import azureUploader from '../../utils/api/azureUploader';
import useScreenSize from '../../hooks/useScreenSize';
import { SearchBarContainer } from '../common/SearchBar/SearchBar.css';
import { XYCoord } from 'react-dnd';

export const FILE_EXISTS_CODE = 'FILE_ALREADY_EXISTS_ERROR';

export type ParsedFile = {
    name: string;
    size: number;
    lastModified: number;
    images?: ParsedFile[];
    isFolder?: boolean;
    originalPath?: string;
    folderName?: string;
    folderPath?: string;
    fullUrl?: string;
    encodeStatus?: EncodeStatus;
    isUploading?: boolean;
    uploadProgress?: number;
    path?: string;
};

export const FILTER_TYPES = {
    IMAGES: 'images',
    ALL_VIDEOS: 'all_videos',
    ENCODED_VIDEOS: 'encoded_videos',
    NOT_ENCODED_VIDEOS: 'not_encoded_videos',
    OTHERS: 'others'
} as const;

export type FilterType = typeof FILTER_TYPES[keyof typeof FILTER_TYPES];

export const FILTER_TYPE_LABELS = {
    [FILTER_TYPES.IMAGES]: 'Images',
    [FILTER_TYPES.OTHERS]: 'Others',
    [FILTER_TYPES.ALL_VIDEOS]: 'Videos',
    [FILTER_TYPES.ENCODED_VIDEOS]: 'Encoded',
    [FILTER_TYPES.NOT_ENCODED_VIDEOS]: 'Not Encoded'
} as const;

export const imgExtensions = ['jpg', 'jpeg', 'png', 'svg', 'gif', 'tiff', 'psd'];
export const videoExtensions = [
    'webm',
    'mpg',
    'mp2',
    'mpeg',
    'mpe',
    'mpv',
    'ogg',
    'mp4',
    'm4p',
    'm4v',
    'avi',
    'wmv',
    'mov',
    'qt',
    'flv',
    'swf',
    'avchd'
];

const sortOptions = [
    { value: 'lastModified_asc', label: 'Recently Updated Ascending' },
    { value: 'lastModified_desc', label: 'Recently Updated Descending' },
    { value: 'name_asc', label: 'Name (A-Z)' },
    { value: 'name_desc', label: 'Name (Z-A)' },
    { value: 'size_asc', label: 'Size Ascending' },
    { value: 'size_desc', label: 'Size Descending' }
];

const FileManager: React.FC<{ openInDialog?: boolean; onFileClick?: any }> = ({ openInDialog, onFileClick }) => {
    const { activeProjectId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const { files, loading, error, uploadFilesProgress, editingFile, overwritingFile }: FilesState = useAppSelector((state) => state.files);
    const { projects }: ProjectsState = useAppSelector((state) => state.projects);
    const { isDesktop } = useScreenSize();

    const [hideUploadZone, setHideUploadZone] = useState(false);

    const [currentFolder, setCurrentFolder] = useState(''); // current folder should always contain the activeProjectId at the start
    const [filter, setFilter] = useState<FilterType | undefined>(undefined);
    const [openNewFileDialog, setOpenNewFileDialog] = useState<boolean>(false);
    const [parsedFiles, setParsedFiles] = useState<ParsedFile[]>([]);
    const [fileToEdit, setFileToEdit] = useState<ParsedFile | undefined>(undefined);
    const [showFolderDialog, setShowFolderDialog] = useState<boolean>(false);
    const [showSortArrows, setShowSortArrows] = useState<boolean>(false);
    const [activeSortingKey, setActiveSortingKey] = useState<string | null>('lastModified');
    const [sortConfig, setSortConfig] = useState<ISortConfig>(DEFAULT_SORT_CONFIG);
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);

    const [openPreview, setOpenPreview] = useState<boolean>(false);
    const [fileToPreview, setFileToPreview] = useState<ParsedFile | undefined>(undefined);
    const [projectName, setProjectName] = useState<string>('');
    const [view, setView] = useState<'LIST' | 'GRID'>('GRID');
    const [openOverlay, setOpenOverlay] = useState<string>('');
    // used for overwriting logic
    const [fileToUpdate, setFileToUpdate] = useState<{ originalPath: string; newPath: string } | null>(null);
    const [newFiles, setNewFiles] = useState<FormData | null>(null);

    const [filesPendingEncode, setFilesPendingEncode] = useState<{ [key: string]: EncodeStatus }>({});
    const [isEncodeRestricted, setIsEncodeRestricted] = useState(false);

    const inputRef = createRef<HTMLInputElement>();
    const fmWrapperRef = createRef<HTMLDivElement>();
    const dispatch = useDispatch();
    const previewExtensions = [...imgExtensions, ...videoExtensions];

    const uploading = !!Object.keys(uploadFilesProgress || {}).length;
    const orderBy = `${sortConfig.field}[${sortConfig.direction}]`;

    const renderUploadingNotPossibleAlert = () => {
        const values = {
            title: 'A file is already uploading!',
            text: `Please wait until your files are uploaded before uploading a new one.`
        };
        dialogAlert('', undefined, values, undefined, undefined, icons.warningYellowIcon);
    };

    const onDrop = useCallback(
        (acceptedFiles) => {
            const formData = new FormData();
            const file = acceptedFiles?.length ? [...acceptedFiles][0] : undefined;
            if (!file) return;
            formData.append('fileupload', file, sanitizeFileName(file.name) || `blob_${generateUID()}`);
            const prefix = currentFolder;
            formData.append('prefix', prefix);
            setNewFiles(formData);
            createFiles(formData);
        },
        [currentFolder, filter]
    );

    const handleResetFilters = () => {
        setSearchTerm(undefined);
        setSortConfig(DEFAULT_SORT_CONFIG);
        setActiveSortingKey(ACCEPTED_SORT_FIELDS.lastModified);
    };

    const moveFileIntoFolder = async (fileName: string, destinationFolder: string) => {
        const file = parsedFiles.find((file) => file.name === fileName);
        const isEncodingInProgress = file && (filesPendingEncode?.[file.name] || file.encodeStatus) === 'ENCODING_IN_PROGRESS';
        if (isEncodingInProgress) {
            return renderDisabledActionWhileEncodingInProgressAlert();
        }

        if (uploading) return renderUploadingNotPossibleAlert();

        const fileOriginalPath = `${currentFolder}\/${fileName}`;

        const moveAndDeleteFile = async (overwrite?: boolean) => {
            editFile({ originalPath: fileOriginalPath, newPath: `${destinationFolder}/${fileName}`, overwrite });
        };

        const checkResponse = await configServiceAPI.checkPlacement(fileOriginalPath || '');
        if ((checkResponse.response as any)?.length) {
            return renderWarningDialog(() => {
                moveAndDeleteFile(true);
            }, checkResponse.response as any);
        }
        moveAndDeleteFile();
    };

    const handleFileInputChange = async (evt: ChangeEvent<HTMLInputElement>) => {
        if (!evt.target.files?.length) return;
        const file = [...evt.target.files][0];
        if (!file) return;

        const formData = new FormData();
        formData.append('fileupload', file, sanitizeFileName(file.name) || `blob_${generateUID()}`);
        const prefix = currentFolder;
        formData.append('prefix', prefix);
        setNewFiles(formData);

        createFiles(formData);
    };

    const fileDeleteHandler = async (file: ParsedFile, options?: { isFolder?: boolean; isEncodingInProgress?: boolean }) => {
        if (options?.isEncodingInProgress) {
            return renderDisabledActionWhileEncodingInProgressAlert();
        }
        if (!options?.isFolder) {
            const response = await configServiceAPI.checkPlacement(file.originalPath || '');
            if ((response.response as any)?.length) {
                return renderWarningDialog(() => handleDeleteIconClick(file, options?.isFolder), response.response as any);
            }
        }
        handleDeleteIconClick(file, options?.isFolder);
    };
    const fileEditHandler = async (file: ParsedFile, options?: { isFolder?: boolean; isEncodingInProgress?: boolean }) => {
        if (options?.isFolder) return;
        if (options?.isEncodingInProgress) return renderDisabledActionWhileEncodingInProgressAlert();
        const response = await configServiceAPI.checkPlacement(file.originalPath || '');
        if ((response.response as any)?.length) {
            return renderWarningDialog(() => {
                setFileToEdit(file);
                setOpenNewFileDialog(true);
            }, response.response as any);
        }
        setFileToEdit(file);
        setOpenNewFileDialog(true);
    };
    const fileDownloadHandler = (file: ParsedFile, options?: { isFolder?: boolean; isEncodingInProgress?: boolean }) => {
        if (options?.isFolder) return;
        if (options?.isEncodingInProgress) return renderDisabledActionWhileEncodingInProgressAlert();
        downloadFile(file);
    };

    const renderWarningDialog = (confirmCb: () => void, placed?: { name: string; objectType: string }[]) => {
        const values = {
            title: 'Already in use',
            text: (
                <>
                    <p>This file is placed in the following objects:</p>
                    <FilePlacedWrapper>
                        {placed?.map((p, index) => (
                            <p key={`placed_${index}`}>
                                <strong>{p.name || EMPTY_WORD_STRING}</strong> ({_.upperFirst(p.objectType.slice(0, -1))})
                            </p>
                        ))}
                    </FilePlacedWrapper>
                    <p>
                        After this operation, the file will not be visible in these objects. Please be aware, that the file could still
                        remain visible in the objects mentioned until the browser cache is cleared.
                    </p>
                    <p>Do you wish to continue?</p>
                </>
            )
        };
        return dialogConfirm(
            '',
            () => {
                confirmCb();
            },
            values,
            null,
            {
                noButton: {
                    label: 'Cancel'
                },
                confirmButton: {
                    label: 'Continue'
                }
            }
        );
    };

    const handleEncodeClick = async (fileName: string, encodeStatus?: EncodeStatus) => {
        if (!encodeStatus) return;

        switch (encodeStatus) {
            case ENCODE_STATUSES.NOT_ENCODED:
            case ENCODE_STATUSES.ENCODING_ERROR:
                setFilesPendingEncode({ ...filesPendingEncode, [fileName]: ENCODE_STATUSES.ENCODING_IN_PROGRESS });
                try {
                    const result = await _encodeVideo(currentFolder, fileName);
                    if (result.ok) {
                        renderEncodingStartedToast();
                    }
                } catch (err) {
                    setFilesPendingEncode({ ...filesPendingEncode, [fileName]: ENCODE_STATUSES.ENCODING_ERROR });
                }

                break;
            case ENCODE_STATUSES.ENCODING_IN_PROGRESS:
                renderEncodingInProgressAlert();
                break;
        }
    };

    const handleOverwriteFile = () => {
        const values = {
            title: 'Overwrite File',
            text: (
                <>
                    <p>
                        There is already a file with the same name and path. If it’s overwritten, the old file could still remain visible
                        until the browser cache is cleared.
                    </p>
                    <p>Do you want to overwrite it?</p>
                </>
            )
        };
        return dialogConfirm(
            '',
            async () => {
                if (newFiles) {
                    const file = newFiles;
                    const uploadPrefix = file.get('prefix');
                    const fileName = (file.get('fileupload') as File).name || '';
                    const fileOriginalPath = `${currentFolder}/${fileName}`;
                    file.append('overwrite', 'true');
                    const createResponse = await createFiles(file);
                    // If the creation was successful, and the if currentFolder is not equal to the prefix where the file was created
                    // That means the file was moved, so we delete from the old folder
                    if (createResponse && uploadPrefix !== currentFolder) {
                        removeFile(fileOriginalPath);
                    }
                } else {
                    if (!fileToUpdate) return;
                    editFile({ ...fileToUpdate, overwrite: true });
                }
            },
            values,
            null,
            {
                noButton: {
                    label: 'Cancel'
                },
                confirmButton: {
                    label: 'Yes'
                }
            }
        );
    };

    const handleDeleteIconClick = (file: ParsedFile, isFolder?: boolean) => {
        const extension = file.name.split('.').pop() || '';
        const fileType = isFolder
            ? 'Folder'
            : videoExtensions.includes(extension)
            ? 'Video'
            : imgExtensions.includes(extension)
            ? 'Image'
            : 'File';
        let customIcon: iconType | undefined = undefined;
        switch (fileType) {
            case 'Folder':
                customIcon = icons.deleteFolderIcon;
                break;
            case 'Video':
                customIcon = icons.deleteVideoIcon;
                break;
            case 'Image':
                customIcon = icons.deleteImageIcon;
                break;
            case 'File':
                customIcon = icons.deleteOtherIcon;
                break;
        }

        const values = {
            title: `Delete ${fileType}`,
            text: (
                <>
                    <div>
                        You are in the process of deleting your {isFolder ? 'folder' : 'file'} <strong>{file.name}</strong>.
                    </div>
                    <br />
                    <div>Are you sure you want to permanently delete the selected file?</div>
                </>
            )
        };
        dialogConfirm(
            '',
            () => {
                isFolder ? removeFolder(file.name) : removeFile(file.originalPath || '');
            },
            values,
            null,
            {
                noButton: {
                    label: 'Cancel'
                },
                confirmButton: {
                    label: 'Delete'
                }
            },
            { customIcon: customIcon },
            undefined,
            true
        );
    };

    const handleSortIconClick = (field: string, defaultDirection?: 'asc' | 'desc') => {
        setActiveSortingKey(field);

        let direction: 'asc' | 'desc' = defaultDirection || 'asc';
        if (
            !defaultDirection &&
            sortConfig &&
            (sortConfig.field === field || sortConfig.field.split('.')[0] === field) &&
            sortConfig.direction === 'asc'
        ) {
            direction = 'desc';
        }

        const config = {
            field,
            direction
        };
        setSortConfig(config);
        const orderBy = `${config.field}[${config.direction}]`;
        loadFiles(orderBy, searchTerm);
    };

    const downloadFile = async (file: any) => {
        const response = await fetch(file.fullUrl);
        if (!response.ok) return;

        const blob = await response.blob();
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = file.name;
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    const loadFiles = async (order?: string, searchTerm?: string) => {
        const orderBy = order || `${DEFAULT_SORT_CONFIG.field}[${DEFAULT_SORT_CONFIG.direction}]`;
        const prefix = currentFolder;
        if (!prefix) return;
        return await dispatch(fetchFiles({ prefix, filter, orderBy, searchTerm })).unwrap();
    };

    const loadProjects = async () => {
        return await dispatch(fetchProjects({ addPermissions: false })).unwrap();
    };

    const handleOnSearch = (searchTerm: string) => {
        loadFiles(orderBy, searchTerm);
        searchTerm && setView('LIST');
    };

    const createFiles = async (data: FormData) => {
        if (uploading) {
            renderUploadingNotPossibleAlert();
            return false;
        }

        const file = data.get('fileupload') as File;
        const overwrite = (data.get('overwrite') as string) === 'true';
        const prefix = data.get('prefix') as string;
        const response = await dispatch(
            uploadFileViaClient({
                file: file,
                prefix: prefix,
                uploadProgressCallback: (data) => {
                    dispatch(setUploadFilesProgress({ key: `${prefix}/${data.name}`, value: data.progress }));
                },
                uploadSuccessCallback: (name) => {
                    dispatch(setUploadFilesProgress({ key: `${prefix}/${name}`, value: 100 }));
                    overwrite && dispatch(unsetOverwritingFile());
                    // wait for the gif animation to end and then load the actual file
                    // in the slice, the placeholder file will be replaced with the actual file
                    setTimeout(async () => {
                        await dispatch(fetchFile(`${currentFolder}/${name}`));
                        dispatch(setUploadFilesProgress({ key: `${prefix}/${name}` }));
                        // we need to load all the files in order to reset the filters after the upload

                        searchTerm && setSearchTerm('');
                    }, 1000);
                },
                overwrite
            })
        ).unwrap();

        const success = response.data.success;
        success && renderSuccessToast(file.name);
        return success;
    };
    const handleCancelFileUploadClick = (fileName: string) => {
        const values = {
            title: 'Cancel Upload',
            text: (
                <>
                    <div>
                        You are in the process of cancelling the upload of the <strong>{fileName}</strong> file.
                    </div>
                    <br />
                    <div>Are you sure you want to cancel the upload of the selected file?</div>
                </>
            )
        };
        return dialogConfirm(
            '',
            () => {
                cancelFileUpload();
            },
            values,
            null,
            {
                noButton: {
                    label: 'Close'
                },
                confirmButton: {
                    label: 'Cancel Upload'
                }
            },
            { customIcon: icons.cancelUploadIcon },
            undefined,
            true
        );
    };

    const renderUploadCancelFailedAlert = () => {
        return dialogAlert(
            '',
            false,
            {
                title: 'Cancel Failed',
                text: 'Cancelling was not performed because the upload finished in the meantime!'
            },
            null,
            false,
            icons.warningYellowIcon
        );
    };
    const cancelFileUpload = () => {
        const canceled = azureUploader.abortFileUpload();
        if (!canceled) {
            return renderUploadCancelFailedAlert();
        }
        dispatch(restoreOldFileAfterCancel());
        dispatch(unsetUploadingFiles());
    };
    const _encodeVideo = async (prefix: string, fileName: string) => {
        return await dispatch(encodeVideo({ prefix, fileName })).unwrap();
    };
    const removeFile = async (originalPath: string) => {
        await dispatch(deleteFile(originalPath)).unwrap();
        loadFiles(orderBy, searchTerm);
    };
    const editFile = async (file: { originalPath: string; newPath: string; overwrite?: boolean }) => {
        if (uploading) {
            renderUploadingNotPossibleAlert();
            return false;
        }
        const name = _.last(file.newPath.split('/')) || '';
        const prefix = file.newPath.replace(`/${name}`, '');

        const response = await dispatch(
            updateFile({
                ...file,
                uploadProgressCallback: (data) => {
                    dispatch(setUploadFilesProgress({ key: `${prefix}/${data.name}`, value: data.progress }));
                },
                uploadSuccessCallback: (name) => {
                    dispatch(setUploadFilesProgress({ key: `${prefix}/${name}`, value: 100 }));
                    file.overwrite && dispatch(unsetOverwritingFile());
                    dispatch(unsetEditingFile());
                    // wait for the gif animation to end and then load the actual file
                    // in the slice, the placeholder file will be replaced with the actual file
                    setTimeout(() => {
                        dispatch(fetchFile(`${prefix}/${name}`));
                        dispatch(setUploadFilesProgress({ key: `${prefix}/${name}` }));
                    }, 1000);
                }
            })
        ).unwrap();

        const success = response.data.success;
        success && renderSuccessToast(name);
        return success;
    };

    const createFolder = async (folderName: string) => {
        const folder = { prefix: currentFolder, folderName };
        const response = await dispatch(uploadFolder(folder)).unwrap();
        if (response.message) {
            setFilter(undefined);
            !filter && loadFiles(); // if we are on the all tab, setting the filter to undefined(meaning 'all'), it wont cause a reload, so we load manually
            ToastAlert('success', '', '', undefined, DIALOG_NAMES.FILE_MANAGER_FOLDER_CREATED);
            handleResetFilters();
        }
    };

    const removeFolder = async (folderName: string) => {
        const folder = { prefix: currentFolder, folderName };
        await dispatch(deleteFolder(folder)).unwrap();
        loadFiles(orderBy, searchTerm);
    };

    const renderSuccessToast = (fileName: string) => {
        if (doesFileBelongToFilter(fileName)) {
            return ToastAlert('success', '', '', undefined, DIALOG_NAMES.FILE_MANAGER_FILE_CREATED);
        }

        const extension = fileName.split('.').pop() || '';
        const tab = videoExtensions.includes(extension) ? 'videos' : imgExtensions.includes(extension) ? 'images' : 'others';
        ToastAlert('success', 'Success', `File upload started in the ${tab}/all tab`);
    };

    const doesFileBelongToFilter = (name: string, encodeStatus: string = ENCODE_STATUSES.NOT_ENCODED) => {
        const extension = name.split('.').pop() || '';

        if (!filter) return true;
        if (videoExtensions.includes(extension)) {
            if (filter === FILTER_TYPES.ALL_VIDEOS) return true;
            if (filter === FILTER_TYPES.ENCODED_VIDEOS) return encodeStatus === ENCODE_STATUSES.ENCODED;
            return filter === FILTER_TYPES.NOT_ENCODED_VIDEOS && encodeStatus !== ENCODE_STATUSES.ENCODED;
        }
        if (imgExtensions.includes(extension)) return filter === FILTER_TYPES.IMAGES;
        return filter === FILTER_TYPES.OTHERS;
    };

    const filterPlaceholders = (files: ParsedFile[]) => {
        const filteredFiles: ParsedFile[] = [];

        files.forEach((file) => {
            if (file.isFolder) {
                filteredFiles.push(file);
                return;
            }
            // if the file is currently being edited, do not show it
            // this is needed for when the user navigates away for the FM and edit is still happening
            if (
                !file.isUploading &&
                ((editingFile && file.originalPath === editingFile) || (overwritingFile && file.originalPath === overwritingFile))
            ) {
                return;
            }
            // if the file is uploading we add it to the files in the slice, however if the project is changed we must not show it
            // so checking if the prefix is equal to the currentFolder (prefix) and hiding the file if not, is easier than conditionally adding the file to the slice files
            if (file.isUploading) {
                const filePrefix = file.originalPath?.split('/')?.slice(0, -1)?.join('/');
                if (filePrefix !== currentFolder) return;
            }
            // with the file upload placeholders, we must filter files based on active tab (filter) and the extension in order to not show placeholders in wrong filters
            if (!doesFileBelongToFilter(file.name, file.encodeStatus)) return;
            filteredFiles.push(file);
        });

        return filteredFiles;
    };

    // used to force wrapper scrolling on dnd files
    const scrollOnDrag = (fileOffset: XYCoord) => {
        const { y: fileTop } = fileOffset;
        const scrollSpeed = 20;
        // used to force scrolling with 50px until the end of the wrapper
        const gap = 50;
        const fmHeader = 293 + gap;

        if (openInDialog) {
            if (!fmWrapperRef?.current) return;

            const { top, bottom } = fmWrapperRef.current.getBoundingClientRect();

            if (fileTop < top + gap) {
                fmWrapperRef.current.scrollTop -= scrollSpeed;
            } else if (fileTop > bottom - gap) {
                fmWrapperRef.current.scrollTop += scrollSpeed;
            }
            return;
        }

        const { scrollY, innerHeight } = window;

        if (fileTop < fmHeader && scrollY > 0) {
            window.scrollBy(0, -scrollSpeed);
        } else if (fileTop > innerHeight - gap) {
            window.scrollBy(0, scrollSpeed);
        }
    };

    useEffect(() => {
        if (!projects) return;
        setProjectName(projects.find((project) => project._id === activeProjectId)?.name || '');
    }, [projects]);

    useEffect(() => {
        if (!files) return;
        if (!error) {
            setFileToUpdate(null);
            setNewFiles(null);
        }

        if (error && error.code === FILE_EXISTS_CODE) {
            handleOverwriteFile();
            // if there is a file already exists error, do not reset the parsedFiles state, only do it after overwrites or successful uploads
            return;
        }
        setParsedFiles(filterPlaceholders(files));
    }, [files, error]);

    useEffect(() => {
        if (!Object.keys(uploadFilesProgress).length) return;

        setParsedFiles((prevParsedFiles) => {
            const newFiles = [...prevParsedFiles];
            Object.keys(uploadFilesProgress).forEach((name) => {
                const index = newFiles.findIndex((f) => name === f.originalPath && f.isUploading);
                if (index < 0) return;
                const file = {
                    ...newFiles[index],
                    uploadProgress: uploadFilesProgress[name]
                };
                newFiles.splice(index, 1, file);
            });
            return newFiles;
        });
    }, [uploadFilesProgress]);

    useEffect(() => {
        const unloadCallback = async (event: any) => {
            // stop page refresh and ask for confirmation
            event.preventDefault();
            // page refresh stops the upload, in which case we need to delete the useless placeholder files
            dispatch(unsetUploadingFiles());
            return 'are you sure?';
        };

        if (!Object.keys(uploadFilesProgress).length) {
            window.removeEventListener('beforeunload', unloadCallback);
            return;
        }

        window.addEventListener('beforeunload', unloadCallback);

        return () => {
            window.removeEventListener('beforeunload', unloadCallback);
        };
    }, [uploadFilesProgress]);

    useEffect(() => {
        azureUploader.projectId = activeProjectId || '';
        if (!activeProjectId) return;
        setCurrentFolder(activeProjectId);
        handleResetFilters();
        loadProjects();
    }, [activeProjectId]);

    useEffect(() => {
        if (!activeProjectId && !projects.length) return;
        const selectedProject = projects.find((project) => project._id === activeProjectId);
        setIsEncodeRestricted(selectedProject?.restrictedSections?.miscellaneous?.includes('bitmovin_encoding') || false);
    }, [activeProjectId, projects]);

    useEffect(() => {
        if (!currentFolder) return;
        loadFiles(orderBy, searchTerm);
    }, [currentFolder, filter]);

    useEffect(() => {
        activeSortingKey && setShowSortArrows(false);
    }, [activeSortingKey, sortConfig]);

    const generateImage = (path: string, lastModified: number, isGrid?: boolean) => {
        if (imgExtensions.includes(_.last(path.split('.')) || '')) {
            const width = isGrid ? '250' : '100';
            const height = isGrid ? '200' : '100';
            //Adding the cache param 10 seconds later with the lastModified, since the image purge for files that are overwrite take some time,
            // this way we force the browser to load the new(overwritten) image
            const query = `${imgixDefaultQuery}&width=${width}&height=${height}&cache=${
                Date.now() / 1000 - lastModified > 10 ? lastModified : 1
            }`;
            return getImgixUrl(path, query, true);
        }
        return icons.fileIcon;
    };

    const renderFolder = (folder: any, isGrid?: boolean) => {
        const { images } = folder;
        const imageUrls: string[] = [];
        const noOfImagesToShow = isGrid ? 4 : 3;

        images?.forEach((image: any, index: number) => {
            const imageURL = generateImage(image.originalPath, new Date(image.versionId).getTime() / 1000, isGrid);
            if (index < noOfImagesToShow) {
                imageUrls.push(imageURL);
            }
        });

        const getImage = (index: number, sideView?: boolean) => {
            const imgIndex = isGrid && !sideView ? (index += 1) : index;
            return imageUrls?.[imgIndex];
        };

        return (
            <FileManagerFolder isGrid={isGrid}>
                {isGrid && (
                    <FileSideSection>
                        <FileImage background={getImage(0, true)} />
                    </FileSideSection>
                )}
                <FileManagerContainer>
                    <FileSection>
                        <FileImage background={getImage(0)} />
                    </FileSection>

                    <FileSection>
                        <FileImage background={getImage(1)} />
                        <FileImage background={getImage(2)} />
                    </FileSection>
                </FileManagerContainer>
            </FileManagerFolder>
        );
    };

    const buildTableColumns = () => {
        const columns = parsedFiles?.length
            ? ['name', 'size', 'lastModified'].map((key, index) => {
                  const text = key === 'lastModified' ? 'Recently Updated' : key;
                  const isAcceptedSortField = ACCEPTED_SORT_FIELDS[key as keyof typeof ACCEPTED_SORT_FIELDS];

                  return isAcceptedSortField ? (
                      <SortableHeaderTableCell
                          key={`${index}_cell`}
                          direction={(sortConfig?.field === key && sortConfig?.direction) || 'asc'}
                          text={_.startCase(text)}
                          onClick={() => {
                              handleSortIconClick(key);
                          }}
                          hideArrow={!showSortArrows && activeSortingKey !== key}
                          onMouseEnter={() => setShowSortArrows(true)}
                          onMouseLeave={() => setShowSortArrows(false)}
                      />
                  ) : (
                      <HeaderTableCell text={_.capitalize(text)} key={`${index}_cell`} />
                  );
              })
            : [];
        return columns;
    };
    const buildTableRows = () => {
        const isSearchResult = !!searchTerm;
        const rows: any[] = [];
        let filesToRender = [...parsedFiles];

        if (isSearchResult) {
            filesToRender = Object.values(sortObject(_.groupBy(parsedFiles, 'folderName'))).flat() as ParsedFile[];
        }

        filesToRender.forEach((file, index) => {
            const lastModified = generateDateStringForTables(file.lastModified);
            const isFolder = file?.isFolder;
            const fileExtension: string = _.last(file?.name.split('.')) || '';
            const hideFolderDelete = isFolder && file.name === 'custom_icons' && currentFolder === `${activeProjectId}`;
            const isEncodingInProgress = (filesPendingEncode?.[file.name] || file.encodeStatus) === 'ENCODING_IN_PROGRESS';
            const getActions = () => {
                if (file.isUploading) return [];
                if (hideFolderDelete) return isFolder ? [] : [tableActions.EDIT, tableActions.DOWNLOAD];
                return isFolder ? [tableActions.REMOVE] : [tableActions.EDIT, tableActions.DOWNLOAD, tableActions.REMOVE];
            };

            if (isSearchResult && file.folderName && rows.findIndex((elem) => elem.key === file.folderPath) < 0) {
                rows.push(
                    <GroupedByTableRow key={file.folderPath}>
                        <TableCell colSpan={7}>
                            <GroupedByBreadcrumbsContainer>
                                <Breadcrumbs
                                    path={file.folderPath}
                                    callback={(path) => setCurrentFolder(path)}
                                    insideTableHeader={openInDialog}
                                />
                            </GroupedByBreadcrumbsContainer>
                        </TableCell>
                    </GroupedByTableRow>
                );
            }
            rows.push(
                <FileManagerDraggableFile
                    view={view}
                    key={`${index}_${file.name}`}
                    type={isFolder ? FILE_MANAGER_DRAGGABLE_TYPES.FOLDER : FILE_MANAGER_DRAGGABLE_TYPES.FILE}
                    name={isFolder ? `${currentFolder}/${file.name}` : file.name}
                    moveFileIntoFolder={(fileName, destinationFolder) => moveFileIntoFolder(fileName, destinationFolder)}
                    filesPendingEncode={filesPendingEncode}
                >
                    <WidthTableCell $width={30} $um={'%'}>
                        <FileManagerNameCell>
                            <FilePreviewCell $isFolder={isFolder}>{renderPreviewByExtensionType(file)}</FilePreviewCell>

                            <FileManagerName
                                isPointer={!file.isUploading && (isFolder || onFileClick || previewExtensions.includes(fileExtension))}
                                onClick={() => {
                                    if (file.isUploading) return;

                                    if (isFolder) {
                                        file?.folderPath
                                            ? setCurrentFolder(file.folderPath)
                                            : setCurrentFolder(`${currentFolder}/${file.name}`);
                                    } else {
                                        if (onFileClick) return onFileClick(file.originalPath);
                                        if (!previewExtensions.includes(fileExtension)) return;
                                        setOpenPreview(true);
                                        setFileToPreview(file);
                                    }
                                }}
                            >
                                {file.name}
                                {file.isUploading && (
                                    <FileCancelUploadText onClick={() => handleCancelFileUploadClick(file.name)} listView>
                                        Cancel Upload
                                    </FileCancelUploadText>
                                )}
                            </FileManagerName>
                        </FileManagerNameCell>
                    </WidthTableCell>
                    <WidthTableCell $width={20} $um={'%'}>
                        {file.isUploading ? '' : bytesToSize(file.size)}
                    </WidthTableCell>

                    <WidthTableCell $width={20} $um={'%'}>
                        {file.isUploading ? '' : lastModified}
                    </WidthTableCell>

                    {/* ACTIONS TABLE CELL */}
                    <WidthTableCell $wTab={92} $um={'px'}>
                        <ActionsTableCell
                            actions={getActions()}
                            onEdit={async (evt: any) => {
                                evt.stopPropagation();
                                fileEditHandler(file, { isFolder, isEncodingInProgress });
                            }}
                            onDownload={(evt: any) => {
                                evt?.stopPropagation();
                                fileDownloadHandler(file, {
                                    isEncodingInProgress,
                                    isFolder
                                });
                            }}
                            onRemove={async (evt: any) => {
                                evt.stopPropagation();
                                fileDeleteHandler(file, { isFolder, isEncodingInProgress });
                            }}
                            tooltipTexts={{
                                edit: isFolder ? 'file_manager_icon_edit_folder' : 'file_manager_icon_edit_file',
                                download: isFolder ? 'file_manager_icon_download_folder' : 'file_manager_icon_download_file',
                                delete: isFolder ? 'file_manager_icon_delete_folder' : 'file_manager_icon_delete_file'
                            }}
                            encodeStatus={
                                videoExtensions.includes(fileExtension) && !isEncodeRestricted
                                    ? filesPendingEncode?.[file.name] || file.encodeStatus
                                    : undefined
                            }
                            onEncode={() => {
                                file.originalPath && handleEncodeClick(file.name, filesPendingEncode?.[file.name] || file.encodeStatus);
                            }}
                        />
                    </WidthTableCell>
                </FileManagerDraggableFile>
            );
        });

        return <TableBody>{rows}</TableBody>;
    };

    const renderPreviewByExtensionType = (file: ParsedFile, isGrid?: boolean, fileId?: string) => {
        const isFolder = file?.isFolder;
        const fileExtension = _.last(file?.name.split('.')) || '';

        if (file.isUploading) {
            return (
                <FileManagerUploadingContainer $isGrid={isGrid}>
                    <FileIconContainer tableView={!isGrid}>
                        <img src={file.uploadProgress === 100 ? icons.uploadDoneIcon : icons.uploadingIcon} />
                    </FileIconContainer>
                    <WrapperBar>
                        <ProgressBar $progress={file.uploadProgress} />
                    </WrapperBar>
                </FileManagerUploadingContainer>
            );
        }

        if (isFolder) {
            return renderFolder(file, isGrid);
        }

        if (!['gif'].includes(fileExtension) && videoExtensions.includes(fileExtension)) {
            return (
                <FileIconContainer tableView={!isGrid}>
                    <SVGInline src={icons.videoIcon} />
                </FileIconContainer>
            );
        }

        return isGrid ? (
            <FileCardImage src={generateImage(file?.originalPath || '', file.lastModified, isGrid)} />
        ) : (
            <FileManagerImage src={generateImage(file.originalPath || '', file.lastModified)} />
        );
    };

    const renderGridView = () => {
        return (
            <FilesGridContainer>
                {parsedFiles?.map((file, index) => {
                    const isFolder = file?.isFolder;
                    const lastModified = generateDateStringForTables(file.lastModified);
                    const fileExtension = _.last(file?.name.split('.')) || '';
                    const fileId = `file_${index}`;
                    const hideFolderDelete = isFolder && file.name === 'custom_icons' && currentFolder === `${activeProjectId}`;
                    const isEncodingInProgress = (filesPendingEncode?.[file.name] || file.encodeStatus) === 'ENCODING_IN_PROGRESS';

                    return (
                        <FileManagerDraggableFile
                            view={view}
                            key={`${file.lastModified}_${file.name}`}
                            type={isFolder ? FILE_MANAGER_DRAGGABLE_TYPES.FOLDER : FILE_MANAGER_DRAGGABLE_TYPES.FILE}
                            name={isFolder ? `${currentFolder}/${file.name}` : file.name}
                            onClick={() => {
                                if (file.isUploading) return;

                                if (isFolder) {
                                    file?.folderPath
                                        ? setCurrentFolder(file.folderPath)
                                        : setCurrentFolder(`${currentFolder}/${file.name}`);
                                } else {
                                    if (onFileClick) return onFileClick(file.originalPath);
                                    if (!previewExtensions.includes(fileExtension)) return;
                                    setOpenPreview(true);
                                    setFileToPreview(file);
                                }
                            }}
                            moveFileIntoFolder={(fileName, destinationFolder) => moveFileIntoFolder(fileName, destinationFolder)}
                            filesPendingEncode={filesPendingEncode}
                        >
                            <FileCardImageWrapper isFolder={isFolder} onMouseLeave={() => setOpenOverlay('')}>
                                {file.isUploading ? (
                                    <></>
                                ) : openOverlay !== fileId ? (
                                    !hideFolderDelete && (
                                        <ActionsHolder
                                            onMouseEnter={(e: any) => {
                                                e.stopPropagation();
                                                setOpenOverlay(fileId);
                                            }}
                                        >
                                            <SVGInline src={icons.moreLightIcon} />
                                        </ActionsHolder>
                                    )
                                ) : (
                                    <FileManagerCardActions>
                                        <ActionsContainer>
                                            {!isFolder && (
                                                <>
                                                    {renderTooltipWithKey(
                                                        <SVGInline
                                                            src={icons.editLightIcon}
                                                            onClick={(evt: any) => {
                                                                evt.stopPropagation();
                                                                fileEditHandler(file, { isFolder, isEncodingInProgress });
                                                            }}
                                                        />,
                                                        'file_manager_icon_edit_file'
                                                    )}
                                                    {renderTooltipWithKey(
                                                        <SVGInline
                                                            src={icons.downloadIconLight}
                                                            onClick={(evt: any) => {
                                                                evt?.stopPropagation();
                                                                fileDownloadHandler(file, {
                                                                    isEncodingInProgress,
                                                                    isFolder
                                                                });
                                                            }}
                                                        />,
                                                        'file_manager_icon_download_file'
                                                    )}
                                                </>
                                            )}
                                            {renderTooltipWithKey(
                                                <SVGInline
                                                    src={icons.trashLightIcon}
                                                    onClick={async (evt) => {
                                                        evt.stopPropagation();
                                                        fileDeleteHandler(file, { isFolder, isEncodingInProgress });
                                                    }}
                                                />,
                                                isFolder ? 'file_manager_icon_delete_folder' : 'file_manager_icon_delete_file'
                                            )}
                                        </ActionsContainer>
                                    </FileManagerCardActions>
                                )}
                                {renderPreviewByExtensionType(file, true, fileId)}
                            </FileCardImageWrapper>
                            <FileCardDetailsHolder>
                                <FileCardDetails>
                                    <FileCardTitle>{file.name}</FileCardTitle>
                                    {file.isUploading && (
                                        <FileCancelUploadText onClick={() => handleCancelFileUploadClick(file.name)}>
                                            Cancel Upload
                                        </FileCancelUploadText>
                                    )}
                                    {!file.isUploading && (
                                        <FileCardDescription>
                                            <span> {bytesToSize(file.size)}</span> <span>{lastModified}</span>
                                        </FileCardDescription>
                                    )}
                                </FileCardDetails>
                                {!file.isUploading && videoExtensions.includes(fileExtension) && !isEncodeRestricted && (
                                    <VideoFileCardEncodeStatusHolder
                                        onClick={(evt) => {
                                            evt.stopPropagation();
                                            file.originalPath &&
                                                handleEncodeClick(file.name, filesPendingEncode?.[file.name] || file.encodeStatus);
                                        }}
                                    >
                                        {renderEncodeStatusIcon(
                                            filesPendingEncode?.[file.name] || file.encodeStatus || ENCODE_STATUSES.NOT_ENCODED
                                        )}
                                    </VideoFileCardEncodeStatusHolder>
                                )}
                            </FileCardDetailsHolder>
                        </FileManagerDraggableFile>
                    );
                })}
            </FilesGridContainer>
        );
    };

    const renderListView = () => {
        return <GenericTable columns={buildTableColumns()} body={buildTableRows()} />;
    };

    const renderFilters = () => {
        const mainFilters = [FILTER_TYPES.IMAGES, FILTER_TYPES.ALL_VIDEOS, FILTER_TYPES.OTHERS];
        const extraVideoFilterOptions = [
            { value: FILTER_TYPES.ALL_VIDEOS, label: 'All' },
            { value: FILTER_TYPES.ENCODED_VIDEOS, label: FILTER_TYPE_LABELS[FILTER_TYPES.ENCODED_VIDEOS] },
            { value: FILTER_TYPES.NOT_ENCODED_VIDEOS, label: FILTER_TYPE_LABELS[FILTER_TYPES.NOT_ENCODED_VIDEOS] }
        ];
        const isVideoFilterActive = [FILTER_TYPES.ALL_VIDEOS, FILTER_TYPES.ENCODED_VIDEOS, FILTER_TYPES.NOT_ENCODED_VIDEOS].includes(
            filter as any
        );
        return (
            <FileManagerFiltersContainer>
                <FileManagerFilterLabel
                    key={'all'}
                    active={!filter}
                    onClick={() => {
                        setFilter(undefined);
                    }}
                >
                    All
                </FileManagerFilterLabel>
                {mainFilters.map((f) => {
                    const active = f === FILTER_TYPES.ALL_VIDEOS ? isVideoFilterActive : f === filter;
                    return (
                        <FileManagerFilterLabel
                            key={f}
                            active={active}
                            onClick={() => {
                                setFilter(f);
                            }}
                        >
                            {FILTER_TYPE_LABELS[f]}
                        </FileManagerFilterLabel>
                    );
                })}
                <ExtraFilters>
                    {isVideoFilterActive && !isEncodeRestricted && (
                        <VideoFiltersSelectContainer>
                            <DialogDropdownSingle
                                onChange={(selectedOption: any) => {
                                    setFilter(selectedOption.value);
                                }}
                                placeholder={''}
                                options={extraVideoFilterOptions}
                                value={extraVideoFilterOptions.find((opt) => opt.value === filter)}
                                noLabel
                                noError
                            />
                        </VideoFiltersSelectContainer>
                    )}
                </ExtraFilters>
            </FileManagerFiltersContainer>
        );
    };

    const isEmpty = !parsedFiles.length;

    if (openInDialog) {
        const isGrid = view === 'GRID';
        return (
            <>
                {error && error.code !== FILE_EXISTS_CODE && <BackendErrorDialog error={error} />}
                <Breadcrumbs
                    path={currentFolder}
                    callback={(folderPath) => {
                        setCurrentFolder(folderPath);
                    }}
                    placeholder={'File Manager'}
                />
                {renderFilters()}
                <SearchBarContainer $extraWidth={isGrid}>
                    <SearchBar
                        title={'Search files'}
                        disabled={loading}
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        tooltipText={'file_manager_icon_search'}
                        onSearch={handleOnSearch}
                    />

                    <SortContainer $extraWidth={isGrid}>
                        {isGrid && (
                            <SortDropdown>
                                <DialogDropdownSingle
                                    value={sortOptions.find((option) => option.value === `${sortConfig.field}_${sortConfig.direction}`)}
                                    options={sortOptions}
                                    onChange={(value: any) => {
                                        const [field, direction] = value.value?.split('_');
                                        handleSortIconClick(field, direction);
                                    }}
                                    placeholder=""
                                    noLabel
                                    noError
                                />
                            </SortDropdown>
                        )}

                        <ToggleViewSwitch
                            checked={view === 'GRID'}
                            toggleCallback={() => {
                                setView(view === 'LIST' ? 'GRID' : 'LIST');
                            }}
                            tooltipTexts={{ list: 'menus_icon_switch_view_list', grid: 'menus_icon_switch_view_grid' }}
                        />
                    </SortContainer>
                </SearchBarContainer>
                {loading ? (
                    <Loader title={'File Manager'} />
                ) : (
                    <ScrollWrapper ref={fmWrapperRef} key={view}>
                        {view === 'LIST' ? renderListView() : renderGridView()}
                    </ScrollWrapper>
                )}
                <FileManagerDNDSuggestion />
                <FileManagerCustomDragLayer
                    onDragging={(isDragging) => {
                        setHideUploadZone(isDragging);
                    }}
                    scrollOnDrag={scrollOnDrag}
                />
                {!hideUploadZone && <UploadDropZone onDrop={onDrop} accept={'image/*'} inDialog />}
                <FileManagerButtonsWrapper>
                    <Button
                        label={'Create Folder'}
                        type={'DEFAULT'}
                        onClick={() => {
                            setOpenNewFileDialog(true);
                            setShowFolderDialog(true);
                        }}
                        responsive={!isDesktop}
                    />
                    <Button
                        label={'Add File'}
                        type="BLUE"
                        onClick={() => {
                            inputRef.current?.click();
                        }}
                        responsive={!isDesktop}
                    />
                </FileManagerButtonsWrapper>

                <input
                    id="filemanager-upload-input"
                    type="file"
                    onChange={(evt: any) => {
                        handleFileInputChange(evt);
                    }}
                    ref={inputRef}
                    hidden
                />

                <NewFolder
                    open={openNewFileDialog}
                    onClose={() => {
                        setFileToEdit(undefined);
                        setOpenNewFileDialog(false);
                        setShowFolderDialog(false);
                    }}
                    onSave={(value) => {
                        if (showFolderDialog) {
                            return createFolder(value);
                        }

                        if (!fileToEdit) return;
                        const { originalPath } = fileToEdit;
                        const newPath = `${currentFolder}/${value}`;
                        if (originalPath) {
                            setFileToUpdate({ originalPath, newPath });
                            editFile({ originalPath, newPath });
                        }
                    }}
                    file={fileToEdit}
                    isFolder={showFolderDialog}
                />
            </>
        );
    }

    return (
        <>
            {error && error.code !== FILE_EXISTS_CODE && <BackendErrorDialog error={error} />}
            <ApplicationWrapper>
                <Sidebar />
                <FileManagerContentWrapper noTransition>
                    <ScreenTitle
                        title={''}
                        breadcrumbsProps={{
                            path: currentFolder,
                            callback: (folderPath) => {
                                setCurrentFolder(folderPath);
                            },
                            placeholder: 'File Manager'
                        }}
                        addBreadcrumbs
                        loading={loading}
                        withAddButton
                        withProfile
                        addLabel={'Add File'}
                        onAdd={() => {
                            inputRef.current?.click();
                        }}
                        circlesSlugOptions={{ default: CIRCLE_SLUGS.file_manager, onboarding: ONBOARDING_CIRCLE_SLUGS.file_manager }}
                    />

                    <FilterAndSortContainer>
                        <SearchBarContainer $extraWidth={view === 'GRID'}>
                            <SearchBar
                                title={'Search files'}
                                disabled={loading}
                                searchTerm={searchTerm}
                                setSearchTerm={setSearchTerm}
                                tooltipText={'file_manager_icon_search'}
                                onSearch={handleOnSearch}
                            />

                            <SortContainer $extraWidth={view === 'GRID'}>
                                {view === 'GRID' && (
                                    <SortDropdown>
                                        <DialogDropdownSingle
                                            value={sortOptions.find(
                                                (option) => option.value === `${sortConfig.field}_${sortConfig.direction}`
                                            )}
                                            options={sortOptions}
                                            onChange={(value: any) => {
                                                const [field, direction] = value.value?.split('_');
                                                handleSortIconClick(field, direction);
                                            }}
                                            placeholder=""
                                            noLabel
                                            noError
                                        />
                                    </SortDropdown>
                                )}

                                <ToggleViewSwitch
                                    checked={view === 'GRID'}
                                    toggleCallback={() => {
                                        setView(view === 'LIST' ? 'GRID' : 'LIST');
                                    }}
                                    tooltipTexts={{ list: 'menus_icon_switch_view_list', grid: 'menus_icon_switch_view_grid' }}
                                />
                            </SortContainer>
                        </SearchBarContainer>

                        {renderFilters()}
                    </FilterAndSortContainer>

                    {loading || !projectName ? (
                        <Loader title={'File Manager'} />
                    ) : isEmpty ? (
                        <NoResourcesContainer>
                            <ResourceCard
                                image={resourceCardImages.fileManagerCard}
                                title={'Files and Folders'}
                                subtitle={'Drag and drop or choose from your browser'}
                                primaryButtonLabel={'Add File'}
                                secondaryButtonLabel={'Create Folder'}
                                onPrimaryButtonClick={() => {
                                    inputRef.current?.click();
                                }}
                                onSecondaryButtonClick={() => {
                                    setOpenNewFileDialog(true);
                                    setShowFolderDialog(true);
                                }}
                                createResource
                            />
                        </NoResourcesContainer>
                    ) : (
                        <>
                            {view === 'LIST' ? renderListView() : renderGridView()}
                            <FileManagerCustomDragLayer
                                onDragging={(isDragging) => {
                                    setHideUploadZone(isDragging);
                                }}
                                scrollOnDrag={scrollOnDrag}
                            />
                            {!openInDialog && (
                                <PageActionsWrapper>
                                    <PageActionButton
                                        onClick={() => {
                                            setOpenNewFileDialog(true);
                                            setShowFolderDialog(true);
                                        }}
                                        label={'Create Folder'}
                                        type={'DEFAULT'}
                                    />
                                    <PageActionButton
                                        onClick={() => {
                                            inputRef.current?.click();
                                        }}
                                        label={'Add File'}
                                        type={'BLUE'}
                                    />
                                </PageActionsWrapper>
                            )}
                        </>
                    )}

                    <NewFolder
                        open={openNewFileDialog}
                        onClose={() => {
                            setFileToEdit(undefined);
                            setOpenNewFileDialog(false);
                            setShowFolderDialog(false);
                        }}
                        onSave={(value) => {
                            if (showFolderDialog) {
                                return createFolder(value);
                            }

                            if (!fileToEdit) return;
                            const { originalPath } = fileToEdit;
                            const newPath = `${currentFolder}/${value}`;
                            if (originalPath) {
                                setFileToUpdate({ originalPath, newPath });
                                editFile({ originalPath, newPath });
                            }
                        }}
                        file={fileToEdit}
                        isFolder={showFolderDialog}
                    />

                    <FilePreview
                        open={openPreview}
                        onClose={() => {
                            setOpenPreview(false);
                            setFileToPreview(undefined);
                        }}
                        file={fileToPreview}
                    />
                    {!loading && <FileManagerDNDSuggestion />}
                </FileManagerContentWrapper>
                <input
                    id="filemanager-upload-input"
                    type="file"
                    onClick={(e: any) => {
                        // reset the input value to be able to trigger onChange event even if the same file path is selected
                        e.target.value = '';
                    }}
                    onChange={(evt: any) => {
                        handleFileInputChange(evt);
                    }}
                    ref={inputRef}
                    hidden
                />
                {!hideUploadZone && <UploadDropZone onDrop={onDrop} accept={'image/*'} />}
            </ApplicationWrapper>
        </>
    );
};

export default FileManager;

import styled, { CSSProperties } from 'styled-components';
import Card from '@material-ui/core/Card';
import Checkbox from '@material-ui/core/Checkbox';
import { withTheme } from '@material-ui/core';
import getTheme, { fontSizesCSS } from '../../style/themes/theme';
import logo from '../../assets/images/icons/logo-login-3Ready.svg';
import rightImage from '../../assets/images/login-right.png';
import { devices } from '../../style/styled-components/reusable.css';
import { DialogDoubleFieldWrapper } from '../common/Dialog/GenericDialog.css';
import { SelectWrapper } from '../common/Select/Select.css';

const theme: any = getTheme();

export const LoginInputFieldStyle: CSSProperties = {
    width: '334px'
};

export const RightContainer = styled.div<{ customBackground?: string }>`
    width: 50vw;
    padding: 10px;
    background-image: ${(props) => `url(${props.customBackground}), url(${rightImage})`};
    background-position: center;
    background-size: cover;
    box-sizing: border-box;
    ${devices.tablet} {
        display: none;
    }
`;

export const LeftContainer = styled(Card)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50vw;
    box-shadow: 0 3px 12px ${theme.palette.background.border};
    box-sizing: border-box;

    ${devices.tablet} {
        width: 100vw;
    }
`;

export const MainContainer = styled.div`
    min-height: 100vh;
    display: flex;
`;

export const Logo = styled.div`
    background: url(${logo}) center no-repeat;
    background-size: contain;
    width: 186px;
    height: 56px;
    margin-bottom: 36px;
`;

export const LoginActionsContainer = withTheme(styled.div`
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    width: 334px;
    height: 24px;
    flex: none;

    text-align: right;
    a {
        color: ${(props) => props.theme.palette.primary.main};
        text-decoration: none;
        ${fontSizesCSS.Caption1}
    }
`);

export const LoginCheckboxContainer = styled.div`
    width: 140px;
    height: 24px;

    text-align: left;
    .MuiFormControlLabel-root {
        margin: 0;
    }
    .MuiTypography-root {
        ${fontSizesCSS.Caption1}
        color: #333333;
        opacity: 0.7;
        margin-left: 10px;
    }
`;

export const LoginCheckbox = styled(Checkbox)`
    width: 24px;
    height: 24px;
    top: calc(50% - 24px / 2);
    border: 1px solid #e1e5e8;
    box-sizing: border-box;
    border-radius: 4px;
    color: ${theme.palette.white.main};
`;

export const LoginTextContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin-top: 24px;
`;

export const LoginDescription = styled.div`
    ${fontSizesCSS.Caption3}
    a {
        text-decoration: none;
        color: ${theme.palette.primary.main};
    }
`;

export const loginButtonStyle: CSSProperties = {
    padding: '6px 16px',
    width: '334px',
    height: '36px',
    borderRadius: '4px',
    margin: '48px 0px',
    fontWeight: 'bold',
    textTransform: 'capitalize'
};

export const sendOtpButtonStyle: CSSProperties = {
    padding: '6px 16px',
    width: '334px',
    height: '36px',
    borderRadius: '4px',
    margin: '48px 0px',
    fontWeight: 'bold',
    textTransform: 'capitalize'
};

export const sendForgotPasswordStyle: CSSProperties = {
    ...sendOtpButtonStyle,
    margin: '24px 0px 48px'
};

export const DoubleFieldWrapper = styled(DialogDoubleFieldWrapper)``;

export const ResendLabel = styled.label`
    ${fontSizesCSS.Caption3};
    color: ${theme.palette.primary.main};
    text-decoration: underline;
    cursor: pointer;
    margin-top: 8px;

    &:hover {
        color: ${theme.palette.primary.focused};
    }
`;

export const FormWrapper = styled.div`
    width: 334px;
    display: flex;
    flex-direction: column;
`;
export const FormTitle = styled.div`
    align-self: center;
    ${fontSizesCSS.Title3};
    margin-bottom: 24px;
`;
export const FormSubtitle = styled.div`
    ${fontSizesCSS.Body2};
`;
export const FormDescription = styled.div`
    ${fontSizesCSS.Body1};
    margin-bottom: 16px;
`;

export const TermsOfServiceButton = styled.div`
    color: ${theme.palette.primary.main};
    user-select: none;
    margin-top: 24px;
    cursor: pointer;
`;

export const ScrollableContainer = styled.div`
    position: relative;
    overflow: scroll;
    height: 100%;

    ::-webkit-scrollbar {
        display: none;
    }
`;

export const FlexContainer = styled.div`
    display: flex;
`;

export const FooterContainer = styled.div`
    background: ${theme.palette.grey.lighter};
    flex-direction: column;
    padding: 0 16px 16px;
    margin-top: 16px;
    display: flex;
`;

export const Text = styled.div`
    color: ${theme.palette.text.main};
    line-height: 24px;
    margin-top: 8px;
    font-size: 15px;
    opacity: 0.8;
`;

export const LinkText = styled.a`
    color: ${theme.palette.primary.main};
    text-decoration: none;
    line-height: 24px;
    margin-left: 4px;
    font-size: 15px;
    margin-top: 8px;
`;

export const Title = styled.div`
    color: ${theme.palette.text.main};
    font-weight: bold;
    margin-top: 16px;
`;

export const HighlightedText = styled.span`
    background: ${theme.palette.grey.lighter};
    color: ${theme.palette.black.main};
    border-radius: 4px;
    font-weight: bold;
    padding: 2px 4px;
`;

export const BoldText = styled.span`
    color: ${theme.palette.black.main};
    font-weight: bold;
`;

export const GradientBottom = styled.div`
    background: linear-gradient(to top, rgba(249, 250, 250, 1), rgba(249, 250, 250, 0));
    position: sticky;
    height: 60px;
    width: 100%;
    bottom: 0;
`;

import { Radio, TableRow, Tooltip } from '@material-ui/core';
import React, { FC, RefObject, useEffect, useRef, useState } from 'react';
import SVGInline from 'react-inlinesvg';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../hooks/redux';
import {
    ColorCard,
    ColorName,
    ColorsContainer,
    ColorsContainerValues,
    ColorValue,
    ImageFieldContainer,
    ImageFields,
    ImageHeader,
    PageStyleArrowsContainer,
    PageStyleSectionTitle,
    PageStyleTogglesContainer,
    StyleDefault,
    StyleFieldsContainer,
    StyleViewLastModified,
    StyleViewName
} from './PageStyle.css';
import icons from '../../../assets/images/icons';
import {
    changePageStyleDefault,
    createPageStyle,
    deletePageStyle,
    fetchContentMarkers,
    fetchPageStyles,
    PageStylesState,
    unsetPageStyles,
    updatePageStyle
} from '../../../redux/slices/pageStylesSlice';
import { ActiveItemState } from '../../../redux/slices/activeItemSlice';
import { setUserPermissions } from '../../../redux/slices/permissionsSlice';
import { Loader } from '../../common/Loader/Loader';
import { generateDateStringForTables } from '../../../utils/fnDate';
import { availableColors, defaultColorValues, PageStyle as PageStyleModel } from '../../../types/PageStyle';
import CustomColorPicker from '../../common/ColorPicker/CustomColorPicker';
import _ from 'lodash';
import {
    DialogCheckbox,
    DialogDropdownMultiple,
    DialogFileField,
    DialogTextField,
    DialogToggleButton,
    ImageInfoType
} from '../../common/Dialog/GenericDialog';
import PageActions from '../../common/PageActions/PageActions';
import { useNavigate } from 'react-router-dom';
import { supportedResolutions, supportedResolutionsType } from '../../Items/Dialogs/BackgroundDialog';
import { API_ERROR_CODES, EMPTY_WORD_STRING, getImgixUrl, imgixBaseUrl, imgixDefaultQuery, imgixFMBaseUrl } from '../../../utils/Globals';
import { DIALOG_NAMES, dialogConfirm } from '../../../utils/fnDialogs';
import { RemoveModuleWrapper } from '../../Modules/Modules.css';
import { renderTooltipWithKey } from '../../common/Tooltips/Tooltips';
import BackendErrorDialog from '../../common/Dialog/BackendErrorDialog';
import { ContentMarkerField, ContentMarkerName, ContentMarkersContainer, ContentMarkersHeader } from '../AppBranding/AppBranding.css';

import { MoreInfoTypes } from '../../common/Dialog/MoreInfoDialog';
import useBlockNavigation from '../../../hooks/useBlockNavigation';
import useScreenSize from '../../../hooks/useScreenSize';
import { FilesState, uploadFilesSync } from '../../../redux/slices/fileManagerSlice';
import { extractFileNameFromAzureURL, getSearchParam, isOpenFileUrl } from '../../../utils/fnUrl';
import { SearchBar } from '../../common/SearchBar/SearchBar';
import { buildPathWithProjectId, PageRoutes } from '../../../types/RouteTypes';
import ContentMarkersDialog from '../AppBranding/Dialogs/ContentMarkers';
import {
    NoResourcesContainer,
    PageActionButton,
    PageActionsWrapper,
    PlacedInPublishedWarningMessageWrapper,
    TruncatedText
} from '../../../style/styled-components/reusable.css';
import useLockSystem, { LockableObjectTypes } from '../../../hooks/useLockSystem';
import { renderLockedError, renderLockedWarningAlert, renderLockIcon } from '../../../utils/fnLockingSystem';
import { templateTypes } from '../../../types/Template';
import TemplateSelection from '../../Pages/Dialogs/TemplateSelection';
import CreateResourceDialog from '../../common/Dialog/CreateResourceDialog';
import { applyTemplate, templatesState } from '../../../redux/slices/templatesSlice';
import { ObjectActions } from '../../common/Actions/Actions';
import GenericTable, { HeaderTableCell, SortableHeaderTableCell, tableActions } from '../../common/Table/Table';
import { PageStylesTableSizes } from '../../../types/TableSizes';
import { TableRowWrapper, WidthTableCell } from '../../common/Table/Table.css';
import Labels from '../../common/Labels/Labels';
import { ScrollableTableRowWrapper } from '../../Menus/Menus';
import { ResourceCard } from '../../Cards/ResourceCard/ResourceCard';
import { resourceCardImages } from '../../../assets/images/resourceCards';
import { openDocumentation } from '../../../utils/parsers';
import circleSlugs from '../../../utils/circle_slugs.json';
import {
    ACCEPTED_SORT_FIELDS,
    AcceptedSortField,
    calculateOrderByFromSortConfig,
    DEFAULT_SORT_CONFIG,
    ISortConfig
} from '../../../utils/fnSort';
import { SearchBarContainer } from '../../common/SearchBar/SearchBar.css';
import { ToggleButtonContainer } from '../../common/Dialog/GenericDialog.css';
import { shouldScroll } from '../../../utils/fnScroll';

export const PAGE_STYLE_ADD_BUTTON_ID = 'PAGE_STYLE_ADD_BUTTON_ID';

export const acceptedExtensionsPageLogo = ['.png', '.jpg', '.svg'];
export const acceptedExtensionsPageBackground = ['.png', '.jpg', '.svg', '.mp4', '.avi'];
export const pageStyleImageInfo: ImageInfoType = { previewImage: true, height: 108, width: 188, um: 'px' };
export const handleImages = async (
    imgFiles: { pageLogo: supportedResolutionsType; pageBackgroundImage: supportedResolutionsType },
    imgUrls: { pageLogo: supportedResolutionsType; pageBackgroundImage: supportedResolutionsType },
    applyForAll: { pageLogo?: boolean; pageBackgroundImage?: boolean },
    initialImages: any,
    createFiles: (files: File[], index: number) => Promise<string[]>
) => {
    const result = [];
    let index = 0;
    for (const key in imgFiles) {
        let imgixUrls: any = {};
        const imageUrls = _.get(imgUrls, key);
        const imageUrl = `${Object.values(imageUrls).find((elem) => elem) || ''}`;
        if (imageUrl) {
            // if there is an imageUrl, it means we are not uploading files but picking urls
            if (_.get(applyForAll, index === 0 ? 'pageLogo' : 'pageBackgroundImage')) {
                const isOpenUrl = isOpenFileUrl(imageUrl);
                Object.values(supportedResolutions).forEach((value, index) => {
                    if (isOpenUrl) {
                        // if it's openUrl, just replicate the same url for each key
                        imgixUrls[Object.keys(supportedResolutions)[index]] = encodeURIComponent(imageUrl);
                        return;
                    }
                    const [width, height] = value.split('x');
                    const query = `&width=${width}&height=${height}`;
                    // if picked by the FM, it already is a imgix url so just append the width/height query
                    imgixUrls[Object.keys(supportedResolutions)[index]] = encodeURIComponent(`${imageUrl}${query}`);
                });
                result.push(imgixUrls);
                index++;
                continue;
            }
            result.push({ ...(initialImages?.[key] || {}), ...imageUrls });
            index++;
            continue;
        }
        const files = _.get(imgFiles, key);
        const imagesToUpload = Object.values(files).filter((image): image is File => typeof image !== 'undefined');
        if (!imagesToUpload.length) {
            // if there is not value for a key, push it's initial value or null
            const key = index === 0 ? 'pageLogo' : 'pageBackgroundImage';
            result.push(initialImages?.[key] || {});
            index++;
            continue;
        }

        const urls = await createFiles(imagesToUpload, index);
        if (!urls?.length) return null;
        if (applyForAll?.[index === 0 ? 'pageLogo' : 'pageBackgroundImage']) {
            // if apply for all, generate imgix urls for the save picture, but use width and height in query
            const fileName = extractFileNameFromAzureURL(urls[0]);
            Object.values(supportedResolutions).forEach((value, index) => {
                const [width, height] = value.split('x');
                const query = `${imgixDefaultQuery}&width=${width}&height=${height}`;
                imgixUrls[Object.keys(supportedResolutions)[index]] = encodeURIComponent(getImgixUrl(fileName, query, true));
            });
        } else {
            Object.keys(files).forEach((key, index) => {
                // if not apply for all, still generate imgix url, but the resolution is kept as inputed
                const fileName = extractFileNameFromAzureURL(urls[index]);
                imgixUrls[key] = encodeURIComponent(getImgixUrl(fileName, undefined, true));
            });
        }
        index++;
        result.push({ ...(initialImages?.[key] || {}), ...imgixUrls });
    }
    return result;
};

export const PageStyleStripeArrows: FC<{ containerRef: RefObject<HTMLDivElement> }> = ({ containerRef }) => {
    const timeoutRef = useRef<any>(null);
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            const checkShouldScroll = () => {
                setIsVisible(shouldScroll(container));
            };
            checkShouldScroll();
            // check changes (i.e: toggling apply for all)
            const mutationObserver = new MutationObserver(checkShouldScroll);
            mutationObserver.observe(container, { childList: true, subtree: true });
            return () => mutationObserver.disconnect();
        }
    }, [containerRef.current?.scrollWidth, containerRef.current?.clientWidth]);

    const moveLeft = (value: number) => {
        if (containerRef.current) {
            containerRef.current.scrollLeft -= value;
        }
    };

    const moveRight = (value: number) => {
        if (containerRef.current) {
            containerRef.current.scrollLeft += value;
        }
    };
    if (!isVisible) return null;
    return (
        <PageStyleArrowsContainer>
            <SVGInline
                src={icons.arrowRightIcon}
                onMouseDown={() => {
                    timeoutRef.current = setInterval(function () {
                        moveLeft(20);
                    }, 100);
                }}
                onMouseUp={() => {
                    clearInterval(timeoutRef.current);
                    timeoutRef.current = null;
                }}
                onClick={() => {
                    moveLeft(20);
                }}
            />
            <SVGInline
                src={icons.arrowRightIcon}
                onMouseDown={() => {
                    timeoutRef.current = setInterval(function () {
                        moveRight(20);
                    }, 100);
                }}
                onMouseUp={() => {
                    clearInterval(timeoutRef.current);
                    timeoutRef.current = null;
                }}
                onClick={() => {
                    moveRight(20);
                }}
            />
        </PageStyleArrowsContainer>
    );
};

const PageStyle: FC = () => {
    const [creatingStyle, setCreatingStyle] = useState(false);
    const [openTemplateSelectionDialog, setOpenTemplateSelectionDialog] = useState(false);
    const [openNewResourceDialog, setOpenNewResourceDialog] = useState(false);
    // Hold the PageStyle object which is being worked on
    const [pageStyleToEdit, setPageStyleToEdit] = useState<any>({});
    const [redirectStyleId, setRedirectStyleId] = useState<string>('');

    // Tracks whether the top values can be edited or not
    const [isEditing, setIsEditing] = useState(false);

    const [applyForAll, setApplyForAll] = useState<{ pageLogo?: boolean; pageBackgroundImage?: boolean }>({
        pageLogo: true,
        pageBackgroundImage: true
    });

    const [imageFiles, setImageFiles] = useState<{ pageLogo: supportedResolutionsType; pageBackgroundImage: supportedResolutionsType }>({
        pageLogo: {},
        pageBackgroundImage: {}
    });
    const [imageUrls, setImageUrls] = useState<{ pageLogo: supportedResolutionsType; pageBackgroundImage: supportedResolutionsType }>({
        pageLogo: {},
        pageBackgroundImage: {}
    });

    const [initialImages, setInitialImages] = useState<
        | {
              pageLogo: supportedResolutionsType;
              pageBackgroundImage: supportedResolutionsType;
          }
        | undefined
    >(undefined);
    const [showUnsaved, setShowUnsaved] = useState<boolean>(false);

    //CONTENT_MARKERS
    const [openContentMarkersDialog, setOpenContentMarkersDialog] = useState(false);
    const [contentMarkerToEdit, setContentMarkerToEdit] = useState('');

    const { activeProjectId, activeTenantId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const { pageStyles: storePageStyles, error, loading, contentMarkers }: PageStylesState = useAppSelector((state) => state.pageStyles);
    const [newDefaultStyleId, setNewDefaultStyleId] = useState<string>('');
    const { error: imageError }: FilesState = useAppSelector((state) => state.files);
    const { error: templatesError }: templatesState = useAppSelector((state) => state.templates);

    const colorsContainerRef = useRef<HTMLDivElement>(null);
    const logoContainerRef = useRef<HTMLDivElement>(null);
    const backgroundContainerRef = useRef<HTMLDivElement>(null);

    const { isDesktop, isMobile, isLargeDesktop } = useScreenSize();

    // PAGINATION, SEARCH AND FILTERING/SORTING RELATED FIELDS
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const [sortConfig, setSortConfig] = useState<ISortConfig>(DEFAULT_SORT_CONFIG);
    const [showSortArrows, setShowSortArrows] = useState<boolean>(false);
    const [activeSortingKey, setActiveSortingKey] = useState<AcceptedSortField>(ACCEPTED_SORT_FIELDS.lastModified);

    const orderBy = `${sortConfig.field}[${sortConfig.direction}]`;

    // Locking
    const { lock, unlock, unlockOnClose, isObjectLocked, objectIsLockedBy } = useLockSystem(LockableObjectTypes.PAGE_STYLES);

    const lockPageStyle = (id: string) => {
        lock(id);
        unlockOnClose(id);
    };

    const unsavedDependencyArray = [creatingStyle, storePageStyles, newDefaultStyleId, pageStyleToEdit, applyForAll];

    // Blocking navigation when there are unsaved changes
    useBlockNavigation(showUnsaved, () => renderAlertUnsavedChanges(), unsavedDependencyArray);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleCancelClick = () => {
        pageStyleToEdit?._id && unlock(pageStyleToEdit._id);
        setIsEditing(false);
        setCreatingStyle(false);
        setPageStyleToEdit(undefined);
        setNewDefaultStyleId('');
        setInitialImages(undefined);
        setRedirectStyleId('');
    };

    const handleResetFilters = () => {
        setSearchTerm(undefined);
        setShowSortArrows(false);
        setSortConfig(DEFAULT_SORT_CONFIG);
        setActiveSortingKey(ACCEPTED_SORT_FIELDS.lastModified);
    };

    useEffect(() => {
        if (!activeProjectId) return;
        if (storePageStyles.length) {
            dispatch(unsetPageStyles());
        }
        handleResetFilters();
        loadPageStyles(true, activeProjectId, calculateOrderByFromSortConfig(DEFAULT_SORT_CONFIG)).then((response: any) => {
            if (response?.permissions) {
                dispatch(setUserPermissions(response.permissions));
            }
            if (!contentMarkers) loadContentMarkers();
        });

        handleCancelClick();
        // Used to open editor in new tab
        setRedirectStyleId(getSearchParam('id') || '');
    }, [activeProjectId]);

    useEffect(() => {
        setShowUnsaved(calculateShowUnsaved());
    }, [...unsavedDependencyArray]);

    useEffect(() => {
        if (!storePageStyles.length || isEditing) return;

        if (redirectStyleId) {
            setIsEditing(true);
            expandPageStyle(redirectStyleId);
        }
    }, [storePageStyles]);

    const handleOnSearch = (searchTerm: string) => {
        loadPageStyles(true, activeProjectId, orderBy, searchTerm);
    };

    const expandPageStyle = (id: string) => {
        if (id === pageStyleToEdit?._id) {
            unlock(id);
            setIsEditing(false);
            setPageStyleToEdit(undefined);
            setInitialImages(undefined);
            return;
        }
        const pageStyle = storePageStyles?.find((ps) => ps._id === id);
        lockPageStyle(id);
        setPageStyleToEdit({
            ..._.cloneDeep(pageStyle),
            placed: pageStyle?.pages?.map((p) => p.pageId)
        });
        setApplyForAll({
            pageBackgroundImage: calculateApplyForAll(pageStyle, 'pageBackgroundImage'),
            pageLogo: calculateApplyForAll(pageStyle, 'pageLogo')
        });
        setInitialImages({ pageLogo: pageStyle?.pageLogo || {}, pageBackgroundImage: pageStyle?.pageBackgroundImage || {} });
    };

    const calculateApplyForAll = (style: any, key: string) => {
        const values: any[] = Object.values({ ...style[key] });
        if (!values.length) return true;
        const firstUrl = decodeURIComponent(values[0]);
        const isOpenUrl = isOpenFileUrl(firstUrl);
        const firstFileName = !isOpenUrl
            ? firstUrl.split(values[0].includes(imgixBaseUrl) ? imgixBaseUrl : imgixFMBaseUrl)[1].split('?')[0]
            : firstUrl.split('/').pop();
        return (
            !values
                .filter((_, index) => index > 0)
                .some((value) => {
                    const url = decodeURIComponent(value);
                    const isOpenUrl = isOpenFileUrl(url);
                    const fileName = !isOpenUrl
                        ? url.split(value.includes(imgixBaseUrl) ? imgixBaseUrl : imgixFMBaseUrl)[1].split('?')[0]
                        : url.split('/').pop();
                    return fileName !== firstFileName;
                }) && values.length === 4
        );
    };

    const handleUpdateDefaultStyle = async () => {
        await modifyDefaultStyle(newDefaultStyleId);
        setNewDefaultStyleId('');
    };

    const handleSave = async () => {
        if (newDefaultStyleId) {
            handleUpdateDefaultStyle();
            return;
        }

        let newStyle: any = {
            _id: pageStyleToEdit?._id === '-1' ? '' : pageStyleToEdit?._id,
            name: pageStyleToEdit.name || '',
            tenantId: activeTenantId,
            projectId: activeProjectId
        };
        const imagesResult = await handleImages(imageFiles, imageUrls, applyForAll, initialImages, createFiles);
        if (imagesResult !== null) {
            imagesResult.forEach((result, i) => {
                const key = i === 0 ? 'pageLogo' : 'pageBackgroundImage';
                const value = Object.assign({ ...newStyle[key] }, { ...result });
                _.set(newStyle, key, value);
            });
        } else return;

        if (Object.keys(pageStyleToEdit || {}).length) {
            newStyle = Object.assign(newStyle, _.pick(pageStyleToEdit, ['colors', 'increasedTopMargin', 'contentMarkers', 'lastModified']));
        }
        if (pageStyleToEdit?._id !== '-1') {
            newStyle._id = pageStyleToEdit?._id;
            if (!Object.keys(pageStyleToEdit || {}).length) {
                newStyle = Object.assign(
                    newStyle,
                    _.pick(
                        storePageStyles.find((s) => s._id === pageStyleToEdit?._id),
                        ['colors', 'increasedTopMargin', 'contentMarkers', 'lastModified']
                    )
                );
            }
            newStyle.placed = pageStyleToEdit?.placed;
            modifyPageStyle(newStyle);
        } else {
            savePageStyle(newStyle);
        }
        handleCancelClick();
    };

    const renderAlertUnsavedChanges = () => {
        dialogConfirm(
            DIALOG_NAMES.UNSAVED_CHANGES,
            () => {
                handleSave();
            },
            null,
            null,
            {
                noButton: { label: 'Discard Changes' },
                confirmButton: { label: 'Save' }
            },
            { warningIcon: true },
            () => {
                handleCancelClick();
            },
            true
        );
    };

    const handleCreateClick = () => {
        setCreatingStyle(true);
        pageStyleToEdit?._id && unlock(pageStyleToEdit._id);
        setIsEditing(true);
        setPageStyleToEdit({ _id: '-1' });
        setApplyForAll({
            pageBackgroundImage: true,
            pageLogo: true
        });
    };

    const toggleArrows = () => {
        setShowSortArrows(!showSortArrows);
    };
    const handleSortIconClick = (field: AcceptedSortField) => {
        setActiveSortingKey(field);
        let direction: 'asc' | 'desc' = 'asc';
        if (sortConfig && sortConfig.field === field && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        const config = {
            field,
            direction
        };
        setSortConfig(config);
        const orderBy = `${config.field}[${config.direction}]`;
        loadPageStyles(true, activeProjectId || '', orderBy, searchTerm);
        setShowSortArrows(false);
    };

    const handleDelete = (id: string) => {
        const values = {
            title: 'Delete Page Style',
            text: ''
        };

        dialogConfirm(
            '',
            async () => {
                await removePageStyle(id);
                loadPageStyles(false, activeProjectId, orderBy, searchTerm);
            },
            values,
            <RemoveModuleWrapper>
                <p>
                    <strong>Are you sure you want to remove this Page Style?</strong>
                    By Pressing “Remove” you still will be able to create new one!
                    <PlacedInPublishedWarningMessageWrapper>
                        If the Page Style is placed in a published page, it will also be removed from it, and replaced with the Default one!
                    </PlacedInPublishedWarningMessageWrapper>
                </p>
            </RemoveModuleWrapper>,
            {
                noButton: {
                    label: 'Cancel'
                },
                confirmButton: {
                    label: 'Remove'
                }
            },
            { warningIcon: true },
            undefined,
            true
        );
    };

    const resetImageKeys = (parentKey: 'pageLogo' | 'pageBackgroundImage') => {
        let newImages: any = { ...imageFiles };
        let newStyle: any = { ...pageStyleToEdit };
        let newUrls: any = { ...imageUrls };
        let newInitialImages: any = { ...initialImages };
        newImages = _.set(newImages, parentKey, {});
        newStyle = _.set(newStyle, parentKey, {});
        newUrls = _.set(newUrls, parentKey, {});
        newInitialImages = _.set(newInitialImages, parentKey, {});

        // when there is no background image selected the increasedTopMargin values should be false
        if (parentKey === 'pageBackgroundImage') {
            newStyle.increasedTopMargin = false;
        }
        setImageFiles(newImages);
        setPageStyleToEdit(newStyle);
        setImageUrls(newUrls);
        setInitialImages(newInitialImages);
    };

    const loadPageStyles = async (addPermissions?: boolean, projectId?: string, orderBy?: string, searchTerm?: string) => {
        if (storePageStyles.length && getSearchParam('id')) {
            navigate(buildPathWithProjectId(activeProjectId, PageRoutes.PAGE_STYLES));
        }
        return await dispatch(fetchPageStyles({ addPermissions, projectId, orderBy, searchTerm })).unwrap();
    };
    const loadContentMarkers = async () => {
        return await dispatch(fetchContentMarkers()).unwrap();
    };
    const modifyPageStyle = async (newStyle: PageStyleModel) => {
        const response = await dispatch(updatePageStyle({ pageStyle: newStyle, shouldUnlockAfterSave: true })).unwrap();
        if (response.ok) {
            loadPageStyles(false, activeProjectId, orderBy, searchTerm);
        }
    };

    const useTemplate = async (id: string) => {
        const result = await dispatch(
            applyTemplate({
                templateId: id,
                type: templateTypes.PAGE_STYLE,
                projectId: `${activeProjectId}`,
                tenantId: `${activeTenantId}`
            })
        ).unwrap();
        if (result.id) {
            loadPageStyles(false, activeProjectId || '');
            handleResetFilters();
        }
    };

    const savePageStyle = async (newStyle: PageStyleModel) => {
        const response = await dispatch(createPageStyle(newStyle)).unwrap();
        if (response.id) {
            loadPageStyles(false, activeProjectId);
            handleResetFilters();
        }
    };

    const removePageStyle = async (id: string) => {
        await dispatch(deletePageStyle(id)).unwrap();
    };

    const modifyDefaultStyle = async (id: string) => {
        const response = await dispatch(changePageStyleDefault({ id })).unwrap();
        response && loadPageStyles(false, activeProjectId, orderBy, searchTerm);
    };

    const createFiles = async (files: File[]) => {
        const prefix = `${activeProjectId}/pageStyle_backgrounds`;
        try {
            const response = await dispatch(uploadFilesSync({ files, prefix, overwrite: true })).unwrap();
            return response.urls;
        } catch (ex) {
            return [];
        }
    };

    const handleImageSelect = (
        image: File | string,
        parentKey: 'pageLogo' | 'pageBackgroundImage',
        key: keyof supportedResolutionsType
    ) => {
        let newImages: any = { ...imageFiles };
        let newUrls: any = { ...imageUrls };
        let newStyleObject: any = { ...pageStyleToEdit };
        let newInitialImages: any = { ...initialImages };
        if (!image) {
            if (applyForAll[parentKey]) {
                newImages = _.set(newImages, parentKey, {});
                newStyleObject = _.set(newStyleObject, parentKey, {});
                newUrls = _.set(newUrls, parentKey, {});
                newInitialImages = _.set(newInitialImages, parentKey, {});
            } else {
                newImages[parentKey] = _.omit(newImages[parentKey], key);
                newStyleObject[parentKey] = _.omit(newStyleObject[parentKey], key);
                newUrls[parentKey] = _.omit(newUrls[parentKey], key);
                newInitialImages[parentKey] = _.omit(newInitialImages[parentKey], key);
            }
        } else {
            if (typeof image === 'string') {
                const isOpenUrl = !image.includes(`${activeProjectId}`);
                _.set(newUrls, `${parentKey}.${key}`, isOpenUrl ? image : getImgixUrl(image, undefined, true));
                _.set(newImages, `${parentKey}.${key}`, isOpenUrl ? image : getImgixUrl(image, undefined, true));
                _.set(newStyleObject, `${parentKey}.${key}`, isOpenUrl ? image : getImgixUrl(image, undefined, true));
            } else {
                _.set(newImages, `${parentKey}.${key}`, image);
                _.set(newStyleObject, `${parentKey}.${key}`, image);
            }
        }

        // when there is no background image selected the increasedTopMargin values should be false
        if (!Object.keys(newStyleObject?.pageBackgroundImage || {}).length) {
            newStyleObject = { ...newStyleObject, increasedTopMargin: false };
        }
        setImageFiles(newImages);
        setPageStyleToEdit(newStyleObject);
        setImageUrls(newUrls);
        setInitialImages(newInitialImages);
    };

    const calculateShowUnsaved = () => {
        if (creatingStyle) return true;
        if (newDefaultStyleId) return true;
        if (!pageStyleToEdit) return false;
        let oldPageStyle = { ...storePageStyles.find((ps) => ps._id === pageStyleToEdit?._id) };
        // calculate "placed" key for oldPageStyle
        if (oldPageStyle) {
            _.set(oldPageStyle, 'placed', oldPageStyle?.pages?.map((p) => p.pageId)?.sort());
        }
        _.set(pageStyleToEdit, 'placed', pageStyleToEdit?.placed?.sort());
        return !_.isEqual(oldPageStyle, pageStyleToEdit);
    };

    const renderCreatingStyle = () => {
        const expanded = pageStyleToEdit?._id === '-1';

        return (
            <>
                <ScrollableTableRowWrapper shouldScroll={creatingStyle} data-cy={'page-style-container-create'}>
                    <WidthTableCell $um={'px'} colSpan={4}>
                        <StyleFieldsContainer>
                            <DialogTextField
                                label={'Page Style Name'}
                                value={pageStyleToEdit?.name || ''}
                                onChange={(evt: any) => {
                                    setPageStyleToEdit({ ...pageStyleToEdit, name: evt.target.value });
                                }}
                                dataCy={'page-style-name-input'}
                            />
                        </StyleFieldsContainer>
                    </WidthTableCell>
                    <WidthTableCell $um={'px'}>
                        <ObjectActions
                            actions={[tableActions.REMOVE]}
                            withArrow
                            onArrowToggle={() => {
                                showUnsaved ? renderAlertUnsavedChanges() : handleCancelClick();
                            }}
                            open={expanded}
                            onRemove={() => {
                                showUnsaved ? renderAlertUnsavedChanges() : handleCancelClick();
                            }}
                            tooltipTexts={{
                                arrowClose: 'page_style_icon_hide',
                                arrowOpen: 'page_style_icon_show',
                                delete: 'page_style_icon_delete'
                            }}
                        />
                    </WidthTableCell>
                </ScrollableTableRowWrapper>

                {/* PageStyle TABLE ROW */}
                {expanded && (
                    <>
                        <TableRowWrapper style={{ background: 'transparent' }}>
                            <WidthTableCell style={{ padding: 24, background: 'rgba(0,0,0,0.06)', maxWidth: 0 }} colSpan={5} $um={'px'}>
                                {renderExpandedPageStyle()}
                            </WidthTableCell>
                        </TableRowWrapper>
                    </>
                )}
            </>
        );
    };

    const renderExpandedPageStyle = (locked = false, lockedBy = '') => {
        return (
            <div>
                <ColorsContainer>
                    <PageStyleSectionTitle>
                        <ImageHeader>Colors</ImageHeader>
                        <PageStyleStripeArrows containerRef={colorsContainerRef} />
                    </PageStyleSectionTitle>
                    <ColorsContainerValues ref={colorsContainerRef}>
                        {availableColors.map((color, i) => (
                            <ColorCard key={`color_${i}`}>
                                <ColorName>{_.capitalize(color.split(/(?=[A-Z])/).join(' '))}</ColorName>
                                <CustomColorPicker
                                    value={pageStyleToEdit?.colors?.[color] || (defaultColorValues as any)[color]}
                                    onChange={(alphaHex: string) => {
                                        setPageStyleToEdit({
                                            ...pageStyleToEdit,
                                            colors: {
                                                ...pageStyleToEdit?.colors,
                                                [color]: alphaHex
                                            }
                                        });
                                    }}
                                    onClick={() => {
                                        if (locked) {
                                            return renderLockedWarningAlert(lockedBy);
                                        }
                                    }}
                                    disabled={locked}
                                />
                                <ColorValue>{pageStyleToEdit?.colors?.[color] || (defaultColorValues as any)[color]}</ColorValue>
                            </ColorCard>
                        ))}
                    </ColorsContainerValues>
                </ColorsContainer>

                <ImageFieldContainer>
                    <PageStyleSectionTitle>
                        <ImageHeader>
                            Page Background
                            {renderTooltipWithKey(<SVGInline src={icons.infoIcon} />, 'style_and_branding_background_image')}
                        </ImageHeader>
                        <PageStyleStripeArrows containerRef={backgroundContainerRef} />
                    </PageStyleSectionTitle>
                    <ImageFields ref={backgroundContainerRef}>
                        <DialogFileField
                            imageInfo={pageStyleImageInfo}
                            customAddIcon={icons.addIconWhite}
                            preview={pageStyleToEdit?.pageBackgroundImage?.bigScreen}
                            fieldKey={'Big Screen (1920x1080)'}
                            customLabel={applyForAll.pageBackgroundImage ? 'All Screens' : 'Big Screen (1920x1080)'}
                            localFileCallBack={(file) => handleImageSelect(file, 'pageBackgroundImage', 'bigScreen')}
                            fileManagerCallback={(url: string) => handleImageSelect(url, 'pageBackgroundImage', 'bigScreen')}
                            openUrlCallback={(url: string) => handleImageSelect(url, 'pageBackgroundImage', 'bigScreen')}
                            alwaysShowLabel
                            locked={locked}
                            lockedBy={lockedBy}
                            accepts={acceptedExtensionsPageBackground}
                        />
                        {!applyForAll.pageBackgroundImage && (
                            <>
                                <DialogFileField
                                    imageInfo={pageStyleImageInfo}
                                    customAddIcon={icons.addIconWhite}
                                    preview={pageStyleToEdit?.pageBackgroundImage?.pc}
                                    fieldKey={'PC (1280x720)'}
                                    customLabel={'PC (1280x720)'}
                                    localFileCallBack={(file) => handleImageSelect(file, 'pageBackgroundImage', 'pc')}
                                    fileManagerCallback={(url: string) => handleImageSelect(url, 'pageBackgroundImage', 'pc')}
                                    openUrlCallback={(url: string) => handleImageSelect(url, 'pageBackgroundImage', 'pc')}
                                    alwaysShowLabel
                                    locked={locked}
                                    lockedBy={lockedBy}
                                    accepts={acceptedExtensionsPageBackground}
                                />
                                <DialogFileField
                                    imageInfo={pageStyleImageInfo}
                                    customAddIcon={icons.addIconWhite}
                                    preview={pageStyleToEdit?.pageBackgroundImage?.tablet}
                                    fieldKey={'Tablet (960x540)'}
                                    customLabel={'Tablet (960x540)'}
                                    localFileCallBack={(file) => handleImageSelect(file, 'pageBackgroundImage', 'tablet')}
                                    fileManagerCallback={(url: string) => handleImageSelect(url, 'pageBackgroundImage', 'tablet')}
                                    openUrlCallback={(url: string) => handleImageSelect(url, 'pageBackgroundImage', 'tablet')}
                                    alwaysShowLabel
                                    locked={locked}
                                    lockedBy={lockedBy}
                                    accepts={acceptedExtensionsPageBackground}
                                />
                                <DialogFileField
                                    imageInfo={pageStyleImageInfo}
                                    customAddIcon={icons.addIconWhite}
                                    preview={pageStyleToEdit?.pageBackgroundImage?.mobile}
                                    fieldKey={'Mobile (360x202)'}
                                    customLabel={'Mobile (360x202)'}
                                    localFileCallBack={(file) => handleImageSelect(file, 'pageBackgroundImage', 'mobile')}
                                    fileManagerCallback={(url: string) => handleImageSelect(url, 'pageBackgroundImage', 'mobile')}
                                    openUrlCallback={(url: string) => handleImageSelect(url, 'pageBackgroundImage', 'mobile')}
                                    alwaysShowLabel
                                    locked={locked}
                                    lockedBy={lockedBy}
                                    accepts={acceptedExtensionsPageBackground}
                                />
                            </>
                        )}
                    </ImageFields>
                    <PageStyleTogglesContainer>
                        <ToggleButtonContainer>
                            <DialogToggleButton
                                checked={!!applyForAll?.pageBackgroundImage}
                                toggleCallback={() => {
                                    if (locked) {
                                        return renderLockedWarningAlert(lockedBy);
                                    }
                                    setApplyForAll({
                                        ...applyForAll,
                                        pageBackgroundImage: !applyForAll?.pageBackgroundImage
                                    });
                                    resetImageKeys('pageBackgroundImage');
                                }}
                                text={'Apply for all screens'}
                                tooltipText={applyForAll?.pageBackgroundImage ? 'apply_for_all_on' : 'apply_for_all_off'}
                            />
                        </ToggleButtonContainer>

                        <DialogCheckbox
                            active={pageStyleToEdit.increasedTopMargin}
                            value={pageStyleToEdit.increasedTopMargin}
                            onClick={() => {
                                setPageStyleToEdit({
                                    ...pageStyleToEdit,
                                    increasedTopMargin: !pageStyleToEdit.increasedTopMargin
                                });
                            }}
                            text={'Increased Top Margin'}
                            tooltipText={locked ? 'style_and_branding_increased_margin_locked' : 'style_and_branding_increased_margin'}
                            disabled={locked || !Object.keys(pageStyleToEdit?.pageBackgroundImage || {}).length}
                        />
                    </PageStyleTogglesContainer>
                </ImageFieldContainer>
                <ImageFieldContainer>
                    <PageStyleSectionTitle>
                        <ImageHeader>Page Logo</ImageHeader>
                        <PageStyleStripeArrows containerRef={logoContainerRef} />
                    </PageStyleSectionTitle>
                    <ImageFields ref={logoContainerRef}>
                        <DialogFileField
                            imageInfo={pageStyleImageInfo}
                            customAddIcon={icons.addIconWhite}
                            preview={pageStyleToEdit?.pageLogo?.bigScreen}
                            fieldKey={'Big Screen (1920x1080)'}
                            customLabel={applyForAll.pageLogo ? 'All Screens' : 'Big Screen (1920x1080)'}
                            localFileCallBack={(file) => handleImageSelect(file, 'pageLogo', 'bigScreen')}
                            fileManagerCallback={(url: string) => handleImageSelect(url, 'pageLogo', 'bigScreen')}
                            openUrlCallback={(url: string) => handleImageSelect(url, 'pageLogo', 'bigScreen')}
                            alwaysShowLabel
                            locked={locked}
                            lockedBy={lockedBy}
                            accepts={acceptedExtensionsPageLogo}
                        />
                        {!applyForAll.pageLogo && (
                            <>
                                <DialogFileField
                                    imageInfo={pageStyleImageInfo}
                                    customAddIcon={icons.addIconWhite}
                                    preview={pageStyleToEdit?.pageLogo?.pc}
                                    fieldKey={'PC (1280x720)'}
                                    customLabel={'PC (1280x720)'}
                                    localFileCallBack={(file) => handleImageSelect(file, 'pageLogo', 'pc')}
                                    fileManagerCallback={(url: string) => handleImageSelect(url, 'pageLogo', 'pc')}
                                    openUrlCallback={(url: string) => handleImageSelect(url, 'pageLogo', 'pc')}
                                    alwaysShowLabel
                                    locked={locked}
                                    lockedBy={lockedBy}
                                    accepts={acceptedExtensionsPageLogo}
                                />
                                <DialogFileField
                                    imageInfo={pageStyleImageInfo}
                                    customAddIcon={icons.addIconWhite}
                                    preview={pageStyleToEdit?.pageLogo?.tablet}
                                    fieldKey={'Tablet (960x540)'}
                                    customLabel={'Tablet (960x540)'}
                                    localFileCallBack={(file) => handleImageSelect(file, 'pageLogo', 'tablet')}
                                    fileManagerCallback={(url: string) => handleImageSelect(url, 'pageLogo', 'tablet')}
                                    openUrlCallback={(url: string) => handleImageSelect(url, 'pageLogo', 'tablet')}
                                    alwaysShowLabel
                                    locked={locked}
                                    lockedBy={lockedBy}
                                    accepts={acceptedExtensionsPageLogo}
                                />
                                <DialogFileField
                                    imageInfo={pageStyleImageInfo}
                                    customAddIcon={icons.addIconWhite}
                                    preview={pageStyleToEdit?.pageLogo?.mobile}
                                    fieldKey={'Mobile (360x202)'}
                                    customLabel={'Mobile (360x202)'}
                                    localFileCallBack={(file) => handleImageSelect(file, 'pageLogo', 'mobile')}
                                    fileManagerCallback={(url: string) => handleImageSelect(url, 'pageLogo', 'mobile')}
                                    openUrlCallback={(url: string) => handleImageSelect(url, 'pageLogo', 'mobile')}
                                    alwaysShowLabel
                                    locked={locked}
                                    lockedBy={lockedBy}
                                    accepts={acceptedExtensionsPageLogo}
                                />
                            </>
                        )}
                    </ImageFields>
                    <ToggleButtonContainer>
                        <DialogToggleButton
                            checked={!!applyForAll?.pageLogo}
                            toggleCallback={() => {
                                if (locked) {
                                    return renderLockedWarningAlert(lockedBy);
                                }
                                setApplyForAll({
                                    ...applyForAll,

                                    pageLogo: !applyForAll?.pageLogo
                                });
                                resetImageKeys('pageLogo');
                            }}
                            text={'Apply for all screens'}
                            tooltipText={applyForAll?.pageLogo ? 'apply_for_all_on' : 'apply_for_all_off'}
                        />
                    </ToggleButtonContainer>
                </ImageFieldContainer>
                {renderContentMarkersSection(locked, lockedBy)}
            </div>
        );
    };

    const renderNoPageStyles = () => (
        <NoResourcesContainer>
            <ResourceCard
                image={resourceCardImages.pageStylesCard}
                title={'New Page Style'}
                subtitle={'Create a Page Style'}
                primaryButtonLabel={'Create Page Style'}
                secondaryButtonLabel={'Learn more'}
                onPrimaryButtonClick={() => {
                    handleCreateClick();
                }}
                onSecondaryButtonClick={() => {
                    openDocumentation(circleSlugs.page_styles);
                }}
                createResource
            />
        </NoResourcesContainer>
    );
    const buildTableColumns = () => {
        const columns = [
            <SortableHeaderTableCell
                key={`name_cell`}
                text={'Page Style Name'}
                hideArrow={!showSortArrows && activeSortingKey !== ACCEPTED_SORT_FIELDS.name}
                onClick={() => handleSortIconClick(ACCEPTED_SORT_FIELDS.name)}
                onMouseEnter={() => toggleArrows()}
                onMouseLeave={() => toggleArrows()}
                columnSize={PageStylesTableSizes['name']}
                direction={(sortConfig?.field === ACCEPTED_SORT_FIELDS.name && sortConfig?.direction) || 'asc'}
            />,
            <HeaderTableCell key={'placed_cell'} text={'Part of Page(s)'} columnSize={PageStylesTableSizes.placed} />,
            <HeaderTableCell key={'default_cell'} text={'Default'} columnSize={PageStylesTableSizes.isDefault} />,
            <SortableHeaderTableCell
                key={`last_modified_cell`}
                text={'Last Modified'}
                hideArrow={!showSortArrows && activeSortingKey !== ACCEPTED_SORT_FIELDS.lastModified}
                onClick={() => handleSortIconClick(ACCEPTED_SORT_FIELDS.lastModified)}
                onMouseEnter={() => toggleArrows()}
                onMouseLeave={() => toggleArrows()}
                columnSize={PageStylesTableSizes['lastModified']}
                direction={(sortConfig?.field === ACCEPTED_SORT_FIELDS.lastModified && sortConfig?.direction) || 'asc'}
            />
        ];
        return columns;
    };

    const buildTableBody = () => {
        return (
            <>
                {storePageStyles?.map((style, index) => {
                    const expanded = pageStyleToEdit?._id === style._id;
                    const locked = isObjectLocked(style);
                    const lockedBy = objectIsLockedBy(style);
                    const dateString = generateDateStringForTables(style.lastModified!);

                    const pageOptions = style?.pages?.map((p) => {
                        return {
                            value: p.pageId,
                            label: p.name || EMPTY_WORD_STRING
                        };
                    });

                    const isLast = storePageStyles.length - 1 === index;

                    return (
                        <>
                            <ScrollableTableRowWrapper
                                shouldScroll={redirectStyleId === style._id}
                                data-cy={`page-style-container-${index}`}
                                key={style._id}
                            >
                                {isEditing && expanded ? (
                                    <WidthTableCell colSpan={4} $um={'px'}>
                                        <StyleFieldsContainer>
                                            <DialogTextField
                                                label={'Page Style Name'}
                                                value={pageStyleToEdit?.name || ''}
                                                onChange={(evt: any) => {
                                                    setPageStyleToEdit({ ...pageStyleToEdit, name: evt.target.value });
                                                }}
                                                dataCy={'page-style-name-input'}
                                            />
                                            {!!pageOptions?.length && (
                                                <DialogDropdownMultiple
                                                    options={pageOptions}
                                                    value={
                                                        pageStyleToEdit?.placed
                                                            ? pageOptions?.filter((opt) =>
                                                                  pageStyleToEdit.placed?.includes(opt.value || '')
                                                              ) || []
                                                            : []
                                                    }
                                                    labelText="Part of Page(s)"
                                                    placeholder=""
                                                    onChange={(value: any) => {
                                                        setPageStyleToEdit({
                                                            ...pageStyleToEdit,
                                                            placed: value.map((elem: any) => elem.value)
                                                        });
                                                    }}
                                                    isDisabled={pageStyleToEdit?.isDefault}
                                                    allowSelectAll
                                                />
                                            )}
                                        </StyleFieldsContainer>
                                    </WidthTableCell>
                                ) : (
                                    <>
                                        {/* SETTING TITLE TABLE CELL */}
                                        <WidthTableCell {...PageStylesTableSizes.name}>
                                            <StyleViewName
                                                onClick={() => {
                                                    if (showUnsaved) return renderAlertUnsavedChanges();
                                                    expandPageStyle(style?._id);

                                                    // Show only on expand not on collapse as well
                                                    if (locked && pageStyleToEdit?._id !== style._id) {
                                                        return renderLockedWarningAlert(lockedBy);
                                                    }
                                                }}
                                            >
                                                <TruncatedText>{style.name || EMPTY_WORD_STRING}</TruncatedText>
                                                {locked && renderLockIcon(lockedBy)}
                                            </StyleViewName>
                                        </WidthTableCell>

                                        {/* PLACED TABLE CELL */}
                                        <WidthTableCell {...PageStylesTableSizes.placed}>
                                            <Labels
                                                withTranslationTooltip
                                                values={style?.placed || []}
                                                type={MoreInfoTypes.PLACED}
                                                noOfLabels={isDesktop ? (isLargeDesktop ? 3 : 2) : isMobile ? 0 : 1}
                                                onClickLabel={(page) => {
                                                    navigate(
                                                        buildPathWithProjectId(
                                                            activeProjectId,
                                                            PageRoutes.PAGE.replace(':page_id', page._id || '')
                                                        )
                                                    );
                                                }}
                                            />
                                        </WidthTableCell>

                                        {/* DEFAULT TABLE CELL */}
                                        <WidthTableCell {...PageStylesTableSizes.isDefault}>
                                            <StyleDefault data-cy={`page-style-default-${style.isDefault}`}>
                                                {renderTooltipWithKey(
                                                    <Radio
                                                        color="primary"
                                                        checked={
                                                            newDefaultStyleId === style._id || (style?.isDefault && !newDefaultStyleId)
                                                        }
                                                        onClick={() => {
                                                            locked ? renderLockedWarningAlert(lockedBy) : setNewDefaultStyleId(style._id);
                                                        }}
                                                        disabled={creatingStyle}
                                                    />,
                                                    'page_style_default'
                                                )}
                                            </StyleDefault>
                                        </WidthTableCell>

                                        {/* LAST MODIFIED TABLE CELL */}
                                        <WidthTableCell {...PageStylesTableSizes.lastModified}>
                                            <StyleViewLastModified>
                                                {dateString}
                                                <Tooltip title={style?.modifiedByUser?.name || ''} placement="right-start">
                                                    <img src={style?.modifiedByUser?.icon || icons.avatarIcon} alt="" />
                                                </Tooltip>
                                            </StyleViewLastModified>
                                        </WidthTableCell>
                                    </>
                                )}

                                {/* ACTIONS TABLE CELL */}
                                <WidthTableCell {...PageStylesTableSizes.actions}>
                                    <ObjectActions
                                        actions={!style?.isDefault ? [tableActions.EDIT, tableActions.REMOVE] : [tableActions.EDIT]}
                                        withArrow
                                        onArrowToggle={() => {
                                            if (showUnsaved) return renderAlertUnsavedChanges();
                                            expandPageStyle(style?._id);

                                            // Show only on expand not on collapse as well
                                            if (locked && pageStyleToEdit?._id !== style._id) {
                                                return renderLockedWarningAlert(lockedBy);
                                            }
                                        }}
                                        open={expanded}
                                        onEdit={() => {
                                            if (showUnsaved && pageStyleToEdit?._id !== style._id) return renderAlertUnsavedChanges();
                                            !locked && setIsEditing(style._id === pageStyleToEdit?._id ? !isEditing : true);
                                            pageStyleToEdit?._id !== style._id && expandPageStyle(style._id);

                                            if (locked) {
                                                return renderLockedWarningAlert(lockedBy);
                                            }
                                        }}
                                        onRemove={() => {
                                            if (showUnsaved) {
                                                return renderAlertUnsavedChanges();
                                            }
                                            if (locked) {
                                                return renderLockedWarningAlert(lockedBy);
                                            }
                                            handleDelete(style._id);
                                        }}
                                        tooltipTexts={{
                                            arrowClose: 'page_style_icon_hide',
                                            arrowOpen: 'page_style_icon_show',
                                            delete: 'page_style_icon_delete',
                                            edit: 'page_style_icon_edit'
                                        }}
                                    />
                                </WidthTableCell>
                            </ScrollableTableRowWrapper>

                            {/* EXTENDED PAGE STYLE TABLE ROW */}
                            {expanded && (
                                <>
                                    <TableRowWrapper style={{ background: 'transparent' }}>
                                        <WidthTableCell
                                            style={{ padding: 24, background: 'rgba(0,0,0,0.06)', maxWidth: 0 }}
                                            colSpan={5}
                                            $um={'px'}
                                        >
                                            {renderExpandedPageStyle(locked, lockedBy)}
                                        </WidthTableCell>
                                    </TableRowWrapper>
                                </>
                            )}

                            {/* SPACE BETWEEN ROWS */}
                            {!isLast && <TableRow style={{ height: '24px' }} />}
                        </>
                    );
                })}
                {creatingStyle && renderCreatingStyle()}
            </>
        );
    };

    const renderContentMarkersSection = (locked = false, lockedBy = '') => {
        const contentMarkersToRender = contentMarkers?.availableContentMarkers;
        if (!contentMarkersToRender) return;

        const fields = Object.keys(contentMarkersToRender).map((key, index) => {
            return (
                <ContentMarkerField
                    $contentMarker={pageStyleToEdit?.contentMarkers?.[key]}
                    key={'content_marker_' + index}
                    onClick={(evt: any) => {
                        evt.preventDefault();
                        if (locked) {
                            return renderLockedWarningAlert(lockedBy);
                        }
                        setOpenContentMarkersDialog(true);
                        setContentMarkerToEdit(key);
                    }}
                >
                    {pageStyleToEdit?.contentMarkers?.[key] ? (
                        <ContentMarkerName>
                            <span>{contentMarkersToRender[key]}</span>
                            <SVGInline src={icons.editLightIcon} />
                        </ContentMarkerName>
                    ) : (
                        <SVGInline src={icons.addIconWhite} />
                    )}
                </ContentMarkerField>
            );
        });

        return (
            <ContentMarkersContainer>
                <ContentMarkersHeader>Content Markers</ContentMarkersHeader>
                {fields}
            </ContentMarkersContainer>
        );
    };

    const beError = error || imageError || templatesError;
    const isEmpty = !storePageStyles.length;
    const renderError = (error: any) => {
        switch (error.code) {
            case API_ERROR_CODES.LOCKED_ERROR:
                return renderLockedError(error);
            default:
                return <BackendErrorDialog error={error} />;
        }
    };

    return (
        <>
            {beError && renderError(beError)}
            <SearchBarContainer>
                <SearchBar
                    title={'Search by Name'}
                    disabled={loading}
                    searchTerm={searchTerm}
                    onSearch={handleOnSearch}
                    setSearchTerm={setSearchTerm}
                    tooltipText={'page_style_icon_search'}
                />
            </SearchBarContainer>

            {loading ? (
                <Loader title="Page Styles" />
            ) : isEmpty ? (
                renderNoPageStyles()
            ) : (
                <>
                    <GenericTable columns={buildTableColumns()} body={buildTableBody()} />
                    {!creatingStyle && (
                        <PageActionsWrapper>
                            <PageActionButton
                                onClick={() => {
                                    showUnsaved ? renderAlertUnsavedChanges() : handleCreateClick();
                                }}
                                label={'Create Page Style'}
                                type={'BLUE'}
                            />
                            <PageActionButton
                                onClick={() => {
                                    showUnsaved ? renderAlertUnsavedChanges() : setOpenTemplateSelectionDialog(true);
                                }}
                                label={'Select Template'}
                                type={'BLUE'}
                            />
                            <button id={PAGE_STYLE_ADD_BUTTON_ID} hidden onClick={() => setOpenNewResourceDialog(true)} />
                        </PageActionsWrapper>
                    )}
                </>
            )}

            {(creatingStyle || pageStyleToEdit || newDefaultStyleId) && (
                <PageActions
                    onSave={() => handleSave()}
                    onCancel={() => {
                        showUnsaved ? renderAlertUnsavedChanges() : handleCancelClick();
                    }}
                    disabled={{ save: !showUnsaved }}
                />
            )}
            <ContentMarkersDialog
                open={openContentMarkersDialog}
                onSave={(contentMarker) => {
                    const newPageStyleToEdit = {
                        ...pageStyleToEdit
                    };
                    newPageStyleToEdit.contentMarkers = {
                        ...(newPageStyleToEdit.contentMarkers || {}),
                        [contentMarkerToEdit]: contentMarker
                    };
                    setPageStyleToEdit(newPageStyleToEdit);
                    setOpenContentMarkersDialog(false);
                }}
                onClose={() => {
                    setOpenContentMarkersDialog(false);
                }}
                title={(contentMarkers?.availableContentMarkers?.[contentMarkerToEdit] || '') + ' Content Marker'}
                contentMarker={pageStyleToEdit?.contentMarkers?.[contentMarkerToEdit]}
            />
            <CreateResourceDialog
                title={'Page Style'}
                open={openNewResourceDialog}
                onClose={() => {
                    setOpenNewResourceDialog(false);
                }}
                historyUrl={''}
                handleOpenTemplateClick={() => setOpenTemplateSelectionDialog(true)}
                handleCreateNewResourceClick={() => {
                    setOpenNewResourceDialog(false);
                    handleCreateClick();
                }}
            />
            <TemplateSelection
                open={openTemplateSelectionDialog}
                onClose={() => {
                    setOpenTemplateSelectionDialog(false);
                }}
                templateUrl={''}
                callback={(id: any) => {
                    setOpenNewResourceDialog(false);
                    setOpenTemplateSelectionDialog(false);
                    if (id) {
                        useTemplate(id);
                    }
                }}
                resourceType={templateTypes.PAGE_STYLE}
            />
        </>
    );
};

export default PageStyle;

import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { DialogDropdownMultiple, DialogDropdownSingle } from '../../common/Dialog/GenericDialog';
import { CustomizableValueDialog } from './ConfigurableValueDialog';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../hooks/redux';
import { ConfigurableValuesState, createValue, deleteValue, fetchValues, updateValue } from '../../../redux/slices/configurableValuesSlice';
import { ActiveItemState } from '../../../redux/slices/activeItemSlice';
import { ConfigurableOption, ConfigurableOptionActions, ConfigurableSelectWrapper } from './Conditions.css';
import SVGInline from 'react-inlinesvg';
import icons from '../../../style';
import { dialogConfirm } from '../../../utils/fnDialogs';
import { calculateIsTenantAdmin, PermissionsState } from '../../../redux/slices/permissionsSlice';
import { indexOf } from 'lodash';

export enum ConfigurableKeys {
    DISPLAY_CONDITIONS_SEGMENTS = 'displayConditions_segments',
    DISPLAY_CONDITIONS_RATINGS = 'displayConditions_ratings',
    DISPLAY_CONDITIONS_SUBSCRIBED = 'displayConditions_subscribed',
    DISPLAY_CONDITIONS_DATA_PROTECTION_REGULATION = 'displayConditions_dataProtectionRegulation'
}

const configurableTitles = {
    [ConfigurableKeys.DISPLAY_CONDITIONS_SEGMENTS]: 'Segment',
    [ConfigurableKeys.DISPLAY_CONDITIONS_RATINGS]: 'Age Rating',
    [ConfigurableKeys.DISPLAY_CONDITIONS_SUBSCRIBED]: 'Package',
    [ConfigurableKeys.DISPLAY_CONDITIONS_DATA_PROTECTION_REGULATION]: 'Data Protection Regulation'
};

export type ConfigurableSelectProps = {
    type: ConfigurableKeys;
    selectedValue: ConfigurableValue;
    error?: string;
    isMultiple?: boolean;
    onSelectedValuesChange: (arg: any) => void;
};

type ConfigurableValue = string | number | string[] | number[] | null;

export const ConfigurableSelect: FC<ConfigurableSelectProps> = ({ type, selectedValue, error, isMultiple, onSelectedValuesChange }) => {
    const { activeProjectId, activeTenantId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const { userPermissions }: PermissionsState = useAppSelector((state) => state.permissions);
    const { values, loading }: ConfigurableValuesState = useAppSelector((state) => state.configurableValues);
    const dispatch = useDispatch();

    const [showNewValueDialog, setShowNewValueDialog] = useState<boolean>(false);
    const [valueToEdit, setValueToEdit] = useState<string | number>('');

    const isAdmin = userPermissions?.isSuperAdmin || (activeTenantId && calculateIsTenantAdmin(activeTenantId, userPermissions));
    const title = configurableTitles?.[type] || '';
    const currentValueRef = useRef<ConfigurableValue>(null);

    useEffect(() => {
        currentValueRef.current = selectedValue;
    }, [selectedValue]);

    const isValueSelected = (value: string | number): boolean => {
        const currentValue = currentValueRef.current;
        if (!Array.isArray(currentValue)) return currentValue === value;

        return typeof value === 'string'
            ? (currentValue as Array<string>).includes(value)
            : typeof value === 'number'
            ? (currentValue as Array<number>).includes(value)
            : false;
    };

    const _createValue = async (value: string | number) => {
        await dispatch(createValue({ type, projectId: activeProjectId || '', value })).unwrap();
        await loadValues();

        onSelectedValuesChange(Array.isArray(currentValueRef.current) ? [...currentValueRef.current, value] : value);
    };

    const _updateValue = async (value: string | number) => {
        const currentValue = currentValueRef.current;
        await dispatch(updateValue({ type, projectId: activeProjectId || '', value, oldValue: valueToEdit })).unwrap();
        await loadValues();

        if (!isValueSelected(valueToEdit)) return;

        if (Array.isArray(currentValue)) {
            const index =
                typeof valueToEdit === 'number'
                    ? (currentValue as Array<number>).indexOf(valueToEdit)
                    : (currentValue as Array<string>).indexOf(valueToEdit);
            const newValues = [...currentValue];
            newValues.splice(index, 1, value);
            onSelectedValuesChange(newValues);
            return;
        }

        onSelectedValuesChange(value);
    };

    const _deleteValue = async (value: string | number) => {
        await dispatch(deleteValue({ type, projectId: activeProjectId || '', value })).unwrap();
        await loadValues();

        if (isValueSelected(value)) {
            onSelectedValuesChange(
                Array.isArray(currentValueRef.current) ? [...currentValueRef.current].filter((val) => val !== value) : ''
            );
        }
    };

    const loadValues = async () => {
        await dispatch(fetchValues({ type, projectId: activeProjectId || '' })).unwrap();
    };

    const handleDeleteClick = (key: string | number) => {
        const deleteDialogValues = {
            title: `Delete ${title}`,
            text: `Are you sure you want to delete this ${title}?`
        };
        dialogConfirm(
            '',
            () => {
                _deleteValue(key);
            },
            deleteDialogValues,
            null,
            {
                noButton: {
                    label: 'Cancel'
                },
                confirmButton: {
                    label: 'Delete'
                }
            }
        );
    };

    const configurableOptions = [...values].map((key) => {
        const label = type === ConfigurableKeys.DISPLAY_CONDITIONS_RATINGS ? `${key}+` : `${key}`;
        return {
            value: key,
            label: (
                <ConfigurableOption>
                    <span>{label}</span>

                    {isAdmin && (
                        <ConfigurableOptionActions>
                            <SVGInline
                                key={'editIcon' + key}
                                src={icons.editIcon}
                                onMouseDown={(evt) => {
                                    evt.preventDefault();
                                    evt.stopPropagation();
                                    setShowNewValueDialog(true);
                                    setValueToEdit(key);
                                }}
                            />

                            <SVGInline
                                key={'deleteIcon' + key}
                                src={icons.trashIcon}
                                onMouseDown={(evt) => {
                                    evt.preventDefault();
                                    evt.stopPropagation();
                                    handleDeleteClick(key);
                                }}
                            />
                        </ConfigurableOptionActions>
                    )}
                </ConfigurableOption>
            ),
            valueForSearch: `${key}`
        };
    });

    useEffect(() => {
        if (!type) return;
        loadValues();
    }, [type]);

    return (
        <>
            <ConfigurableSelectWrapper>
                {isMultiple ? (
                    <DialogDropdownMultiple
                        options={[...configurableOptions]}
                        value={configurableOptions.filter((opt) => (selectedValue as Array<string | number>).includes(opt.value))}
                        onChange={(val: any) => {
                            onSelectedValuesChange(val.map((v: any) => v.value));
                        }}
                        placeholder={`Select ${title}`}
                        newOption={{
                            name: title,
                            onClick: () => {
                                setShowNewValueDialog(true);
                            }
                        }}
                        allowSelectAll
                        isDisabled={loading}
                        error={error}
                    />
                ) : (
                    <DialogDropdownSingle
                        options={[...configurableOptions]}
                        placeholder={`Select ${title}`}
                        onChange={(value: any) => {
                            onSelectedValuesChange(value.value);
                        }}
                        value={configurableOptions.find((opt) => opt.value === selectedValue) || ''}
                        error={error}
                        newOption={{
                            name: title,
                            onClick: () => {
                                setShowNewValueDialog(true);
                            }
                        }}
                        isDisabled={loading}
                    />
                )}
            </ConfigurableSelectWrapper>

            <CustomizableValueDialog
                type={type}
                title={title}
                open={showNewValueDialog}
                onClose={() => {
                    setShowNewValueDialog(false);
                    setValueToEdit('');
                }}
                existingValue={valueToEdit}
                onSave={(value) => {
                    !!valueToEdit ? _updateValue(value) : _createValue(value);
                }}
            />
        </>
    );
};

import styled, { CSSProperties } from 'styled-components';
import getTheme, { fontSizesCSS } from '../../../style/themes/theme';
import { StyledDialog } from '../Dialog/GenericDialog.css';

const theme: any = getTheme();

export const TimePickerDialog = styled(StyledDialog)`
    div.MuiPaper-root {
        width: fit-content;
    }
`;

export const TimePickerContainer = styled.div``;
export const StrictTimeError = styled.div`
    color: ${theme.palette.error.main};
    text-align: center;
    ${fontSizesCSS.Caption2}
`;
export const TimePickeWithRangeDialogStyle: CSSProperties = {
    width: '570px'
};

export const TimePickerDialogStyle: CSSProperties = {
    width: '332px'
};
export const TimePickerTitle = styled.div`
    height: 68px;
    width: 100%;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const TimeBoxSection = styled.div`
    width: 100%;
    display: flex;
`;
export const TimePickerBox = styled.div`
    width: 248px;
    height: 162px;
    display: flex;
    padding: 24px 40px;
    box-sizing: border-box;
    border: 1px solid ${theme.palette.background.pressed};
`;

export const TimeSection = styled.div`
    width: 56px;
    height: 82px;
    font-size: 24px;
    line-height: 24px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const TimeContainer = styled.div``;

export const TopArrow = styled.div`
    text-align: center;
    cursor: pointer;
`;
export const BottomArrow = styled.div`
    text-align: center;
    cursor: pointer;
`;

export const TimeTextSection = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    height: 68px;
    align-items: center;

    svg {
        margin: 0 10px;
    }
`;

export const TimePickerTextField = styled.div`
    input {
        pointer-events: none;
    }
`;

import React, { useEffect, useState } from 'react';
import {
    ApplicationWrapper,
    LastModifiedTableCell,
    MainContentWrapper,
    NoResourcesContainer,
    PageActionButton,
    PageActionsWrapper,
    TruncatedText
} from '../../style/styled-components/reusable.css';
import Sidebar from '../common/Sidebar/Sidebar';

import { useNavigate } from 'react-router-dom';
import GenericTable, {
    ActionsTableCell,
    HeaderTableCell,
    ImageTableCell,
    SelectAllTableCell,
    SortableHeaderTableCell,
    tableActions
} from '../common/Table/Table';
import { SvgIcon, TableBody, TableCell, TableRow } from '@material-ui/core';
import {
    ACCEPTED_SORT_FIELDS,
    AcceptedSortField,
    calculateOrderByFromSortConfig,
    DEFAULT_SORT_CONFIG,
    ISortConfig
} from '../../utils/fnSort';
import avatarIcon from '../../assets/images/icons/ico-avatar.svg';
import { DIALOG_NAMES, dialogRemove, ToastAlert } from '../../utils/fnDialogs';
import ScreenTitle from '../common/DashboardTitle/ScreenTitle';
import { Page, PageIntents } from '../../types/Page';
import { calculateIsTenantAdmin, PermissionsState, setUserPermissions } from '../../redux/slices/permissionsSlice';
import { ActiveItemState } from '../../redux/slices/activeItemSlice';
import { useAppDispatch as useDispatch, useAppSelector } from '../../hooks/redux';
import { deletePage, deletePageList, fetchPages, pagesState, unsetPages } from '../../redux/slices/pagesSlice';
import { generateDateStringForTables } from '../../utils/fnDate';
import BackendErrorDialog from '../common/Dialog/BackendErrorDialog';
import { ResourceCard } from '../Cards/ResourceCard/ResourceCard';
import CreateResourceDialog from '../common/Dialog/CreateResourceDialog';
import { WidthTableCell } from '../common/Table/Table.css';
import TemplateSelection from './Dialogs/TemplateSelection';
import { MoreInfoTypes } from '../common/Dialog/MoreInfoDialog';
import { PageNameContainer } from './Pages.css';
import { Loader } from '../common/Loader/Loader';
import { API_ERROR_CODES, EMPTY_WORD_STRING } from '../../utils/Globals';
import { templateTypes } from '../../types/Template';
import { resourceCardImages } from '../../assets/images/resourceCards';
import { applyTemplate, templatesState } from '../../redux/slices/templatesSlice';
import TranslationTooltip from '../common/TranslationTooltip/TranslationTooltip';
import useScreenSize from '../../hooks/useScreenSize';
import circleSlugs from '../../utils/circle_slugs.json';
import { buildPathWithProjectId, PageRoutes } from '../../types/RouteTypes';
import { renderABIcon, renderLockedError, renderLockedWarningAlert, renderLockIcon } from '../../utils/fnLockingSystem';
import useLockSystem, { LockableObjectTypes } from '../../hooks/useLockSystem';
import { TooltipDivider } from '../Modules/Modules.css';
import SVGInline from 'react-inlinesvg';
import icons from '../../style';
import { useMultiSelect } from '../../hooks/useMultiSelect';
import { renderTooltip, renderTooltipWithKey, tooltipPositions, tooltipTypes } from '../common/Tooltips/Tooltips';
import { openDocumentation } from '../../utils/parsers';
import CopyToProjectDialog, { renderItemActionWarningAfterCopy } from '../common/Dialog/CopyToProjectDialog';
import { copyObject, copyObjectState } from '../../redux/slices/copyObjectSlice';
import { ObjectTypes } from '../../types/Object';
import { templates } from '../../types/Module';
import {
    ObjectNameTooltipContentHolder,
    ObjectNameTooltipIconHolder,
    ObjectNameTooltipLabelHolder,
    ObjectNameTooltipNameHolder
} from '../common/Tooltips/Tooltips.css';
import { PagesTableSizes } from '../../types/TableSizes';
import Labels from '../common/Labels/Labels';
import { renderFailedObjectDeletions } from '../../utils/fnListDelete';
import { CIRCLE_SLUGS, ONBOARDING_CIRCLE_SLUGS } from '../common/HelpIcon/HelpIcon';
import { RemovableObjects } from '../common/Dialog/RemoveObjectDialog';
import PaginationWrapper, { ResetCallbackProps } from '../PaginationWrapper/PaginationWrapper';
import { ObjectFilter } from '../../utils/fnFilter';
import { usePersistentState } from '../../hooks/usePersistentState';
import { renderAdminLockedError, renderAdminLockedWarningAlert, renderAdminLockIcon } from '../../utils/fnAdminLocking';
import { adminLockObject, adminUnlockObject } from '../../redux/slices/adminLockSlice';
import { searchTermUnsetValue } from '../common/Select/FancyFilter';
import ObjectActivityDialog from '../common/Dialog/ObjectActivityDialog';
import { getObjectActivity, unsetObjectActivity } from '../../redux/slices/objectActivitySlice';
import useTranslation from '../../hooks/useTranslation';
import { renderReferencedObjectDeleteError } from '../../utils/fnPublish';

const Pages: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { select } = useMultiSelect();
    const { isMobile, isDesktop, isLargeDesktop } = useScreenSize();
    const { translate } = useTranslation();
    const { isObjectLocked, objectIsLockedBy } = useLockSystem(LockableObjectTypes.PAGES);
    const { store: paginationState, set: setPaginationState } = usePersistentState('paginationState');

    const { pages: storePages, error: pagesError, totalResults, loading }: pagesState = useAppSelector((state) => state.pages);
    const { activeProjectId, activeTenantId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const { error: copyObjectError }: copyObjectState = useAppSelector((state) => state.copyObject);
    const { error: templatesError }: templatesState = useAppSelector((state) => state.templates);
    const { userPermissions }: PermissionsState = useAppSelector((state) => state.permissions);
    const { loading: objectActivityLoading } = useAppSelector((state) => state.objectActivity);

    const [pages, setPages] = useState<Page[]>([]);
    const [openNewPageDialog, setOpenNewPageDialog] = useState(false);
    const [openTemplateSelectionDialog, setOpenTemplateSelectionDialog] = useState(false);
    const [openNameTooltip, setOpenNameTooltip] = useState<any>({});
    const [showObjectActivityDialog, setShowObjectActivityDialog] = useState<{
        open: boolean;
        objectTitle: string;
    }>({
        open: false,
        objectTitle: ''
    });

    // MULTISELECT PAGES
    const [isMultiSelectVisible, setIsMultiSelectVisible] = useState<boolean>(false);
    const [multiSelectedIds, setMultiSelectedIds] = useState<string[]>([]);
    const [allPagesSelected, setAllPagesSelected] = useState<boolean>(false);

    const [showCopyToProjectDialog, setShowCopyToProjectDialog] = useState<boolean>(false);
    const [selectedPageToCopy, setSelectedPageToCopy] = useState<Page | undefined>(undefined);
    const canCopy = userPermissions?.isSuperAdmin || userPermissions?.tenantAdminIn?.includes(activeTenantId || '');

    // PAGINATION, SEARCH AND FILTERING/SORTING RELATED FIELDS
    const [activeObjectFilter, setActiveObjectFilter] = useState<ObjectFilter | undefined>(undefined);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const [sortConfig, setSortConfig] = useState<ISortConfig>(DEFAULT_SORT_CONFIG);
    const [showSortArrows, setShowSortArrows] = useState<boolean>(false);
    const [activeSortingKey, setActiveSortingKey] = useState<AcceptedSortField>(ACCEPTED_SORT_FIELDS.lastModified);

    const orderBy = calculateOrderByFromSortConfig(sortConfig);

    const resetCallback = (keep?: ResetCallbackProps) => {
        !keep?.currentPage && setCurrentPage(1);
        !keep?.searchTerm && setSearchTerm(undefined);
        !keep?.sortConfig && setActiveSortingKey(DEFAULT_SORT_CONFIG.field as AcceptedSortField);
        !keep?.sortConfig && setSortConfig(DEFAULT_SORT_CONFIG);
        !keep?.filterObject && setActiveObjectFilter(undefined);
        setIsMultiSelectVisible(false);
    };

    // ----------------------
    const handleCopyToProject = async (
        projectId: string,
        pageId: string,
        newTemplateValues: { moduleId: string; template: string }[],
        showItemActionWarningAfterCopy?: boolean,
        withRedirect?: boolean,
        hideSuccessToast?: boolean
    ) => {
        if (!pageId) return ToastAlert('critical_warning', '', '', undefined, DIALOG_NAMES.OBJECT_COPY_FAIL);
        const result = await dispatch(copyObject({ _id: pageId, projectId, objectType: ObjectTypes.PAGES, newTemplateValues })).unwrap();
        if (result.id) {
            showItemActionWarningAfterCopy && renderItemActionWarningAfterCopy();
            !hideSuccessToast && ToastAlert('success', '', '', undefined, DIALOG_NAMES.OBJECT_COPY);
            withRedirect && navigate(buildPathWithProjectId(projectId, PageRoutes.PAGE.replace(':page_id', result.id)));
        }
        setShowCopyToProjectDialog(false);
    };

    const loadPages = async (
        addPermissions?: boolean,
        projectId?: string,
        pageSize?: number,
        pageNumber?: number,
        orderBy?: string,
        searchTerm?: string,
        filter?: ObjectFilter
    ) => {
        !searchTerm && setSearchTerm(searchTermUnsetValue);
        return await dispatch(fetchPages({ addPermissions, projectId, pageSize, pageNumber, orderBy, searchTerm, filter })).unwrap();
    };

    const onSearchTermOrPaginationChange = (
        pageSize: number,
        currentPage: number,
        orderBy: string,
        searchTerm?: string,
        filter?: ObjectFilter
    ) => {
        setIsMultiSelectVisible(false);
        loadPages(true, activeProjectId || '', pageSize, currentPage, orderBy, searchTerm, filter);
    };

    const removePage = async (id: string, withChildren?: boolean) => {
        const response = await dispatch(deletePage({ id, withChildren })).unwrap();
        if (!!response?.failedDeletions) {
            renderFailedObjectDeletions(response.failedDeletions);
        }
        loadPages(false, activeProjectId, pageSize, currentPage, orderBy, searchTerm, activeObjectFilter);
        resetCallback({ currentPage: true, searchTerm: true, sortConfig: true, filterObject: true });
    };

    const removeList = async (pages: string[], withChildren?: boolean) => {
        const result = await dispatch(deletePageList({ pages, withChildren })).unwrap();
        if (result.failedDeletions.length) {
            renderFailedObjectDeletions(result.failedDeletions);
        }
        setMultiSelectedIds([]);
        loadPages(false, activeProjectId, pageSize, currentPage, orderBy, searchTerm, activeObjectFilter);
        resetCallback({ currentPage: true, searchTerm: true, sortConfig: true, filterObject: true });
    };

    const useTemplate = async (id: string) => {
        const result = await dispatch(
            applyTemplate({ templateId: id, type: templateTypes.PAGE, projectId: `${activeProjectId}`, tenantId: `${activeTenantId}` })
        ).unwrap();
        if (result.id) {
            resetCallback();
            loadPages(false, activeProjectId, pageSize, 1, calculateOrderByFromSortConfig(DEFAULT_SORT_CONFIG), searchTerm);
        }
    };

    const lockPage = async (id: string) => {
        const result = await dispatch(adminLockObject({ objectId: id, objectType: LockableObjectTypes.PAGES })).unwrap();
        if (result.ok) {
            //reload current page
            loadPages(false, activeProjectId, pageSize, currentPage, orderBy, searchTerm, activeObjectFilter);
            resetCallback({ currentPage: true, searchTerm: true, sortConfig: true, filterObject: true });
        }
    };
    const unlockPage = async (id: string) => {
        const result = await dispatch(adminUnlockObject({ objectId: id, objectType: LockableObjectTypes.PAGES })).unwrap();
        if (result.ok) {
            // reload current page
            loadPages(false, activeProjectId, pageSize, currentPage, orderBy, searchTerm, activeObjectFilter);
            resetCallback({ currentPage: true, searchTerm: true, sortConfig: true, filterObject: true });
        }
    };

    useEffect(() => {
        if (!activeProjectId) return;

        // everything should be reset between project changes
        resetCallback();
        setPageSize(10);
        setShowSortArrows(false);

        if (storePages.length) {
            dispatch(unsetPages());
        }

        let pageSize = 10;
        let currentPage = 1;
        let searchTerm = undefined;
        let activeObjectFilter = undefined;
        let orderBy = calculateOrderByFromSortConfig(DEFAULT_SORT_CONFIG);

        if (paginationState) {
            const {
                activeSortingKey,
                currentPage: page,
                searchTerm: term,
                sortConfig: config,
                pageSize: size,
                activeObjectFilter: filter
            } = paginationState;

            setActiveSortingKey(activeSortingKey);
            setActiveObjectFilter(filter);
            setCurrentPage(page);
            setSearchTerm(term);
            setSortConfig(config);
            setPageSize(size);

            pageSize = size;
            currentPage = page;
            searchTerm = term;
            activeObjectFilter = filter;
            orderBy = calculateOrderByFromSortConfig(config);

            setPaginationState(undefined);
        }

        loadPages(true, activeProjectId, pageSize, currentPage, orderBy, searchTerm, activeObjectFilter).then((response: any) => {
            if (response.permissions) {
                dispatch(setUserPermissions(response.permissions));
            }
        });
    }, [activeProjectId]);

    useEffect(() => {
        if (totalResults === undefined) return;
        setTotalPages(Math.ceil(totalResults / pageSize));
    }, [pageSize, totalResults]);

    useEffect(() => {
        setIsMultiSelectVisible(false);
        setMultiSelectedIds([]);
    }, [currentPage]);

    useEffect(() => {
        if (loading || pagesError) return;
        setPages(storePages);
    }, [loading]);

    useEffect(() => {
        if (!pages.length) return;
        setAllPagesSelected(multiSelectedIds.length === pages.length);
    }, [multiSelectedIds, pages]);

    useEffect(() => {
        if (!isMultiSelectVisible) {
            setAllPagesSelected(false);
            setMultiSelectedIds([]);
        }
    }, [isMultiSelectVisible]);

    useEffect(() => {
        const newTooltips = { ...openNameTooltip };
        pages.forEach((_, index) => {
            newTooltips[index] = false;
        });
        setOpenNameTooltip(newTooltips);
    }, [pages]);

    const handleDeleteIconClick = (page?: Page) => {
        return dialogRemove(
            RemovableObjects.PAGE,
            { object: page?.name },
            (withItems) => (page ? removePage(page._id, withItems) : removeList(multiSelectedIds, withItems)),
            undefined,
            undefined,
            !page
        );
    };

    const handleCreateClick = () => {
        setOpenNewPageDialog(true);
    };

    const handleSortIconClick = (field: AcceptedSortField) => {
        setShowSortArrows(false);
        setActiveSortingKey(field);
        let direction: 'asc' | 'desc' = 'asc';
        if (sortConfig && sortConfig.field === field && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        const config = {
            field,
            direction
        };
        setSortConfig(config);
        const orderBy = `${config.field}[${config.direction}]`;
        setCurrentPage(1);
        loadPages(false, activeProjectId, pageSize, 1, orderBy, searchTerm, activeObjectFilter);
        setShowSortArrows(false);
        setIsMultiSelectVisible(false);
    };

    const onLastModifiedInfoClick = (page: Page) => {
        setShowObjectActivityDialog({ open: true, objectTitle: translate(page.name) });
        !objectActivityLoading &&
            dispatch(
                getObjectActivity({
                    objectId: page._id,
                    objectType: ObjectTypes.PAGES,
                    withObjectName: true
                })
            );
    };

    const renderNameTooltip = (page: Page, index: number, locked: boolean, lockedBy: string) => {
        const tooltipValue = (
            <ObjectNameTooltipContentHolder>
                <ObjectNameTooltipIconHolder>
                    <SVGInline src={icons.pagesIcon} />
                </ObjectNameTooltipIconHolder>
                <ObjectNameTooltipNameHolder>{page.name || EMPTY_WORD_STRING}</ObjectNameTooltipNameHolder>
                <TooltipDivider />
                <ObjectNameTooltipLabelHolder>Open Editor</ObjectNameTooltipLabelHolder>
            </ObjectNameTooltipContentHolder>
        );
        return renderTooltip(
            <PageNameContainer>
                <TruncatedText
                    onMouseEnter={() =>
                        setOpenNameTooltip((tooltips: any) => {
                            const newOpenNameTooltip = { ...tooltips };
                            Object.keys(newOpenNameTooltip).forEach((key) => {
                                newOpenNameTooltip[key] = key === index.toString();
                            });
                            return newOpenNameTooltip;
                        })
                    }
                >
                    {translate(page.name || EMPTY_WORD_STRING)}
                </TruncatedText>
                {<TranslationTooltip translationKey={page.name} />}
                {locked && renderLockIcon(lockedBy)}
                {page.adminLocked && renderAdminLockIcon()}
                {renderABIcon(page)}
            </PageNameContainer>,
            tooltipTypes.HTML,
            tooltipValue,
            tooltipPositions.TOP,
            false,
            true,
            false,
            openNameTooltip?.[index] || false
        );
    };

    const buildTableColumns = () => {
        const columns = [
            <SortableHeaderTableCell
                key={`name_cell`}
                text={'Page Name'}
                hideArrow={!showSortArrows && activeSortingKey !== ACCEPTED_SORT_FIELDS.name}
                onClick={() => handleSortIconClick(ACCEPTED_SORT_FIELDS.name)}
                onMouseEnter={() => setShowSortArrows(true)}
                onMouseLeave={() => setShowSortArrows(false)}
                columnSize={PagesTableSizes['name']}
                direction={(sortConfig?.field === ACCEPTED_SORT_FIELDS.name && sortConfig?.direction) || 'asc'}
            />,
            <HeaderTableCell key={'placed_cell'} text={'Part of Group(s)'} columnSize={PagesTableSizes.placed} />,
            <HeaderTableCell key={'conditions_cell'} text={'Target Conditions'} columnSize={PagesTableSizes.conditions} />,
            <SortableHeaderTableCell
                key={`last_modified_cell`}
                text={'Last Modified'}
                hideArrow={!showSortArrows && activeSortingKey !== ACCEPTED_SORT_FIELDS.lastModified}
                onClick={() => handleSortIconClick(ACCEPTED_SORT_FIELDS.lastModified)}
                onMouseEnter={() => setShowSortArrows(true)}
                onMouseLeave={() => setShowSortArrows(false)}
                columnSize={PagesTableSizes['lastModified']}
                direction={(sortConfig?.field === ACCEPTED_SORT_FIELDS.lastModified && sortConfig?.direction) || 'asc'}
            />
        ];

        pages.length &&
            columns.push(
                <>
                    {isDesktop && <TableCell key={`first_cell_pages`} />}
                    <SelectAllTableCell
                        onDelete={handleDeleteIconClick}
                        onSelectAll={(selected) => {
                            setMultiSelectedIds(selected ? pages.map((page) => page._id) : []);
                        }}
                        allValuesSelected={allPagesSelected}
                        isMultiSelectVisible={isMultiSelectVisible}
                        setIsMultiSelectVisible={(arg) => setIsMultiSelectVisible(arg)}
                        isDeleteDisabled={!multiSelectedIds.length}
                    />
                </>
            );
        isMobile && columns.splice(3, 1); // remove last modified column if not in desktop view
        return columns;
    };

    const buildTableBody = () => {
        const rows = pages.map((page, index) => {
            const dateString = generateDateStringForTables(page.lastModified || 0);
            const locked = isObjectLocked(page);
            const lockedBy = objectIsLockedBy(page);

            let actions: tableActions[] = [];
            if (isMultiSelectVisible) {
                actions = [tableActions.MULTI_SELECT];
            } else {
                actions = [tableActions.COPY, tableActions.DUPLICATE, tableActions.REMOVE];
            }
            if (userPermissions?.isSuperAdmin || (activeTenantId && calculateIsTenantAdmin(activeTenantId, userPermissions))) {
                actions.unshift(tableActions.ADMIN_LOCK);
            }

            return (
                <TableRow
                    key={page._id}
                    style={{ cursor: 'pointer' }}
                    data-cy={`page-row-${index}`}
                    onClick={() => {
                        setPaginationState({
                            pageSize,
                            currentPage,
                            searchTerm,
                            sortConfig,
                            activeSortingKey,
                            activeObjectFilter
                        });
                        navigate(`${page._id}`);
                    }}
                >
                    {/* PAGE NAME TABLE CELL */}
                    <WidthTableCell
                        {...PagesTableSizes.name}
                        onMouseLeave={() => {
                            setOpenNameTooltip((tooltips: any) => {
                                const newOpenNameTooltip = { ...tooltips };
                                Object.keys(newOpenNameTooltip).forEach((key) => {
                                    newOpenNameTooltip[key] = false;
                                });
                                return newOpenNameTooltip;
                            });
                        }}
                    >
                        {renderNameTooltip(page, index, locked, lockedBy)}
                    </WidthTableCell>

                    {/* PLACED TABLE CELL */}
                    <WidthTableCell {...PagesTableSizes.placed}>
                        <Labels
                            withTranslationTooltip
                            values={page.placed || []}
                            type={MoreInfoTypes.PLACED}
                            noOfLabels={isLargeDesktop ? 3 : isMobile ? 0 : 1}
                            onClickLabel={(obj) => {
                                navigate(buildPathWithProjectId(activeProjectId, PageRoutes.TARGET_GROUP.replace(':group_id', obj._id)));
                            }}
                        />
                    </WidthTableCell>

                    {/* TARGET CONDITIONS TABLE CELL */}
                    <WidthTableCell {...PagesTableSizes.conditions}>
                        <Labels
                            values={page?.conditions || []}
                            type={MoreInfoTypes.TARGETS}
                            noOfLabels={isLargeDesktop ? 3 : 1}
                            onClickLabel={(obj) => {
                                const isAudience = obj?.objectType === ObjectTypes.AUDIENCES;

                                navigate(
                                    buildPathWithProjectId(
                                        activeProjectId,
                                        isAudience ? PageRoutes.AUDIENCES : PageRoutes.TARGET_CONDITIONS
                                    ),
                                    {
                                        state: isAudience ? { audienceId: obj._id } : { conditionId: obj._id }
                                    }
                                );
                            }}
                        />
                    </WidthTableCell>

                    {/* LAST MODIFIED TABLE CELL */}
                    {!isMobile && (
                        <WidthTableCell {...PagesTableSizes.lastModified}>
                            <LastModifiedTableCell
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onLastModifiedInfoClick(page);
                                }}
                            >
                                <TruncatedText>{dateString}</TruncatedText>
                                {renderTooltipWithKey(<SVGInline src={icons.infoIcon} />, 'generic_icon_activity_log')}
                            </LastModifiedTableCell>
                        </WidthTableCell>
                    )}

                    {/* MODIFIED BY TABLE CELL */}
                    {isDesktop && (
                        <ImageTableCell
                            um={'px'}
                            shape="round"
                            src={page?.modifiedByUser?.icon || avatarIcon}
                            toolTipName={page?.modifiedByUser?.name}
                            imageSize={{ width: 32, height: 32 }}
                        />
                    )}

                    {/* ACTIONS TABLE CELL */}
                    <WidthTableCell {...PagesTableSizes.actions}>
                        <ActionsTableCell
                            tooltipTexts={{
                                duplicate: 'pages_icon_duplicate',
                                delete: 'pages_icon_delete',
                                copy: 'pages_icon_copy',
                                adminLock: page.adminLocked ? 'pages_admin_unlock' : 'pages_admin_lock'
                            }}
                            actions={actions}
                            onRemove={(e: React.MouseEvent<any>) => {
                                e.stopPropagation();
                                if (page.adminLocked) {
                                    return renderAdminLockedWarningAlert(page.name);
                                }
                                if (locked) {
                                    return renderLockedWarningAlert(lockedBy);
                                }
                                handleDeleteIconClick(page);
                            }}
                            onDuplicate={(e: React.MouseEvent<any>) => {
                                navigate(buildPathWithProjectId(activeProjectId, PageRoutes.NEW_PAGE), {
                                    state: { duplicatePageId: page._id }
                                });
                                e.stopPropagation();
                            }}
                            onMultiSelect={() => {
                                const pagesIds = pages.map((page) => page._id);
                                const newValues = select(pagesIds, multiSelectedIds, page._id);
                                setMultiSelectedIds([...newValues]);
                            }}
                            onCopy={(e: React.MouseEvent<any>) => {
                                e.stopPropagation();
                                if (page.adminLocked) {
                                    return renderAdminLockedWarningAlert(page.name);
                                }
                                if (locked) {
                                    return renderLockedWarningAlert(lockedBy);
                                }
                                setSelectedPageToCopy(page);
                                setShowCopyToProjectDialog(true);
                            }}
                            onAdminLock={(e: React.MouseEvent<any>) => {
                                e.stopPropagation();
                                page.adminLocked ? unlockPage(page._id) : lockPage(page._id);
                            }}
                            adminLocked={page.adminLocked}
                            canCopy={canCopy}
                            selected={multiSelectedIds.includes(page._id)}
                            publishedStatus={page.publishStatus}
                            publishAt={page.publishAt}
                        />
                    </WidthTableCell>
                </TableRow>
            );
        });

        return <TableBody>{rows}</TableBody>;
    };

    if (!loading && !userPermissions) {
        return <BackendErrorDialog error={{ status: 401 }} />;
    }

    const renderNoPages = () => (
        <NoResourcesContainer>
            <ResourceCard
                image={resourceCardImages.pagesNewCard}
                title={'Empty Page'}
                subtitle={'Create a page from scratch'}
                primaryButtonLabel={'Create Page'}
                secondaryButtonLabel={'Learn more'}
                onPrimaryButtonClick={() => {
                    navigate(buildPathWithProjectId(activeProjectId, PageRoutes.NEW_PAGE));
                }}
                onSecondaryButtonClick={() => {
                    openDocumentation(circleSlugs.pages);
                }}
                createResource
            />
            <ResourceCard
                image={resourceCardImages.pagesCard}
                title={'Select Template'}
                subtitle={'Add a page selecting existing templates'}
                primaryButtonLabel={'Select Template'}
                secondaryButtonLabel={'Learn more'}
                onPrimaryButtonClick={() => {
                    setOpenTemplateSelectionDialog(true);
                }}
                onSecondaryButtonClick={() => {
                    openDocumentation(circleSlugs.templates);
                }}
            />
        </NoResourcesContainer>
    );

    const isEmpty = !storePages?.length;
    const error = pagesError || templatesError || copyObjectError;

    const renderError = (error: any) => {
        switch (error.code) {
            case API_ERROR_CODES.REFERENCE_OBJECT:
                return renderReferencedObjectDeleteError(ObjectTypes.PAGES, error, translate);
            case API_ERROR_CODES.LOCKED_ERROR:
                return renderLockedError(error);
            case API_ERROR_CODES.ADMIN_LOCKED_ERROR:
                return renderAdminLockedError(error);
            default:
                return <BackendErrorDialog error={error} />;
        }
    };

    return (
        <>
            {error && renderError(error)}
            <ApplicationWrapper>
                <Sidebar />
                <MainContentWrapper>
                    <ScreenTitle
                        loading={loading}
                        title="Pages"
                        withAddButton={!isEmpty}
                        withProfile
                        addLabel={'Create Page'}
                        onAdd={() => {
                            handleCreateClick();
                        }}
                        circlesSlugOptions={{ default: CIRCLE_SLUGS.pages, onboarding: ONBOARDING_CIRCLE_SLUGS.pages }}
                    />
                    <PaginationWrapper
                        orderBy={orderBy}
                        totalPages={totalPages}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        activeObjectFilter={activeObjectFilter}
                        onSearchTermOrPaginationChange={onSearchTermOrPaginationChange}
                        setActiveObjectFilter={(value: ObjectFilter) => setActiveObjectFilter(value)}
                        resetCallback={resetCallback}
                        type={ObjectTypes.PAGES}
                    >
                        {loading ? (
                            <Loader title={'Pages'} />
                        ) : isEmpty ? (
                            renderNoPages()
                        ) : (
                            <>
                                <GenericTable body={buildTableBody()} columns={buildTableColumns()} dataCy={'pages-table'} />
                                <PageActionsWrapper>
                                    <PageActionButton
                                        onClick={() => {
                                            setPaginationState({
                                                pageSize,
                                                currentPage,
                                                searchTerm,
                                                sortConfig,
                                                activeSortingKey,
                                                activeObjectFilter
                                            });
                                            navigate(buildPathWithProjectId(activeProjectId, PageRoutes.NEW_PAGE));
                                        }}
                                        label={'Create Page'}
                                        type={'BLUE'}
                                    />
                                    <PageActionButton
                                        onClick={() => setOpenTemplateSelectionDialog(true)}
                                        label={'Select Template'}
                                        type={'BLUE'}
                                    />
                                </PageActionsWrapper>
                            </>
                        )}
                    </PaginationWrapper>
                </MainContentWrapper>
                {!!pages?.length && (
                    <CreateResourceDialog
                        title={'Page'}
                        open={openNewPageDialog}
                        onClose={() => setOpenNewPageDialog(false)}
                        handleCreateNewResourceClick={() => {
                            setPaginationState({ pageSize, currentPage, searchTerm, sortConfig, activeSortingKey, activeObjectFilter });
                            navigate(buildPathWithProjectId(activeProjectId, PageRoutes.NEW_PAGE));
                        }}
                        handleOpenTemplateClick={() => setOpenTemplateSelectionDialog(true)}
                        historyUrl={buildPathWithProjectId(activeProjectId, PageRoutes.NEW_PAGE)}
                    />
                )}
                <TemplateSelection
                    open={openTemplateSelectionDialog}
                    onClose={() => {
                        setOpenTemplateSelectionDialog(false);
                    }}
                    templateUrl={''}
                    resourceType={templateTypes.PAGE}
                    callback={(id?: string) => {
                        if (id) {
                            useTemplate(id);
                            setOpenNewPageDialog(false);
                            setOpenTemplateSelectionDialog(false);
                        } else {
                            setPaginationState({
                                pageSize,
                                currentPage,
                                searchTerm,
                                sortConfig,
                                activeSortingKey,
                                activeObjectFilter
                            });
                            navigate(buildPathWithProjectId(activeProjectId, PageRoutes.NEW_PAGE));
                        }
                    }}
                />
                <CopyToProjectDialog
                    open={showCopyToProjectDialog}
                    onCopy={(projectId, newTemplateValues, showItemActionWarningAfterCopy, withRedirect, hideSuccessToast) => {
                        handleCopyToProject(
                            projectId,
                            selectedPageToCopy?._id || '',
                            newTemplateValues || [],
                            showItemActionWarningAfterCopy,
                            withRedirect,
                            hideSuccessToast
                        );
                        setShowCopyToProjectDialog(false);
                        setSelectedPageToCopy(undefined);
                    }}
                    onClose={() => {
                        setShowCopyToProjectDialog(false);
                        setSelectedPageToCopy(undefined);
                    }}
                    objectType={ObjectTypes.PAGES}
                    objectId={selectedPageToCopy?._id || ''}
                    restrictedTemplatesForPage={
                        selectedPageToCopy?.intent &&
                        ![
                            PageIntents.BASIC,
                            PageIntents.CONTENT_WORLD,
                            PageIntents.HOME,
                            PageIntents.FAVOURITES,
                            PageIntents.RECORDINGS
                        ].includes(selectedPageToCopy.intent)
                            ? [templates.GALLERY]
                            : undefined
                    }
                />
                <ObjectActivityDialog
                    objectType={ObjectTypes.PAGES}
                    open={showObjectActivityDialog.open}
                    objectTitle={showObjectActivityDialog.objectTitle}
                    onClose={() => {
                        dispatch(unsetObjectActivity());
                        setShowObjectActivityDialog({
                            open: false,
                            objectTitle: ''
                        });
                    }}
                />
            </ApplicationWrapper>
        </>
    );
};

export default Pages;

import styled, { css, CSSProperties } from 'styled-components';
import getTheme, { fontSizesCSS, zIndexesCSS } from '../../../style/themes/theme';
import { ButtonInner, ButtonWrapper } from '../../Buttons/Button/Button.css';
import { UserAvatar } from '../../Projects/Projects.css';
import { devices, DropdownOptionWrapper, ObjectNameLabel } from '../../../style/styled-components/reusable.css';
import { CalendarContainer, DateContainer } from '../DatePicker/DatePicker.css';

const theme: any = getTheme();

const getOptionsIds = (indexes: number[]) => {
    let options: string[] = [];

    indexes.forEach((index) => {
        options.push(`[id$='-option-${index}']`);
    });

    return options.join(',');
};

// FANCY FILTER SELECT
export const DateSelectorWrapper = styled.div`
    position: relative;
`;

export const FilterValueWrapper = styled.div`
    gap: 8px;
    display: flex;
    cursor: pointer;
    padding: 5px 8px;
    align-items: center;
    box-sizing: border-box;
    ${fontSizesCSS.Caption3};
    border-radius: 4px 0 0 4px;
    background-color: ${theme.palette.background.main};
    border: 1px solid ${theme.palette.background.pressed};
    border-right: none;

    ${UserAvatar} {
        width: 16px;
        height: 16px;
    }

    svg {
        width: 16px;
        height: 16px;
        path {
            fill: ${theme.palette.text.main};
        }
    }
`;

export const OptionContainer = styled.div`
    align-items: center;
    display: flex;
    gap: 8px;
    ${UserAvatar} {
        width: 24px;
        height: 24px;
    }
    svg {
        width: 24px;
        height: 24px;
        path {
            fill: ${theme.palette.text.main};
        }
    }
`;

export const MultiValueContainer = styled.div`
    max-height: 28px;
    display: flex;
    gap: 2px;
`;

export const GroupColumnContainer = styled.div<{ numOfColumns: number }>`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    ${fontSizesCSS.Body1};

    .conditions-select__group {
        width: calc(100% / ${(props) => props.numOfColumns});
        &.full-width {
            width: 100%;
        }
    }
`;

export const OptionsColumnContainer = styled.div<{ numOfColumns: number }>`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    ${fontSizesCSS.Body1};

    .conditions-select__option {
        width: calc(100% / ${(props) => props.numOfColumns});
    }
`;

export const GroupHeadingText = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: bold;
    width: calc(100% - 18px); // leave space for checkmark
`;

export const GroupHeadingWithTooltip = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    span {
        display: flex;
        align-items: center;
        cursor: pointer;
    }
`;

export const HideWhenSelected = styled.div``;

export const SelectWrapper = styled.div<{
    $error: boolean;
    unclickableIndexes?: number[];
    $noError?: boolean;
    $isSingle?: boolean;
    $withoutLabel?: boolean;
    $withTopMargin?: boolean;
    inDialog?: boolean; // in case of targetCondition select, the menu should not have more with than the select
}>`
    outline: none;
    margin-top: ${({ $withTopMargin }) => ($withTopMargin ? '8px' : '0')};

    label {
        color: ${theme.palette.label.main};
        ${fontSizesCSS.Caption3}
        display: block;
        height: ${({ $withoutLabel }) => ($withoutLabel ? '0px' : '16px')};
        &.error {
            color: ${theme.palette.error.main};
            display: ${({ $noError }) => ($noError ? 'none' : 'block')};
            opacity: 1;
            margin-top: 4px;
            height: 16px;
            width: 100%;

            span {
                color: ${theme.palette.label.main};
            }
        }
    }
    ${ObjectNameLabel} {
        color: ${theme.palette.text.main};
    }
    // SELECT SINGLE VALUE STYLE
    .cc_select__single-value {
        text-overflow: ellipsis;
        ${fontSizesCSS.Body1};
        width: 100%;
        margin: 0;
        span {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    // SELECT MULTIPLE VALUE STYLE
    .search-filter-input__value-container {
        display: flex;
        cursor: text;
        height: 100%;
        padding: 0;
        top: -1px;
        gap: 8px;
    }

    .cc_select__multi-value,
    .conditions-select__multi-value {
        margin: 0;
        max-width: 116px;
        height: 28px;
        padding: 6px 8px;
        border-radius: 4px;
        box-sizing: border-box;
        border: 1px solid ${theme.palette.background.pressed};
        background-color: ${theme.palette.background.main};
            ${DropdownOptionWrapper} {
                width: 100%;
                svg {
                    max-width: 14px;
                    max-height: 14px;
                }
            }           
        }

    }

    .search-filter-input__multi-value {
        background-color: transparent;
        max-width: unset;
        margin: 0;
    }

    .cc_select__multi-value__remove,
    .conditions-select__multi-value__remove,
    .search-filter-input__multi-value__remove {
        opacity: 0.7;
        background-color: unset;
        &:hover {
            opacity: 1;
            background-color: unset;
            cursor: pointer;
        }
        svg {
            width: 13px;
            height: 13px;
        }
    }

    .search-filter-input__multi-value__remove {
        border: 1px solid ${theme.palette.background.pressed};
        background-color: ${theme.palette.background.main};
        border-radius: 0 4px 4px 0;
        border-left: none;
        padding: 0;
    }

    .conditions-select__menu {
        width: 608px;
        padding-right: 12px;
        // tablet and below that
        ${devices.tablet} {
            width: ${({ inDialog }) => (inDialog ? `100%` : '456px')};
        }
        ${({ inDialog }) => inDialog && `width:100%`}
    }
    .search-filter-input__menu {
        width: fit-content;
        position: relative;
        top: 0;
    }
    .search-filter-input__menu-list {
        max-height: 580px;
    }

    .conditions-select__menu-list {
        overflow: scroll;
        padding-right: 4px;
        ::-webkit-scrollbar {
            width: 8px;
        }
        ::-webkit-scrollbar-track {
            background: transparent;
            margin-top: 40px; // move down the start position of the scrollbar
            margin-left: 4px;
        }
        ::-webkit-scrollbar-thumb {
            background: ${theme.palette.background.border};
            border-radius: 8px;
        }
    }
    .conditions-select__group-heading {
        text-transform: unset; // override default capitalize
        &.with-pointer:hover {
            cursor: pointer;
        }
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: space-between;
        height: 24px;
        width: calc(100% - 8px); // 8px left margin
        background: ${theme.palette.background.pressed};
        color: ${theme.palette.black.main};
        span {
            ${fontSizesCSS.Caption4};
        }
        margin-left: 8px;
    }
    // SELECTED OPTION STYLE
    .cc_select__option--is-selected,
    .conditions-select__option--is-selected,
    .search-filter-input__option--is-selected {
        color: ${theme.palette.text.main};
        background-color: transparent;
    }
    .cc_select__option,
    .conditions-select__option,
    .search-filter-input__option {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > svg {
            min-width: 16px;
            margin-left: 10px;
        }

        &:hover {
            cursor: pointer;
            background-color: ${theme.palette.background.contrast};
        }

        & > :first-child,
        span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .search-filter-input__option {
        width: 240px;
        height: 48px;
    }
    .search-filter-input__clear-indicator {
        visibility: visible !important;
        opacity: 1 !important;
    }
    ${(props) =>
        props.unclickableIndexes
            ? css`
                  div.cc_select__menu-list {
                      ${getOptionsIds(props.unclickableIndexes)} {
                          pointer-events: none;
                      }
                  }
              `
            : css``}

    [class$='container'] {
        min-height: unset;
        height: 36px;
        margin-top: ${({ $withoutLabel }) => ($withoutLabel ? `0px` : '4px')};
    }
    [class$='menu'] {
        z-index: 4;
        margin-top: 2px;
    }
    [class$='control'] {
        min-height: unset;
        height: 36px;
        padding: 0 0 0 12px;
        border: solid 1px ${theme.palette.background.border};

        margin: 0;
        div.cc_select__value-container,
        div.conditions-select__value-container {
            padding: 0;
            display: flex;
            gap: 8px;
            //because of borders and sizes, 1px is off from being centered correctly
            top: -1px;
            // prevent wrapping, so it remains in a single row
            flex-wrap: nowrap;

            ${HideWhenSelected} {
                display: none;
            }
        }
        //the selected value will not display the tooltip
        div.object_name_with_tooltip {
            pointer-events: none;
            color: ${theme.palette.text.main};
        }
        div.custom-value-label {
            & > :first-child {
                height: 100%;
                width: 100%;
            }

            ${UserAvatar} {
                width: 14px;
                height: 14px;
                min-width: 14px;
                min-height: 14px;
            }

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            ${fontSizesCSS.Caption3};
        }
    }

    ${DateSelectorWrapper} ${DateContainer} {
        ${CalendarContainer} {
            position: relative;
            height: 360px;

        }
        div.react-datepicker__tab-loop {
            position: relative;
            top: 10px;
            left: 0;
        }
        div.react-datepicker-popper {
            position: relative !important;
            transform: unset !important;
        }
        [class$='container'] {
            height: fit-content;
            border-bottom: none;
        }
    }
`;

export const dropdownButtonStyle: CSSProperties = {
    color: '#999',
    margin: '0',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    minHeight: '33px',
    maxHeight: 'max-content',
    flexWrap: 'wrap',
    borderRadius: '4px',
    backgroundColor: theme.palette.white.main,
    padding: '6px 8px'
};

export const DropdownIndicatorWrapper = styled.div`
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        opacity: 0.3;
        width: 12px;
        height: 12px;
    }
`;

export const StyledLabel = styled.label<{ $error?: boolean; $selected?: boolean }>`
    ${fontSizesCSS.Caption3}
    label {
        color: ${theme.palette.text.main};
    }
    ${ButtonWrapper} {
        border: solid 1px ${(props) => (props.$error ? theme.palette.error.main : theme.palette.background.border)};
    }
    ${ButtonInner} {
        color: ${({ $selected }) => ($selected ? theme.palette.grey.main : '')};
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 2px;
    }
    .MuiTouchRipple-root {
        display: none;
    }
`;

export const DropdownWrapper = styled.div`
    height: 76px;
    [class$='menu'] {
        margin: 0;
        position: absolute;
        scrollbar-width: none;
    }
    [class$='menu'] ::-webkit-scrollbar {
        display: none;
    }
    label {
        ${fontSizesCSS.Caption3}
        &.error {
            color: ${theme.palette.error.main};
            opacity: 1;
        }
    }
`;

export const ButtonItemWrapper = styled.div`
    z-index: ${zIndexesCSS.zIndexDropdown + 100};
    display: flex;
    flex-direction: row;
    gap: 1px;
    background-color: rgb(230, 230, 230);
    border-radius: 4px;
    button {
        color: ${theme.palette.error.main};
        border: none;
        font-weight: bold;
        background-color: transparent;
        &:hover {
            color: ${theme.palette.error.main};
            background-color: ${theme.palette.error.hover};
            border-bottom-right-radius: 4px;
            border-top-right-radius: 4px;
        }
    }
`;

export const AddNewOption = styled.div`
    width: 100%;
    cursor: pointer;
    display: flex;
    gap: 8px;
    color: ${theme.palette.primary.main};
    ${fontSizesCSS.Body1};
    box-sizing: border-box;
    align-items: center;

    svg {
        width: 16px;
        height: 16px;
        path {
            fill: ${theme.palette.primary.main};
        }
    }
`;

export const AutocompleteSelectWrapper = styled.div`
    .MuiAutocomplete-inputRoot {
        padding: 0 12px;
    }
    .MuiAutocomplete-inputRoot[class*='MuiInput-root'] .MuiAutocomplete-input:first-child {
        padding: 0;
    }
    .MuiInputAdornment-root {
        cursor: pointer;
        svg {
            path {
                fill: ${theme.palette.background.border};
            }
        }

        &:hover {
            svg {
                path {
                    fill: ${theme.palette.background.hover};
                }
            }
        }
    }

    .MuiAutocomplete-popper {
        .MuiAutocomplete-paper {
            margin-top: -20px;
            width: 100%;
        }
    }

    .MuiAutocomplete-option {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: transparent;
        display: block;

        &:hover {
            cursor: pointer;
            background-color: ${theme.palette.background.contrast};
        }
    }
`;

export const AdornmentSpan = styled.span`
    opacity: 0.5;
    ${fontSizesCSS.Body1};
`;

//VIRTUALIZED LIST

export const GridWrapper = styled.div`
    .virtualized_grid_wrapper {
        ::-webkit-scrollbar {
            width: 8px;
        }
        ::-webkit-scrollbar-thumb {
            background-color: ${theme.palette.background.border};
            width: 8px;
            height: 72px;
            border-radius: 8px;
        }
    }
`;

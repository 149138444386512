import { IconButton, InputAdornment } from '@material-ui/core';
import _ from 'lodash';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import icons from '../../../../assets/images/icons';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../../hooks/redux';
import { DisplayConditionsState, fetchPredefinedValues } from '../../../../redux/slices/displayConditionsSlice';
import { LanguagesState } from '../../../../redux/slices/languagesSlice';
import { generateTimeString } from '../../../../utils/fnDate';
import { validator } from '../../../../utils/fnValidator';
import { DatePickerComponent as DatePicker } from '../../../common/DatePicker/DatePicker';
import GenericDialog, {
    DialogButton,
    DialogDropdownMultiple,
    DialogDropdownSingle,
    DialogRadioButton,
    DialogTextField,
    DialogTypes
} from '../../../common/Dialog/GenericDialog';
import { TimePicker } from '../../../common/TimePicker/TimePicker';
import { SelectLanguageDropdownLabel } from '../../../Languages/Dialogs/NewLanguage.css';
import SVGInline from 'react-inlinesvg';
import { StateToggleWrapper } from '../Conditions.css';
import { TimePickerTextField } from '../../../common/TimePicker/TimePicker.css';
import { CIRCLE_SLUGS } from '../../../common/HelpIcon/HelpIcon';
import {
    CategoriesKeys,
    KeysPlaceHolders,
    SubscriptionOptions,
    booleanCategoryKeys,
    keysTitle,
    valueKeys
} from '../../../../types/DisplayCondition';
import { ConfigurableKeys, ConfigurableSelect } from '../ConfigurableSelect';
import { ConfigurableValuesState } from '../../../../redux/slices/configurableValuesSlice';
import BackendErrorDialog from '../../../common/Dialog/BackendErrorDialog';

export type NewConditionProps = {
    open: boolean;
    conditionsKeysToExclude: string[];
    onClose: () => void;
    onSave: (item: { [key: string]: any }) => void;
    condition?: { [key: string]: any };
};

export const NewConditionDialog: FC<NewConditionProps> = ({ open, conditionsKeysToExclude, onClose, onSave, condition }) => {
    const { error: configurableValueError }: ConfigurableValuesState = useAppSelector((state) => state.configurableValues);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isTimePickerOpen, setIsTimePickerOpen] = useState<boolean>(false);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState<number>(-1);

    const [conditionType, setConditionType] = useState<string>('');
    const [conditionOptions, setConditionOptions] = useState<{ label: string; value: string }[]>([]);

    const [conditionValuesOptions, setConditionValuesOptions] = useState<
        { label: string | number | ReactElement; value: string | number }[]
    >([]);
    const [conditionValues, setConditionValues] = useState<any[]>([]);

    const [selectvaluesOptions, setSelectValuesOptions] = useState<{
        [key: string]: { title: string | number; value: string | number; flag?: string }[];
    }>({});

    const [ratingValue, setRatingValue] = useState<number | null>(null);
    const [subscribedValue, setSubscribedValue] = useState<string>('');
    const [personaValue, setPersonaValue] = useState<string>('');
    const [happinessValue, setHappinessValue] = useState<string>('');
    const [dateIntervals, setDateIntervals] = useState<{ startDate: number; endDate: number }[]>([]);
    const [timeIntervals, setTimeIntervals] = useState<{ startTime: number; endTime: number }[]>([{ startTime: 0, endTime: 0 }]);
    const [guestMode, setGuestMode] = useState(true);
    const [coldStart, setColdStart] = useState(true);
    const [timeEditingIndex, setTimeEditingIndex] = useState<number>(-1);
    const [errors, setErrors] = useState<{
        conditionType?: string;
        conditionArrayValues?: string;
        rating?: string;
        subscribed?: string;
        persona?: string;
        happiness?: string;
    }>({});

    const [inverted, setInverted] = useState<any>({});

    const { predefinedValues }: DisplayConditionsState = useAppSelector((state) => state.displayConditions);
    const { languageCodes }: LanguagesState = useAppSelector((state) => state.languages);

    const dispatch = useDispatch();

    const onCloseClick = () => {
        setConditionType('');
        setConditionOptions([]);
        setConditionValuesOptions([]);
        setSelectValuesOptions({});
        resetValues();

        onClose && onClose();
    };

    const resetValues = () => {
        setConditionValues([]);
        setSubscribedValue('');
        setSubscribedValue('');
        setRatingValue(null);
        setPersonaValue('');
        setHappinessValue('');
        setDateIntervals([]);
        setTimeIntervals([{ startTime: 0, endTime: 0 }]);
        setGuestMode(true);
        setColdStart(true);
        setErrors({});
        setInverted({});
    };

    const onSaveClick = () => {
        if (!validateCondition()) return;

        const values: any = {
            subscriptionStatus: subscribedValue.trim(),
            ageRating: ratingValue,
            weekDays: conditionValues,
            languages: conditionValues,
            countries: conditionValues,
            timeInterval: timeIntervals,
            dateInterval: dateIntervals,
            persona: personaValue,
            happinessScore: happinessValue,
            guestMode: guestMode,
            coldStart: coldStart,
            segments: conditionValues,
            dataProtectionRegulation: conditionValues
        };

        const newCondition = {
            [conditionType]: {
                is: !inverted[conditionType],
                value: values[conditionType]
            }
        };

        onSave && onSave(newCondition);
        onCloseClick();
    };

    const loadValues = async () => {
        return await dispatch(fetchPredefinedValues()).unwrap();
    };

    const cancelButton: DialogButton = {
        label: 'Cancel',
        type: 'DEFAULT',
        onClick: onCloseClick
    };

    const saveButton: DialogButton = {
        label: 'Add',
        type: 'BLUE',
        onClick: onSaveClick
    };
    const ValueSetters: any = {
        weekDays: setConditionValues,
        languages: setConditionValues,
        countries: setConditionValues,
        segments: setConditionValues,
        ageRating: setRatingValue,
        subscriptionStatus: setSubscribedValue,
        timeInterval: setTimeIntervals,
        dateInterval: setDateIntervals,
        persona: setPersonaValue,
        happinessScore: setHappinessValue,
        guestMode: setGuestMode,
        coldStart: setColdStart,
        dataProtectionRegulation: setConditionValues
    };

    const validateCondition = () => {
        const newErrors = { ...errors };

        newErrors.conditionType = validator({ required: true }, conditionType);

        switch (conditionType) {
            case CategoriesKeys.WEEK_DAYS:
            case CategoriesKeys.LANGUAGES:
            case CategoriesKeys.COUNTRIES:
            case CategoriesKeys.SEGMENTS:
            case CategoriesKeys.DATA_PROTECTION_REGULATION:
                newErrors.conditionArrayValues = validator({ required: true }, conditionValues);
                break;
            case CategoriesKeys.RATING:
                newErrors.rating = validator({ required: true }, ratingValue ? `${ratingValue}` : '');
                break;

            case CategoriesKeys.SUBSCRIBED:
                newErrors.subscribed = validator({ required: true }, subscribedValue.trim());
                break;
            case CategoriesKeys.PERSONA:
                newErrors.persona = validator({ required: true }, personaValue);
                break;
            case CategoriesKeys.HAPPINESS:
                newErrors.happiness = validator({ required: true }, happinessValue);
                break;

            default:
                break;
        }

        setErrors(newErrors);
        return Object.values(newErrors).filter((value) => !!value).length === 0;
    };
    const cancelAdornment = (index: number) => (
        <InputAdornment position="end">
            <IconButton
                onClick={(e: any) => {
                    e.stopPropagation();
                    setTimeIntervals((intervals: any[]) => {
                        const newIntervals = [...intervals];
                        newIntervals.splice(index, 1);
                        return newIntervals;
                    });
                }}
            >
                <SVGInline src={icons.closeIcon} />
            </IconButton>
        </InputAdornment>
    );

    const renderFieldByType = (type: CategoriesKeys) => {
        switch (type) {
            case CategoriesKeys.WEEK_DAYS:
            case CategoriesKeys.LANGUAGES:
            case CategoriesKeys.COUNTRIES:
                return (
                    <DialogDropdownMultiple
                        notSorted={type === CategoriesKeys.WEEK_DAYS}
                        options={conditionValuesOptions}
                        placeholder={KeysPlaceHolders[type]}
                        onChange={(value: any) => {
                            setConditionValues(value.map((v: any) => v.value));
                            setErrors(_.omit(errors, 'conditionArrayValues'));
                        }}
                        value={conditionValuesOptions.filter((opt) => conditionValues.includes(opt.value))}
                        error={errors.conditionArrayValues}
                    />
                );
            case CategoriesKeys.DATA_PROTECTION_REGULATION:
                return (
                    <ConfigurableSelect
                        type={ConfigurableKeys.DISPLAY_CONDITIONS_DATA_PROTECTION_REGULATION}
                        selectedValue={conditionValues}
                        onSelectedValuesChange={(values) => {
                            setConditionValues(values);
                            setErrors(_.omit(errors, 'conditionArrayValues'));
                        }}
                        error={errors.conditionArrayValues}
                        isMultiple
                    />
                );
            case CategoriesKeys.SEGMENTS:
                return (
                    <ConfigurableSelect
                        type={ConfigurableKeys.DISPLAY_CONDITIONS_SEGMENTS}
                        selectedValue={conditionValues}
                        onSelectedValuesChange={(values) => {
                            setConditionValues(values);
                            setErrors(_.omit(errors, 'conditionArrayValues'));
                        }}
                        error={errors.conditionArrayValues}
                        isMultiple
                    />
                );

            case CategoriesKeys.RATING:
                return (
                    <ConfigurableSelect
                        type={ConfigurableKeys.DISPLAY_CONDITIONS_RATINGS}
                        selectedValue={ratingValue}
                        onSelectedValuesChange={(value) => {
                            setRatingValue(value);
                            setErrors(_.omit(errors, 'rating'));
                        }}
                        error={errors.rating}
                    />
                );
            case CategoriesKeys.DATES:
                return (
                    <>
                        {dateIntervals.map((interval, index) => {
                            return (
                                <DatePicker
                                    toggleOpenDialog={(state) => setIsDatePickerOpen(state ? index : -1)}
                                    isOpen={isDatePickerOpen === index}
                                    key={`date_picker_${index}`}
                                    sDate={interval?.startDate}
                                    eDate={interval?.endDate}
                                    withCancel={index > 0}
                                    index={index}
                                    isRange
                                    saveDate={(date) =>
                                        setDateIntervals((intervals: any[]) => {
                                            const newIntervals = [...intervals];
                                            newIntervals.splice(index, 1, date);
                                            return newIntervals;
                                        })
                                    }
                                    onCancel={() => {
                                        setDateIntervals((intervals: any[]) => {
                                            const newIntervals = [...intervals];
                                            newIntervals.splice(index, 1);
                                            return newIntervals;
                                        });
                                    }}
                                />
                            );
                        })}
                        <DatePicker
                            toggleOpenDialog={(state) => setIsDatePickerOpen(state ? dateIntervals.length + 1 : -1)}
                            saveDate={(date) => setDateIntervals([...dateIntervals, date])}
                            isOpen={isDatePickerOpen === dateIntervals.length + 1}
                            index={dateIntervals.length}
                            isRange
                        />
                    </>
                );

            case CategoriesKeys.TIMES:
                return (
                    <>
                        {timeIntervals.map((interval, index) => {
                            return (
                                <TimePickerTextField key={index}>
                                    <DialogTextField
                                        optional={index > 0}
                                        label={`Time interval ${index + 1}`}
                                        toolTipText={'Only one interval is mandatory'}
                                        InputProps={index > 0 && { endAdornment: cancelAdornment(index) }}
                                        value={`${generateTimeString(interval.startTime)} - ${generateTimeString(interval.endTime)}`}
                                        onChange={() => {}}
                                        isDisabled
                                        onClick={() => {
                                            setIsTimePickerOpen(true);
                                            setTimeEditingIndex(index);
                                        }}
                                        whiteWhileDisabled
                                    />
                                </TimePickerTextField>
                            );
                        })}
                        <TimePickerTextField>
                            <DialogTextField
                                value={``}
                                onChange={() => {}}
                                isDisabled
                                onClick={() => setIsTimePickerOpen(true)}
                                label={`Time interval ${timeIntervals.length + 1}`}
                                optional={timeIntervals.length > 0}
                                toolTipText={'Only one interval is mandatory'}
                                whiteWhileDisabled
                            />
                        </TimePickerTextField>
                    </>
                );

            case CategoriesKeys.SUBSCRIBED:
                return (
                    <ConfigurableSelect
                        type={ConfigurableKeys.DISPLAY_CONDITIONS_SUBSCRIBED}
                        selectedValue={subscribedValue}
                        onSelectedValuesChange={(value) => {
                            setSubscribedValue(value);
                            setErrors(_.omit(errors, 'subscribed'));
                        }}
                        error={errors.subscribed}
                    />
                );
            case CategoriesKeys.PERSONA:
                return (
                    <DialogTextField
                        placeholder={KeysPlaceHolders[type]}
                        label={KeysPlaceHolders[type]}
                        value={personaValue}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setPersonaValue(e.target.value);
                            setErrors(_.omit(errors, 'persona'));
                        }}
                        error={errors.persona}
                    />
                );
            case CategoriesKeys.HAPPINESS:
                return (
                    <DialogTextField
                        placeholder={KeysPlaceHolders[type]}
                        label={KeysPlaceHolders[type]}
                        value={happinessValue}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setHappinessValue(e.target.value);
                            setErrors(_.omit(errors, 'happiness'));
                        }}
                        error={errors.happiness}
                    />
                );
            case CategoriesKeys.GUEST_MODE:
                return (
                    <StateToggleWrapper>
                        <DialogRadioButton onClick={() => setGuestMode(true)} text={'Guest'} active={guestMode} />
                        <DialogRadioButton onClick={() => setGuestMode(false)} text={'Logged-in'} active={!guestMode} />
                    </StateToggleWrapper>
                );
            case CategoriesKeys.COLD_START:
                return (
                    <StateToggleWrapper>
                        <DialogRadioButton onClick={() => setColdStart(true)} text={'Completed'} active={coldStart} />
                        <DialogRadioButton onClick={() => setColdStart(false)} text={'Not completed'} active={!coldStart} />
                    </StateToggleWrapper>
                );
            default:
                return null;
        }
    };

    useEffect(() => {
        if (!condition) return;
        const key = Object.keys(condition)?.[0] || '';
        const value = condition[key].value;

        setConditionType(key);
        setInverted({
            ...inverted,
            [key]: !condition[key].is
        });
        ValueSetters[key]?.(value);
    }, [condition]);

    useEffect(() => {
        setConditionOptions(
            valueKeys
                .filter((value) => !conditionsKeysToExclude.includes(value))
                .map((value) => {
                    return {
                        label: keysTitle[value],
                        value: value
                    };
                })
        );
    }, [open]);

    useEffect(() => {
        if (!languageCodes || !predefinedValues) return;

        const languages = languageCodes.map((l) => {
            return {
                title: l.name,
                value: l.code,
                flag: l.flag
            };
        });

        const newValues = {
            ...predefinedValues,
            languages
        };

        setSelectValuesOptions(newValues);
    }, [languageCodes, predefinedValues]);

    useEffect(() => {
        if (!open) {
            setIsOpen(false);
            return;
        }
        loadValues().then(() => {
            setIsOpen(true);
        });
    }, [open]);

    useEffect(() => {
        if (!conditionType || !Object.keys(selectvaluesOptions).length) return;
        if (
            [
                CategoriesKeys.SUBSCRIBED,
                CategoriesKeys.DATES,
                CategoriesKeys.TIMES,
                CategoriesKeys.HAPPINESS,
                CategoriesKeys.PERSONA,
                CategoriesKeys.GUEST_MODE,
                CategoriesKeys.COLD_START
            ].includes(conditionType as CategoriesKeys)
        )
            return;

        if (conditionType === CategoriesKeys.LANGUAGES) {
            setConditionValuesOptions(
                (selectvaluesOptions[conditionType] || []).map((condition) => {
                    return {
                        label: (
                            <span>
                                <SelectLanguageDropdownLabel>
                                    <img alt={`${condition.value}`} src={condition.flag} />
                                    {`${condition.title} (${condition.value})`}
                                </SelectLanguageDropdownLabel>
                            </span>
                        ),
                        value: condition.value,
                        valueForSearch: `${condition.title} ${condition.value}`
                    };
                })
            );

            return;
        }

        if (conditionType === CategoriesKeys.COUNTRIES) {
            setConditionValuesOptions(
                selectvaluesOptions[conditionType].map((condition) => {
                    return {
                        label: (
                            <span>
                                <SelectLanguageDropdownLabel>
                                    <img alt={`${condition.value}`} src={condition.flag} />
                                    {condition.title
                                        .toString()
                                        .split('_')
                                        .map((s) => (s !== 'and' ? _.capitalize(s) : 'and'))
                                        .join(' ')}
                                </SelectLanguageDropdownLabel>
                            </span>
                        ),
                        value: condition.value,
                        valueForSearch: condition.title
                    };
                })
            );

            return;
        }

        setConditionValuesOptions(
            (selectvaluesOptions[conditionType] || []).map((condition) => {
                const title = conditionType === CategoriesKeys.RATING ? `${condition.title}+` : condition.title;
                return {
                    label: title,
                    value: condition.value
                };
            })
        );
    }, [conditionType, selectvaluesOptions]);

    if (!isOpen) return null;

    return (
        <>
            {configurableValueError && <BackendErrorDialog error={configurableValueError} />}
            <GenericDialog
                onClose={() => onCloseClick()}
                actionButtons={[cancelButton, saveButton]}
                type={DialogTypes.Form}
                title={'Add Condition'}
                circlesSlugOptions={{ default: CIRCLE_SLUGS.conditions }}
            >
                <DialogDropdownSingle
                    options={conditionOptions}
                    placeholder={'Select Condition to add'}
                    onChange={(e: any) => {
                        setConditionType(e.value);
                        resetValues();
                    }}
                    value={conditionOptions.find((condition) => condition.value === conditionType)}
                    error={errors.conditionType}
                />

                {!!conditionType &&
                    !booleanCategoryKeys.includes(conditionType as CategoriesKeys) && ( // Show the inverter for non-boolean conditions
                        <StateToggleWrapper>
                            <DialogRadioButton
                                onClick={() => {
                                    setInverted({
                                        ...inverted,
                                        [conditionType]: false
                                    });
                                }}
                                text={'Is'}
                                active={!inverted[conditionType]}
                            />
                            <DialogRadioButton
                                onClick={() => {
                                    setInverted({
                                        ...inverted,
                                        [conditionType]: true
                                    });
                                }}
                                text={'Is not'}
                                active={inverted[conditionType] === true}
                            />
                        </StateToggleWrapper>
                    )}
                {!!conditionType && renderFieldByType(conditionType as CategoriesKeys)}
                <TimePicker
                    sTime={timeEditingIndex >= 0 ? timeIntervals[timeEditingIndex]?.startTime : undefined}
                    eTime={timeEditingIndex >= 0 ? timeIntervals[timeEditingIndex]?.endTime : undefined}
                    saveTime={(time) => {
                        if (timeEditingIndex >= 0) {
                            setTimeIntervals((intervals: any[]) => {
                                const newIntervals = [...intervals];
                                newIntervals.splice(timeEditingIndex, 1, time);
                                return newIntervals;
                            });
                        } else {
                            setTimeIntervals([...timeIntervals, time]);
                        }
                    }}
                    open={isTimePickerOpen}
                    onClose={() => {
                        setIsTimePickerOpen(false);
                        setTimeEditingIndex(-1);
                    }}
                    inRange
                />
            </GenericDialog>
        </>
    );
};

import React, { useEffect, useRef, useState } from 'react';
import { IconButton, InputAdornment } from '@material-ui/core';
import { dialogAlert, DIALOG_NAMES, DIALOG_TITLES_MAP, ToastAlert } from '../../../utils/fnDialogs';
import { DialogDropdownSingle, DialogTextField } from '../../common/Dialog/GenericDialog';

import icons from '../../../style';
import SVGInline from 'react-inlinesvg';
import { validator } from '../../../utils/fnValidator';
import _ from 'lodash';
import configServiceAPI from '../../../utils/api/configServiceAPI';
import { SelectLanguageDropdownLabel } from '../../Languages/Dialogs/NewLanguage.css';
import { DoubleFieldWrapper, FormDescription, FormSubtitle, FormTitle, FormWrapper, ResendLabel, sendOtpButtonStyle } from '../Login.css';
import { API_ERROR_CODES } from '../../../utils/Globals';
import Button from '../../Buttons/Button/Button';
import BackendErrorDialog from '../../common/Dialog/BackendErrorDialog';

export type ActivateUserDialogProps = {
    onActivate: any;
    errorMsg: string;
    token: string;
    phoneOnly?: boolean;
    returnToLoginCallback?: () => void;
    userId?: string;
};

const ActivateUserDialog: React.FC<ActivateUserDialogProps> = ({
    onActivate,
    errorMsg,
    token,
    phoneOnly,
    returnToLoginCallback,
    userId
}) => {
    const [errors, setErrors] = useState<{ password?: string; repeat?: string; phoneNumber?: string; otp?: string }>({});
    const [newPassword, setNewPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberVerified, setPhoneNumberVerified] = useState(false);
    const [otp, setOtp] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [prefixes, setPrefixes] = useState<any[] | null>(null);
    const [selectedPrefix, setSelectedPrefix] = useState<any>(undefined);
    const [loading, setLoading] = useState(false);
    const [generalError, setGeneralError] = useState<any>(null);
    const [disabled2FA, setDisabled2FA] = useState<boolean | undefined>(undefined);

    const otpInputRef = useRef<HTMLInputElement>();
    const newPasswordInputRef = useRef<HTMLInputElement>();

    useEffect(() => {
        loadPrefixes().then((data) => setPrefixes(data));
        check2FADisabled().then((isDisabled) => setDisabled2FA(isDisabled));
    }, []);

    useEffect(() => {
        if (!errorMsg) return;
        setErrors({
            password: errorMsg,
            repeat: errorMsg
        });
    }, [errorMsg]);

    const check2FADisabled = async () => {
        const result = await configServiceAPI.check2FADisabled();
        return !!result.response?.data?.isDisabled;
    };

    const loadPrefixes = async () => {
        const result = await configServiceAPI.loadPhonePrefixes();
        return result.response?.data || [];
    };

    const checkMatchingPasswords = (value: string) => {
        return value !== newPassword ? 'Passwords do not match!' : null;
    };

    const handleActivateClick = (evt: any) => {
        evt.preventDefault();
        if (Object.keys(errors).length > 0) return;
        const phoneNumberValue = disabled2FA ? '[EMPTY]' : `${selectedPrefix}${phoneNumber}`;
        onActivate?.(newPassword, phoneNumberValue);
    };

    const handleSendOTPClick = async (evt: any, resend = false) => {
        evt.preventDefault();
        setLoading(true);
        if (errors.phoneNumber) return;
        let values = {
            title: '',
            text: ''
        };
        const result = await (resend
            ? configServiceAPI.resendOTP(`${selectedPrefix}${phoneNumber}`)
            : configServiceAPI.sendOTP(`${selectedPrefix}${phoneNumber}`));
        if (!result.response?.data) {
            setLoading(false);
            if (result?.error?.code === API_ERROR_CODES.DATA_VALIDATION_ERROR_PHONE_NUMBER) {
                return setErrors({
                    ...errors,
                    phoneNumber: 'Phone number is not valid'
                });
            }
            values.title = 'Code could not be sent';
            values.text = 'The Code could not be sent to the given phone number! ' + (result?.error?.message || '');
            return dialogAlert('', false, values);
        }
        ToastAlert('success', 'Success', 'The Code has been sent to the specified phone number.');
        setOtpSent(true);
        setLoading(false);
        otpInputRef.current?.focus();
    };

    const handleVerifyOTPClick = async (evt: any) => {
        evt.preventDefault();
        setLoading(true);
        if (Object.keys(errors).length > 0) return;
        const result = await configServiceAPI.verifyOTP(`${selectedPrefix}${phoneNumber}`, otp, token, userId);
        if (!result.response?.data) {
            const newErrors = { ...errors };
            setLoading(false);

            switch (result.error?.code) {
                case API_ERROR_CODES.AUTHENTICATION_ERROR:
                    setGeneralError(result.error);
                    break;
                case API_ERROR_CODES.DATA_VALIDATION_ERROR_PHONE_NUMBER:
                    newErrors.phoneNumber = 'Phone number is not valid or is already used';
                    break;
                case API_ERROR_CODES.AUTHENTIFICATION_ERROR_OTP_NOT_VALID:
                    newErrors.otp = result.error.message;
                    break;
                default:
                    newErrors.otp = 'The Code could not be verified';
                    break;
            }

            return setErrors(newErrors);
        }
        ToastAlert('success', 'Success', 'The Code has been verified.');
        setLoading(false);

        if (phoneOnly) return returnToLoginCallback?.();

        setPhoneNumberVerified(true);
        newPasswordInputRef.current?.focus();
    };

    const render2FASetup = () => {
        const { title, text, subtitle } = DIALOG_TITLES_MAP.get(DIALOG_NAMES.ACTIVATE_USER_PHONE) ?? {};
        return (
            <FormWrapper>
                <FormTitle>{title}</FormTitle>
                <FormSubtitle>{subtitle}</FormSubtitle>
                <FormDescription>{text}</FormDescription>
                <DoubleFieldWrapper $width1={0}>
                    <DialogDropdownSingle
                        labelText={'Country Codes'}
                        isDisabled={phoneNumberVerified || otpSent}
                        placeholder={''}
                        onChange={(value: any) => {
                            setSelectedPrefix(value?.value);
                            // after selecting / changing a prefix, recheck the whether there's an error with the phoneNumber or not
                            const error = validator({ required: true, minLength: 8 }, phoneNumber);
                            setErrors(!!error ? _.set(errors, 'phoneNumber', error) : _.omit(errors, 'phoneNumber'));
                        }}
                        value={prefixesOptions.length ? prefixesOptions.find((o) => o.value === selectedPrefix) : undefined}
                        options={prefixesOptions}
                    />

                    <DialogTextField
                        value={phoneNumber}
                        error={errors.phoneNumber}
                        label={'Phone Number'}
                        type={'text'}
                        isDisabled={phoneNumberVerified || otpSent}
                        InputProps={phoneNumberVerified && { endAdornment: checkmarkAdornment() }}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                            const { value } = evt.target;
                            if (value && !/^\d+$/.test(value)) return;
                            setPhoneNumber(value);
                            const error = validator({ required: true, minLength: 8 }, value);
                            setErrors(!!error ? _.set(errors, 'phoneNumber', error) : _.omit(errors, 'phoneNumber'));
                        }}
                        onKeyPress={(evt: any) => {
                            if (evt.key === 'Enter') {
                                otpSent ? handleVerifyOTPClick(evt) : handleSendOTPClick(evt);
                            }
                        }}
                    />
                </DoubleFieldWrapper>
                {otpSent && !phoneNumberVerified && (
                    <>
                        <DialogTextField
                            inputRef={otpInputRef}
                            value={otp}
                            label={'Enter the Code received via SMS'}
                            error={errors?.otp}
                            type={'text'}
                            onKeyPress={(evt: any) => {
                                if (evt.key === 'Enter') {
                                    document.getElementById('verify-otp-button')?.click();
                                }
                            }}
                            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                const { value } = evt.target;
                                setOtp(value);
                                setErrors(_.omit(errors, 'otp'));
                            }}
                            withTopMargin
                        />
                        <ResendLabel onClick={(evt) => handleSendOTPClick(evt, true)}>{`Didn't get an SMS? Resend Code!`}</ResendLabel>
                    </>
                )}
                <Button
                    onClick={otpSent ? handleVerifyOTPClick : handleSendOTPClick}
                    loading={loading}
                    id="verify-otp-button"
                    label={otpSent ? 'Verify Code' : 'Send Code'}
                    type="BLUE"
                    style={sendOtpButtonStyle}
                    isDisabled={!!errors?.otp || !!errors.phoneNumber}
                />
            </FormWrapper>
        );
    };

    const renderPasswordSetup = () => {
        const { title, text, subtitle } = DIALOG_TITLES_MAP.get(DIALOG_NAMES.ACTIVATE_USER_PASSWORD) ?? {};
        return (
            <FormWrapper>
                <FormTitle>{title}</FormTitle>
                <FormSubtitle>{subtitle}</FormSubtitle>
                <FormDescription>{text}</FormDescription>
                <DialogTextField
                    inputRef={newPasswordInputRef}
                    value={newPassword}
                    error={errors.password}
                    label={'Enter New Password'}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{ endAdornment: passwordAdornment() }}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                        setNewPassword(evt.target.value);
                        const error = validator({ required: true, minLength: 8, password: true }, evt.target.value);
                        setErrors(!!error ? _.set(errors, 'password', error) : _.omit(errors, 'password'));
                    }}
                    withTopMargin
                />
                <DialogTextField
                    value={repeatPassword}
                    error={errors.repeat}
                    label={'Repeat New Password'}
                    type={showRepeatPassword ? 'text' : 'password'}
                    InputProps={{ endAdornment: passwordAdornment(true) }}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                        setRepeatPassword(evt.target.value);
                        const error =
                            validator({ required: true, minLength: 8, password: true }, evt.target.value) ||
                            checkMatchingPasswords(evt.target.value);
                        setErrors(!!error ? _.set(errors, 'repeat', error) : _.omit(errors, 'repeat'));
                    }}
                    onKeyPress={(evt: any) => {
                        if (evt.key === 'Enter') {
                            document.getElementById('activate-button')?.click();
                        }
                    }}
                    withTopMargin
                />
                <Button
                    onClick={handleActivateClick}
                    loading={loading}
                    id="activate-button"
                    label={'Activate'}
                    type="BLUE"
                    style={sendOtpButtonStyle}
                />
            </FormWrapper>
        );
    };

    const passwordAdornment = (repeat = false) => (
        <InputAdornment position="end">
            <IconButton
                onClick={(evt) => {
                    evt.preventDefault();
                    if (repeat) {
                        setShowRepeatPassword(!showRepeatPassword);
                    } else {
                        setShowPassword(!showPassword);
                    }
                }}
            >
                {repeat ? (
                    <SVGInline src={showRepeatPassword ? icons.hidePasswordIcon : icons.showPasswordIcon} />
                ) : (
                    <SVGInline src={showPassword ? icons.hidePasswordIcon : icons.showPasswordIcon} />
                )}
            </IconButton>
        </InputAdornment>
    );

    const checkmarkAdornment = () => (
        <InputAdornment position="end">
            <IconButton>
                <SVGInline src={icons.checkmarkCircularIcon} />
            </IconButton>
        </InputAdornment>
    );

    const prefixesOptions = prefixes?.length
        ? prefixes.map((p) => {
              return {
                  label: (
                      <span>
                          <SelectLanguageDropdownLabel>
                              <img src={p.flag} alt="" />
                              {p.code}
                          </SelectLanguageDropdownLabel>
                      </span>
                  ),
                  value: p.code,
                  valueForSearch: `${p.name} ${p.code}`
              };
          })
        : [];

    // if check call is pending, disabled2FA is undefined so display nothing
    if (disabled2FA === undefined) return null;

    return (
        <>
            {generalError && (
                <BackendErrorDialog
                    error={generalError}
                    customCallback={() => {
                        setGeneralError(null);
                    }}
                />
            )}
            {!phoneNumberVerified && !disabled2FA && render2FASetup()}
            {!phoneOnly && (phoneNumberVerified || disabled2FA) && renderPasswordSetup()}
        </>
    );
};

export default ActivateUserDialog;

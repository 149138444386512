import { createSlice } from '@reduxjs/toolkit';

export type SidebarState = {
    sidebarOpen: boolean;
    engageSidebarOpen: boolean;
};

export const initialState: SidebarState = {
    sidebarOpen: true,
    engageSidebarOpen: true
};

const slice = createSlice({
    name: 'sidebarOpen',
    initialState,
    reducers: {
        toggleSidebar: (state) => {
            state.sidebarOpen = !state.sidebarOpen;
        },
        toggleEngageSidebar: (state) => {
            state.engageSidebarOpen = !state.engageSidebarOpen;
        },

        closeSideBar: (state) => {
            state.sidebarOpen = false;
        },
        openSideBar: (state) => {
            state.sidebarOpen = true;
        }
    }
});

export const { toggleSidebar, closeSideBar, openSideBar, toggleEngageSidebar } = slice.actions;
export default slice.reducer;

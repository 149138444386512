export type CC3BaseObject = {
    _id: string;
    name: string;
    tenantId: string;
    projectId: string;
    lastModified: number;
    placed?: { name: string; _id: string; type: PlacedObjectType }[];
    modifiedBy: string;
    modifiedByUser?: { name: string; icon?: string };

    locked?: string;
    adminLocked?: boolean;
    lockedByUser?: { name: string; icon?: string };
    objectType: ObjectType;
};

// expand with values that are needed in the future
export const ObjectTypes = {
    PAGES: 'pages',
    MODULES: 'modules',
    ITEMS: 'items',
    MENUS: 'menus',
    LANGUAGES: 'languages',
    AUDIENCES: 'audiences',
    CONDITIONS: 'conditions',
    SETTINGS: 'settings',
    PAGE_STYLES: 'pageStyles',
    DYNAMIC_SOURCES: 'dynamicSources',
    TARGET_GROUPS: 'targetGroups'
} as const;

export const PlacedObjectTypes = {
    ...ObjectTypes,
    TARGET_GROUPS: 'targetGroups'
} as const;

export type ObjectActivity = {
    _id: string;
    objectId: string;
    createdAt: number;
    createdBy: string;
    objectType: string;
    objectName: string;
    updatedKeys?: string[];
    type: ObjectActivityType;
    createdByUser: { name: string; icon?: string };
};

export interface ActivityTreeNode extends ObjectActivity {
    isRoot?: boolean;
    children?: ActivityTreeNode[];
}

export const ObjectActivityTypes = {
    CREATED: 'created',
    UPDATED: 'updated',
    PUBLISHED: 'published'
} as const;

export type parsedObjectActivities = {
    [key: string]: { [key in ObjectActivityType]?: ActivityTreeNode[] };
};

export type ObjectActivityType = typeof ObjectActivityTypes[keyof typeof ObjectActivityTypes];

export type ObjectType = typeof ObjectTypes[keyof typeof ObjectTypes];

export type PlacedObjectType = typeof PlacedObjectTypes[keyof typeof PlacedObjectTypes];

export type FailedDeletionMessages = {
    objectId: string;
    objectName: string;
    objectType: ObjectType;
    message: string;
}[];

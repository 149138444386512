import React from 'react';
import SVGInline from 'react-inlinesvg';
import TableRow from '@material-ui/core/TableRow';
import { Tooltip } from '@material-ui/core';

import {
    CheckboxTableCellWrapper,
    CloseSelectWrapper,
    DeleteIconWrapper,
    DraggableTableCell,
    HeaderSortIcon,
    ImageCell,
    RoundImageContainer,
    SelectAllText,
    SelectAllWrapper,
    SortableTableHeaderCell,
    SquareImageContainer,
    Table,
    TableContainer,
    TableHeader as TableHead,
    TableHeaderCell,
    WidthTableCell
} from './Table.css';
import icons from '../../../style';
import { StyledSVGInline, TruncatedText } from '../../../style/styled-components/reusable.css';
import { useDrag, useDrop } from 'react-dnd';
import { CSSProperties } from 'styled-components';
import { renderTooltip, renderTooltipWithKey, tooltipPositions, tooltipTypes } from '../Tooltips/Tooltips';
import { PUBLISHED_STATUS } from '../../../utils/fnPublish';
import { DialogCheckbox } from '../Dialog/GenericDialog';
import { ObjectActions } from '../Actions/Actions';
import getTheme from '../../../style/themes/theme';
import { withScroll } from '../../../HOCs/ScrollableWrapper/ScrollableWrapper';
import { EncodeStatus } from '../../../utils/fnEncode';

const theme: any = getTheme();

export enum tableActions {
    COPY = 0,
    EDIT = 1,
    REMOVE,
    DUPLICATE,
    CUSTOMIZE,
    TOKEN,
    DOWNLOAD,
    ENABLE,
    MULTI_SELECT,
    PURGE,
    INFO,
    ADMIN_LOCK
}

interface ITableProps {
    columns: JSX.Element[];
    body: JSX.Element;
    sortableColumns?: string[];
    onSort?: any;
    ref?: any;
    onMouseLeave?: any;
    dataCy?: string;
}

interface IDraggableTr {
    id: string;
    type: string;
    moveItem: (id: string, to: number) => void;
    findItem: (id: string) => { index: number };
    children?: React.ReactNode;
    dataCy?: string;
    dragDisabled?: boolean;
}

export function ImageTableCell(props: {
    shape: 'round' | 'square';
    src: string;
    width?: number;
    um?: '%' | 'vw' | 'px';
    toolTipName?: string;
    imageSize?: { width?: number; height?: number; um?: '%' | 'vw' | 'px' };
}) {
    const { shape, src, width, um, toolTipName, imageSize } = props;
    const imageWidth = imageSize?.width;
    const imageHeight = imageSize?.height;
    const imageUm = imageSize?.um;
    return (
        <ImageCell $width={width} $um={um}>
            {shape === 'round' ? (
                toolTipName ? (
                    <Tooltip title={toolTipName} placement="right-start">
                        <RoundImageContainer $src={src} $height={imageHeight} $width={imageWidth} $um={imageUm} />
                    </Tooltip>
                ) : (
                    <RoundImageContainer $src={src} $height={imageHeight} $width={imageWidth} $um={imageUm} />
                )
            ) : toolTipName ? (
                <Tooltip title={toolTipName} placement="right-start">
                    <SquareImageContainer $src={src} $height={imageHeight} $width={imageWidth} $um={imageUm} />
                </Tooltip>
            ) : (
                <SquareImageContainer $src={src} $height={imageHeight} $width={imageWidth} $um={imageUm} />
            )}
        </ImageCell>
    );
}

export function ActionsTableCell(props: {
    actions: tableActions[];
    canCopy?: boolean;
    onCopy?: any;
    onEdit?: any;
    onRemove?: any;
    onDuplicate?: any;
    onCustomize?: any;
    onToken?: any;
    onDownload?: any;
    onEnable?: any;
    onMultiSelect?: any;
    onEncode?: any;
    onAdminLock?: any;
    tooltipTexts?: {
        copy?: string;
        edit?: string;
        customize?: string;
        token?: string;
        delete?: string;
        duplicate?: string;
        download?: string;
        enable?: string;
        published?: string;
        encode?: string;
        adminLock?: string;
    };
    encodeStatus?: EncodeStatus;
    publishedStatus?: PUBLISHED_STATUS;
    publishAt?: number;
    selected?: boolean;
    // needed until we bring back duplicate functionality because project duplication should still exist
    showDuplicateAnyway?: boolean;
    adminLocked?: boolean;
}) {
    return <ObjectActions {...props} />;
}

export function SelectAllTableCell(props: {
    onSelectAll: (arg: boolean) => void;
    onDelete: () => void;
    setIsMultiSelectVisible: (arg: boolean) => void;
    isMultiSelectVisible: boolean;
    allValuesSelected: boolean;
    isDeleteDisabled?: boolean;
    withoutDeleteIcon?: boolean;
    style?: CSSProperties;
    customText?: string;
}) {
    const {
        onSelectAll,
        onDelete,
        setIsMultiSelectVisible,
        style,
        isMultiSelectVisible,
        allValuesSelected,
        isDeleteDisabled,
        withoutDeleteIcon
    } = props;

    const handleDeleteClick = () => {
        if (isDeleteDisabled) return;

        onDelete();
    };

    const showCloseIcon = !isDeleteDisabled && isMultiSelectVisible;

    return (
        <div style={style}>
            <CheckboxTableCellWrapper>
                <SelectAllText onClick={() => setIsMultiSelectVisible(!isMultiSelectVisible)}>Select All</SelectAllText>
                <SelectAllWrapper withCloseIcon={showCloseIcon}>
                    <DialogCheckbox
                        text=""
                        value={allValuesSelected}
                        active={allValuesSelected}
                        onClick={() => {
                            isMultiSelectVisible ? onSelectAll(!allValuesSelected) : setIsMultiSelectVisible(true);
                        }}
                        reversed
                        tooltipText={allValuesSelected ? 'multi_select_object_unselect_all' : 'multi_select_object_select_all'}
                        tooltipPosition={tooltipPositions.BOTTOM}
                    />
                    {!withoutDeleteIcon && (
                        <DeleteIconWrapper disabled={isDeleteDisabled} onClick={handleDeleteClick}>
                            {renderTooltipWithKey(
                                <SVGInline src={icons.trashLightIcon} />,
                                'multi_select_object_delete',
                                tooltipPositions.BOTTOM
                            )}
                        </DeleteIconWrapper>
                    )}
                    {showCloseIcon && (
                        <CloseSelectWrapper onClick={() => setIsMultiSelectVisible(false)}>
                            {renderTooltipWithKey(
                                <SVGInline src={icons.closeIcon} />,
                                'multi_select_object_close',
                                tooltipPositions.BOTTOM
                            )}
                        </CloseSelectWrapper>
                    )}
                </SelectAllWrapper>
            </CheckboxTableCellWrapper>
        </div>
    );
}

export function SortableHeaderTableCell(props: {
    text: string;
    direction?: 'asc' | 'desc';
    onClick: any;
    hideArrow?: boolean;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    style?: CSSProperties;
    tooltipText?: string;
    columnSize?: any;
}) {
    const { text, direction, onClick, hideArrow, onMouseEnter, onMouseLeave, style, tooltipText, columnSize } = props;
    const icon = direction === 'asc' ? icons.arrowDownIcon : icons.arrowUpIcon;

    const handleClick = (evt: any) => {
        evt.preventDefault();
        if (onClick) {
            onClick();
        }
    };
    const tooltipedSortArrowSVG = renderTooltipWithKey(<SVGInline src={icon} />, `${tooltipText || 'generic_icon_sort'}`);
    return (
        <WidthTableCell onClick={handleClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} style={style} {...columnSize}>
            <SortableTableHeaderCell>
                <TruncatedText>
                    {renderTooltip(<>{text}</>, tooltipTypes.TEXT, text, tooltipPositions.TOP, false, false, false)}
                </TruncatedText>

                <HeaderSortIcon>{!hideArrow && tooltipedSortArrowSVG}</HeaderSortIcon>
            </SortableTableHeaderCell>
        </WidthTableCell>
    );
}

export function HeaderTableCell(props: { text: string; tooltipText?: string; columnSize?: any }) {
    const { text, columnSize } = props;

    return (
        <WidthTableCell {...columnSize}>
            <TableHeaderCell>
                <TruncatedText>
                    {renderTooltip(<>{text}</>, tooltipTypes.HTML, text, tooltipPositions.TOP, false, false, false)}
                </TruncatedText>
            </TableHeaderCell>
        </WidthTableCell>
    );
}

export const DraggableTableRow: React.FC<IDraggableTr> = ({ id, moveItem, findItem, type, children, dataCy, dragDisabled }) => {
    const originalIndex = findItem(id).index;
    const [{ isDragging }, drag] = useDrag(
        () => ({
            type,
            item: { id, originalIndex },
            collect: (monitor) => ({
                isDragging: monitor.isDragging()
            })
        }),
        [id, originalIndex, moveItem]
    );
    const [{ isHovering }, drop] = useDrop(
        () => ({
            accept: type,
            drop: (draggedItem: { id: string }) => {
                if (draggedItem.id !== id) {
                    moveItem(draggedItem.id, originalIndex);
                }
            },
            collect: (monitor) => ({
                isHovering: monitor?.isOver() && monitor?.canDrop()
            })
        }),
        [findItem, moveItem]
    );
    const opacity = isDragging ? 0.7 : 1;
    return (
        <TableRow
            ref={(node) => drag(drop(node))}
            style={{ opacity, transition: 'linear 100ms', backgroundColor: isHovering ? theme.palette.background.menuItemHover : '' }}
            data-cy={dataCy}
        >
            <DraggableTableCell $disabled={dragDisabled}>
                {renderTooltipWithKey(
                    <StyledSVGInline src={icons.dragIcon} />,
                    dragDisabled ? 'pages_edit_icon_drag_and_drop_disabled' : 'generic_icon_drag_drop'
                )}
            </DraggableTableCell>
            {children}
        </TableRow>
    );
};

export const ScrollableTableRow = withScroll(TableRow);

const GenericTable: React.FC<ITableProps> = ({ columns, body, onMouseLeave, dataCy }) => {
    const handleMouseLeave = (evt: any) => {
        evt.preventDefault();
        if (onMouseLeave) {
            onMouseLeave();
        }
    };

    return (
        <TableContainer onMouseLeave={handleMouseLeave} data-cy={dataCy}>
            <Table>
                <TableHead>
                    <TableRow>{columns}</TableRow>
                </TableHead>
                {body}
            </Table>
        </TableContainer>
    );
};

export default GenericTable;
